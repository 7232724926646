import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { Button, ButtonProps } from '.';
import { Check } from 'lucide-react';

export default function AsyncButton(
  props: PropsWithChildren<ButtonProps> & { afterSuccess?: () => void }
): ReactElement {
  const timeoutRef = React.useRef<number>();
  const [state, setState] = useState<'EMPTY' | 'PENDING' | 'SUCCESS'>('EMPTY');

  function delay(count: number) {
    return new Promise((res) => {
      timeoutRef.current = window.setTimeout(res, count);
    });
  }

  return (
    <Button
      {...props}
      onClick={async (event) => {
        window.clearTimeout(timeoutRef.current);
        setState('PENDING');
        try {
          await props.onClick?.(event);

          // Have a tiny minimum delay so it doesn't feel too agressive
          await delay(400);
          setState('SUCCESS');
        } finally {
          // reset to the original icon after a time
          await delay(2000);
          props.afterSuccess?.();
          setState('EMPTY');
        }
      }}
      loading={state === 'PENDING'}
      startIcon={state === 'SUCCESS' ? <Check size="1rem" /> : props.startIcon}
    />
  );
}
