import {
  Layers as TemplateIcon,
  ThumbsUp as ConfirmationIcon,
  GitFork as ConditionIcon,
  ListTodo,
  Blocks,
  Sparkle,
  Mails,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { useReactFlow } from '@xyflow/react';

import { selectAiOutputLanguage } from '../../redux/selectors';
import AddNodeMenuItem from './AddNodeMenuItem';
import { AddNodeFn, outputNodeTypes, useNodeTypeToName } from './nodes';
import React from 'react';
import { useIntl } from 'react-intl';

const usePrefillData = (source: string) => {
  const rf = useReactFlow();
  const edges = rf.getEdges();

  const firstUsableNode = (function findParent(source: string) {
    // Find the parent node, return early if nothing is found
    const node = rf.getNode(source);
    if (!node) return null;

    // return early if this node has an output
    if (outputNodeTypes.has(node.type ?? '')) return node;

    // keep looking
    const sourceEdge = edges.find((e) => e.target === source);
    if (!sourceEdge) return null;
    return findParent(sourceEdge?.source);
  })(source);

  return firstUsableNode ? `{{ ${firstUsableNode.id}.output }}` : '';
};

type AddNodeMenuListProps = {
  source: string;
  options?: { addAsBranch: boolean };
  onAddNode: AddNodeFn;
};
const AddNodeMenuList = ({
  source,
  options,
  onAddNode,
}: AddNodeMenuListProps) => {
  const aiOutputLanguage = useSelector(selectAiOutputLanguage);
  const prefillData = usePrefillData(source);
  const nodeTypeToName = useNodeTypeToName();
  const intl = useIntl();

  const handleMenuItemClicked =
    (...args: Parameters<AddNodeFn>) =>
    () => {
      onAddNode(...args);
    };

  return (
    <>
      <AddNodeMenuItem
        icon={<ListTodo />}
        label={nodeTypeToName.SaveToSpace}
        onSelect={handleMenuItemClicked('SaveToSpace', null, options)}
      />
      <AddNodeMenuItem
        icon={<ListTodo />}
        label={nodeTypeToName.AddLabels}
        onSelect={handleMenuItemClicked('AddLabels', null, options)}
      />
      <AddNodeMenuItem
        icon={<Sparkle />}
        label={nodeTypeToName.RunMeetingKit}
        onSelect={handleMenuItemClicked(
          'RunMeetingKit',
          { aiOutputLanguage },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<Sparkle />}
        label={nodeTypeToName.CustomPrompt}
        onSelect={handleMenuItemClicked(
          'CustomPrompt',
          {
            saveToMeeting: true,
            prompt: prefillData,
            aiOutputLanguage,
            includeTranscriptContext: true,
          },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<Blocks />}
        label={nodeTypeToName.SendData}
        onSelect={handleMenuItemClicked(
          'SendData',
          { parameterData: {} },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<ConfirmationIcon />}
        label={nodeTypeToName.Confirmation}
        onSelect={handleMenuItemClicked(
          'Confirmation',
          { prompt: prefillData },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<ConditionIcon />}
        label={nodeTypeToName.Condition}
        onSelect={handleMenuItemClicked(
          'Condition',
          {
            condition: prefillData,
          },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<TemplateIcon />}
        label={nodeTypeToName.Template}
        onSelect={handleMenuItemClicked(
          'Template',
          {
            saveToMeeting: false,
            template: prefillData,
            title: '',
          },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<Mails />}
        label={nodeTypeToName.SendEmailParticipants}
        onSelect={handleMenuItemClicked(
          'SendEmailParticipants',
          {
            subjectTemplate: '{{ meeting.title }}',
            bodyTemplate: intl.formatMessage(
              {
                defaultMessage:
                  'Hello,\n\nWe had a meeting {url}.\n\nBest regards,\n{sender}',
              },
              {
                prefill: prefillData ? `\n\n${prefillData}` : '',
                url: '{{ meeting.url }}',
                sender: '{{ user.name }}',
              }
            ),
            shareWithParticipants: true,
            recipients: [],
          },
          options
        )}
      />
      <AddNodeMenuItem
        icon={<Mails />}
        label={nodeTypeToName.SendEmailTeam}
        onSelect={handleMenuItemClicked(
          'SendEmailTeam',
          {
            subjectTemplate: '{{ meeting.title }}',
            bodyTemplate: intl.formatMessage(
              {
                defaultMessage:
                  'Hello,\n\nWe had a meeting {url}.\n\nBest regards,\n{sender}',
              },
              {
                prefill: prefillData ? `\n\n${prefillData}` : '',
                url: '{{ meeting.url }}',
                sender: '{{ user.name }}',
              }
            ),
            shareWithParticipants: false,
            recipients: [],
          },
          options
        )}
      />
    </>
  );
};

export default AddNodeMenuList;
