import {
  Archive,
  CakeSlice,
  GitCompare,
  HashIcon,
  MenuIcon,
  PieChart,
  Settings2,
  Sparkles,
  X,
} from 'lucide-react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import { useFeatureFlags } from '../../../helpers/feature-flags';
import {
  kBilling,
  kIntegrations,
  kInvite,
  kMeetingKits,
  kReportingOwnMeetings,
  kSettingsGeneral,
  kSharedWithMe,
  kTeam,
  kTranscripts,
  kTranscriptsArchive,
  workflowList,
} from '../../../helpers/routes';
import { cx, handleScrollLock } from '../../../helpers/utils';
import {
  selectIsProUser,
  selectIsUserPaidByTeam,
  selectTeam,
  selectUserSettings,
} from '../../../redux/selectors';
import { RootState } from '../../../redux/store';
import { MeetingsOrCreditsRemaining } from '../../Credits/MeetingsOrCreditsRemaining';
import { Banners } from '../Banners';
import { InstallExtensionBannerIcons } from '../Banners/install-extension-banner/InstallExtensionBanner';
import { Logo, UserPictureDropDown } from '../HeadImages';
import { PaymentPastDueAlert } from '../PaymentPastDueAlert';
import { PromotionNavButton } from '../PromoComponent';
import PullRequestButton from '../PullRequestButton';
import RateUsModal from '../rate-modal';
import { SubscriptionAboutToCancelAlert } from '../SubscriptionAboutToCancelAlert';
import { NavItemWithSubItems, NavMenuItem } from './NavMenuItem';
import { SpacesList } from './Spaces';
import { TeamMenu } from './TeamMenu';
import { PageTitle } from '../PageTitle';

const NavigationMenuClasses =
  'flex-col gap-y-0.5 content-center bg-transparent cursor-pointer [&>svg]:w-6';

const Navigation: React.FC<{
  pageName: string;
  isMobileMenuVisible: boolean;
}> = ({ pageName, isMobileMenuVisible }) => {
  const featureFlagService = useFeatureFlags();
  const intl = useIntl();
  const isPaidUser = useSelector(selectIsProUser);
  const isPaidTeamSeat = useSelector(selectIsUserPaidByTeam);

  return (
    <div
      className={cx(
        NavigationMenuClasses,
        isMobileMenuVisible ? 'flex' : 'hidden md:flex'
      )}
    >
      <NavMenuItem
        href={kTranscripts}
        icon={HashIcon}
        label={intl.formatMessage({
          defaultMessage: 'My Meetings',
          description: 'Menu bar text.',
        })}
        currentPage={pageName}
      />
      <NavMenuItem
        href={kSharedWithMe}
        icon={HashIcon}
        label={intl.formatMessage({
          defaultMessage: 'Shared With Me',
          description: 'Menu bar text.',
        })}
        currentPage={pageName}
      />
      <NavMenuItem
        href={kMeetingKits}
        label={intl.formatMessage({
          defaultMessage: 'AI Tools',
        })}
        icon={Sparkles}
        currentPage={pageName}
      />
      <NavItemWithSubItems
        icon={Settings2}
        label={intl.formatMessage({
          defaultMessage: 'Account & Settings',
          description: 'Menu bar text.',
        })}
        currentPage={pageName}
        subItems={[
          {
            label: intl.formatMessage({
              defaultMessage: 'Settings',
            }),
            href: kSettingsGeneral,
          },
          {
            href: kBilling,
            label: intl.formatMessage({
              defaultMessage: 'Billing',
              description: 'Menu bar text.',
            }),
          },
          {
            href: kTeam,
            label: intl.formatMessage({
              defaultMessage: 'Team',
              description: 'Menu bar text.',
            }),
          },
          {
            href: kIntegrations,
            label: intl.formatMessage({
              defaultMessage: 'Integrations',
              description: 'Menu bar text.',
            }),
          },
        ]}
      />

      <NavMenuItem
        href={kTranscriptsArchive}
        label={intl.formatMessage({
          defaultMessage: 'Archive',
          description: 'Menu bar text.',
        })}
        icon={Archive}
        currentPage={pageName}
      />
      {featureFlagService.isReportingEnabled() ? (
        <NavMenuItem
          href={kReportingOwnMeetings}
          label={intl.formatMessage({
            defaultMessage: 'Reporting',
          })}
          icon={PieChart}
          currentPage={pageName}
        />
      ) : null}
      {!isPaidTeamSeat ? (
        <NavMenuItem
          href={kInvite}
          label={
            isPaidUser
              ? intl.formatMessage({
                  defaultMessage: 'Get free AI credits',
                  description: 'Menu bar text.',
                })
              : intl.formatMessage({
                  defaultMessage: 'Get free meetings',
                  description: 'Menu bar text.',
                })
          }
          icon={CakeSlice}
          currentPage={pageName}
        />
      ) : null}
      <NavMenuItem
        href={workflowList}
        label={intl.formatMessage({
          defaultMessage: 'My Workflows',
        })}
        icon={GitCompare}
        currentPage={pageName}
        isNew={true}
      />
      <SpacesList currentPage={pageName} />
      <PromotionNavButton />
      {/* Promotion Navigation Button */}
      <RateUsModal />
    </div>
  );
};

const NavBarClasses =
  'flex max-w-full w-full h-auto md:h-full fixed z-20 md:z-auto bg-white left-0 md:w-64 border-b md:gap-0 border-neutral-muted md:border-r overflow-x-hidden overflow-y-auto flex-col';

const NavBarNewWrapClasses =
  'flex grow flex-col gap-y-4 overflow-y-auto bg-transparent px-6';

const NavbarLogoClasses =
  'flex items-center justify-between px-8 md:max-h-16 md:justify-start py-2 md:py-3 mb-2 md:mb-1.5 ';

const NavbarToggleClasses =
  'flex justify-center rounded-lg w-10 h-10 items-center text-2xl hover:border md:hidden';

export const PageContainerClasses =
  'flex flex-col pl-0 md:pl-64 md:bg-white md:h-screen md:box-border';

/**
 * Page
 */
export const Page: React.FC<
  PropsWithChildren<{
    title: string;
    /** Allow the child components to take up the full height of the page */
    grow?: boolean;
    /** Allow the child components control the scroll overlfow */
    overflow?: boolean;
    maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
    hidePaymentAlert?: boolean;
    bgRaised?: boolean;
  }>
> = ({
  title,
  grow,
  overflow,
  children,
  bgRaised,
  maxWidth = 'md',
  hidePaymentAlert = false,
}) => {
  const team = useSelector(selectTeam);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const requestedDeletion = useSelector(selectUserSettings).requestedDeletion;
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );

  useEffect(() => {
    handleScrollLock('unlock');
  }, []);

  return (
    <>
      <PageTitle title={title} />
      <div className={PageContainerClasses}>
        <nav
          className={cx(NavBarClasses, requestedDeletion ? 'pb-[74px]' : '')}
        >
          <div className={NavbarLogoClasses}>
            <Link className="font-3xl md:flex-none md:px-0" to="/">
              <Logo />
            </Link>
            <PullRequestButton />
            <button
              className={NavbarToggleClasses}
              onClick={() => {
                setIsMobileMenuVisible(!isMobileMenuVisible);
                handleScrollLock(!isMobileMenuVisible ? 'lock' : 'unlock');
              }}
            >
              {!isMobileMenuVisible ? (
                <MenuIcon className="h-7 w-7" />
              ) : (
                <X className="h-7 w-7" />
              )}
            </button>
          </div>
          <div className={NavBarNewWrapClasses}>
            {team ? (
              <TeamMenu
                team={team}
                pageName={title}
                isMobileMenuVisible={isMobileMenuVisible}
              />
            ) : null}
            <Navigation
              pageName={title}
              isMobileMenuVisible={isMobileMenuVisible}
            />
          </div>

          <div
            className={cx(
              'flex-col gap-1 md:pb-4',
              isMobileMenuVisible ? 'flex' : 'hidden md:flex'
            )}
          >
            {isExtensionUnavailable ? (
              <>
                <div className="flex flex-col items-start gap-y-1 border-neutral-xmuted border-t px-6 py-5 text-left md:flex-col md:gap-y-2 md:py-3">
                  <div className="flex flex-row items-start gap-x-4 gap-y-2 md:flex-col">
                    <div className="flex flex-row gap-1 font-medium text-neutral-default text-sm">
                      <Button
                        variant="text"
                        size="text"
                        href="https://tactiq.io/r/notinstalled"
                        onClick={() => {
                          trackWebEvent(
                            'Clicked Install Extension Banner link',
                            {
                              from: 'navigation',
                            }
                          );
                          return true;
                        }}
                      >
                        <FormattedMessage defaultMessage="Add to Chrome" />
                      </Button>
                      <FormattedMessage defaultMessage=" to use with" />
                    </div>
                    <InstallExtensionBannerIcons />
                  </div>
                </div>
                <MeetingsOrCreditsRemaining
                  isMobileNavOpen={isMobileMenuVisible}
                  mini
                />
              </>
            ) : (
              <MeetingsOrCreditsRemaining
                isMobileNavOpen={isMobileMenuVisible}
              />
            )}
            <div className="justify-start px-6 pt-1 pb-6 md:mt-2 md:pt-0 md:pb-0">
              <UserPictureDropDown />
            </div>
          </div>
        </nav>
        {/* This is the background for the mobile */}
        <div
          className={cx(
            'z-10 h-full w-full bg-neutral-emphasis opacity-70 md:hidden',
            isMobileMenuVisible ? 'fixed' : 'hidden'
          )}
          onClick={() => setIsMobileMenuVisible(false)}
        />
        {/* This is the background for the mobile */}

        <Banners title={title} />
        <div
          className={cx(
            'mx-auto flex min-h-screen w-full flex-col',
            maxWidth === 'full' ? '' : 'p-6 lg:p-12',
            grow ? 'flex-grow' : undefined,
            overflow ? 'overflow-auto' : undefined,
            bgRaised ? 'bg-neutral-xmuted' : 'bg-white',
            {
              // These are direct copies of the MUI breakpoints
              // as tailwind is much smaller.
              sm: 'max-w-[600px]',
              md: 'max-w-[900px]',
              lg: 'max-w-[1200px]',
              xl: 'max-w-[1536px]',
              full: '',
            }[maxWidth]
          )}
        >
          <div className="flex flex-col gap-4 [&:has(*)]:mb-4">
            {!hidePaymentAlert && <PaymentPastDueAlert />}
            <SubscriptionAboutToCancelAlert />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
