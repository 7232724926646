import React, { useCallback, useState } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { useNavigate } from 'react-router';
import { kTeam } from '../../helpers/routes';
import {
  Team,
  TeamDecreaseSeatCountDocument,
  TeamIncreaseSeatCountDocument,
  TeamPlanDetails,
} from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { AddTeamSeatsDialog } from '../Teams/Team/AddTeamSeatsDialog';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/buttons';
import { enqueueSnackbar } from 'notistack';
import { gotTeam } from '../../redux/modules/user';
import { useMutation } from '@apollo/client';
import { Alert } from '../../components/Alert';
import { ModalDialog } from '../../components/modals';
import { Select } from '../../components/Select';

/**
 * Change team seat dialog
 */
export const ChangeTeamSeatsDialog: React.FC<{
  teamPlanDetails: TeamPlanDetails;
  teamSizeModalOpen: boolean;
  onClose: () => void;
  completeAction: () => void;
  upgradeOnly?: boolean;
}> = ({
  teamPlanDetails,
  teamSizeModalOpen,
  onClose,
  completeAction,
  upgradeOnly,
}) => {
  const currentTeamSeatsTotal = teamPlanDetails.plan.quantity;
  const currentAllocatedSeats = teamPlanDetails.seatsAllocated;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTeamSeats, setSelectedTeamSeats] = React.useState<number>(
    currentTeamSeatsTotal
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const unallocatedSeats = currentTeamSeatsTotal - currentAllocatedSeats;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mustUnassignBeforeDowngrade = currentAllocatedSeats > selectedTeamSeats;
  const upgradingSeats = currentTeamSeatsTotal < selectedTeamSeats;
  const downgradingSeats = currentTeamSeatsTotal > selectedTeamSeats;

  const [teamIncreaseSeatCount] = useMutation(TeamIncreaseSeatCountDocument);
  const [teamDecreaseSeatCount] = useMutation(TeamDecreaseSeatCountDocument);

  const additionalTeamMembersQuantity =
    selectedTeamSeats - currentTeamSeatsTotal;

  const processAction = useCallback(
    async (action: () => Promise<Team | undefined>, onComplete: () => void) => {
      setIsLoading(true);
      await dispatch(gotTeam(await action()));
      setIsLoading(false);
      completeAction();
      onComplete();
      onClose();
    },
    [completeAction, dispatch, onClose]
  );

  const SelectSeatsArray = Array.from(new Array(100), (_e, index) => ({
    value: index + 1,
    label: String(index + 1),
  }));

  if (!teamSizeModalOpen) return null;

  let dialogConfirmAction = null;

  if (upgradingSeats) {
    dialogConfirmAction = (
      <Button
        loading={isLoading}
        onClick={async () => {
          trackWebEvent(
            'Change team seat dialog - Add seats to a team - Open Preview',
            {
              previous: currentTeamSeatsTotal,
              new: selectedTeamSeats,
              delta: additionalTeamMembersQuantity,
            }
          );

          setIsDialogOpen(true);
        }}
      >
        <FormattedMessage defaultMessage="Buy" />
      </Button>
    );
  } else if (downgradingSeats) {
    dialogConfirmAction = (
      <Button
        loading={isLoading}
        color="warning"
        onClick={async () => {
          trackWebEvent('Change team seat dialog - Remove unallocated seats', {
            previous: currentTeamSeatsTotal,
            new: selectedTeamSeats,
            delta: currentTeamSeatsTotal - selectedTeamSeats,
          });
          await processAction(
            async () =>
              (
                await teamDecreaseSeatCount({
                  variables: {
                    quantity: currentTeamSeatsTotal - selectedTeamSeats,
                  },
                })
              ).data?.team_billing_decreaseSeatCount,
            () =>
              enqueueSnackbar('You plan has been updated', {
                variant: 'SUCCESS',
              })
          );
        }}
      >
        <FormattedMessage
          defaultMessage="Remove {quantity} seats"
          values={{
            quantity: <b>{currentTeamSeatsTotal - selectedTeamSeats}</b>,
          }}
        />
      </Button>
    );
  }

  let dialogMessage = null;
  if (mustUnassignBeforeDowngrade) {
    // Selecting less seats than are unallocated
    dialogMessage = (
      <Alert
        severity="warning"
        action={
          <Button
            onClick={() => {
              trackWebEvent('Change team seat dialog - view team');
              navigate(kTeam);
            }}
          >
            <FormattedMessage defaultMessage="Go to team" />
          </Button>
        }
        description={
          <FormattedMessage defaultMessage="You need to downgrade or remove members from this team that currently hold seats." />
        }
      />
    );
  } else if (upgradingSeats && !mustUnassignBeforeDowngrade) {
    // Selecting more seats than the current plan
    dialogMessage = (
      <FormattedMessage
        defaultMessage="Add {seatQuantity} to your team"
        values={{
          seatQuantity: additionalTeamMembersQuantity,
        }}
      />
    );
  } else if (downgradingSeats && !mustUnassignBeforeDowngrade) {
    // Selecting less seats than the current plan
    dialogMessage = (
      <FormattedMessage
        defaultMessage="Remove {seatQuantity} from your team"
        values={{
          seatQuantity: currentTeamSeatsTotal - selectedTeamSeats,
        }}
      />
    );
  }

  return (
    <ModalDialog
      open={teamSizeModalOpen}
      onClose={() => {
        if (isLoading) return;
        onClose();
      }}
      title={
        <FormattedMessage
          defaultMessage="Update your team size"
          description="Team view. Add members dialog title"
        />
      }
      text={
        <div className="flex flex-col gap-2">
          {unallocatedSeats >= 1 ? (
            <FormattedMessage
              defaultMessage="You currently have {unallocatedAmount} unallocated {plural}"
              values={{
                unallocatedAmount: unallocatedSeats,
                plural: <FormattedPlural one="seat" other="seats" value={0} />,
              }}
            />
          ) : (
            <FormattedMessage defaultMessage="You're using all of your seats! Need to add some more?" />
          )}
          <div className="flex items-center justify-center gap-2">
            <FormattedMessage defaultMessage="Total seats" />
            <Select
              value={selectedTeamSeats}
              onChange={setSelectedTeamSeats}
              options={
                upgradeOnly
                  ? SelectSeatsArray.slice(currentTeamSeatsTotal - 1, 101)
                  : SelectSeatsArray
              }
            />
          </div>

          <div className="text-center">{dialogMessage}</div>
          <AddTeamSeatsDialog
            membersCount={additionalTeamMembersQuantity}
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onConfirm={async () => {
              await processAction(
                async () =>
                  (
                    await teamIncreaseSeatCount({
                      variables: {
                        quantity: additionalTeamMembersQuantity,
                      },
                    })
                  ).data?.team_billing_increaseSeatCount,
                () =>
                  enqueueSnackbar('You plan has been updated', {
                    variant: 'SUCCESS',
                  })
              );
            }}
          />
        </div>
      }
      actions={
        <div className="flex flex-row gap-2">
          {dialogConfirmAction}
          <Button variant="soft" onClick={onClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </div>
      }
    />
  );
};
