import React, { useState } from 'react';
import { Chip } from '../Chips';
import { TextInput } from '../TextInput';
import { tv } from 'tailwind-variants';

const DELIMETERS = [',', 'Enter', 'Tab'];

const chipInputClasses = tv({
  variants: {
    size: {
      default: 'gap-2 p-2',
      small: 'gap-1 p-1',
    },
  },
});

export const ChipInput = ({
  placeholder,
  value,
  size = 'default',
  onChange,
}: {
  placeholder?: string;
  value: string[];
  size?: keyof typeof chipInputClasses.variants.size;
  onChange: (value: string[]) => void;
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [items, setItems] = useState<Set<string>>(
    new Set(value.filter(Boolean).map((string) => string.trim()))
  );

  const addItem = (item: string) => {
    items.add(item);
    setItems(new Set(items));
    onChange(Array.from(items));
    setInputValue('');
  };

  const removeItem = (item: string) => {
    items.delete(item);
    setItems(new Set(items));
    onChange(Array.from(items));
  };

  return (
    <div
      className={chipInputClasses({
        size,
        className: 'flex flex-wrap items-center',
      })}
    >
      <ol className="flex flex-wrap items-center gap-2">
        {Array.from(items.entries()).map(([item]) => (
          <li key={item}>
            <Chip color={'slate'} onDelete={() => removeItem(item)}>
              {item}
            </Chip>
          </li>
        ))}
      </ol>
      <TextInput
        size={size}
        variant="naked"
        value={inputValue}
        type="text"
        key={items.size}
        placeholder={placeholder}
        autoFocus
        onKeyDown={(e) => {
          if (DELIMETERS.includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            addItem((e.target as HTMLInputElement).value);
          }
        }}
      />
    </div>
  );
};
