import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { LabelConfig } from '@tactiq/model';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { FuseResult } from 'fuse.js';
import {
  ListFilter,
  SearchIcon,
  Text as TextIcon,
  Loader,
  X,
  Link as LinkIcon,
  Mail,
  Share2,
  Archive,
} from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/buttons';
import {
  MeetingSearchResult,
  SearchMeetingsDocument,
  SearchMeetingsQueryVariables,
  SortBy,
  UserSpace,
} from '../../../../graphql/operations';
import { cx } from '../../../../helpers/utils';
import { selectTeam, selectUserSpaces } from '../../../../redux/selectors';
import { SearchBarFilters } from './SearchBarFilters';
import SearchBarNavigation, { Ref } from './SearchBarNavigation';
import {
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import {
  trackFiltersButtonClicked,
  trackSearchPopupOpen,
  trackTranscriptListSearched,
} from '../../../../helpers/analytics';
import { TextInput } from '../../../../components/TextInput';
import { useSearchQueryState } from '../../../../services/Search';
import { Link } from 'react-router-dom';
import { DeleteDialog } from '../../card/DeleteDialog';
import { AddToSpaceMenu } from '../../../Spaces/AddToSpaceMenu';
import { SpaceIcon } from '../../../Common/icons';
import { Tooltip } from '../../../../components/Tooltip';
import { useArchiveMeeting } from '../../actions/useArchiveMeeting';
import { useCopyMeetingLink } from '../../actions/useCopyMeetingLink';
import { useShareMeeting } from '../../actions/useShareMeeting';
import { useShareMeetingByEmail } from '../../actions/useShareMeetingByEmail';

interface Props<T = unknown> {
  query: string;
  placeholder?: string;
  labels?: LabelConfig[];
  selectedLabels?: LabelConfig[];
  matches?: FuseResult<T>[];
  onChange: (value: string) => void;
  onChangeLabels?: (labels: LabelConfig[]) => void;
  onMatchSelected?: (match: FuseResult<T>) => void;
  interceptNativeSearch?: boolean;
  loading?: boolean;
}

interface MeetingsSearchBarProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  value: SearchMeetingsQueryVariables['filter'];
  debouncedValue: SearchMeetingsQueryVariables['filter'];
  onChange: (value: SearchMeetingsQueryVariables['filter']) => void;
  onSelectMeeting?: (meeting: MeetingSearchResult) => void;
  sortBy: SearchMeetingsQueryVariables['sortBy'];
  setSortBy: (value: SearchMeetingsQueryVariables['sortBy']) => void;
  hits: MeetingSearchResult[];
  loading?: boolean;
  skipEmpty: boolean;
  refetch: () => void;
}

const EMPTY_MATCHES: FuseResult<unknown>[] = [];

const SearchResultsSkeleton = () => (
  <div className="relative flex animate-pulse flex-col gap-4 p-4">
    {Array.from({ length: 20 }).map((value, index) => (
      <SearchResultsSkeletonRow key={index} seed={index * 1.1} />
    ))}
    <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-br from-transparent to-white" />
  </div>
);

function prngWidthArray(seed: number) {
  const rotationSpeed = 10000;
  let position = seed;
  const nums = [];
  for (let i = 0; i < 8; i++) {
    nums.push(0.5 + 0.5 * Math.sin(position++ * rotationSpeed));
  }
  const numsSorted = nums.toSorted((a, b) => a - b);
  return numsSorted
    .map((num, index) => {
      if (index === 0) {
        return num;
      }
      return num - numsSorted[index - 1];
    })
    .filter((num) => num > 0.03)
    .map((num) => Math.ceil(num * 100));
}

const SearchResultsSkeletonRow = ({ seed }: { seed: number }) => {
  const widthsRow1 = prngWidthArray(seed).slice(0, 3);
  const widthsRow2 = prngWidthArray(seed + 11);

  return (
    <div className="flex w-full flex-row gap-4 px-4">
      <div className="h-10 w-10 rounded-lg bg-slate-200" />
      <div className="flex grow flex-col gap-2">
        <div className="flex h-4 grow flex-row gap-2">
          {widthsRow1.map((w, index) => (
            <div
              key={index}
              className="w-6 rounded-md bg-slate-300"
              style={{ width: `${w}%` }}
            />
          ))}
        </div>
        <div className="flex h-1 grow flex-row gap-2">
          <div className="w-[10%] rounded-md bg-slate-300" />
          <div className="w-1 rounded-md bg-slate-300" />
          <div className="w-[10%] rounded-md bg-slate-300" />
        </div>
        <div className="flex h-2 grow flex-row gap-2">
          {widthsRow2.map((w, index) => (
            <div
              key={index}
              className={cx(
                'w-6 rounded-md bg-slate-200',
                w < 10 ? 'bg-yellow-50' : ''
              )}
              style={{ width: `${w}%` }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const SearchRadarImage = () => (
  <div className="rounded-full bg-[radial-gradient(circle,rgb(194,203,255)_0%,rgb(255,255,255)_45%)]">
    <div className="rounded-full border-2 border-[rgba(194,203,255,0.2)] border-dashed p-2 lg:p-4">
      <div className="rounded-full border-2 border-[rgba(194,203,255,0.5)] border-dashed p-2 lg:p-4">
        <div className="rounded-full border-2 border-[rgb(194,203,255)] border-dashed p-2 lg:p-4">
          <div className="rounded-full bg-white p-2 lg:p-4">
            <SearchIcon className="h-4 w-4 rounded-full text-[#C2CBFF] lg:h-8 lg:w-8" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SearchResultsEmptyState = ({
  onClearClick,
}: {
  onClearClick: () => void;
}) => (
  <div className="flex grow flex-col items-center justify-center gap-4 pb-4 align-center">
    <SearchRadarImage />
    <div className="font-semibold text-lg">
      <FormattedMessage defaultMessage="No matching results found" />
    </div>
    <div className="text-slate-500 text-sm">
      <FormattedMessage defaultMessage="Try changing your search term" />
    </div>
    <Button onClick={onClearClick}>Clear search</Button>
  </div>
);

const HitDetails: React.FC<{ hit: MeetingSearchResult }> = ({ hit }) => {
  const SpacingDot = <span className="text-slate-400">•</span>;
  const spaces = useSelector(selectUserSpaces) ?? [];
  function isUserSpace(space: UserSpace | undefined): space is UserSpace {
    return space !== undefined;
  }
  const matchingSpaces: UserSpace[] = (hit.spaces ?? [])
    .map((spaceId) => spaces.find((s) => s.id === spaceId))
    .filter(isUserSpace);

  return (
    <span className="flex flex-row gap-x-2 overflow-hidden text-ellipsis text-slate-600 text-xs">
      <span>{format(hit.created, 'PPP')}</span>
      {SpacingDot}
      <span>{hit.owner}</span>
      {matchingSpaces?.map((space: UserSpace) => (
        <span className="flex flex-row gap-x-2" key={space.id}>
          {SpacingDot}
          {space.name}
        </span>
      ))}
    </span>
  );
};

const MAX_HIGHLIGHTS_PER_HIT = 5;

const SearchResultRow: React.FC<{
  hit: MeetingSearchResult;
  refetch: () => void;
  showActions: boolean;
}> = function ({ hit, refetch, showActions }) {
  const highlights = [
    ...(hit.searchHighlights?.aiOutputs ?? []),
    ...(hit.searchHighlights?.transcript ?? []),
    ...(hit.searchHighlights?.notes ?? []),
  ].slice(0, MAX_HIGHLIGHTS_PER_HIT);
  const [active, setActive] = useState(false);
  const titleClasses = 'h-7 text-sm font-semibold truncate';
  return (
    <div
      className="flex flex-row justify-start gap-4 p-1"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <TextIcon className="h-4 w-4 text-slate-500" />

      <div className="flex w-full cursor-pointer flex-col gap-2 overflow-hidden">
        <div className="flex justify-between overflow-hidden">
          {hit.searchHighlights?.title[0] ? (
            <div
              className={titleClasses}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(hit.searchHighlights.title[0], {
                  ALLOWED_TAGS: ['span'],
                }),
              }}
            />
          ) : (
            <div className={titleClasses}>{hit.title}</div>
          )}

          {active && showActions && (
            <SearchActions
              meetingId={hit.id}
              spaces={hit.spaces ?? []}
              onCompleted={refetch}
            />
          )}
        </div>
        <HitDetails hit={hit} />
        {highlights.length > 0
          ? highlights.map((highlight, index) => (
              <div
                key={index}
                className="border-slate-200 border-l-2 pl-2 text-slate-500 text-sm"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(highlight, {
                    ALLOWED_TAGS: ['span'],
                  }),
                }}
              />
            ))
          : null}
      </div>
    </div>
  );
};

const MeetingsSearchBar: React.FC<MeetingsSearchBarProps> = (props) => {
  const [showFilters, setShowFilters] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const {
    isOpen,
    setIsOpen,
    value,
    debouncedValue,
    onChange,
    sortBy,
    setSortBy,
    hits,
    loading,
    skipEmpty,
    refetch,
  } = props;

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
  });
  const listRef = useRef<HTMLAnchorElement[] | HTMLButtonElement[]>([]);

  const click = useClick(context, { toggle: false });
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    outsidePress: ({ target }) => {
      // Don't dismiss clicks that are outside the floating window but have the dont-close class
      if (target instanceof Element) return !target.closest('.dont-close');
      return true;
    },
  });
  const role = useRole(context);
  const focus = useFocus(context);

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, focus, dismiss, role, listNavigation]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(true);
    onChange?.({ ...value, query: e.target.value });
  };

  const handleFilterIconClick = () => {
    trackFiltersButtonClicked(!showFilters);
    setShowFilters((showFilters) => !showFilters);
  };

  const handleClearClick = () => {
    onChange?.({ query: '' });
  };

  const intl = useIntl();
  const searchInput = (
    <div>
      <div
        className={cx(
          'relative flex w-full flex-row justify-center gap-2 rounded-lg border border-slate-300/75 px-2 py-1.5 shadow-sm focus-within:ring-2 focus-within:ring-brand-400',
          isOpen ? 'z-ModalDialog' : ''
        )}
      >
        <SearchIcon className="h-5 w-5 self-center text-slate-500" />
        <input
          type="text"
          role="searchbox"
          placeholder={intl.formatMessage({
            defaultMessage:
              'Search for keywords, participants, labels, and more...',
            description: 'Search meeting lists field label',
          })}
          value={value.query}
          onChange={handleInputChange}
          className="w-full border-none p-0 focus-within:ring-transparent focus:outline-none focus:ring-transparent"
          ref={refs.setReference}
          {...getReferenceProps()}
        />
        {isOpen && (
          <div
            className="dont-close flex items-center gap-2"
            // don't lose focus from input when clicking these buttons
            // Without this the modal closes and reopens before the click events even fire.
            onMouseDown={(e) => e.preventDefault()}
          >
            {loading ? (
              <Loader className="h-4 w-4 self-center text-slate-400 motion-safe:animate-spin" />
            ) : (
              <X
                onClick={handleClearClick}
                className="h-4 w-4 cursor-pointer self-center text-slate-500"
              />
            )}
            <ListFilter
              onClick={handleFilterIconClick}
              className="h-4 w-4 cursor-pointer self-center text-slate-500"
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="relative">
      {isOpen && (
        <>
          <FloatingOverlay
            lockScroll
            className="z-Backdrop bg-slate-400 bg-opacity-60 transition-opacity"
          />
          <FloatingFocusManager
            context={context}
            order={['reference', 'content']}
          >
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              className={cx(
                '-left-4 -right-4 -top-4 absolute z-ModalDialog flex max-h-[80vh] flex-col overflow-hidden rounded-card bg-white pt-16 shadow-2xl',
                isEmptySearch(debouncedValue) ? '0px' : 'h-[80vh]'
              )}
            >
              {showFilters && (
                <div className="border-t px-4 py-4">
                  <SearchBarFilters
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    filters={value}
                    setFilters={onChange}
                  />
                </div>
              )}

              {skipEmpty && isEmptySearch(debouncedValue) ? (
                <></>
              ) : hits.length > 0 ? (
                <div className="flex min-h-20 flex-col divide-y divide-slate-100 overflow-y-scroll border-t">
                  {hits.map((hit, index) => {
                    const commonProps = {
                      className:
                        'p-3 text-left hover:bg-neutral-100 focus-visible:bg-neutral-100 focus-visible:outline-none',
                      tabIndex: activeIndex === index ? 0 : -1,
                    };
                    return props.onSelectMeeting ? (
                      <button
                        {...commonProps}
                        {...getItemProps({
                          onClick: () => props.onSelectMeeting?.(hit),
                        })}
                        key={hit.id}
                        ref={(node) => {
                          if (node) listRef.current[index] = node;
                        }}
                      >
                        <SearchResultRow
                          hit={hit}
                          showActions={false}
                          refetch={refetch}
                        />
                      </button>
                    ) : (
                      <Link
                        {...commonProps}
                        {...getItemProps()}
                        key={hit.id}
                        to={`/transcripts/${hit.id}`}
                        ref={(node) => {
                          if (node) listRef.current[index] = node;
                        }}
                      >
                        <SearchResultRow
                          hit={hit}
                          showActions
                          refetch={refetch}
                        />
                      </Link>
                    );
                  })}
                </div>
              ) : loading ? (
                <SearchResultsSkeleton />
              ) : (
                <SearchResultsEmptyState onClearClick={handleClearClick} />
              )}
            </div>
          </FloatingFocusManager>
        </>
      )}
      {searchInput}
    </div>
  );
};

const DEFAULT_SORT_BY = SortBy.RELEVANCE;

/**
 * Check if a set of filters is in a default empty state.
 *
 * if the query is empty and all the filters are either undefined or an empty array
 */
function isEmptySearch(
  filters: SearchMeetingsQueryVariables['filter']
): boolean {
  return Object.entries(filters).every(([, value]) => {
    if (Array.isArray(value)) return value.length === 0;
    return !value;
  });
}

export const SearchPopup: React.FC<{
  isOpen?: boolean;
  onClose?: () => void;
  query?: SearchMeetingsQueryVariables['filter'] | undefined;
  setQuery?: (next: SearchMeetingsQueryVariables['filter'] | undefined) => void;
  sortBy?: SearchMeetingsQueryVariables['sortBy'] | undefined;
  setSortBy?: (
    next: SearchMeetingsQueryVariables['sortBy'] | undefined
  ) => void;
  onSelectMeeting?: (meeting: MeetingSearchResult) => void;
  skipEmpty?: boolean;
}> = (props) => {
  // Create a state island when filter persists
  // even when the query string is removed
  const { skipEmpty = true } = props;
  const queryState = useSearchQueryState();
  const query = props.query ?? queryState[0];
  const setQuery = props.setQuery ?? queryState[1];
  const [filter, setFilterState] = useState(query ?? { query: '' });
  const [isOpen, setIsOpenState] = useState(Boolean(query ?? props.isOpen));

  // Make sure changes to the query string are propograted to the state island
  if (filter !== query && query) {
    setIsOpenState(true);
    setFilterState(query);
  }

  const setFilter = (filter: SearchMeetingsQueryVariables['filter']) => {
    setQuery(filter);
    setFilterState(filter);
  };

  // Remove query when the modal is closed
  const setIsOpen = (open: boolean) => {
    if (!open) setQuery(undefined);
    setIsOpenState(open);
  };

  useEffect(() => {
    if (isOpen) {
      trackSearchPopupOpen();
    }
  }, [isOpen]);

  const internalSortBy =
    useState<SearchMeetingsQueryVariables['sortBy']>(DEFAULT_SORT_BY);

  const sortBy = props.sortBy ?? internalSortBy[0];
  const setSortBy = props.setSortBy ?? internalSortBy[1];

  const [debouncedVariables, setDebouncedVariables] =
    useState<SearchMeetingsQueryVariables>({
      filter: {
        query: filter.query,
      },
      sortBy,
    });

  const { data, previousData, loading, refetch } = useQuery(
    SearchMeetingsDocument,
    {
      skip: skipEmpty && isEmptySearch(debouncedVariables.filter),
      variables: debouncedVariables,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        const { filter, sortBy } = debouncedVariables;
        trackTranscriptListSearched({
          filter: {
            created: !!filter.created,
            labels: (filter.labels ?? []).length,
            languages: (filter.languages ?? []).length,
            owners: (filter.owners ?? []).length,
            platforms: (filter.platforms ?? []).length,
            query: !!filter.query,
            spaces: (filter.spaces ?? []).length,
            speakers: (filter.speakers ?? []).length,
            tags: (filter.tags ?? []).length,
          },
          sortBy: sortBy ?? DEFAULT_SORT_BY,
        });
      },
    }
  );

  const hits =
    data?.searchMeetings.meetings ??
    previousData?.searchMeetings.meetings ??
    [];

  const SEARCH_DEBOUNCE_VALUE = 300;
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedVariables({
        filter: {
          created: filter.created ?? undefined,
          labels: filter.labels ?? [],
          languages: filter.languages ?? [],
          owners: filter.owners ?? [],
          platforms: filter.platforms ?? [],
          query: filter.query ?? undefined,
          spaces: filter.spaces ?? [],
          speakers: filter.speakers ?? [],
          tags: filter.tags ?? [],
        },
        sortBy: sortBy ?? DEFAULT_SORT_BY,
      });
    }, SEARCH_DEBOUNCE_VALUE);
    return () => {
      clearTimeout(handler);
    };
  }, [filter, sortBy]);

  return (
    <MeetingsSearchBar
      skipEmpty={skipEmpty}
      isOpen={isOpen}
      setIsOpen={(next) => {
        setIsOpen(next);
        if (!next) props.onClose?.();
      }}
      onChange={setFilter}
      value={filter}
      debouncedValue={debouncedVariables.filter}
      sortBy={sortBy}
      setSortBy={setSortBy}
      hits={hits}
      loading={loading}
      onSelectMeeting={props.onSelectMeeting}
      refetch={refetch}
    />
  );
};

const SearchBar: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    query,
    interceptNativeSearch = false,
    matches = EMPTY_MATCHES,
    onMatchSelected,
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const searchBarRef = useRef<Ref | undefined>();

  const onWindowKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
      event.preventDefault();
      inputRef.current?.focus();

      const yOffset = -40;
      const element = inputRef.current;

      if (!element) {
        return;
      }

      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === 'Enter') {
        searchBarRef.current?.onNextResult();
      }
    },
    [searchBarRef]
  );

  useEffect(() => {
    if (!interceptNativeSearch) {
      return;
    }

    document.addEventListener('keydown', onWindowKeyDown);

    return () => {
      document.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [interceptNativeSearch, onWindowKeyDown]);

  useEffect(() => {
    if (onMatchSelected && matches.length > 0) {
      onMatchSelected(matches[0]);
    }
    // ignore `matches` to make sure we don't trigger this effect on every transcript edit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, onMatchSelected]);

  return (
    <div className="relative">
      <TextInput
        type="search"
        placeholder={props.placeholder}
        value={query}
        onChange={props.onChange}
        onKeyDown={matches.length ? onKeyDown : undefined}
      />
      <div className="absolute top-0 right-0 flex items-center gap-2 px-2 py-1.5">
        {matches.length > 0 && onMatchSelected && (
          <SearchBarNavigation
            ref={searchBarRef}
            matches={matches}
            onMatchSelected={onMatchSelected}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

const SearchActions: React.FC<{
  meetingId: string;
  spaces: string[];
  onCompleted: () => void;
}> = (props) => {
  const { meetingId, spaces, onCompleted } = props;

  const iconClasses = 'size-4 text-slate-600';

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const team = useSelector(selectTeam);
  const copyMeetingLink = useCopyMeetingLink({
    meetingId,
    fullSize: false,
  });
  const shareMeeting = useShareMeeting({
    meetingId,
    fullSize: false,
    teamId: team?.id,
  });
  const shareMeetingByEmail = useShareMeetingByEmail({
    meetingId,
    fullSize: false,
    teamId: team?.id,
  });
  const meetingArchive = useArchiveMeeting({
    meetingIds: [meetingId],
    fullSize: false,
    onCompleted: () => {
      setDeleteDialogOpen(false);
      onCompleted?.();
    },
  });

  const actions = [
    {
      icon: <LinkIcon className={iconClasses} />,
      title: <FormattedMessage defaultMessage="Copy link" />,
      onClick: copyMeetingLink.trigger,
    },
    {
      icon: <Mail className={iconClasses} />,
      title: <FormattedMessage defaultMessage="Email" id="sy+pv5" />,
      onClick: shareMeetingByEmail.trigger,
    },
    {
      icon: <Share2 className={iconClasses} />,
      title: <FormattedMessage defaultMessage="Share" />,
      onClick: shareMeeting.trigger,
    },
    {
      icon: <Archive className={iconClasses} />,
      title: <FormattedMessage defaultMessage="Archive" id="hrgo+E" />,
      onClick: () => setDeleteDialogOpen(true),
    },
  ];

  return (
    <div onClick={(e) => e.preventDefault()} className="flex gap-2">
      <AddToSpaceMenu
        meetingId={meetingId}
        activeSpaces={spaces}
        onCompleted={onCompleted}
        trigger={
          <div>
            <Tooltip
              placement="top"
              title={<FormattedMessage defaultMessage="Add to space" />}
            >
              <Button variant="soft" size="tiny">
                <SpaceIcon className={iconClasses} />
              </Button>
            </Tooltip>
          </div>
        }
      />

      {actions.map((action, index) => (
        <Tooltip key={index} placement="top" title={action.title}>
          <Button onClick={action.onClick} variant="soft" size="tiny">
            {action.icon}
          </Button>
        </Tooltip>
      ))}

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => meetingArchive.trigger(false)}
        isArchived={false}
        loading={meetingArchive.loading}
      />
    </div>
  );
};

export default SearchBar;
