import { useMutation } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import { X } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Banner } from '../../../components/Banner';
import { Button } from '../../../components/buttons';
import { Tooltip } from '../../../components/Tooltip';
import { RequestUpgradeDocument, TeamTier } from '../../../graphql/operations';
import {
  trackUpgradeToPaidSeatBannerViewed,
  trackUpgradeToPaidSeatDismissed,
  trackUpgradeToPaidSeatRequestButtonClicked,
} from '../../../helpers/analytics';
import { useFeatureFlags } from '../../../helpers/feature-flags';
import {
  selectIsUserPaidByTeam,
  selectTeam,
  selectUser,
} from '../../../redux/selectors';

const useShouldShowUpgradeToPaidSeatBanner = () => {
  const user = useSelector(selectUser);
  const team = useSelector(selectTeam);
  const paidTeamSeat = useSelector(selectIsUserPaidByTeam);
  const featureFlagService = useFeatureFlags();

  const isAdmin =
    team?.members.some((m) => m.uid === user.id && m.roles.ADMIN) ?? false;
  const previouslyDismissedBanner = localStorage.getItem(
    'dismissedUpgradeToPaidSeatBanner'
  );

  const dismissalExpired = !previouslyDismissedBanner
    ? true
    : differenceInDays(new Date(), previouslyDismissedBanner) >= 30;

  const hasRequestedUpgrade =
    team?.members.some((m) => m.uid === user.id && m.requestedUpgrade) ?? false;

  const teamTier = team?.tier;

  const [renderBanner, setRenderBanner] = React.useState(
    team &&
      dismissalExpired &&
      !isAdmin &&
      !hasRequestedUpgrade &&
      !paidTeamSeat &&
      team?.tier !== TeamTier.FREE &&
      featureFlagService.getUpgradePaidSeatBanner()
  );

  function handleBannerDismissed() {
    trackUpgradeToPaidSeatDismissed(team?.id, user.id, user.tier);
    setRenderBanner(false);
  }

  function trackUpgradeRequest() {
    trackUpgradeToPaidSeatRequestButtonClicked(team?.id, user.id, user.tier);
  }

  return {
    renderBanner,
    handleBannerDismissed,
    teamTier,
    trackUpgradeRequest,
  };
};

export const UpgradeToPaidSeatBanner: React.FC = () => {
  const { renderBanner, handleBannerDismissed, teamTier, trackUpgradeRequest } =
    useShouldShowUpgradeToPaidSeatBanner();

  const [requestUpgrade, requestUpgradeMutation] = useMutation(
    RequestUpgradeDocument
  );

  function handleDismiss() {
    const dismissedAt = Date.now();
    localStorage.setItem(
      'dismissedUpgradeToPaidSeatBanner',
      dismissedAt.toString()
    );
    handleBannerDismissed();
  }

  async function handleRequestUpgrade() {
    trackUpgradeRequest();
    await requestUpgrade();
  }
  React.useEffect(() => {
    if (renderBanner) {
      trackUpgradeToPaidSeatBannerViewed();
    }
  }, [renderBanner]);

  if (!renderBanner) return null;

  return (
    <Banner>
      <div className="m-auto flex items-center gap-4">
        <div className="flex flex-col justify-start">
          <FormattedMessage
            defaultMessage="Your team is on {plural} {planType} plan."
            values={{
              plural:
                teamTier === TeamTier.ENTERPRISE ? (
                  <FormattedMessage defaultMessage="an" />
                ) : (
                  <FormattedMessage defaultMessage="a" />
                ),
              planType: (
                <b>
                  {teamTier === TeamTier.ENTERPRISE ? (
                    <FormattedMessage defaultMessage="Enterprise" />
                  ) : (
                    <FormattedMessage defaultMessage="Team" />
                  )}
                </b>
              ),
            }}
          />
          <FormattedMessage defaultMessage="To unlock unlimited AI credits and meeting transcripts, claim your paid seat now." />
        </div>
        <Button
          size="small"
          variant="neutral-primary"
          onClick={async () => handleRequestUpgrade()}
          loading={requestUpgradeMutation.loading}
        >
          <FormattedMessage defaultMessage="Claim the paid seat" />
        </Button>
      </div>
      <Tooltip title={<FormattedMessage defaultMessage="Dismiss" />}>
        <Button
          className="-ml-3"
          onClick={() => {
            handleDismiss();
          }}
          variant="neutral-tertiary"
        >
          <X className="size-5" />
        </Button>
      </Tooltip>
    </Banner>
  );
};
