import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Button } from '../../../../components/buttons';
import { Banner } from '../../../../components/Banner';
import {
  PermissionsGrantedStatus,
  gotArePermissionsGranted,
} from '../../../../redux/modules/global';
import { UpdateUserSettingDocument } from '../../../../graphql/operations';
import { useMutation } from '@apollo/client';
import { getArePermissionsGranted } from '../../../../graphql/user';
import { TactiqMessageType } from '@tactiq/model';
import { sendMessage } from '../../../../helpers/extension';
import {
  trackFinishOnboardingFixPermissions,
  trackFinishOnboardingFixZoomWebClient,
} from '../../../../helpers/analytics';

/**
 *
 * @returns {React.ReactNode} Onboarding banner
 */
export const FinishOnboardingBanner: React.FC = () => {
  const isExtensionAvailable = useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations.arePermissionsGranted
  );
  const zoomwcredirect = useSelector(
    (state: RootState) => state.user.settings?.zoomwcredirect
  );

  const dispatch = useDispatch();
  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  if (!isExtensionAvailable) {
    return null;
  }

  if (arePermissionsGranted === PermissionsGrantedStatus.UNKNOWN) {
    return null;
  }
  if (arePermissionsGranted === PermissionsGrantedStatus.MISSING) {
    return (
      <Banner>
        <div className="flex grow items-center justify-center gap-4 px-10">
          <p>
            <FormattedMessage defaultMessage="Tactiq needs additional permissions" />
          </p>
          <Button
            size="small"
            loading={updateUserSettingMutation.loading}
            onClick={async () => {
              trackFinishOnboardingFixPermissions();

              const result = await sendMessage<{ granted: boolean }>({
                type: TactiqMessageType.openOptionsPage,
                path: '/Permissions?return=/setup',
              });

              if (result?.granted) {
                await updateUserSetting({
                  variables: {
                    input: { zoomwcredirect: true },
                  },
                });
              }

              const granted = await getArePermissionsGranted(dispatch);
              dispatch(gotArePermissionsGranted(granted));
            }}
          >
            <FormattedMessage defaultMessage="Fix" />
          </Button>
        </div>
      </Banner>
    );
  } else if (
    arePermissionsGranted === PermissionsGrantedStatus.GRANTED &&
    !zoomwcredirect
  ) {
    return (
      <Banner>
        <div className="flex grow items-center justify-between px-10">
          <p>
            <FormattedMessage defaultMessage="Your Zoom connection needs to be updated" />
          </p>
          <Button
            size="small"
            loading={updateUserSettingMutation.loading}
            onClick={async () => {
              trackFinishOnboardingFixZoomWebClient();
              await updateUserSetting({
                variables: {
                  input: { zoomwcredirect: true },
                },
              });
            }}
          >
            <FormattedMessage defaultMessage="Fix" />
          </Button>
        </div>
      </Banner>
    );
  }

  // All is well and we have the extension, permissions, and zoomwcredirect
  return null;
};
