import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '../../components/Alert';
import { trackWebPage } from '../../helpers/analytics';
import { Page } from '../Common/Navigation';
import { MeetingList } from './list/MeetingList';
import { Archive } from 'lucide-react';
import { ListMeetingsDocument, MeetingType } from '../../graphql/operations';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { gotMeetings } from '../../redux/modules/global';
import { RootState } from '../../redux/store';
import { SearchPopup } from './common/SearchBar';
import featureFlagService from '../../helpers/feature-flags';
import { LegacyMeetingList } from './list/LegacyList';

const TranscriptsArchive: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    previousData,
    data = previousData,
    loading,
    fetchMore,
  } = useQuery(ListMeetingsDocument, {
    variables: {
      type: MeetingType.ARCHIVED,
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(newData) {
      dispatch(
        gotMeetings({
          type: MeetingType.ARCHIVED,
          meetings: newData.meetings.meetings,
        })
      );
    },
  });
  const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);

  const meetings = Object.values(
    useSelector((state: RootState) => state.global.meetings.archived)
  );

  useEffect(() => {
    trackWebPage('Archived transcripts');
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Archive',

        description: 'Menu bar text.',
      })}
      maxWidth={featureFlagService.isNewMeetingListsEnabled() ? 'full' : 'md'}
    >
      <div
        className={
          featureFlagService.isNewMeetingListsEnabled()
            ? 'flex flex-col items-center justify-between gap-2 px-8 pt-8 md:flex-row'
            : ''
        }
      >
        <h1 className="mb-4 text-3xl">
          <FormattedMessage
            defaultMessage="Archived transcripts"
            description="Archived transcripts page title."
          />
        </h1>
        <Alert
          severity="info"
          alternateIcon={<Archive />}
          description={
            <FormattedMessage
              defaultMessage="Archived transcripts are automatically deleted after 30 days."
              id="Mo+1j/"
            />
          }
        />
      </div>

      {featureFlagService.isNewMeetingListsEnabled() ? (
        <MeetingList
          meetings={meetings}
          loadMore={async () => {
            if (data?.meetings.hasMore) {
              setIsLoadingMore(true);
              const result = await fetchMore({
                variables: { offset: data.meetings.meetings.length },
              });

              dispatch(
                gotMeetings({
                  type: MeetingType.ARCHIVED,
                  meetings: [...meetings, ...result.data.meetings.meetings],
                })
              );
              setIsLoadingMore(false);
            }
          }}
          isLoading={loading && !meetings.length}
          isLoadingMore={isLoadingMore}
          searchBar={<SearchPopup />}
        />
      ) : (
        <LegacyMeetingList
          meetings={meetings}
          loadMore={async () => {
            if (data?.meetings.hasMore) {
              setIsLoadingMore(true);
              const result = await fetchMore({
                variables: { offset: data.meetings.meetings.length },
              });

              dispatch(
                gotMeetings({
                  type: MeetingType.ARCHIVED,
                  meetings: [...meetings, ...result.data.meetings.meetings],
                })
              );
              setIsLoadingMore(false);
            }
          }}
          isLoading={loading && !meetings.length}
          isLoadingMore={isLoadingMore}
          searchBar={<SearchPopup />}
        />
      )}
    </Page>
  );
};

export default TranscriptsArchive;
