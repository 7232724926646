import React from 'react';
import { FormattedMessage } from 'react-intl';
import icon from '../../../img/webex.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import { createOAuthConnector } from './connector';
import { useSelector } from 'react-redux';
import { selectUserIntegrationConnections } from '../../../redux/selectors';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';

const WebexStatus: React.FC = () => {
  const connection = useSelector(selectUserIntegrationConnections)?.find(
    (c) => c.type === 'webex'
  );
  if (connection) {
    return (
      <div>
        <div className="text-slate-500">
          <FormattedMessage
            defaultMessage="Capture Webex transcripts"
            description="Webex integration description"
          />
        </div>
        <Alert
          severity="success"
          description={
            <FormattedMessage defaultMessage="Tactiq is fully configured for this browser" />
          }
          action={
            <Button
              target="_blank"
              href="https://web.webex.com/meetings"
              color={'success'}
              onClick={() => {
                trackWebEvent('Integration Page Button - Try in Webex');
              }}
            >
              <FormattedMessage
                defaultMessage="Try in {brand}"
                values={{ brand: 'Webex' }}
              />
            </Button>
          }
        />
      </div>
    );
  } else {
    return (
      <div>
        <div className="text-slate-500">
          <FormattedMessage
            defaultMessage="Capture Webex transcripts"
            description="Webex integration description"
          />
        </div>
      </div>
    );
  }
};

export const integration: IntegrationImplementation = {
  order: 4,
  id: 'webex',
  category: IntegrationCategory.Meetings,
  title: 'Webex',
  icon,
  description: WebexStatus,
  connector: createOAuthConnector('webex', 'Webex'),
  allowMultipleConnections: true,
};

export default integration;
