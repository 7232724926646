import { useMutation, useQuery } from '@apollo/client';
import { logger } from '@tactiq/model';
import { formatDistance } from 'date-fns/formatDistance';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import {
  GetPersonalInvitesDocument,
  PersonalInvitation,
  PersonalInvitationAccepted,
  PersonalInvitationPending,
  Query,
  SendInvitationFromUserDocument,
} from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { isEmailValid } from '../../helpers/utils';
import UserAvatar from '../../img/user.svg';
import TactiqLogomark from '../../img/tacticlogoripple.svg';
import { RateUsModalSource, showRateUsModal } from '../../redux/modules/global';
import { selectIsProUser, selectTeam } from '../../redux/selectors';
import { InvitationLink, SettingAddButtonRowClasses } from './InvitationLink';
import { CheckIcon } from 'lucide-react';
import { Loading } from '../Landing/Loading/LoadingComponent';
import { Avatar } from '../../components/Avatar';
import { Chip } from '../../components/Chips';
import { Tabs } from '../../components/Tabs';

const TabContent: React.FC<{
  children?: React.ReactNode;
  id: string;
  value: string;
}> = ({ children, id, value }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`tab-content-${id}`}
      aria-labelledby={`tab-${id}`}
    >
      {value === id && (
        <div className="flex flex-col gap-3 p-3">{children}</div>
      )}
    </div>
  );
};

const AcceptedInviteComponent: React.FC<{
  invitations: PersonalInvitationAccepted[];
}> = ({ invitations }) => {
  if (!invitations.length) {
    return (
      <div className="flex content-center items-center">
        <div className="text-slate-500">
          <FormattedMessage defaultMessage="You don't have any accepted invitations...yet!" />
        </div>
      </div>
    );
  }

  return (
    <div className="gap-2">
      {invitations
        .sort((a, b) => b.acceptedAt - a.acceptedAt)
        .map((invitation, index) => (
          <div className="flex items-center justify-between gap-2" key={index}>
            <div className="flex items-center gap-2">
              <Avatar src={UserAvatar} name={invitation.name} />
              <div className="flex flex-col">
                <span>{invitation.name}</span>
                <span className="text-sm">{invitation.email}</span>
              </div>
              {invitation.fromLink && (
                <Chip color="green">
                  <FormattedMessage
                    defaultMessage="Joined from link!"
                    id="T7Zxd/"
                  />
                </Chip>
              )}
            </div>
            <div className="flex items-start gap-1">
              <CheckIcon className="h-4 w-4" />
              <div className="flex flex-col">
                <FormattedMessage
                  defaultMessage="Credit awarded for invite"
                  id="+PeY1F"
                />
                <span className="align-right text-xs">
                  <FormattedMessage
                    defaultMessage="{date} ago"
                    values={{
                      date: formatDistance(invitation.acceptedAt, Date.now()),
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        ))}

      <Alert
        severity="info"
        description={
          <FormattedMessage
            defaultMessage="Invitations sent before 20th May 2023 will not show in this list."
            id="6/nkHh"
          />
        }
      />
    </div>
  );
};

const PendingInviteComponent: React.FC<{
  invitations: PersonalInvitationPending[];
}> = ({ invitations }) => {
  if (!invitations.length) {
    return (
      <div className="flex flex-col content-center items-center">
        <div className="text-slate-500">
          <FormattedMessage defaultMessage="You haven't invited anyone to try out Tactiq." />
        </div>
        <div className="text-slate-500">
          <FormattedMessage defaultMessage="Invite someone to get your free meetings when they accept!" />
        </div>
      </div>
    );
  }

  return (
    <div className="gap-2">
      {invitations
        .sort((a, b) => b.sentAt - a.sentAt)
        .map((invitation, index) => (
          <div
            className="flex w-md content-between items-center gap-8"
            key={index}
          >
            <Avatar src={UserAvatar} name={invitation.email} />
            <div>{invitation.email}</div>
            <FormattedMessage
              defaultMessage="Invited {date} ago"
              values={{
                date: formatDistance(invitation.sentAt, Date.now()),
              }}
            />
          </div>
        ))}
    </div>
  );
};

/**
 * Send invite component
 * @returns {React.FC} a component
 */
export const SendInvitationComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [sentEmail, setSentEmail] = useState('');
  const intl = useIntl();
  const [tabSelected, setTabSelected] = React.useState('');
  const [invitees, setInvitees] = useState<PersonalInvitation[]>([]);
  const isPaidUser = useSelector(selectIsProUser);
  const team = useSelector(selectTeam);

  const onEmailChange = useCallback((value: string) => {
    const emailValue = value;

    setEmail(emailValue);
    setValidEmail(isEmailValid(emailValue));
  }, []);

  const getPersonalInvitesQuery = useQuery<Query>(GetPersonalInvitesDocument, {
    onCompleted(incomingData) {
      setInvitees(incomingData.getPersonalInvites);
    },
  });

  const acceptedInvitations = invitees.filter(
    (i) => i.__typename === 'PersonalInvitationAccepted'
  ) as PersonalInvitationAccepted[];

  const pendingInvitations =
    getPersonalInvitesQuery?.data?.getPersonalInvites.filter(
      (i) => i.__typename === 'PersonalInvitationPending'
    );

  const [sendInvitationFromUser, sendInvitationFromUserMutation] = useMutation(
    SendInvitationFromUserDocument,
    {
      variables: { input: { email } },
    }
  );

  const onSent = useCallback(async () => {
    if (!validEmail || !email.length) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Invalid email',
          description: 'Invalid email message',
        }),
        {
          variant: 'WARNING',
          autoHideDuration: 1000,
        }
      );
      return;
    }

    // send invite
    try {
      await sendInvitationFromUser({});
    } catch (e) {
      logger.error(e);
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'We ran into a problem. Please try again.',
          description: 'SendInvitationComponent billing section',
        }),
        { variant: 'ERROR' }
      );
    }
    trackWebEvent('User invited user', {
      invitee: email,
      team_id: team?.id,
    });
    dispatch(showRateUsModal(RateUsModalSource.SEND_TEAM_INVITATION));

    setSentEmail(email);
    setEmail('');
    await getPersonalInvitesQuery.refetch();
  }, [
    validEmail,
    email,
    dispatch,
    getPersonalInvitesQuery,
    intl,
    sendInvitationFromUser,
    team,
  ]);

  return (
    <div className="mx-auto flex max-w-3xl flex-col items-center gap-y-6 rounded-xl border-slate-200 p-6 text-center md:gap-y-10">
      <div className="flex flex-col gap-y-8">
        <div className="flex flex-col gap-2">
          <img
            src={TactiqLogomark}
            alt="logo"
            className="h-40 w-40 self-center md:h-48 md:w-48"
          />
          <div className="font-semibold text-2xl text-slate-800">
            {isPaidUser ? (
              <FormattedMessage
                defaultMessage="Get 1 free AI credit every month for each invited friend"
                description="Invitation component title"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Love Tactiq? Invite your friends!"
                description="Invitation component title"
              />
            )}
          </div>
          <div className="m-auto max-w-lg">
            <div className="text-base text-slate-500">
              {isPaidUser ? (
                <FormattedMessage
                  defaultMessage="Each accepted invitation will raise your monthly AI credits by 1"
                  description="Invitation component description"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Each accepted invitation will raise your free monthly meeting limit by 5 and AI credits by 1."
                  description="Invitation component description"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 md:flex-row md:items-start md:gap-x-8">
          <div className="flex flex-col items-start gap-y-2">
            <div className="font-medium text-slate-600 text-sm">
              <FormattedMessage
                defaultMessage="Invite by email"
                description="Invite by email title."
              />
            </div>
            <div>
              <div className="flex">
                <TextInput
                  id="members"
                  onChange={onEmailChange}
                  value={email}
                  type="email"
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Add email address',
                    description: 'Invite by email input placeholder',
                  })}
                />
                <div className={SettingAddButtonRowClasses}>
                  <Button
                    onClick={onSent}
                    loading={sendInvitationFromUserMutation.loading}
                  >
                    <FormattedMessage
                      defaultMessage="Invite"
                      description="Invite button title"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {sendInvitationFromUserMutation.data?.sendInvitationFromUser
            ?.success === false && (
            <div>
              <FormattedMessage
                defaultMessage="User already exists, or has already been invited."
                id="rd3p+3"
                description="User already exists message text"
              />
            </div>
          )}
          {sendInvitationFromUserMutation.data?.sendInvitationFromUser
            ?.success &&
            sentEmail && (
              <div>
                <FormattedMessage
                  defaultMessage="Invitation to {sentEmail} was sent 📪"
                  description="Invitation send message text"
                  values={{
                    sentEmail: <strong>{sentEmail}</strong>,
                  }}
                />
              </div>
            )}
          <div className="mb-1 flex self-center">
            <p className="flex max-h-6 max-w-6 items-center justify-center rounded-full bg-slate-100 p-4 font-semibold text-slate-500 text-xs">
              or
            </p>
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <InvitationLink />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex">
          <Tabs
            onChange={setTabSelected}
            aria-label="Invitation pending and accpeted list"
            tabs={[
              {
                id: 'accepted',
                name: intl.formatMessage({
                  defaultMessage: 'Accepted invites',
                }),
                count: acceptedInvitations.length,
              },
              {
                id: 'pending',
                name: intl.formatMessage({
                  defaultMessage: 'Pending invites',
                }),
                count: pendingInvitations?.length,
              },
            ]}
          />
        </div>
        <TabContent value={tabSelected} id="accepted">
          {getPersonalInvitesQuery.loading || !getPersonalInvitesQuery.data ? (
            <div className="flex content-center">
              <Loading />
            </div>
          ) : (
            <AcceptedInviteComponent invitations={acceptedInvitations} />
          )}
        </TabContent>
        <TabContent value={tabSelected} id="pending">
          {getPersonalInvitesQuery.loading || !getPersonalInvitesQuery.data ? (
            <div className="flex content-center">
              <Loading />
            </div>
          ) : (
            <PendingInviteComponent
              invitations={
                pendingInvitations
                  ? (pendingInvitations as PersonalInvitationPending[])
                  : []
              }
            />
          )}
        </TabContent>
      </div>
    </div>
  );
};
