import React, { useContext, useRef } from 'react';
import { Select } from '../Select';
import { ConditionContext, setValueFocus } from './lib';
import { ConditionOperator } from './types';
import { useIntl } from 'react-intl';

export const ConditionBuilderOperator = () => {
  const intl = useIntl();
  const fieldsetRef = useRef<HTMLFieldSetElement>(null);
  const { condition, onUpdate, key } = useContext(ConditionContext);
  if (!condition?.variable || !key) {
    return <div />;
  }
  let allowedOperators: Set<ConditionOperator>;

  const operatorLabels: Record<ConditionOperator, string> = {
    [ConditionOperator.Equal]: intl.formatMessage({
      defaultMessage: 'is',
    }),
    [ConditionOperator.NotEqual]: intl.formatMessage({
      defaultMessage: 'is not',
    }),
    [ConditionOperator.GreaterThan]: intl.formatMessage({
      defaultMessage: 'is greater than',
    }),
    [ConditionOperator.LessThan]: intl.formatMessage({
      defaultMessage: 'is less than',
    }),
    [ConditionOperator.GreaterThanOrEqual]: intl.formatMessage({
      defaultMessage: 'is greater than or equal to',
    }),
    [ConditionOperator.LessThanOrEqual]: intl.formatMessage({
      defaultMessage: 'is less than or equal to',
    }),
    [ConditionOperator.Contains]: intl.formatMessage({
      defaultMessage: 'contains',
    }),
    [ConditionOperator.StartsWith]: intl.formatMessage({
      defaultMessage: 'starts with',
    }),
    [ConditionOperator.OneOf]: intl.formatMessage({
      defaultMessage: 'includes',
    }),
    [ConditionOperator.NotOneOf]: intl.formatMessage({
      defaultMessage: 'do not include',
    }),
  };

  switch (condition?.variable?.type) {
    case 'string':
      allowedOperators = new Set([
        ConditionOperator.Equal,
        ConditionOperator.Contains,
      ]);
      break;
    case 'number':
      allowedOperators = new Set([
        ConditionOperator.Equal,
        ConditionOperator.NotEqual,
        ConditionOperator.GreaterThan,
        ConditionOperator.LessThan,
        ConditionOperator.GreaterThanOrEqual,
        ConditionOperator.LessThanOrEqual,
      ]);
      break;
    case 'boolean':
      allowedOperators = new Set([
        ConditionOperator.Equal,
        ConditionOperator.NotEqual,
      ]);
      break;
    case 'date':
      allowedOperators = new Set([
        ConditionOperator.Equal,
        ConditionOperator.NotEqual,
        ConditionOperator.GreaterThan,
        ConditionOperator.LessThan,
      ]);
      break;
    case 'array':
      allowedOperators = new Set([
        ConditionOperator.OneOf,
        ConditionOperator.NotOneOf,
      ]);
      break;
    default:
      allowedOperators = new Set();
  }

  const options = Object.values(ConditionOperator)
    .filter((operator) => allowedOperators.has(operator))
    .map((operator) => ({
      value: operator,
      label: operatorLabels[operator],
    }));

  return (
    <fieldset ref={fieldsetRef} aria-label="condition-operator">
      <Select
        full
        onChange={(operator) => {
          setValueFocus(fieldsetRef.current?.parentElement);
          onUpdate(key, { operator });
        }}
        options={options}
        value={condition?.operator}
        variant="naked"
        size="small"
      />
    </fieldset>
  );
};
