import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Banner } from '../../../components/Banner';
import { Button } from '../../../components/buttons';
import { trackNeedAuthenticationViewed } from '../../../helpers/analytics';
import { forceSignOut } from '../../../helpers/authentication';
import { RootState } from '../../../redux/store';

export const AlertNeedAuthentication: React.FC = () => {
  const needAuthentication = useSelector(
    (state: RootState) => state.global.needAuthentication
  );

  useEffect(() => {
    if (needAuthentication) {
      trackNeedAuthenticationViewed();
    }
  }, [needAuthentication]);
  if (!needAuthentication) return null;

  return (
    <Banner className="bg-red-200">
      <div className="flex grow items-center justify-between px-10">
        <p className="text-sm">
          <FormattedMessage defaultMessage="Tactiq might not be working correctly for you. Please sign out and sign in again." />
        </p>
        <Button
          size="small"
          onClick={async () => {
            return forceSignOut('alertneedauth');
          }}
        >
          <FormattedMessage defaultMessage="Fix" />
        </Button>
      </div>
    </Banner>
  );
};
