import React, { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Copy } from 'lucide-react';

import { useWorkflowDuplicate } from '../../services/Workflow';
import { Tooltip, TooltipProps } from '../../components/Tooltip';
import { Button, ButtonProps } from '../../components/buttons';

export type WorkflowButtonProps = {
  workflowId: string;
  title?: ReactNode;
  withLabel?: boolean;
} & Omit<ButtonProps, 'onClick' | 'loading' | 'startIcon'> &
  Pick<TooltipProps, 'title'>;

const DuplicateWorkflowButton = ({
  workflowId,
  withLabel = false,
  title,
  ...props
}: WorkflowButtonProps) => {
  const navigate = useNavigate();

  const { data, request, loading } = useWorkflowDuplicate(workflowId);
  const isSuccess = useMemo(() => Boolean(data && !loading), [data, loading]);
  const newId = useMemo(
    () => data?.duplicateWorkflow.id,
    [data?.duplicateWorkflow]
  );

  useEffect(() => {
    if (isSuccess) navigate(`/workflows/${newId}`);
  }, [newId, isSuccess, navigate]);

  const handleOnClick = () => {
    request();
  };

  return (
    <Tooltip arrow placement="top" isDisabled={withLabel} title={title}>
      <Button
        onClick={handleOnClick}
        loading={loading}
        startIcon={<Copy size="1rem" />}
        {...props}
      >
        {withLabel && <FormattedMessage defaultMessage="Duplicate" />}
      </Button>
    </Tooltip>
  );
};

export default DuplicateWorkflowButton;
