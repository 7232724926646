import React from 'react';
import { trackWebEvent } from '../../helpers/analytics';
import { useMutation } from '@apollo/client';
import { UpdateUserSettingDocument } from '../../graphql/operations';
import { Button } from '../../components/buttons';
import { Alert } from '../../components/Alert';
import { FormattedMessage } from 'react-intl';

export const AlertNeedZoomWCRedirect: React.FC = () => {
  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  return (
    <Alert
      severity="warning"
      action={
        <Button
          color="warning"
          size="small"
          loading={updateUserSettingMutation.loading}
          onClick={async () => {
            trackWebEvent('Fix-enabled Zoom Web Client setting');
            await updateUserSetting({
              variables: {
                input: { zoomwcredirect: true },
              },
            });
          }}
        >
          Fix
        </Button>
      }
      description={
        <FormattedMessage defaultMessage="Zoom Web Client is not enabled" />
      }
    />
  );
};
