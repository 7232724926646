import React from 'react';
import { FormattedMessage } from 'react-intl';
import icon from '../../../img/zoom.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import { createOAuthConnector } from './connector';
import { ZoomSetupStatus } from './zoom';
import { PermissionsGrantedStatus } from '../../../redux/modules/global';

export const integration: IntegrationImplementation = {
  order: 3,
  id: 'zoomapi',
  category: IntegrationCategory.Meetings,
  title: 'Zoom',
  isConnected: (integrationsState: any) =>
    integrationsState.arePermissionsGranted ===
    PermissionsGrantedStatus.GRANTED,
  icon,
  description: () => (
    <>
      <FormattedMessage
        defaultMessage="Capture Zoom Web Client and Desktop transcripts."
        description="Zoom Desktop integration description"
      />
      <ZoomSetupStatus />
    </>
  ),
  connector: createOAuthConnector('zoom', 'Zoom Desktop'),
  allowMultipleConnections: true,
};

export default integration;
