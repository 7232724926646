import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import iconPipedrive from '../../../img/pipedrive.png';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';

import {
  IntegrationCategory,
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import { NamedObject, SharingResult } from '@tactiq/model';
import { createOAuthConnector } from './connector';
import {
  ShareDialogFormClasses,
  ShareDialogListWrapperClasses,
  ShareDialogWrapperClasses,
} from './styles';
import { trackWebEvent } from '../../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import { MeetingAiPromptOutput } from '../../../graphql/operations';
import { Search, User2 } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { connection, share } = props;
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState<NamedObject[]>([]);
  const intl = useIntl();

  useAsyncEffect(
    async () => {
      if (connection && isLoading) {
        const result = (await fetchApiV2('/a/integrations/pipedrive/deals', {
          method: 'POST',
          body: JSON.stringify({
            query,
            connectionKey: connection.connectionKey,
          }),
        })) as {
          data: NamedObject[];
        };
        if (result) {
          setContacts(result.data);
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection, isLoading]
  );

  const listContent = contacts.length ? (
    contacts.map((x) => (
      <Button
        key={x.id}
        variant="naked"
        className="w-full"
        onClick={() => {
          trackWebEvent('Selected a deals in Pipedrive to share into');
          return share(integration, connection, {
            user_id: connection.user_id,
            dealId: x.id,
            contactName: x.name,
          });
        }}
        startIcon={<User2 size="1rem" />}
      >
        {x.name}
      </Button>
    ))
  ) : (
    <span>
      <FormattedMessage
        defaultMessage="No deals were found. Try another search term."
        description="Pipedrive integration. No deals found message text."
      />
    </span>
  );

  return (
    <div className={ShareDialogWrapperClasses}>
      <div>
        <FormattedMessage
          defaultMessage="Please select deal. Your new meeting log will be created for it."
          description="Pipedrive integration. Select a deal."
        />
      </div>
      <form
        className={ShareDialogFormClasses}
        onSubmit={(event) => {
          setIsLoading(true);

          event.preventDefault();
        }}
      >
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Search Pipedrive deals',

            description:
              'Pipedrive integration. Search deals input placeholder.',
          })}
          inputProps={{
            'aria-label': intl.formatMessage({
              defaultMessage: 'search Pipedrive deals',

              description:
                'Pipedrive integration. Search deals input aria-label.',
            }),
            name: 'query',
          }}
          disabled={isLoading}
          value={query}
          onChange={setQuery}
        />
        <Button
          variant="icon"
          type="submit"
          aria-label={intl.formatMessage({
            defaultMessage: 'Search',

            description:
              'Pipedrive integration. Search button icon aria-label.',
          })}
        >
          <Search className="h-6 w-6" />
        </Button>
      </form>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <ul className={ShareDialogListWrapperClasses}>{listContent}</ul>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const {
    title,
    meetingDetails,
    highlights,
    footerLink,
    footerLinkText,
    isSharingDetails,
    isSharingHighlights,
    isSharingTranscript,
    aiOutputs,
  } = options;
  const { start, startMillis, durationMillis, participants } = meetingDetails;

  const lines: string[] = [];

  if (isSharingDetails) {
    lines.push(`<p>Started: ${start}</p>
      <p>Participants: ${participants}</p><br />`);
  }

  if (isSharingHighlights) {
    if (aiOutputs) {
      const promptOutputs = aiOutputs as MeetingAiPromptOutput[];
      if (promptOutputs.length) {
        for (const output of promptOutputs) {
          if (
            output?.content?.__typename === 'MeetingAIOutputTypeString' &&
            output.content?.text
          ) {
            lines.push(
              `<p>${output.promptTitle ?? 'Generated Content'}:</p>
            <p>${output.content.text.replaceAll('\n', '<br />')}</p><br />`
            );
          } else if (
            output?.content?.__typename === 'MeetingAIOutputTypeActionItems' &&
            output.content?.actionItems
          ) {
            lines.push(
              `<p>Action Items:</p>
              <ul>${output?.content?.actionItems
                ?.map(
                  (participant) =>
                    `<li><b>${
                      participant.participantName
                    }</b>:<ul>${participant.actions
                      .map(
                        (action) =>
                          `<li>${action.isDone ? '[DONE] ' : ''}${
                            action.content
                          }</li>`
                      )
                      .join(``)}</ul></li>`
                )
                .join('')}
              </ul>`
            );
          }
        }
      }
    }

    if (highlights.length) {
      lines.push(
        `<p>Highlights:</p>
        <ul>${highlights
          .map(
            (block) =>
              `<li>${block.blocks[0].fromStart} <b>${block.speakerName}</b>: ${block.transcript}</li>`
          )
          .join('')}
        </ul>`
      );
    }
  }

  if (isSharingTranscript) {
    lines.push(`<p><a href="${footerLink}">${footerLinkText}</a></p>`);
  }
  const d = new Date(startMillis);
  const minutes = Math.floor((durationMillis / 1000 / 60) % 60);
  const hours = Math.floor((durationMillis / 1000 / 3600) % 24);
  const data = {
    due_date: d.toISOString().slice(0, 10), // Due date of the Activity. Format: YYYY-MM-DD
    due_time: d.toISOString().slice(11, 16), // Due time of the Activity in UTC. Format: HH:MM
    duration: `${hours}:${minutes}`, // Format: HH:MM
    deal_id: options.dealId,
    note: lines.join(' '),
    subject: title,
    type: 'Meeting',
  };

  const result = (await fetchApiV2('/a/integrations/pipedrive/activity', {
    method: 'POST',
    body: JSON.stringify({
      connectionKey: connection.connectionKey,
      user_id: connection.user_id,
      content: {
        data,
      },
    }),
  })) as SharingResult;

  return { externalId: result.externalId, link: result.link };
};

export const integration: IntegrationImplementation = {
  id: 'pipedrive',
  category: IntegrationCategory.CRM,
  title: 'Pipedrive',
  icon: iconPipedrive,
  description: () => (
    <FormattedMessage
      defaultMessage="Link your transcripts with contacts and deals in Pipedrive, and create tasks based on your highlights and tags."
      description="Pipedrive integration description"
    />
  ),
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Attach to a record"
      description="Pipedrive integration share description"
    />
  ),
  connector: createOAuthConnector('pipedrive', 'Pipedrive'),
  showReconnectButton: true,
  allowMultipleConnections: false,
  share: shareImpl,
  ShareDialog,
  waitlist: false,
  getSharingDestinationTitle: (options) => {
    return `${options.contactName}`;
  },
};

export default integration;
