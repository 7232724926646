import { createContext } from 'react';
import {
  ConditionOperator,
  ConditionType,
  ExportTypes,
  type Condition,
  type ConditionCollection,
  type ConditionVariable,
} from './types';
import { Liquid } from 'liquidjs';
import { isValid, parseISO } from 'date-fns';

interface ConditionContextInterface {
  variables: ConditionVariable[];
  key?: string;
  condition?: Condition;
  onUpdate: (index: string, value: Partial<Condition>) => void;
}

export const ConditionContext = createContext<ConditionContextInterface>({
  variables: [],
  onUpdate: () => {
    return;
  },
});

export const id = () =>
  `${Date.now().toString(36)}-${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36)}`;

export const ingestString = (
  liquidCondition: string | undefined,
  variables: ConditionVariable[]
): ConditionCollection => {
  const conditions: ConditionCollection = new Map();

  if (!liquidCondition?.trim()) {
    return conditions;
  }
  const operators = Object.values(ConditionOperator)
    .map((operator) => operator.replace(/\|/g, '\\|'))
    .join('|');
  const regex = new RegExp(
    `([\\w.]+)\\s*(${operators})\\s*(true|false|['"].*?['"]|\\d+|\\d{4}-\\d{2}-\\d{2})\\s*((?:AND|OR))?`,
    'gi' // 'g' for global matching, 'i' for case-insensitive
  );

  const variableTypes = new Map(variables.map((v) => [v.value, v.type]));
  const variableLabels = new Map(variables.map((v) => [v.value, v.label]));

  for (const match of liquidCondition.matchAll(regex)) {
    const operator = match[2] as ConditionOperator;
    const variableName = match[1];
    const type: ConditionType = variableTypes.get(variableName) ?? 'string';
    const label = variableLabels.get(variableName) ?? variableName;
    const variable: ConditionVariable = { value: variableName, type, label };

    let stringifiedValue = match[3].trim();
    if (
      (stringifiedValue.startsWith('"') || stringifiedValue.startsWith("'")) &&
      (stringifiedValue.endsWith('"') || stringifiedValue.endsWith("'"))
    ) {
      stringifiedValue = stringifiedValue.slice(1, -1);
    }
    let value: Condition['value'];
    try {
      switch (variable.type) {
        case 'number':
          value = Number.parseFloat(stringifiedValue);
          if (Number.isNaN(value)) throw new Error();
          break;

        case 'date':
          if (!isValid(parseISO(stringifiedValue))) throw new Error();
          break;

        case 'boolean':
          value = stringifiedValue === 'true';
          break;
        default:
          value = stringifiedValue;
          break;
      }
    } catch {
      throw new Error(
        `Could not parse '${stringifiedValue}' as type '${type}' for '${variableName} ${operator}'`
      );
    }
    const condition = { variable, operator, value };
    conditions.set(id(), condition);
  }
  return conditions;
};

const exportStringToLiquid = (conditionCollection: ConditionCollection) => {
  const statement = Array.from(conditionCollection.values())
    .map(({ variable, operator, value }) => {
      const formattedValue = (() => {
        switch (variable?.type) {
          case 'number':
            return (value ?? 1).toString().trim();
          case 'boolean':
            return value || false;
          default:
            return `"${value ?? ''}"`;
        }
      })();

      return `${variable?.value} ${operator} ${formattedValue}`;
    })
    .filter(Boolean)
    .join(' and ');
  new Liquid().parse(statement);
  return statement ? `{{ ${statement} }}` : '';
};

export const exportConditions = (
  conditionCollection: ConditionCollection,
  format: ExportTypes
): string => {
  if (format === 'Liquid') {
    return exportStringToLiquid(conditionCollection);
  }
  return '';
};

export const setValueFocus = (parent?: HTMLElement | null) => {
  const targetElement = parent?.querySelector(
    '[aria-label="condition-builder-value"]'
  );
  if (targetElement instanceof HTMLElement) {
    targetElement.focus();
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(targetElement);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
};
