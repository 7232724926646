import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resumeSubscription } from '../../graphql/billing';
import {
  BillingStatus,
  ResumeTeamSubscriptionDocument,
} from '../../graphql/operations';
import { gotTeam } from '../../redux/modules/user';
import { selectTeam, selectUid } from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { useCallback, useState } from 'react';

/**
 *
 * @returns {boolean} isRenewalDisabled
 */
export function useIsRenewalDisabled(): (disableSnackbar?: boolean) => boolean {
  const dispatch = useDispatch();
  const intl = useIntl();
  const userId = useSelector(selectUid);
  const userState = useSelector(({ user }: RootState) => user);
  const team = useSelector(selectTeam);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasActiveTeamPlan = team?.isPaid;
  const canManageTeamPlan = team?.members.find((u) => u.uid === userId)?.roles
    .ADMIN;
  const teamBillingStatus = team?.billingStatus;
  const userBillingStatus = useSelector(
    (state: RootState) => state.user.billingStatus
  );
  const userCanResubscribe =
    userState.plan?.paid?.__typename === 'StripePaidPlan';

  const [resumeTeamSubscription] = useMutation(ResumeTeamSubscriptionDocument);

  const isAdministratorOnTeamPlan = Boolean(
    hasActiveTeamPlan && canManageTeamPlan
  );

  const onResumeSubscription = useCallback(async () => {
    setIsLoading(true);
    if (team?.isPaid || isAdministratorOnTeamPlan) {
      dispatch(
        gotTeam(
          (await resumeTeamSubscription()).data?.team_billing_resumeSubscription
        )
      );
    } else if (userCanResubscribe) {
      await resumeSubscription();
    }
    setIsLoading(false);
  }, [
    dispatch,
    isAdministratorOnTeamPlan,
    resumeTeamSubscription,
    team?.isPaid,
    userCanResubscribe,
  ]);

  return (disableSnackbar?: boolean) => {
    if (
      teamBillingStatus === BillingStatus.RENEWAL_DISABLED ||
      userBillingStatus === BillingStatus.RENEWAL_DISABLED
    ) {
      if (!disableSnackbar) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'Please resume your subscription to use this action.',
          }),
          {
            variant: 'INFO',
            interaction: {
              label: 'Resume subscription',
              onClick: onResumeSubscription,
              loading: isLoading,
            },
          }
        );
      }
      return true;
    }
    return false;
  };
}
