import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import GoogleMeetinIcon from '../../img/google_meet_logo_workmark.svg';
import ActivatedCheckIcon from '../../img/check_circle.svg';

import CheckIcon from '../../img/check_icon_16.svg';
import PromoGraphic from '../../img/activation_promo_image@2x.png';
import { RootState } from '../../redux/store';
import { AlertExtensionIsNotInstalled } from './AlertExtensionIsNotInstalled';
import { AlertNeedMorePermissions } from './AlertNeedMorePermissions';
import { PermissionsGrantedStatus } from '../../redux/modules/global';
import { trackSetupHelperButton } from '../../helpers/analytics';
import { MainBlockTypes } from '.';

export const OnboardingGoogleMeetHero: React.FC<{
  mainBlock: MainBlockTypes;
}> = ({ mainBlock }) => {
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations?.arePermissionsGranted
  );

  const intl = useIntl();

  const cardFooter = () => {
    if (isExtensionUnavailable) {
      return <AlertExtensionIsNotInstalled />;
    } else if (arePermissionsGranted === PermissionsGrantedStatus.MISSING) {
      return <AlertNeedMorePermissions />;
    } else {
      return (
        <div className="flex w-full items-center gap-4">
          <Button
            target="_blank"
            href="https://meet.google.com/new"
            onClick={() => {
              trackSetupHelperButton('googlemeet', mainBlock);
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Try in Google Meet Now',
            })}
          </Button>
          <div className="flex items-center gap-1">
            <img src={ActivatedCheckIcon} alt="Check Icon" />
            <p className="font-wieght-500 text-[#30A46C] leading-6">
              {intl.formatMessage({
                defaultMessage: 'Activated',
              })}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full overflow-hidden rounded-lg border-2 border-indigo-600 border-solid bg-white drop-shadow-xl md:w-2/3">
      <div className="space-between flex flex-col items-center gap-4 p-8 md:px-12 md:py-10 lg:flex-row lg:gap-8">
        <div className="h-auto w-3/5">
          <img
            className="drop-shadow-xl"
            src={PromoGraphic}
            alt="Tactiq Promotional Graphic"
          />
        </div>
        <div className="flex w-full flex-col items-center justify-between gap-4 lg:items-start">
          <div>
            <img src={GoogleMeetinIcon} alt="Google Meet Logo" />
          </div>
          <div className="flex flex-col">
            <CheckItem
              caption={intl.formatMessage({
                defaultMessage: 'Capture every detail from the call',
              })}
            />
            <CheckItem
              caption={intl.formatMessage({
                defaultMessage: 'Generate AI summaries and extract Insights',
              })}
            />
            <CheckItem
              caption={intl.formatMessage({
                defaultMessage:
                  'No intrusive bots, just seamless conversations',
              })}
            />
          </div>
          <div>{cardFooter()}</div>
        </div>
      </div>
    </div>
  );
};

const CheckItem: React.FC<{ caption: React.ReactNode }> = ({
  caption,
}): JSX.Element => (
  <div className="flex w-full gap-2 leading-7">
    <img src={CheckIcon} alt="Check Icon" />
    <p>{caption}</p>
  </div>
);
