import { AlertTriangle } from 'lucide-react';
import { TactiqMessageType, logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/buttons';
import { ModalDialog } from '../../components/modals';
import {
  FetchAllSpacesDocument,
  UserSettingsDataMoveStatus,
} from '../../graphql/operations';
import {
  acceptDataMove,
  cancelDataMove,
  requestDataMove,
  setRequestedDeletion,
} from '../../graphql/settings';
import { trackWebEvent } from '../../helpers/analytics';
import { sendMessage } from '../../helpers/extension';
import { kBilling, kTranscripts } from '../../helpers/routes';
import {
  selectTeam,
  selectUid,
  selectUserEmail,
  selectUserPlan,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { TextInput } from '../../components/TextInput';
import { useQuery } from '@apollo/client';
import { Link } from '../../components/Link';
import { cx } from '../../helpers/utils';
import { RemoveMemberDialog } from '../Teams/Team/RemoveMemberDialog';
import { Debug, useWithinDebuggingPeriod } from './Debug';

/**
 * Account
 * @returns {React.FC} component
 */
export const Account: React.FC = () => {
  const withinDebuggingPeriod = useWithinDebuggingPeriod();
  const requestedDeletion = useSelector(
    (state: RootState) => state.user.settings?.requestedDeletion
  );
  const requestedDataMove = useSelector(
    (state: RootState) => state.user.settings?.requestedDataMove
  );

  const user = useSelector((state: RootState) => state.user);
  const userId = useSelector(selectUid);
  const userEmail = useSelector(selectUserEmail);
  const userPlan = useSelector(selectUserPlan);
  const team = useSelector(selectTeam);

  const [showDataMoveDialog, setShowDataMoveDialog] = useState(false);
  const [dataMoveTargetEmail, setDataMoveTargetEmail] = useState('');
  const [showCleanUpTeam, setShowCleanUpTeam] = useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [reasonEmpty, setReasonEmpty] = useState(false);
  const intl = useIntl();

  const { data: spacesData } = useQuery(FetchAllSpacesDocument);
  const soleManagerSpaces =
    spacesData?.spaces?.filter((space) => {
      const hasOtherManager = space.members?.some(
        (member) => member.permissions.MANAGE && member.uid !== userId
      );
      // filter the spaces where the user is the only manager.
      // exclude the spaces where the user is the only member
      return !hasOtherManager && (space.members?.length ?? 0) > 1;
    }) ?? [];

  const teamSpaceOwner =
    spacesData?.spaces?.filter((space) => space.createdBy === userId) ?? [];

  const detailsClasses = 'group rounded-md border p-4 marker:text-sm';
  const summaryClasses = 'group-open:mb-4 cursor-pointer text-lg';
  const actionsClasses = 'pt-3 flex justify-end gap-3';

  return (
    <>
      <Alert
        severity="info"
        title={
          <FormattedMessage
            defaultMessage="Your privacy matters, and we take it very seriously."
            description="Account page privacy alert header."
          />
        }
        description={
          <FormattedMessage
            defaultMessage={`
              This page allows you do download a copy of your data and to request
              account deletion. If you have any questions, please reach out to us
              using the support chat at the bottom right corner of the screen.
            `}
            description="Account page privacy alert description."
          />
        }
      />

      <div className="flex flex-col gap-4">
        <h2 className="mt-4 text-2xl">
          <FormattedMessage
            defaultMessage="Get your data"
            description="Account page data section header."
          />
        </h2>

        <details
          className={detailsClasses}
          onToggle={() =>
            trackWebEvent(
              'Toggled Section on the Account page - Data stored in Tactiq'
            )
          }
        >
          <summary className={summaryClasses}>
            <FormattedMessage defaultMessage="Data stored in Tactiq" />
          </summary>
          <div className="flex flex-col gap-2">
            <FormattedMessage
              defaultMessage="We store limited amount of data in Tactiq. It includes the
                following:"
              description="Account page data section. Accordion details."
            />

            <ul className="list-disc pl-6">
              <li>
                <FormattedMessage
                  defaultMessage="A list of your meetings with links to the transcripts"
                  description="Account page data section. Accordion details."
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Your transcript contents"
                  description="Account page data section. Accordion details."
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Your Tactiq settings (custom tags, custom autocorrect and
                    autohighlight rules, the list of connected integrations,
                    etc.)."
                  description="Account page data section. Accordion details."
                />
              </li>
            </ul>
          </div>
        </details>

        <details
          className={detailsClasses}
          onToggle={() =>
            trackWebEvent(
              'Toggled Section on the Account page - Data stored in your browser'
            )
          }
        >
          <summary className={summaryClasses}>
            <FormattedMessage
              defaultMessage="Data stored in your browser"
              description="Account page data section. Accordion summary."
            />
          </summary>
          <div className="flex flex-col gap-2">
            <p>
              <FormattedMessage
                defaultMessage="Unsaved transcripts are stored temporarily in your browser.
                    Tactiq does not have direct access to them unless you go to the
                    list of unsaved transcripts and save them from there."
                description="Account page data section. Accordion details."
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="In that list, you can both save them and
                  delete from them from your browser."
                description="Account page data section. Accordion details."
              />
            </p>

            <Alert
              severity="info"
              description={
                <FormattedMessage
                  defaultMessage="You will not see any information if you open this in a different
                  browser from the one that you had a meeting in, since it is not
                  shared between them."
                  description="Account page data section. Accordion details."
                />
              }
            />

            <Alert
              severity="warning"
              description={
                <FormattedMessage
                  defaultMessage="All data stored in your browser is lost when you delete the
                  extension - if you had any unsaved meetings, they will be gone
                  and we won't be able to assist you in recovering them."
                  description="Account page data section. Accordion details."
                />
              }
            />
          </div>
          <div className={actionsClasses}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                trackWebEvent(
                  'Clicked Button - Manage unsaved transcripts from the account page'
                );
                sendMessage({ type: TactiqMessageType.openOptions }).catch(
                  logger.error
                );
              }}
            >
              <FormattedMessage
                defaultMessage="Manage Unsaved Transcripts"
                description="Account page data section. Manage unsaved transcriptions button title"
              />
            </Button>
          </div>
        </details>

        <details
          className={detailsClasses}
          onToggle={() =>
            trackWebEvent(
              'Toggled Section on the Account page - Data stored in Google Drive'
            )
          }
        >
          <summary className={summaryClasses}>
            <FormattedMessage
              defaultMessage="Data stored in your Google Drive"
              description="Account page data section. Accordion summary."
            />
          </summary>
          <FormattedMessage
            defaultMessage="All transcripts are stored in your Google Drive if you have it configured. You can download
                them or delete them from there directly. You can also navigate to
                them or delete them from the list of meetings."
            description="Account page data section. Accordion details."
          />
          <div className={actionsClasses}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                trackWebEvent(
                  'Clicked Link - Go to the meeting list from the account page'
                );
                return true;
              }}
              href={kTranscripts}
            >
              <FormattedMessage
                defaultMessage="Go to the meeting list"
                description="Account page data section. Meetings list link button title"
              />
            </Button>

            <Button
              size="small"
              color="primary"
              onClick={() => {
                trackWebEvent(
                  'Clicked Link - Go to Google Drive from the account page'
                );
                return true;
              }}
              href="https://drive.google.com"
            >
              <FormattedMessage
                defaultMessage="Go to Google Drive"
                description="Account page data section. Google Drive link button title"
              />
            </Button>
          </div>
        </details>

        <details
          className={detailsClasses}
          onToggle={() =>
            trackWebEvent('Toggled Section on the Account page - Billing Data')
          }
        >
          <summary className={summaryClasses}>
            <FormattedMessage
              defaultMessage="Billing data"
              description="Account page data section. Accordion summary."
            />
          </summary>
          <FormattedMessage
            defaultMessage="If you are a paid customer, we also hold limited data for accounting
              purposes. We do not store your payment details directly - we work
              with Stripe and PayPal. You can access this information from the
              Subscription page. If you need additional details please reach out
              through the support chat in the bottom right corner of the screen."
            id="oWOU/h"
            description="Account page data section. Accordion details."
          />
          <div className={actionsClasses}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                trackWebEvent(
                  'Clicked Link - Go to the subscription page from the account page'
                );
                return true;
              }}
              href={kBilling}
            >
              <FormattedMessage
                defaultMessage="Go to the Subscription page"
                description="Account page data section. Subscriptions link button title"
              />
            </Button>
          </div>
        </details>

        <h2 className="mt-4 text-2xl">
          <FormattedMessage defaultMessage="Move your data" />
        </h2>

        <details
          className={detailsClasses}
          open={Boolean(requestedDataMove)}
          onToggle={() =>
            trackWebEvent(
              'Toggled Section on the Account page - Move your data'
            )
          }
        >
          <summary className={summaryClasses}>
            <FormattedMessage defaultMessage="Moving your meetings to another account" />
          </summary>
          <div className="flex flex-col gap-2">
            {!requestedDataMove ? (
              <>
                <Alert
                  severity="warning"
                  description={
                    <FormattedMessage defaultMessage="It is an irreversible operation. Once the move is complete, the meetings will become a part of the target account." />
                  }
                />
                <Alert
                  severity="info"
                  description={
                    <FormattedMessage
                      defaultMessage="This operation requires manual review by our support team at the moment. It might take up to 5 business days for your request to be
                  reviewed and processed. You will receive a final acknowledgement email."
                    />
                  }
                />
              </>
            ) : (
              <Alert
                severity="error"
                description={
                  <FormattedMessage
                    defaultMessage="Data move requested on {requestedDate} {source}"
                    values={{
                      requestedDate: new Date(
                        requestedDataMove.requestedAt * 1000
                      ).toString(),
                      source:
                        requestedDataMove.sourceUid === userId ? (
                          <FormattedMessage
                            defaultMessage="into {email}"
                            id="ILVeW/"
                            values={{
                              email: requestedDataMove.targetEmail,
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="from {email}"
                            values={{
                              email: requestedDataMove.sourceEmail,
                            }}
                          />
                        ),
                    }}
                  />
                }
              />
            )}
            {requestedDataMove?.status ===
            UserSettingsDataMoveStatus.PENDING ? (
              <Alert
                severity="info"
                description={
                  requestedDataMove?.sourceUid === userId ? (
                    <FormattedMessage defaultMessage="Waiting for the target account to accept the move." />
                  ) : (
                    <FormattedMessage defaultMessage="Waiting for you to accept the move." />
                  )
                }
              />
            ) : null}
          </div>
          <div className={actionsClasses}>
            {requestedDataMove &&
            requestedDataMove?.status !== UserSettingsDataMoveStatus.DONE ? (
              <Button
                size="small"
                color="primary"
                onClick={async () => {
                  trackWebEvent(
                    'Clicked Button - Cancel data move on the account page'
                  );
                  await cancelDataMove();
                }}
              >
                <FormattedMessage defaultMessage="Cancel data move" />
              </Button>
            ) : null}
            {requestedDataMove?.targetUid === userId &&
            requestedDataMove?.status === UserSettingsDataMoveStatus.PENDING ? (
              <Button
                size="small"
                color="primary"
                onClick={async () => {
                  trackWebEvent(
                    'Clicked Button - Accept data move on the account page'
                  );
                  await acceptDataMove();
                }}
              >
                <FormattedMessage defaultMessage="Accept data move" />
              </Button>
            ) : null}
            {!requestedDataMove ? (
              <Button
                size="small"
                onClick={() => {
                  trackWebEvent(
                    'Clicked Button - Request data move on the account page'
                  );
                  setShowDataMoveDialog(true);
                }}
              >
                <FormattedMessage defaultMessage="Request data move" />
              </Button>
            ) : null}
          </div>
        </details>

        <ModalDialog
          open={showDataMoveDialog}
          onClose={() => setShowDataMoveDialog(false)}
          color="error"
          icon={
            <AlertTriangle
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          }
          title={<FormattedMessage defaultMessage="Request Data Move" />}
          text={
            <FormattedMessage
              defaultMessage="This will move all your meetings to the account you specify. All meetings will lose their sharing permissions and calendar data (if any) in the process."
              id="+go56L"
            />
          }
          actions={
            <>
              <Button
                color="error"
                onClick={async () => {
                  if (dataMoveTargetEmail.trim()) {
                    if (dataMoveTargetEmail.trim() === userEmail) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'You cannot move the data to yourself',
                        }),
                        {
                          variant: 'ERROR',
                        }
                      );
                      return;
                    }

                    await requestDataMove({
                      targetEmail: dataMoveTargetEmail.trim(),
                    });
                    setDataMoveTargetEmail('');
                    setShowDataMoveDialog(false);
                  }
                }}
              >
                <FormattedMessage defaultMessage="Request data move" />
              </Button>
              <Button
                variant="neutral-secondary"
                onClick={() => setShowDataMoveDialog(false)}
              >
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
            </>
          }
        >
          <div className="pb-6">
            <TextInput
              autoFocus
              label={<FormattedMessage defaultMessage="Target account email" />}
              type="email"
              value={dataMoveTargetEmail}
              onChange={setDataMoveTargetEmail}
            />
          </div>
        </ModalDialog>

        <h2 className="mt-4 text-2xl text-red-500">
          <FormattedMessage
            defaultMessage="Danger Zone"
            description="Account page. Account deletion section header."
          />
        </h2>

        <details
          className={detailsClasses}
          onToggle={() =>
            trackWebEvent(
              'Toggled Section on the Account page - Deleting your account'
            )
          }
        >
          <summary className={cx(summaryClasses, 'text-red-500')}>
            <FormattedMessage
              defaultMessage="Deleting your account"
              description="Account page data section. Accordion summary."
            />
          </summary>
          <div className="flex flex-col gap-2">
            {userPlan.paid?.isPaid && (
              <Alert
                severity="warning"
                description={
                  <FormattedMessage
                    defaultMessage="You need to first cancel your subscription. You can do that {link}"
                    values={{
                      link: (
                        <a href="https://app.tactiq.io/#/billing">
                          <b>
                            <FormattedMessage defaultMessage="here" />.
                          </b>
                        </a>
                      ),
                    }}
                  />
                }
              />
            )}

            {soleManagerSpaces.length > 0 && (
              <Alert
                severity="warning"
                description={
                  <div className="space-y-2">
                    <FormattedMessage
                      defaultMessage="You're the only one who can manage the following spaces. Please delegate management duties to someone else before you go!"
                      tagName="p"
                    />
                    <ul className="list-disc pl-8">
                      {soleManagerSpaces.map((space) => (
                        <li key={space.id}>
                          <Link to={`/space/${space.id}`}>{space.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              />
            )}

            <Alert
              severity="warning"
              description={
                <FormattedMessage
                  defaultMessage="It is an irreversible operation. We will not be able to assist
                    you with any additional requests once you delete your account."
                  description="Account page data section. Accordion details."
                />
              }
            />

            <Alert
              severity="info"
              description={
                <FormattedMessage
                  defaultMessage="It might take up to 5 business days for your request to be
                    processed. You will receive a final acknowledgement email."
                  description="Account page data section. Accordion details."
                />
              }
            />

            {requestedDeletion ? (
              <Alert
                severity="error"
                description={
                  <>
                    <FormattedMessage
                      defaultMessage="Account deletion requested on {date}"
                      description="Account page data section. Accordion details."
                      values={{
                        date: new Date(requestedDeletion * 1000).toString(),
                      }}
                    />
                  </>
                }
              />
            ) : null}
            {showCleanUpTeam && team && (
              <RemoveMemberDialog
                team={team}
                member={{
                  uid: user.id,
                  email: user.email,
                  displayName: user.displayName,
                }}
                onSuccess={() => setShowDeleteAccountDialog(true)}
                onClose={() => setShowCleanUpTeam(false)}
              />
            )}
          </div>
          <div className={actionsClasses}>
            {requestedDeletion ? (
              <Button
                size="small"
                color="primary"
                onClick={async () => {
                  trackWebEvent(
                    'Clicked Button - Cancel account deletion on the account page'
                  );
                  await setRequestedDeletion({ requested: false });
                }}
              >
                <FormattedMessage
                  defaultMessage="Cancel account deletion"
                  description="Account page data section. Cancel account deletion button title"
                />
              </Button>
            ) : (
              <Button
                size="small"
                color="error"
                onClick={async () => {
                  if (userPlan.paid?.isPaid) {
                    enqueueSnackbar(
                      intl.formatMessage({
                        defaultMessage:
                          'You need to cancel your subscription first',
                      }),
                      { variant: 'WARNING' }
                    );
                    return;
                  }

                  if (team) {
                    const isLastAdmin =
                      team.members.length > 1 &&
                      team.members
                        .filter((m) => m.roles.ADMIN)
                        .every((m) => m.uid === userId);

                    const hasBadSpaces = teamSpaceOwner.length > 0;
                    const args = { user: user.id };

                    if (isLastAdmin || hasBadSpaces) {
                      if (isLastAdmin) {
                        trackWebEvent(
                          'User tried to delete account but is last admin in team',
                          args
                        );
                      }
                      if (hasBadSpaces) {
                        trackWebEvent(
                          'User tried to delete account but has spaces that need re-assigning',
                          args
                        );
                      }
                      setShowCleanUpTeam(true);
                      return;
                    }
                  }

                  // @TODO this can be removed when the space data has been cleaned up to
                  // add teamId's to spaces with multiple members.
                  if (soleManagerSpaces.length > 0) {
                    enqueueSnackbar(
                      intl.formatMessage({
                        defaultMessage:
                          'You need to assign new managers to the spaces first',
                        id: 'oB+l6p',
                      }),
                      { variant: 'WARNING' }
                    );
                    return;
                  }

                  trackWebEvent(
                    'Clicked Button - Request account deletion on the account page'
                  );

                  setShowDeleteAccountDialog(true);
                }}
              >
                <FormattedMessage
                  defaultMessage="Request account deletion"
                  description="Account page data section. Request account deletion button title"
                />
              </Button>
            )}
          </div>
          <ModalDialog
            open={showDeleteAccountDialog}
            onClose={() => setShowDeleteAccountDialog(false)}
            title={
              <FormattedMessage defaultMessage="Before you go, Please help us improve" />
            }
            text={
              <FormattedMessage defaultMessage="Could you share why you're deleting your account? Your feedback helps us improve Tactiq for everyone." />
            }
            actions={
              <>
                <Button
                  color="error"
                  onClick={async () => {
                    if (deleteReason.length === 0) {
                      return setReasonEmpty(true);
                    }
                    trackWebEvent('Clicked account deletion button in modal', {
                      reason: deleteReason,
                      email: user.email,
                      domain: user.domain,
                    });
                    await setRequestedDeletion({ requested: true });
                    setShowDeleteAccountDialog(false);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Request account deletion"
                    description="Account page data section. Request account deletion button title"
                  />
                </Button>
                <Button
                  variant="neutral-secondary"
                  onClick={() => {
                    trackWebEvent(
                      'Canceled account deletion modal on the account page'
                    );
                    setShowDeleteAccountDialog(false);
                  }}
                >
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
              </>
            }
          >
            <TextInput
              id="delete-account-textfield"
              autoFocus
              error={reasonEmpty}
              errorLabel={intl.formatMessage({
                defaultMessage: "Reason can't be empty",
              })}
              type="textarea"
              required
              label={
                <FormattedMessage defaultMessage="Reason for deleting your account" />
              }
              value={deleteReason}
              onChange={(value) => {
                setReasonEmpty(false);
                setDeleteReason(value);
              }}
            />
          </ModalDialog>
        </details>
        {/* Only show the debug section if the user is within the debugging period */}
        {withinDebuggingPeriod && <Debug />}
      </div>
    </>
  );
};
