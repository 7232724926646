import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UpdateUserCompanionAppLoginDocument } from '../graphql/operations';
import { fetchApiV2 } from '../helpers/api/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setHasMacApp } from '../redux/modules/user';
import { hasMacApp, selectUserOnboarding } from '../redux/selectors';

export const useShouldShowCompanionAppBanner = () => {
  const userHasApp = useSelector(hasMacApp);
  const onboarding = useSelector(selectUserOnboarding);
  const {
    Zoom: { isSelected: isZoomSelected },
    GoogleMeet: { isSelected: isGoogleMeetSelected },
    Teams: { isSelected: isMSTeamsSelected },
    Webex: { isSelected: isWebexSelected },
  } = onboarding;
  // Show Mac app download card if Zoom is selected or no other integrations are selected
  const isZoomUser =
    isZoomSelected ||
    (!isGoogleMeetSelected && !isMSTeamsSelected && !isWebexSelected);

  const hasDismissedBanner = Boolean(
    localStorage.getItem('dismissedCompanionAppDownloadBanner')
  );

  return {
    hasDismissedBanner,
    userHasApp,
    isZoomUser,
  };
};

export const useLoginToCompanionApp = () => {
  const dispatch = useDispatch();
  const [fn, { loading, error }] = useMutation(
    UpdateUserCompanionAppLoginDocument
  );

  const trigger = useCallback(async () => {
    const result = await fetchApiV2<{ token: string }>('/a/user/custom-token');

    if (result && !('error' in result) && result.token) {
      // Fire off hasMacApp to User
      fn();
      dispatch(setHasMacApp(true));
      // redirect to app
      window.location.href = `tactiq://desktop-auth?token=${result.token}`;
    }
  }, [fn, dispatch]);

  return {
    trigger,
    loading,
    error,
  };
};
