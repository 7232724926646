import { NodeType } from '.';

export const minHeights: Record<NodeType, number> = {
  AddLabels: 150,
  CustomPrompt: 415,
  RunMeetingKit: 170,
  SaveToSpace: 150,
  SendData: 132,
  Share: 300,
  StartNode: 60,
  Template: 256,
  Condition: 256,
  Confirmation: 256,
  SendEmailTeam: 256,
  SendEmailParticipants: 256,
};

export const minWidths: Record<NodeType, number> = {
  AddLabels: 384,
  CustomPrompt: 384,
  RunMeetingKit: 384,
  SaveToSpace: 384,
  SendData: 384,
  Share: 384,
  StartNode: 400,
  Template: 430,
  Condition: 384,
  Confirmation: 430,
  SendEmailTeam: 400,
  SendEmailParticipants: 400,
};

export const minDimensions = (type: NodeType) => ({
  minHeight: minHeights[type],
  minWidth: minWidths[type],
});
