import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as routes from '../../helpers/routes';
import { trackWebPage } from '../../helpers/analytics';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../models/integration';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { IntegrationListItem } from './IntegrationListItem';
import { AllIntegrations } from './integrations';
import { MessageSquareDashed } from 'lucide-react';

export const IntegrationsList: React.FC = () => {
  const user = useSelector(({ user }: RootState) => user);
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Integration');
  }, []);

  const userConnections = Object.values(user.connections ?? {});

  const integrations = AllIntegrations.filter(
    (x) => x.description && !x.hidden
  ).map(
    (i) =>
      ({
        ...i,
        category: i.category ?? IntegrationCategory.Other,
      }) as IntegrationImplementation
  );

  return (
    <Page
      maxWidth="full"
      bgRaised
      overflow
      title={intl.formatMessage({
        defaultMessage: 'Integrations',
        description: 'Integrations page navigation title',
      })}
    >
      <div className="mx-auto w-full max-w-[1536px] p-6 lg:p-12">
        <h1 className="pb-6 font-bold text-3xl text-slate-800 tracking-tight">
          <FormattedMessage
            defaultMessage="Integrations"
            description="Integrations page header."
          />
        </h1>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {[
            IntegrationCategory.Meetings,
            IntegrationCategory.Automation,
            IntegrationCategory.Storage,
            IntegrationCategory.CRM,
            IntegrationCategory.Other,
          ].map((category) => (
            <React.Fragment key={category}>
              <h2 className="col-span-full mt-4 font-semibold text-lg text-slate-800">
                {category}
              </h2>
              {integrations
                .filter((i) => i.category === category)
                .map((integration) => (
                  <IntegrationListItem
                    key={integration.id}
                    integration={integration}
                    userConnections={userConnections}
                  />
                ))}
            </React.Fragment>
          ))}
        </div>

        <div className="my-12 flex flex-col items-center justify-center gap-x-4 gap-y-1 text-center text-slate-700 lg:my-16 ">
          <div className="mb-2 flex h-16 w-16 items-center justify-center rounded-2xl border border-slate-200 bg-white">
            <MessageSquareDashed
              size={32}
              strokeWidth="1.75"
              className=" text-slate-600"
            />
          </div>
          <p className="font-semibold text-2xl text-slate-800 tracking-tighter">
            <FormattedMessage
              defaultMessage="Don't see your platform?"
              description="Request integration heading text."
            />
          </p>
          <p className="text-slate-500 text-sm">
            <FormattedMessage
              defaultMessage="Request the next integration {requestIntegration}."
              description="Request integration description text."
              values={{
                requestIntegration: (
                  <a
                    className="font-medium text-slate-800 hover:underline hover:opacity-80"
                    href={routes.kIntegrationRequest}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      defaultMessage="here"
                      description="Request integration link text."
                    />
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </Page>
  );
};

export default IntegrationsList;
