import React, { useCallback, useId } from 'react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAutocomplete } from './useAutocomplete';
import { useReactFlow } from '@xyflow/react';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';
import { minDimensions } from './minDimensions';
import {
  trackSendEmailToParticipantsBodyEdited,
  trackSendEmailToParticipantsSubjectEdited,
} from '../../../helpers/analytics';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { EmailNodeData } from '.';

export function SendEmailParticipants(props: WorkflowNodeProps<EmailNodeData>) {
  const { id, data } = props;

  const intl = useIntl();
  const autocomplete = useAutocomplete(props);
  const { updateNodeData: update } = useReactFlow();
  const { workflowId } = useWorkflowId();
  const isExecution = Boolean(data.execution);
  const isPreview = useIsPreview();

  const hasGoogleCalendar = useSelector(
    (state: RootState) => state.user.hasGoogleCalendar
  );

  const handleSubjectChange = useCallback(
    (subject: string) => {
      trackSendEmailToParticipantsSubjectEdited();
      update(id, { subjectTemplate: subject });
    },
    [id, update]
  );

  const handleBodyChange = useCallback(
    (body: string) => {
      trackSendEmailToParticipantsBodyEdited();
      update(id, { bodyTemplate: body });
    },
    [id, update]
  );

  const emailSubjectId = useId();
  const emailBodyId = useId();

  return (
    <SingleSourceNode
      workflowNode={props}
      {...minDimensions('SendEmailParticipants')}
      contentClassName="h-full"
    >
      <div className="flex w-full grow flex-col gap-3">
        {!hasGoogleCalendar && (
          <div className="text-neutral-subtle text-sm italic">
            <FormattedMessage
              defaultMessage="A Google Calendar integration is required, you can connect your calendar {calendarLink}."
              values={{
                calendarLink: (
                  <a
                    className="underline"
                    href="/#/integrations/google-calendar"
                  >
                    here
                  </a>
                ),
              }}
            />
          </div>
        )}
        <div className="flex w-full flex-grow flex-col gap-1">
          <div className="flex font-medium text-sm">
            <label htmlFor={emailSubjectId}>
              <FormattedMessage defaultMessage="Subject" />
            </label>
          </div>
          <LiquidTemplateInput
            id={emailSubjectId}
            className="min-h-16 text-base"
            properties={autocomplete.properties}
            variables={autocomplete.variables}
            value={data.subjectTemplate}
            onChange={handleSubjectChange}
            workflowId={workflowId}
            nodeType={props.type}
            disabled={isExecution || isPreview}
            placeholder={intl.formatMessage({
              defaultMessage: 'Start typing your liquid template',
            })}
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Liquid template input',
            })}
          />
        </div>
        <div className="flex w-full flex-grow flex-col gap-1">
          <div className="flex font-medium text-sm">
            <label htmlFor={emailBodyId}>
              <FormattedMessage defaultMessage="Body" />
            </label>
          </div>
          <LiquidTemplateInput
            id={emailBodyId}
            className="min-h-48 text-base"
            properties={autocomplete.properties}
            variables={autocomplete.variables}
            value={data.bodyTemplate}
            onChange={handleBodyChange}
            workflowId={workflowId}
            nodeType={props.type}
            disabled={isExecution || isPreview}
            placeholder={intl.formatMessage({
              defaultMessage: 'Start typing your liquid template',
            })}
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Liquid template input',
            })}
          />
        </div>
      </div>
    </SingleSourceNode>
  );
}
