import React, { useState } from 'react';
import {
  MeetingSearchResult,
  SearchFilterInput,
  SortBy,
} from '../../graphql/operations';
import { SearchPopup } from '../Transcripts/common/SearchBar';

type MeetingSearchProps = {
  open: boolean;
  setOpen: (next: boolean) => void;
  onSelectMeeting: (meetingId: string) => void;
};

const MeetingSearch = ({
  open,
  setOpen,
  onSelectMeeting,
}: MeetingSearchProps) => {
  const [query, setQuery] = useState<SearchFilterInput | undefined>({
    query: '',
  });

  const [sortBy, setSortBy] = useState<SortBy | undefined>(
    SortBy.CREATED_NEWEST_FIRST
  );

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSelectMeeting = (meeting: MeetingSearchResult) => {
    onSelectMeeting(meeting.id);
  };

  return (
    <div className="absolute top-[10vh] right-0 left-0 m-auto w-full min-w-md max-w-lg md:left-64">
      <SearchPopup
        isOpen={open}
        onClose={handleOnClose}
        query={query}
        skipEmpty={false}
        setQuery={setQuery}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onSelectMeeting={handleOnSelectMeeting}
      />
    </div>
  );
};

export default MeetingSearch;
