import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BasicMeeting } from '../../../../models/meeting';
import TranscriptSection from '../TranscriptSection';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '../../../../components/buttons';
import { MeetingListItem } from '../../list/MeetingListItem';

const MAX_MEETINGS = 3;

/**
 * Related meetings component
 * @param {unknown} param0 params
 * @param {BasicMeeting[]} param0.relatedMeetings related meetings
 * @returns {React.FC} a component
 */
const RelatedMeetings: React.FC<{
  relatedMeetings: BasicMeeting[];
}> = ({ relatedMeetings }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  if (!relatedMeetings.length) {
    return null;
  }

  const showMoreButton = (
    <div className="flex w-full justify-center">
      <Button variant="icon" onClick={toggleShowMore}>
        {showMore ? <ChevronUp /> : <ChevronDown />}
      </Button>
    </div>
  );

  return (
    <TranscriptSection
      place="recordings"
      header={
        <FormattedMessage
          defaultMessage="Related meetings"
          description="Meeting page. Related meetings section header."
        />
      }
    >
      <div>
        {(showMore
          ? relatedMeetings
          : relatedMeetings.slice(0, MAX_MEETINGS)
        ).map((meeting) => (
          <MeetingListItem
            key={meeting.id}
            meeting={meeting}
            readOnly
            checkboxColumnVisible={false}
            actionsVisible={false}
            createdColumnMode="date"
          />
        ))}
      </div>
      {relatedMeetings.length > MAX_MEETINGS ? showMoreButton : null}
    </TranscriptSection>
  );
};

export default RelatedMeetings;
