import React, { ReactElement, useCallback, useMemo } from 'react';
import { useReactFlow } from '@xyflow/react';
import { BooleanNode, WorkflowNodeProps } from './BaseNode';
import { GitFork } from 'lucide-react';
import { useAutocomplete } from './useAutocomplete';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';
import { useIntl } from 'react-intl';
import { minHeights, minWidths } from './minDimensions';
import ConditionInput, { ConditionMode } from '../ConditionInput';
import {
  ConditionOperator,
  ConditionVariable,
} from '../../../components/ConditionBuilder/types';

/**
 * A node to perform a boolean check and branch the workflows down one of two paths
 */
export function Condition(
  props: WorkflowNodeProps<{
    condition?: string;
    conditionMode?: ConditionMode;
  }>
): ReactElement {
  const intl = useIntl();
  const { workflowId } = useWorkflowId();
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const isExecution = Boolean(props.data.execution);
  const isPreview = useIsPreview();
  const handleChange = useCallback(
    (value: string) => {
      update(props.id, {
        condition: value,
      });
    },
    [props.id, update]
  );

  const variables: ConditionVariable[] = useMemo(
    () => [
      {
        value: 'meeting.title',
        type: 'string',
        label: intl.formatMessage({ defaultMessage: 'Meeting Title' }),
        defaultOperator: ConditionOperator.Contains,
      },
      {
        value: 'meeting.url',
        type: 'string',
        label: intl.formatMessage({ defaultMessage: 'Meeting Url' }),
        defaultOperator: ConditionOperator.Contains,
      },
      {
        value: 'meeting.date',
        type: 'date',
        label: intl.formatMessage({ defaultMessage: 'Meeting Date' }),
        defaultOperator: ConditionOperator.Equal,
      },
      {
        value: 'meeting.notes',
        type: 'string',
        label: intl.formatMessage({ defaultMessage: 'Meeting Notes' }),
        defaultOperator: ConditionOperator.Contains,
      },
      ...autocomplete.variables
        .filter((x) => x.label.includes('.output'))
        .map((x) => ({
          value: x.label,
          type: 'string' as const,
          label: x.label,
          defaultOperator: ConditionOperator.Contains,
        })),
    ],
    [intl, JSON.stringify(autocomplete.variables)]
  );

  return (
    <BooleanNode
      icon={
        <GitFork className="size-8 rotate-180 rounded-lg border border-sky-500/25 bg-sky-50 p-1.5 text-sky-500" />
      }
      workflowNode={props}
      minHeight={minHeights.Condition}
      minWidth={minWidths.Condition}
    >
      <ConditionInput
        className="min-h-40 flex-grow text-base"
        nodeType={props.type}
        autocomplete={autocomplete}
        workflowId={workflowId}
        value={props.data.condition ?? ''}
        onChange={handleChange}
        conditionMode={props.data.conditionMode}
        setConditionMode={(conditionMode) =>
          update(props.id, { conditionMode })
        }
        disabled={isExecution || isPreview}
        placeholder={intl.formatMessage({
          defaultMessage: 'Enter a condition that needs to be met',
        })}
        variables={variables}
        ariaLabel={intl.formatMessage({
          defaultMessage: 'Condition input',
        })}
      />
    </BooleanNode>
  );
}
