import { endOfDay, isEqual, startOfDay, subDays } from 'date-fns';
import { CalendarDays, ChevronDown, ChevronLeft, X } from 'lucide-react';
import React, { ReactElement, ReactNode } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { FormattedMessage } from 'react-intl';
import { Button } from '../buttons';
import { Menu } from '../Menu';
import { cx } from '../../helpers/utils';

const classes = ({ singleDateSelected }: { singleDateSelected: boolean }) => ({
  root: 'relative text-sm',
  caption: 'text-center w-full',
  caption_label: 'pt-1 pb-4 pl-2 leading-none font-semibold',
  table: 'w-full text-center border-collapse',
  head_cell: 'text-xs font-normal p-0 py-1',
  nav_button_previous: 'absolute left-0 top-0 py-2',
  nav_button_next: 'absolute right-0 top-0 py-2',
  button_next: 'rotate-180 fill-transparent',
  chevron: 'fill-transparent',
  day_button: 'size-8 my-1',
  disabled: 'text-neutral-disabled hover-bg-transparent',
  range_start: cx(
    'bg-accent-primary text-onAccent-solid',
    singleDateSelected ? 'rounded' : 'rounded-r-none rounded-l'
  ),
  range_end: cx(
    'bg-accent-primary text-onAccent-solid',
    singleDateSelected ? 'rounded' : 'rounded-r rounded-l-none'
  ),
  range_middle: 'rounded-none bg-accent-secondary !text-accent-secondary',
  today: 'text-accent-primary',
  weekday: 'text-center text-neutral-subtle text-xs font-normal p-0 py-1',
  outside: 'text-neutral-disabled',
  selected:
    'text-sm rounded-md bg-accent-primary text-onAccent-solid font-bold',
});
/**
 * A searchable multi select
 */
export function DateRangePicker(props: {
  label: ReactNode;
  onChange: (next: { from: Date; to: Date } | undefined) => void;
  value: { from: Date; to: Date } | undefined;
}): ReactElement {
  const { onChange, value, label } = props;
  const todayStart = startOfDay(new Date());
  const todayEnd = endOfDay(new Date());

  const onClickToday = () => onChange({ from: todayStart, to: todayEnd });
  const onClickLastWeek = () =>
    onChange({ from: subDays(todayStart, 6), to: todayEnd });
  const onClickLastMonth = () =>
    onChange({ from: subDays(todayStart, 29), to: todayEnd });
  const onClear = () => onChange(undefined);
  const hasValue = Boolean(value);
  const singleDateSelected = value ? isEqual(value?.from, value?.to) : false;

  const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Menu>
      <Menu.Trigger>
        <Button variant="neutral-secondary" size="small" hasValue={hasValue}>
          <span>
            {label}
            {value && <span>:</span>}
          </span>
          {value
            ? value.from && value.to
              ? dateTimeFormat.formatRange(value?.from, value?.to)
              : dateTimeFormat.format(value.from)
            : null}
          <span className="ml-auto flex-shrink-0">
            {hasValue ? (
              <X size="1rem" onClick={onClear} />
            ) : (
              <ChevronDown size="1rem" />
            )}
          </span>
        </Button>
      </Menu.Trigger>
      <div className="flex items-start gap-2">
        <div className="flex flex-grow flex-col">
          <Menu.Item onClick={onClickToday} icon={<CalendarDays size="1rem" />}>
            <FormattedMessage defaultMessage="Today" />
          </Menu.Item>
          <Menu.Item
            onClick={onClickLastWeek}
            icon={<CalendarDays size="1rem" />}
          >
            <FormattedMessage defaultMessage="Last 7 days" />
          </Menu.Item>
          <Menu.Item
            onClick={onClickLastMonth}
            icon={<CalendarDays size="1rem" />}
          >
            <FormattedMessage defaultMessage="Last 30 days" />
          </Menu.Item>
        </div>
        <Menu.Item onClick={onClear} className="flex-grow-0">
          <FormattedMessage defaultMessage="Clear" />
        </Menu.Item>
      </div>
      <Menu.Divider />
      <DayPicker
        showOutsideDays
        defaultMonth={value?.from ?? todayStart}
        selected={value}
        onSelect={({ from, to } = { from: undefined, to: undefined }) => {
          if (from && to) return onChange({ from, to });
          if (from && !to) return onChange({ from, to: endOfDay(from) });
          return onChange(undefined);
        }}
        mode="range"
        components={{
          Chevron: ChevronLeft,
        }}
        disabled={{ after: todayEnd }}
        classNames={classes({ singleDateSelected })}
      />
    </Menu>
  );
}

export function DatePicker(props: {
  label?: ReactNode;
  onChange: (next: Date | undefined) => void;
  value: Date | undefined;
}): ReactElement {
  const { onChange, value, label } = props;
  const todayStart = startOfDay(new Date());
  const todayEnd = endOfDay(new Date());

  const onClickToday = () => onChange(todayStart);
  const onClear = () => onChange(undefined);
  const hasValue = Boolean(value);

  const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Menu>
      <Menu.Trigger>
        <Button variant="neutral-secondary" size="small" hasValue={hasValue}>
          {label && (
            <span>
              {label}
              {value && <span>:</span>}
            </span>
          )}
          {value ? dateTimeFormat.format(value) : null}
          <span className="ml-auto flex-shrink-0">
            <ChevronDown size="1rem" />
          </span>
        </Button>
      </Menu.Trigger>
      <div className="flex items-start gap-2">
        <div className="flex flex-grow flex-col">
          <Menu.Item onClick={onClickToday} icon={<CalendarDays size="1rem" />}>
            <FormattedMessage defaultMessage="Today" />
          </Menu.Item>
        </div>
        <Menu.Item onClick={onClear} className="flex-grow-0">
          <FormattedMessage defaultMessage="Clear" />
        </Menu.Item>
      </div>
      <Menu.Divider />
      <DayPicker
        showOutsideDays
        defaultMonth={value}
        selected={value}
        onSelect={onChange}
        mode="single"
        components={{ Chevron: ChevronLeft }}
        disabled={{ after: todayEnd }}
        classNames={classes({ singleDateSelected: true })}
      />
    </Menu>
  );
}
