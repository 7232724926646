import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import icon from '../../../img/slackIcon.svg';
import { Loading } from '../../Landing/Loading/LoadingComponent';
import { api2post } from '../../../helpers/api2';
import {
  IntegrationImplementation,
  IntegrationShare,
} from '../../../models/integration';
import { BlockGroup, logger } from '@tactiq/model';
import { HIGHLIGHT_TAG_GQL, TranscriptBlock } from '../../../models/meeting';
import { createOAuthConnector } from './connector';
import { trackWebEvent } from '../../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import { UserIntegrationConnection } from '../../../graphql/operations';
import { Hash } from 'lucide-react';
import { Button } from '../../../components/buttons';

const ShareDialog: React.FC<{
  connection: UserIntegrationConnection;
  share: (
    integration: IntegrationImplementation,
    connection: UserIntegrationConnection,
    options: any
  ) => Promise<void>;
}> = (props) => {
  const { connection, share } = props;
  const [channels, setChannels] = useState<
    { id: string; name: string }[] | null
  >(null);

  useAsyncEffect(async () => {
    if (connection) {
      const result = await api2post('a/integrations/slack/channels', {
        connectionKey: connection.connectionKey,
      });
      setChannels(result.channels);
    }
  }, [connection]);

  return (
    <div>
      <div>
        <FormattedMessage
          defaultMessage="Please select the channel."
          description="Slack integration. Select channel dialog title."
        />
      </div>
      {channels ? (
        <ul className="flex w-full flex-col gap-2 py-4">
          {channels.map((x) => (
            <li key={x.id}>
              <Button
                key={x.id}
                className="w-full"
                onClick={() => {
                  trackWebEvent('Selected a channel in Slack to share into');
                  share(integration, connection, {
                    channelId: x.id,
                    channelName: x.name,
                  }).catch(logger.error);
                }}
                variant="naked"
                startIcon={<Hash size="1rem" />}
              >
                {x.name}
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const {
    title,
    meetingDetails,
    highlights,
    footerLink,
    footerLinkText,
    isSharingDetails,
    isSharingHighlights,
    isSharingTranscript,
  } = options;
  const { start, duration, participants } = meetingDetails;

  const children = [];

  if (isSharingDetails) {
    children.push(
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: title,
          emoji: true,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: `:date: Started ${start}`,
          },
        ],
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: `:stopwatch: Lasted ${duration}`,
          },
        ],
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: `:busts_in_silhouette: ${participants}`,
          },
        ],
      }
    );
  }

  if (isSharingHighlights) {
    if (isSharingDetails && highlights?.length) {
      children.push({
        type: 'divider',
      });
    }

    children.push(
      ...highlights.slice(0, 40).map((x: BlockGroup<TranscriptBlock>) => ({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `${x.blocks[0].fromStart} ${
            x.isPinned ? HIGHLIGHT_TAG_GQL.icon : x.tags[0].icon
          } *${x.speakerName}*: ${x.transcript}`,
        },
      }))
    );
  }

  if (isSharingTranscript) {
    if (isSharingDetails || (isSharingHighlights && highlights?.length)) {
      children.push({
        type: 'divider',
      });
    }

    children.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: 'Transcribed by Tactiq with :heart:',
      },
      accessory: {
        type: 'button',
        text: {
          type: 'plain_text',
          text: footerLinkText,
          emoji: true,
        },
        url: footerLink,
      },
    });
  }

  await api2post('a/integrations/slack/message', {
    connectionKey: connection.connectionKey,
    content: {
      channel: options.channelId,
      blocks: children,
    },
  });

  return { link: '' };
};

export const integration: IntegrationImplementation = {
  id: 'slack',
  title: 'Slack',
  icon,
  description: () => (
    <div className="text-slate-500">
      <FormattedMessage
        defaultMessage="Send your transcripts, highlights, and tags to Slack."
        description="Slack integration description"
      />
    </div>
  ),
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Send a message"
      description="Slack integration share description"
    />
  ),
  connector: createOAuthConnector('slack', 'Slack'),
  allowMultipleConnections: true,
  share: shareImpl,
  ShareDialog,
  getSharingDestinationTitle: (options) => `#${options.channelName}`,
};

export default integration;
