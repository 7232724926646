import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size as floatingUISize,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import { cx, usePortalRoot } from '../../helpers/utils';
import { Check, ChevronDown } from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../Spinner';
import { Tooltip } from '../Tooltip';
import { tv } from 'tailwind-variants';

/**
 * Helper types to simplify conditional handling
 */
type SingleOrArray<T, Multiple extends boolean> = Multiple extends true
  ? T[]
  : T;
type OnChangeHandler<T, Multiple extends boolean> = (
  next: SingleOrArray<T, Multiple>
) => void;

const selectClasses = tv({
  base: 'inline-flex min-h-9 items-center gap-2 truncate rounded-input border border-slate-200 py-1 pr-2 pl-2.5 font-medium text-sm shadow-sm hover:border-slate-400/50 hover:bg-slate-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-100',
  variants: {
    full: {
      true: 'w-full',
      false: 'max-w-64',
    },
    disabled: {
      true: 'pointer-events-none cursor-default bg-slate-100 text-slate-400',
      false: 'cursor-pointer bg-white-900',
    },
    size: {
      small: 'min-h-8 gap-1 pr-1 text-xs hover:bg-neutral-muted',
    },
    variant: {
      naked:
        'border-transparent shadow-transparent hover:border-transparent focus-visible:ring-transparent',
    },
  },
});

/**
 * Define Select component props with conditional types for single/multiple select modes.
 */
type SelectProps<T, Multiple extends boolean = false> = {
  multiple?: Multiple;
  variant?: keyof typeof selectClasses.variants.variant;
  size?: keyof typeof selectClasses.variants.size;
  value: SingleOrArray<T, Multiple> | undefined;
  disabled?: boolean;
  full?: boolean;
  onChange: OnChangeHandler<T, Multiple>;
  options: Array<{
    value: T;
    label: string;
    icon?: ReactNode;
    divider?: 'top' | 'bottom';
    selectable?: boolean;
    tooltip?: boolean;
  }>;
  placeholder?: ReactNode;
  onOpenChange?: (open: boolean) => void;
  loading?: boolean;
  role?: string;
};

export function Select<T, Multiple extends boolean = false>({
  value,
  options,
  onChange,
  placeholder,
  disabled,
  variant,
  full,
  size,
  onOpenChange,
  loading,
  multiple,
  role,
}: SelectProps<T, Multiple>): ReactElement {
  const root = usePortalRoot();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const getInitialSelectedIndexes = () => {
    if (multiple && Array.isArray(value)) {
      return options.reduce((acc, o, index) => {
        if (value.includes(o.value)) acc.push(index);
        return acc;
      }, [] as number[]);
    }
    if (value !== null) {
      return [options.findIndex((o) => o.value === value)];
    }
    return [];
  };

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    getInitialSelectedIndexes
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(options.length):ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(value):ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(multiple):ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(getInitialSelectedIndexes):ignore
  useEffect(() => {
    setSelectedIndexes(getInitialSelectedIndexes());
  }, [options.length, value, multiple]);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(size === 'small' ? 0 : 5),
      flip({ padding: 10 }),
      floatingUISize({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(options.map((ii) => ii.label));
  const isTypingRef = useRef(false);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'mousedown' }),
      useDismiss(context),
      useRole(context, { role: 'listbox' }),
      useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex: selectedIndexes.length ? selectedIndexes[0] : null,
        onNavigate: setActiveIndex,
        loop: true,
      }),
      useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        selectedIndex: selectedIndexes.length ? selectedIndexes[0] : null,
        onMatch: isOpen ? setActiveIndex : undefined,
        onTypingChange(isTyping) {
          isTypingRef.current = isTyping;
        },
      }),
    ]
  );

  const handleSelect = (index: number) => {
    const selectable = options[index].selectable ?? true;
    const selectedOption = options[index]?.value; // Add a check for valid index

    if (multiple) {
      let newSelectedValues: T[];
      if (Array.isArray(value)) {
        newSelectedValues = value.includes(selectedOption)
          ? value.filter((v) => v !== selectedOption)
          : [...value, selectedOption];
      } else {
        newSelectedValues = [selectedOption];
      }
      if (selectable) {
        setSelectedIndexes(
          newSelectedValues.map((v) => options.findIndex((o) => o.value === v))
        );
      }
      onChange(selectedOption as SingleOrArray<T, Multiple>); // Casting to SingleOrArray<T, Multiple>
    } else {
      if (selectable) setSelectedIndexes([index]);
      onChange(selectedOption as SingleOrArray<T, Multiple>); // Casting to SingleOrArray<T, Multiple>
      handleOpenChange(false);
    }
  };

  // Ensure selectedIndexes are valid before accessing options
  const selectedLabels = selectedIndexes
    .filter((index) => options[index]) // Filter out invalid indexes
    .map((index) => options[index].label)
    .join(', ');

  const selected =
    selectedIndexes.length && options[selectedIndexes[0]]
      ? options[selectedIndexes[0]]
      : null;

  const renderPlaceholder = () => (
    <span className="text-slate-500">
      {placeholder ?? <FormattedMessage defaultMessage="Select" />}
    </span>
  );

  let selectedLabelDisplay: string | JSX.Element;
  const renderedPlaceholder = renderPlaceholder();
  if (multiple) {
    selectedLabelDisplay =
      selectedLabels.length > 0 ? selectedLabels : renderedPlaceholder;
  } else {
    selectedLabelDisplay = selected?.label ?? renderedPlaceholder;
  }

  const label = (label: string, tooltip?: boolean) => {
    const labelEl = <span className="truncate">{label}</span>;
    return tooltip ? <Tooltip title={label}>{labelEl}</Tooltip> : labelEl;
  };

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        role={role}
        aria-autocomplete="none"
        aria-label={selectedLabelDisplay as string}
        {...getReferenceProps()}
        className={selectClasses({ variant, full, size, disabled })}
      >
        {selected?.icon && <div className="flex-shrink-0">{selected.icon}</div>}
        <span className="truncate">{selectedLabelDisplay}</span>
        <span className="ml-auto flex-shrink-0">
          {loading ? <Spinner /> : <ChevronDown size="1rem" />}
        </span>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <FloatingPortal root={root}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              className="z-Menu flex max-h-[30vh] max-w-64 flex-col gap-1 overflow-auto rounded-lg border border-slate-200 bg-white p-2 shadow-lg shadow-slate-600/20 focus:outline-none"
            >
              {options.map((o, i) => (
                <Fragment key={String(o.value)}>
                  {o.divider === 'top' && <hr className="-mx-2 my-1" />}
                  <div
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    // biome-ignore lint/a11y/useSemanticElements:ignore
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={selectedIndexes.includes(i)}
                    {...getItemProps({
                      onClick: () => handleSelect(i),
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                    className={cx(
                      'flex flex-shrink-0 cursor-pointer items-center gap-2 overflow-hidden truncate rounded px-2 py-1 text-left text-sm',
                      size ? 'text-xs' : 'text-sm',
                      selectedIndexes.includes(i)
                        ? 'bg-brand-500 text-white focus:bg-brand-500'
                        : 'bg-white',
                      'disabled:cursor-default',
                      'focus:bg-brand-100 focus:outline-none',
                      o.divider === 'top' ? 'border-t-slate-300' : '',
                      o.divider === 'bottom' ? 'border-b-slate-300' : ''
                    )}
                  >
                    <span aria-hidden className="size-4 flex-shrink-0">
                      {selectedIndexes.includes(i) && <Check size="1rem" />}
                    </span>
                    {o.icon && <div className="flex-shrink-0">{o.icon}</div>}
                    {label(o.label, o.tooltip)}
                  </div>
                  {o.divider === 'bottom' && <hr className="-mx-2 my-1" />}
                </Fragment>
              ))}
            </div>
          </FloatingPortal>
        </FloatingFocusManager>
      )}
    </>
  );
}
