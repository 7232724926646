import { ClickToComponent } from 'click-to-react-component';
import { ApolloProvider } from '@apollo/client';
import { I18nProvider, Locale } from '@tactiq/i18n';
import { logger } from '@tactiq/model';
import { initializeApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { SnackbarProvider } from 'notistack';
import React, { StrictMode, useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { TactiqRoutes } from './Routes';
import { ErrorBoundary } from './app/Common/ErrorBoundary';
import {
  ErrorToast,
  InfoToast,
  SuccessToast,
  WarningToast,
} from './components/toastNotification';
import { ApolloClientFactory } from './graphql/client';
import { getEverything } from './graphql/user';
import {
  setPreviousGoogleLogin,
  syncLoginWithExtension,
} from './helpers/authentication';
import { trackAppVisitor } from './helpers/facebook';
import featureFlags, { FeatureFlagProvider } from './helpers/feature-flags';
import { resubscribeToUserChanges } from './helpers/firebase/api/user';
import { firebaseConfig } from './helpers/firebase/config';
import { loadI18nMessages } from './helpers/i18n';
import { setStartedUp } from './redux/modules/global';
import { clearUserData, updateBasicDetails } from './redux/modules/user';
import { store } from './redux/store';
import { MeetingListSubscriptionProvider } from './app/Transcripts/MeetingListSubscriptionProvider';
import { trackPageTranslation } from './helpers/analytics';
import { IntegrationProvider } from './services/Integration';

initializeApp(firebaseConfig);

const appElement = document.getElementById('app') as HTMLElement;

const App: React.FC = () => {
  const [preferredLocale, setPreferredLocale] = useState<Locale | undefined>();
  const [isAuthStateInitialized, setIsAuthStateInitialized] = useState(false);
  const [client, setClient] =
    useState<(typeof ApolloClientFactory)['_client']>();
  useEffect(() => {
    async function init() {
      const apolloClient = await ApolloClientFactory.getClient();
      setClient(apolloClient);
    }

    init().catch(logger.error);
  });
  const onStoreUpdate = useCallback(() => {
    const { user } = store.getState();

    if (user.settings?.lang) {
      setPreferredLocale(user.settings.lang as Locale);
    }
  }, []);
  const onAuthStateUpdate = useCallback(async (u: User | null) => {
    logger.configure({
      parameters: {
        uid: u?.uid ?? 'anonymous',
      },
    });

    await featureFlags.updateContext({
      userId: u?.uid ?? 'anonymous',
      domain: u?.email?.split('@')[1] ?? 'anonymous',
    });
    await featureFlags.start();

    setIsAuthStateInitialized(true);

    if (u?.uid) {
      const user = u as unknown as {
        uid: string;
        displayName: string;
        email: string;
        photoURL: string;
      };
      store.dispatch(
        updateBasicDetails({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        })
      );
      store.dispatch(setStartedUp());
      await resubscribeToUserChanges(user.uid, store.dispatch);
      window.Intercom &&
        window.Intercom('boot', {
          app_id: 'l68txapg',
          email: user.email,
          user_id: user.uid,
        });

      await getEverything(user.uid, store.dispatch);
    } else {
      store.dispatch(clearUserData());
      store.dispatch(setStartedUp());
      window.Intercom &&
        window.Intercom('boot', {
          app_id: 'l68txapg',
        });
    }

    trackAppVisitor(u);

    if (!store.getState().global.signedOut) {
      const syncResult = await syncLoginWithExtension(u);

      if (syncResult.redirectTo) {
        // TODO: implement
        logger.info('REDIRECT NEEDED', syncResult.redirectTo);
      } else {
        if (syncResult.loggedIn) {
          setPreviousGoogleLogin(syncResult.email);
        } else {
          // TODO: figure out what happens when you don't have an extension (right now it signs you out, undesired!)
          // store.dispatch(signOut());
          // window.Intercom('boot', {
          //   app_id: 'l68txapg',
          // });
        }
      }
    }
  }, []);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), onAuthStateUpdate);
  }, [onAuthStateUpdate]);
  useEffect(() => {
    return store.subscribe(onStoreUpdate);
  }, [onStoreUpdate]);

  // This is checking for a language change in the document as a translation
  useEffect(() => {
    const originalLanguage = document.documentElement.lang;

    const observer = new MutationObserver(() => {
      const lang = document.documentElement.lang;
      const { user } = store.getState();
      if (lang !== originalLanguage) {
        logger.info('Page translation detected', lang);
        trackPageTranslation(lang, user.id || 'auto translate');
      }
    });

    // Looking for lang changes on every browser but edge
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['lang'],
      childList: false,
      characterData: false,
    });
    return () => observer.disconnect();
  }, []);

  if (!isAuthStateInitialized) {
    return null;
  }

  if (!client) {
    return null;
  }

  return (
    <StrictMode>
      <I18nProvider
        selectedLocale={preferredLocale}
        loadMessages={loadI18nMessages}
      >
        <FeatureFlagProvider value={featureFlags}>
          <ErrorBoundary>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <IntegrationProvider>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    hideIconVariant={false}
                    preventDuplicate
                    Components={{
                      SUCCESS: SuccessToast,
                      ERROR: ErrorToast,
                      INFO: InfoToast,
                      WARNING: WarningToast,
                    }}
                  >
                    <MeetingListSubscriptionProvider>
                      <TactiqRoutes />
                    </MeetingListSubscriptionProvider>
                  </SnackbarProvider>
                </IntegrationProvider>
              </ApolloProvider>
            </Provider>
          </ErrorBoundary>
          <ClickToComponent />
        </FeatureFlagProvider>
      </I18nProvider>
    </StrictMode>
  );
};

const root = createRoot(appElement);
root.render(<App />);
