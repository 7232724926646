import { useSelector } from 'react-redux';
import { selectUsedMeetingKitsSelector } from '../redux/selectors';
import { RootState } from '../redux/store';

export function useUsedKits() {
  const usedKits = useSelector(selectUsedMeetingKitsSelector);
  return usedKits;
}

export function useExploreKits() {
  const exploreKits = useSelector(
    (state: RootState) => state.global.meetingKits.explore
  );
  return exploreKits;
}

export function useSystemKit() {
  const systemKit = useSelector(
    (state: RootState) => state.global.meetingKits.system
  );
  return systemKit;
}
