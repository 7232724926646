import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Banner } from '../../../components/Banner';
import { Button } from '../../../components/buttons';
import { Tooltip } from '../../../components/Tooltip';
import {
  trackCompanionAppBannerDismissed,
  trackCompanionAppBannerInstallButtonClicked,
  trackCompanionAppBannerViewed,
} from '../../../helpers/analytics';
import { useShouldShowCompanionAppBanner } from '../../../services/Auth';
import { MacAppDownloadModal } from '../../Setup/AlertDownloadMacApp';

export const DownloadCompanionAppBanner: React.FC = () => {
  const { hasDismissedBanner, userHasApp, isZoomUser } =
    useShouldShowCompanionAppBanner();
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [bannerDismissed, setBannerDismissed] = useState(hasDismissedBanner);

  useEffect(() => {
    if (isZoomUser && !hasDismissedBanner) trackCompanionAppBannerViewed();
  }, [isZoomUser, hasDismissedBanner]);

  const showBanner = !bannerDismissed && !userHasApp && isZoomUser;

  return (
    <>
      {showBanner && (
        <Banner>
          <div className="m-auto flex items-center gap-4">
            <FormattedMessage defaultMessage="Tactiq is now available as a standalone app for Mac" />
            <Button
              size="small"
              variant="neutral-primary"
              onClick={() => {
                trackCompanionAppBannerInstallButtonClicked();
                setBannerDismissed(true);
                setShowDownloadDialog(true);
              }}
            >
              <FormattedMessage defaultMessage="Install Now" />
            </Button>
            <Tooltip title={<FormattedMessage defaultMessage="Dismiss" />}>
              <Button
                className="-ml-3"
                onClick={() => {
                  trackCompanionAppBannerDismissed();
                  localStorage.setItem(
                    'dismissedCompanionAppDownloadBanner',
                    Date.now().toString()
                  );
                  setBannerDismissed(true);
                }}
                variant="neutral-tertiary"
              >
                <X className="size-5" />
              </Button>
            </Tooltip>
          </div>
        </Banner>
      )}

      {showDownloadDialog && (
        <MacAppDownloadModal onClose={() => setShowDownloadDialog(false)} />
      )}
    </>
  );
};
