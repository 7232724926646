import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PreviewTeamUpgradeInvoiceDocument,
  TeamPreviewUpgradeInvoice,
} from '../../../graphql/operations';
import { PreviewInvoice } from '../../Credits/PreviewInvoice';
import { useMutation } from '@apollo/client';

export const AddTeamSeatsDialog: React.FC<{
  open: boolean;
  membersCount: number;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}> = ({ open, membersCount, onClose, onConfirm }) => {
  const [previewTeamUpgradeInvoice] = useMutation(
    PreviewTeamUpgradeInvoiceDocument
  );

  return (
    <PreviewInvoice
      invoiceProducer={async function (
        approved: boolean
      ): Promise<TeamPreviewUpgradeInvoice | undefined | false> {
        if (approved) {
          await onConfirm();
          return Promise.resolve(undefined);
        } else {
          return (
            await previewTeamUpgradeInvoice({
              variables: { input: { quantity: membersCount } },
            })
          )?.data?.team_billing_previewUpgradeInvoice?.invoice;
        }
      }}
      open={open}
      onClose={onClose}
      tag="team-upgrade"
      description={
        <FormattedMessage
          defaultMessage="Upgrade {membersCount} members to Tactiq Team."
          values={{ membersCount: <b>{membersCount}</b> }}
        />
      }
    />
  );
};
