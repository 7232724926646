import { useMutation } from '@apollo/client';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import {
  AcceptSubscriptionMoveDocument,
  CancelSubscriptionMoveDocument,
  UserSettingsSubscriptionMoveStatus,
} from '../../graphql/operations';
import { selectUid, selectUserSettings } from '../../redux/selectors';
import { Alert } from '../../components/Alert';

export const RequestSubscriptionMoveStatus: React.FC = () => {
  const userId = useSelector(selectUid);
  const userSettings = useSelector(selectUserSettings);
  const requestedSubscriptionMove = userSettings.requestedSubscriptionMove;

  const [acceptSubscriptionMove, acceptSubscriptionMoveMutation] = useMutation(
    AcceptSubscriptionMoveDocument
  );
  const [cancelSubscriptionMove, cancelSubscriptionMoveMutation] = useMutation(
    CancelSubscriptionMoveDocument
  );

  if (!requestedSubscriptionMove) return null;

  return (
    <div className="flex flex-1 flex-col gap-2">
      {requestedSubscriptionMove ? (
        <>
          <Alert
            severity="error"
            description={
              <>
                <FormattedMessage
                  defaultMessage="Subscription move requested on {date} at {time} "
                  values={{
                    date: (
                      <FormattedDate
                        value={
                          new Date(requestedSubscriptionMove.requestedAt * 1000)
                        }
                      />
                    ),
                    time: (
                      <FormattedTime
                        value={
                          new Date(requestedSubscriptionMove.requestedAt * 1000)
                        }
                      />
                    ),
                  }}
                />
                {requestedSubscriptionMove.sourceUid === userId ? (
                  <FormattedMessage
                    defaultMessage="into {email}"
                    id="ILVeW/"
                    values={{
                      email: requestedSubscriptionMove.targetEmail,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="from {email}"
                    values={{
                      email: requestedSubscriptionMove.sourceEmail,
                    }}
                  />
                )}
              </>
            }
          />
        </>
      ) : null}
      {requestedSubscriptionMove?.status ===
      UserSettingsSubscriptionMoveStatus.PENDING ? (
        <>
          <Alert
            severity="info"
            action={
              requestedSubscriptionMove?.sourceUid === userId ? (
                <Button
                  loading={cancelSubscriptionMoveMutation.loading}
                  onClick={() => {
                    return cancelSubscriptionMove();
                  }}
                >
                  <FormattedMessage defaultMessage="Cancel subscription move" />
                </Button>
              ) : (
                <Button
                  loading={acceptSubscriptionMoveMutation.loading}
                  onClick={() => {
                    return acceptSubscriptionMove();
                  }}
                >
                  <FormattedMessage defaultMessage="Accept subscription move" />
                </Button>
              )
            }
            description={
              requestedSubscriptionMove?.sourceUid === userId ? (
                <FormattedMessage defaultMessage="Waiting for the target account to accept the move." />
              ) : (
                <FormattedMessage defaultMessage="Waiting for you to accept the move." />
              )
            }
          />
        </>
      ) : null}
    </div>
  );
};
