import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PlayIcon } from 'lucide-react';

import { Button } from '../../components/buttons';
import { Workflow } from '../../graphql/operations';
import { Tooltip } from '../../components/Tooltip';
import { useWorkflowRun } from '../../services/Workflow';
import DuplicateWorkflowButton from './DuplicateWorkflowButton';
import { useCurrentUserId } from '../../services/User';
import CopyWorkflowLinkButton from './CopyWorkflowLinkButton';
import MeetingSearch from './MeetingSearch';

type WorkflowActionsButtonProps = {
  workflow: Workflow;
  preview?: boolean;
  /** Called once the workflowRun request returns */
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  /** If a meetingId is not provided, the search box will pop up instead
   * not used anywhere this component is rendered, probably can remove it
   */
  meetingId?: string;
  onSave?: () => Promise<void>;
  loading?: boolean;
  hasUnsavedChanges?: boolean;
};

/**
 * Wraps up all the render logic required to be able to perform actions on a workflow.
 * - are you allowed to run this workflow
 * - should it save before trying to run
 */
const WorkflowActionsButton = ({
  workflow,
  preview,
  hasUnsavedChanges,
  onSave,
  onSuccess,
  loading,
  meetingId,
}: WorkflowActionsButtonProps) => {
  const intl = useIntl();

  const currentUserId = useCurrentUserId();
  const workflowRun = useWorkflowRun(onSave ? 'editor' : 'meeting');
  const [showMeetingSearch, setShowMeetingSearch] = useState(false);

  const isUserWorkflowOwner = workflow.createdBy.uid === currentUserId;

  const title = !workflow.canRun
    ? intl.formatMessage({
        description: 'Tooltip text for the workflow not being runnable',
        defaultMessage: 'This workflow cannot be run',
      })
    : intl.formatMessage({
        description: 'Tooltip text for the workflow being runnable',
        defaultMessage: 'Run the workflow',
      });

  const showSaveButton = workflow.canModify && onSave;
  const showRunButton = isUserWorkflowOwner && workflow.canRun;

  const handleOnSelectMeeting = async (meetingId: string) => {
    setShowMeetingSearch(false);

    if (onSave) await onSave();

    const execution = await workflowRun.request({
      input: { id: workflow.id, meetingId: meetingId },
    });

    const executionId = execution.data?.runWorkflow.id;

    if (executionId) onSuccess({ meetingId, executionId });
  };

  const handleOnRunButtonClick = async () => {
    if (!meetingId) setShowMeetingSearch(true);
    else {
      setShowMeetingSearch(false);

      if (onSave) await onSave();

      const execution = await workflowRun.request({
        input: { id: workflow.id, meetingId: meetingId },
      });

      const executionId = execution.data?.runWorkflow.id;

      if (executionId) onSuccess({ meetingId, executionId });
    }
  };

  const handleOnSaveButtonClick = () => {
    if (onSave) onSave();
  };

  return (
    <>
      {showMeetingSearch && (
        <MeetingSearch
          open={showMeetingSearch}
          setOpen={setShowMeetingSearch}
          onSelectMeeting={handleOnSelectMeeting}
        />
      )}

      <div className="flex items-center gap-x-2">
        <CopyWorkflowLinkButton
          workflow={workflow}
          source="preview"
          withMessage={preview}
        />

        <DuplicateWorkflowButton
          title={
            isUserWorkflowOwner ? (
              <FormattedMessage defaultMessage="Duplicate workflow" />
            ) : (
              <FormattedMessage
                defaultMessage="To modify this workflow, or create a copy for yourself, duplicate it."
                description="Duplicate a workflow. Tooltip text for a user who is not the owner of the workflow."
              />
            )
          }
          variant={showRunButton ? 'neutral-secondary' : 'filled'}
          withLabel={true}
          workflowId={workflow.id}
          className="h-9"
        />

        {showSaveButton && (
          <Button
            variant="neutral-secondary"
            loading={loading}
            disabled={!hasUnsavedChanges}
            onClick={handleOnSaveButtonClick}
            startIcon={
              hasUnsavedChanges ? (
                <div className="size-1.5 rounded-full bg-accent-primary" />
              ) : undefined
            }
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
        )}

        {showRunButton && (
          <Tooltip title={title} arrow placement="bottom-end">
            <div className="text-slate-400 text-sm">
              <Button
                variant={workflow.canRun ? 'filled' : 'neutral-secondary'}
                loading={workflowRun.loading}
                disabled={!workflow.canRun}
                onClick={handleOnRunButtonClick}
                startIcon={<PlayIcon size="1rem" />}
              >
                <FormattedMessage defaultMessage="Run" />
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default WorkflowActionsButton;
