import { useMutation, useQuery } from '@apollo/client';
import { logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { resumeSubscription } from '../../graphql/billing';
import {
  ClaimTeamSeatDocument,
  BillingStatus,
  CreateStripePortalLinkDocument,
  CreateTeamPortalLinkDocument,
  GetTeamPlanDetailsDocument,
  PaypalPaidPlan,
  ResumeTeamSubscriptionDocument,
  StripePaidPlan,
  StripePaidPlanFieldsFragment,
  TeamMemberStatus,
  TeamPlanDetails,
  TeamTier,
  UpcomingUserInvoice,
  UserTier,
} from '../../graphql/operations';
import { getUpcomingInvoice } from '../../graphql/user';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../helpers/analytics';
import { isProduction } from '../../helpers/firebase/config';
import { gotTeam } from '../../redux/modules/user';
import {
  selectIsUserPaidByTeam,
  selectTeam,
  selectUid,
  selectUserSettings,
  selectUserTier,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { PaymentPastDueAlert } from '../Common/PaymentPastDueAlert';
import {
  ProCancellationDialog,
  TeamCancellationDialog,
} from './CancellationDialog';
import { ChangeTeamSeatsDialog } from './ChangeTeamSeatsDialog';
import { RequestSubscriptionMoveDialog } from './RequestSubscriptionMoveDialog';
import { RequestSubscriptionMoveStatus } from './RequestSubscriptionMoveStatus';
import { useIsRenewalDisabled } from './ResumeSubscriptionToast';
import { SwitchToCardBillingDialog } from './SwitchToCardBillingDialog';
import { UpgradePlanAlert } from './UpgradeToAnnualPlan';
import { Alert } from '../../components/Alert';
import { DowngradeTeamToProDialog } from '../Common/upgrade-dialog/DowngradeTeamToPro';
import { TierPricingDialog } from './TierPricing/TierPricingDialog';
import { Spinner } from '../../components/Spinner';
import PlanName from './PlanName';

const getStripePrice = (plan: StripePaidPlanFieldsFragment): number => {
  if (plan.amountOff) {
    return (plan.price - plan.amountOff) / 100;
  } else if (plan.percentOff) {
    return Math.round(100 * plan.price * (1 - plan.percentOff / 100)) / 10000;
  }

  return plan.price / 100;
};

/**
 * Billing page
 * @returns {React.FC} a component
 */
export const Billing: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userId = useSelector(selectUid);
  const userState = useSelector(({ user }: RootState) => user);
  const plan = userState.plan;
  const settings = useSelector(selectUserSettings);
  const team = useSelector(selectTeam);
  const isTeamPaidPlan = useSelector(selectIsUserPaidByTeam);

  const teamPlan = team?.plan;

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);

  const hasActiveTeamPlan = team?.isPaid;
  const canManageTeamPlan = team?.members.find((u) => u.uid === userId)?.roles
    .ADMIN;

  const isAdministratorOnTeamPlan = Boolean(
    hasActiveTeamPlan && canManageTeamPlan
  );

  const requestedSubscriptionMove = settings.requestedSubscriptionMove;

  const [loadingTeamPlanDetails, setLoadingTeamPlanDetails] = useState(false);
  const [teamPlanDetails, setTeamPlanDetails] =
    useState<TeamPlanDetails | null>();
  const [loadingNextInvoiceDetails, setLoadingNextInvoiceDetails] =
    useState(false);
  const [nextPersonalInvoiceDetails, setNextPersonalInvoiceDetails] =
    useState<UpcomingUserInvoice | null>();
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);
  const [isSwitchToCardDialogOpen, setIsSwitchToCardDialogOpen] =
    useState(false);
  const [switchToCardDialogMessage, setSwitchToCardDialogMessage] =
    useState('');

  const [
    isRequestSubscriptionMoveDialogOpen,
    setIsRequestSubscriptionMoveDialogOpen,
  ] = useState(false);

  const [teamSizeModalOpen, setTeamSizeModalOpen] = useState<boolean>(false);
  const getTeamPlanDetails = useQuery(GetTeamPlanDetailsDocument, {
    skip: true,
  });

  useEffect(() => {
    if (
      (plan.paid?.__typename === 'TeamPaidPlan' || isAdministratorOnTeamPlan) &&
      !loadingTeamPlanDetails
    ) {
      setLoadingTeamPlanDetails(true);
      getTeamPlanDetails
        .refetch()
        .then((r) => setTeamPlanDetails(r.data?.teamPlanDetails))
        .catch(logger.error);
    }
  }, [
    isAdministratorOnTeamPlan,
    loadingTeamPlanDetails,
    plan,
    getTeamPlanDetails,
  ]);

  useEffect(() => {
    if (
      plan.paid?.__typename === 'StripePaidPlan' &&
      !loadingNextInvoiceDetails
    ) {
      setLoadingNextInvoiceDetails(true);
      getUpcomingInvoice()
        .then(setNextPersonalInvoiceDetails)
        .catch(logger.error);
    }
  }, [loadingNextInvoiceDetails, plan]);

  const reloadBilling = useCallback((doneSomething?: boolean) => {
    setIsCancellationDialogOpen(false);
    if (doneSomething) {
      setIsResuming(false);
      setLoadingTeamPlanDetails(false);
      setNextPersonalInvoiceDetails(undefined);
      setTeamPlanDetails(undefined);
    }
  }, []);

  const [isResuming, setIsResuming] = useState(false);
  const [claimTeamSeat, claimTeamSeatMutation] = useMutation(
    ClaimTeamSeatDocument
  );
  const [resumeTeamSubscription] = useMutation(ResumeTeamSubscriptionDocument);
  const onResume = useCallback(async () => {
    setIsResuming(true);

    if (plan.paid?.__typename === 'TeamPaidPlan' || isAdministratorOnTeamPlan) {
      dispatch(
        gotTeam(
          (await resumeTeamSubscription()).data?.team_billing_resumeSubscription
        )
      );
    } else {
      await resumeSubscription();
    }

    reloadBilling(true);
  }, [
    dispatch,
    isAdministratorOnTeamPlan,
    plan.paid?.__typename,
    reloadBilling,
    resumeTeamSubscription,
  ]);

  const [createTeamPortalLink, createTeamPortalLinkMutation] = useMutation(
    CreateTeamPortalLinkDocument
  );
  const [createStripePortalLink, createStripePortalLinkMutation] = useMutation(
    CreateStripePortalLinkDocument
  );

  const [
    showDowngradeTeamToProDialogOpen,
    setShowDowngradeTeamToProDialogOpen,
  ] = useState(false);

  const isRenewalDisabled = useIsRenewalDisabled();
  const customContract = team?.plan?.isCustomContract ?? false;

  let alert;

  if (
    (plan.paid?.__typename === 'StripePaidPlan' &&
      plan.paid?.latest_failed_charge) ||
    teamPlan?.lastFailedCharge
  ) {
    alert = <PaymentPastDueAlert />;
  } else if (userState.isPaid || isAdministratorOnTeamPlan) {
    alert = <UpgradePlanAlert />;
  }

  // TODO: if have unallocated seats, prompt to allocate seats
  // TODO: if have self-paid members, prompt to consolidate billing
  // TODO: if plan.paid?.__typename === 'StripePaidPlan' && plan.paid.quantity > 1, show as legacy and prompt to upgrade to team plan

  // TODO: write a message about chanigng the plan or size
  // TODO: if a solo team, make clear that downgrading self does not cancel the plan

  /**
   * Plan details
   * - Free - to do later, as this is the key avenue for the free users to become paid
   * - Individual (PayPal)
   * - Individual (Stripe)
   * - Team (Billing admin)
   * - Team (Member)
   */
  const nextRenewalDate = new Date(plan.free.lastRenewDate);
  nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);

  const upcomingInvoice =
    teamPlanDetails?.upcomingInvoice ?? nextPersonalInvoiceDetails?.invoice;

  if (!plan.paid && !isAdministratorOnTeamPlan) return null;

  const paidPlanDetails: StripePaidPlan | PaypalPaidPlan | undefined =
    teamPlanDetails?.plan ||
    (plan.paid?.__typename === 'TeamPaidPlan'
      ? undefined
      : (plan.paid as StripePaidPlan | PaypalPaidPlan | undefined));

  const listClass = 'list-disc pl-8 flex flex-col gap-4';

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mt-4 text-2xl">
        <FormattedMessage defaultMessage="Plan details" />
      </h2>

      {plan.paid?.__typename === 'TeamPaidPlan' &&
      typeof teamPlanDetails === 'undefined' ? (
        <Spinner className="m-6" />
      ) : null}
      {/* Alert to show admin there is a spare seat they can assign to themselves */}
      {team &&
      isAdministratorOnTeamPlan &&
      team?.tier === TeamTier.TEAM &&
      userState.tier !== UserTier.TEAM &&
      (teamPlanDetails?.plan?.quantity ?? 0) >
        (teamPlanDetails?.seatsAllocated ?? 0) ? (
        <Alert
          severity="info"
          description={
            <FormattedMessage
              defaultMessage="You are a billing administrator of your team's plan, and there is a seat available"
              id="U1+Bwx"
            />
          }
          action={
            <Button
              size="small"
              loading={claimTeamSeatMutation.loading}
              onClick={async () => {
                await claimTeamSeat();
                reloadBilling(true);
              }}
            >
              <FormattedMessage defaultMessage="Assign seat to yourself" />
            </Button>
          }
        />
      ) : null}

      <ul className={listClass}>
        {plan.paid?.__typename === 'TeamPaidPlan' ||
        isAdministratorOnTeamPlan ? (
          <>
            <li>
              <FormattedMessage
                defaultMessage="Your Tactiq is on {planName} {planCycle}."
                id="W/fbl2"
                values={{
                  planName:
                    team?.tier === TeamTier.ENTERPRISE ? (
                      <b>Enterprise Plan</b>
                    ) : (
                      <b>Team Plan</b>
                    ),
                  planCycle: teamPlanDetails ? (
                    <b>
                      <span> (</span>
                      <PlanName plan={teamPlanDetails.plan} />
                      <span>)</span>
                    </b>
                  ) : (
                    <span></span>
                  ),
                }}
              />
            </li>
            {teamPlanDetails ? (
              <li>
                <div className="flex items-center gap-x-1">
                  <FormattedMessage
                    defaultMessage="You have {seatsAvailable} seats ({seatsAllocated} allocated)."
                    values={{
                      seatsAvailable: <b>{teamPlanDetails?.plan?.quantity}</b>,
                      seatsAllocated: teamPlanDetails?.seatsAllocated,
                    }}
                  />
                  {!customContract && (
                    <Button
                      onClick={() => {
                        if (isRenewalDisabled()) return;
                        trackWebEvent('Billing – Open change team size modal');
                        setTeamSizeModalOpen(true);
                      }}
                    >
                      Change team size
                    </Button>
                  )}
                  <ChangeTeamSeatsDialog
                    teamPlanDetails={teamPlanDetails}
                    teamSizeModalOpen={teamSizeModalOpen}
                    completeAction={() => reloadBilling(true)}
                    onClose={() => {
                      setTeamSizeModalOpen(false);
                    }}
                  />
                </div>
              </li>
            ) : (
              <li>
                <FormattedMessage defaultMessage="Your subscription is managed by your team." />
              </li>
            )}

            {teamPlanDetails &&
            team?.members.find((m) => m.status === TeamMemberStatus.FREE) ? (
              <li>
                <FormattedMessage
                  defaultMessage="Your team has {free} users missing the team plan."
                  id="RdVd/B"
                  values={{
                    free: (
                      <b>
                        {
                          team.members.filter(
                            (m) => m.status === TeamMemberStatus.FREE
                          ).length
                        }
                      </b>
                    ),
                  }}
                />
              </li>
            ) : null}
            {teamPlanDetails?.balance ? (
              <li>
                <FormattedMessage
                  defaultMessage="Your team balance is {balance}."
                  id="a/9LPi"
                  values={{
                    balance: <b>{teamPlanDetails?.balance}</b>,
                  }}
                />
              </li>
            ) : null}
          </>
        ) : plan.paid ? (
          <li>
            <FormattedMessage
              defaultMessage="Your Tactiq is on {planName} ({planCycle})."
              values={{
                planName:
                  plan.paid.__typename === 'PaypalPaidPlan' ? (
                    <b>Pro Plan (PayPal)</b>
                  ) : (
                    <b>Pro Plan</b>
                  ),
                planCycle: (
                  <b>
                    <PlanName plan={paidPlanDetails} />
                  </b>
                ),
              }}
            />
            {plan.paid?.__typename === 'StripePaidPlan' ? (
              <Button
                variant="soft"
                onClick={() => setShowTierPricingDialog(true)}
              >
                <FormattedMessage defaultMessage="Change plan." />
              </Button>
            ) : null}
            {showTierPricingDialog ? (
              <TierPricingDialog
                userTier={userTier}
                teamTier={team?.tier}
                source={TierPricingDialogSource.BILLING}
                onClose={() => setShowTierPricingDialog(false)}
              />
            ) : null}
          </li>
        ) : null}

        {plan.paid ? (
          <li>
            <FormattedMessage
              defaultMessage="You have {meetingAllowance} meetings."
              values={{
                meetingAllowance: (
                  <b>
                    <FormattedMessage defaultMessage="unlimited" />
                  </b>
                ),
              }}
            />
          </li>
        ) : null}

        <li>
          {isTeamPaidPlan ? (
            <FormattedMessage
              defaultMessage="You have {creditAllowance} AI credits."
              values={{
                creditAllowance: (
                  <b>
                    <FormattedMessage defaultMessage="unlimited" />
                  </b>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You have used {aiUsed} out of {aiAllowance} AI credits, which will reset on {renewalDate}."
              values={{
                aiAllowance: <b>{plan.free.aiCredits.allowance}</b>,
                aiUsed: <b>{plan.free.aiCredits.used}</b>,
                renewalDate: <b>{nextRenewalDate.toLocaleDateString()}</b>,
              }}
            />
          )}
        </li>
        {team ? (
          <li>
            <FormattedMessage
              defaultMessage="Each team member has their own AI credit limits and usage."
              id="zIaN+m"
            />
          </li>
        ) : null}
        {paidPlanDetails?.__typename === 'StripePaidPlan' &&
        paidPlanDetails.percentOff ? (
          <li>
            <FormattedMessage
              defaultMessage="You have a {percentOff} discount."
              values={{
                percentOff: <b>{paidPlanDetails.percentOff}%</b>,
              }}
            />
          </li>
        ) : null}
        {paidPlanDetails?.cancelAtPeriodEnd ? (
          <li className="text-red-500">
            <FormattedMessage
              defaultMessage="Your plan will cancel on {renewalDate}."
              values={{
                renewalDate: (
                  <b>
                    {paidPlanDetails?.cancelAt
                      ? new Date(paidPlanDetails?.cancelAt).toLocaleDateString()
                      : nextRenewalDate.toLocaleDateString()}
                  </b>
                ),
              }}
            />
            {paidPlanDetails?.__typename === 'StripePaidPlan' ? (
              <Button
                className="ml-1"
                loading={isResuming}
                size="small"
                color="success"
                onClick={onResume}
              >
                <FormattedMessage defaultMessage="Resume the subscription" />
              </Button>
            ) : null}
          </li>
        ) : upcomingInvoice ? (
          <li>
            <FormattedMessage
              defaultMessage="Your plan will renew on {date} for {amount}"
              values={{
                date: (
                  <b>
                    {new Date(upcomingInvoice.date * 1000).toLocaleDateString()}
                  </b>
                ),
                amount: <b>{upcomingInvoice.amount}</b>,
              }}
            />
            {teamPlanDetails?.balance ? (
              <span>
                <span> (</span>
                <FormattedMessage defaultMessage="using your balance" />
                <span>).</span>
              </span>
            ) : (
              <span>.</span>
            )}
          </li>
        ) : paidPlanDetails?.price ? (
          <li>
            <FormattedMessage
              defaultMessage="Your plan will renew for {currency}{amount}."
              id="wxL0/E"
              values={{
                currency:
                  plan?.paid?.__typename === 'StripePaidPlan'
                    ? plan.paid.currencySymbol
                    : '$',
                amount:
                  plan?.paid?.__typename === 'StripePaidPlan' ? (
                    <b>{getStripePrice(plan.paid)}</b>
                  ) : (
                    <b>{Math.ceil(paidPlanDetails?.price / 100).toFixed(2)}</b>
                  ),
              }}
            />
          </li>
        ) : null}
      </ul>

      {alert}

      <h2 className="mt-4 text-2xl">
        <FormattedMessage defaultMessage="Billing actions" id="S8/D52" />
      </h2>

      <RequestSubscriptionMoveStatus />

      <ul className={listClass}>
        {plan.paid?.__typename === 'TeamPaidPlan' ||
        isAdministratorOnTeamPlan ? (
          <li>
            {teamPlanDetails ? (
              <FormattedMessage defaultMessage="You are managing your team's plan." />
            ) : (
              <FormattedMessage defaultMessage="Your plan is managed by your team." />
            )}
          </li>
        ) : null}

        {plan.paid?.__typename === 'PaypalPaidPlan' ? (
          <li>
            <Button
              variant="soft"
              size="small"
              onClick={() => {
                setSwitchToCardDialogMessage('');
                setIsSwitchToCardDialogOpen(true);
              }}
            >
              <FormattedMessage defaultMessage="Switch to card billing." />
            </Button>
          </li>
        ) : null}

        {paidPlanDetails ? (
          <li>
            <Button
              variant="soft"
              loading={
                createTeamPortalLinkMutation.loading ||
                createStripePortalLinkMutation.loading
              }
              onClick={async () => {
                if (isRenewalDisabled()) return;
                trackWebEvent('Update billing details or payment method', {
                  planType: plan.paid?.__typename,
                });

                if (paidPlanDetails.__typename === 'StripePaidPlan') {
                  const url =
                    plan.paid?.__typename === 'TeamPaidPlan' ||
                    isAdministratorOnTeamPlan
                      ? (await createTeamPortalLink()).data
                          ?.team_billing_createStripePortalLink.url
                      : (await createStripePortalLink()).data
                          ?.payment_createStripePortalLink.url;
                  if (url) window.location.href = url;
                } else if (paidPlanDetails.__typename === 'PaypalPaidPlan') {
                  window.open(
                    `https://www.${
                      isProduction() ? '' : 'sandbox.'
                    }paypal.com/myaccount/autopay/connect/${
                      paidPlanDetails.subscriptionId
                    }`,
                    '_blank'
                  );
                }
              }}
              size="small"
            >
              <FormattedMessage defaultMessage="Update billing details or payment method." />
            </Button>
          </li>
        ) : null}

        {paidPlanDetails ? (
          <li>
            <Button
              variant="soft"
              loading={
                createTeamPortalLinkMutation.loading ||
                createStripePortalLinkMutation.loading
              }
              onClick={async () => {
                trackWebEvent('Manage Plan', {
                  planType: plan.paid?.__typename,
                });

                if (paidPlanDetails.__typename === 'StripePaidPlan') {
                  const url =
                    plan.paid?.__typename === 'TeamPaidPlan' ||
                    isAdministratorOnTeamPlan
                      ? (await createTeamPortalLink())?.data
                          ?.team_billing_createStripePortalLink.url
                      : (await createStripePortalLink()).data
                          ?.payment_createStripePortalLink.url;

                  if (url) window.location.href = url;
                } else if (paidPlanDetails.__typename === 'PaypalPaidPlan') {
                  window.open(
                    `https://www.${
                      isProduction() ? '' : 'sandbox.'
                    }paypal.com/myaccount/transactions/?filter_id=${
                      paidPlanDetails.subscriptionId
                    }`,
                    '_blank'
                  );
                }
              }}
              size="small"
            >
              <FormattedMessage
                defaultMessage="View past invoices."
                id="lE+7GF"
              />
            </Button>
          </li>
        ) : null}

        {paidPlanDetails &&
        team?.isPaid &&
        !customContract &&
        team.plan?.quantity === 1 &&
        isAdministratorOnTeamPlan &&
        isTeamPaidPlan ? (
          <li>
            <Button
              variant="soft"
              size="small"
              onClick={() => setShowDowngradeTeamToProDialogOpen(true)}
            >
              <FormattedMessage
                defaultMessage="Downgrade to Pro plan."
                id="2lNa/7"
              />
            </Button>
          </li>
        ) : null}

        {paidPlanDetails && !requestedSubscriptionMove && !customContract ? (
          <li>
            <Button
              variant="soft"
              size="small"
              color="warning"
              onClick={() => {
                if (isRenewalDisabled()) return;
                trackWebEvent(
                  'Subscription info page - Move the subscription to another account button - clicked'
                );

                if (plan.paid?.__typename === 'PaypalPaidPlan') {
                  setSwitchToCardDialogMessage(
                    intl.formatMessage({
                      defaultMessage:
                        'You need to switch to Pay by Card first before moving your subscription.',
                    })
                  );
                  setIsSwitchToCardDialogOpen(true);
                } else {
                  setIsRequestSubscriptionMoveDialogOpen(true);
                }
              }}
            >
              <FormattedMessage defaultMessage="Move the subscription to another account" />
            </Button>
          </li>
        ) : null}

        {paidPlanDetails &&
        !customContract &&
        userState.billingStatus !== BillingStatus.RENEWAL_DISABLED &&
        team?.billingStatus !== BillingStatus.RENEWAL_DISABLED ? (
          <li>
            <Button
              variant="soft"
              size="small"
              color="warning"
              onClick={() => {
                if (
                  paidPlanDetails.cancelAtPeriodEnd &&
                  paidPlanDetails.status !== 'cancelled'
                ) {
                  enqueueSnackbar(
                    'Your subscription is already pending cancellation',
                    { variant: 'WARNING' }
                  );
                } else {
                  trackWebEvent(
                    'Subscription info page - Cancel subscription button - clicked'
                  );

                  setIsCancellationDialogOpen(true);
                }
              }}
            >
              <FormattedMessage defaultMessage="Cancel the subscription." />
            </Button>
          </li>
        ) : null}

        {plan.paid?.__typename === 'TeamPaidPlan' ||
        isAdministratorOnTeamPlan ? (
          <TeamCancellationDialog
            open={isCancellationDialogOpen}
            onClose={reloadBilling}
          />
        ) : (
          <ProCancellationDialog
            open={isCancellationDialogOpen}
            onClose={reloadBilling}
          />
        )}

        <SwitchToCardBillingDialog
          open={isSwitchToCardDialogOpen}
          message={
            <div className="font-semibold text-base text-slate-900 leading-6">
              {switchToCardDialogMessage}
            </div>
          }
          onClose={() => {
            setIsSwitchToCardDialogOpen(false);
            reloadBilling();
          }}
        />
        {isRequestSubscriptionMoveDialogOpen && (
          <RequestSubscriptionMoveDialog
            isBillingAdministratorOnTeamPlan={isAdministratorOnTeamPlan}
            onClose={(doneSomething) => {
              setIsRequestSubscriptionMoveDialogOpen(false);
              reloadBilling(doneSomething);
            }}
          />
        )}
        {team?.isPaid &&
          team.plan?.quantity === 1 &&
          showDowngradeTeamToProDialogOpen && (
            <DowngradeTeamToProDialog
              onClose={() => setShowDowngradeTeamToProDialogOpen(false)}
            />
          )}
      </ul>
    </div>
  );
};
