// This is optional but highly recommended

import { createIntl, createIntlCache } from 'react-intl';

// since it prevents memory leak
const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: 'en',
    messages: {},
  },
  cache
);
