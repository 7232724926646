import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/buttons';
import { trackWebEvent } from '../../helpers/analytics';
import {
  selectIsUserPaidByTeam,
  selectTeam,
  selectUid,
  selectUserPlan,
} from '../../redux/selectors';
import { BillingStatus } from '../../graphql/operations';
import { RootState } from '../../redux/store';

/**
 * Payment is past due
 */
export const PaymentPastDueAlert: React.FC = () => {
  const team = useSelector(selectTeam);
  const teamPlan = team?.plan;
  const userId = useSelector(selectUid);
  const userPlan = useSelector(selectUserPlan);
  const paidUserPlan = userPlan.paid;
  const userTeamBillingStatus = useSelector(
    (state: RootState) => state.user.team?.billingStatus
  );
  const isPaidTeamSeat = useSelector(selectIsUserPaidByTeam);

  const lastStripeFailedCharge =
    teamPlan?.lastFailedCharge ??
    (paidUserPlan?.__typename === 'StripePaidPlan' &&
      paidUserPlan.latest_failed_charge);

  const isAdmin =
    !team || team.members.some((m) => m.uid === userId && m.roles.ADMIN);

  useEffect(() => {
    if (lastStripeFailedCharge) {
      trackWebEvent('Viewed PaymentPastDueAlert', { isAdmin });
    }
  }, [lastStripeFailedCharge, isAdmin]);

  if (!lastStripeFailedCharge) {
    return null;
  }

  if (
    paidUserPlan?.__typename === 'StripePaidPlan' &&
    !paidUserPlan.latest_failed_charge
  ) {
    return null;
  }

  if (
    isPaidTeamSeat &&
    userTeamBillingStatus === BillingStatus.PAST_DUE &&
    !isAdmin
  ) {
    return (
      <Alert
        severity="warning"
        title={
          <p>
            <FormattedMessage
              defaultMessage="You could lose your team seat."
              id="XssTy/"
              description="Subscription past due warning title."
            />
          </p>
        }
        description={
          <p>
            <FormattedMessage
              defaultMessage="Your team's subscription payment failed and it is now past due. Please contact your team admin to avoid losing your plan."
              description="Subscription past due warning description."
            />
          </p>
        }
      />
    );
  }

  return (
    <Alert
      severity="warning"
      action={
        isAdmin &&
        lastStripeFailedCharge?.invoice_url && (
          <Button
            onClick={async () => {
              trackWebEvent('Clicked button - update payment method');
              window.open(lastStripeFailedCharge?.invoice_url);
            }}
          >
            <FormattedMessage
              defaultMessage="Update payment method"
              id="iIM9E+"
            />
          </Button>
        )
      }
      title={
        <p>
          <FormattedMessage
            defaultMessage="Your subscription is past due."
            description="Subscription past due warning title."
          />
        </p>
      }
      description={
        <div className="flex flex-col gap-1.5">
          <p>
            <FormattedMessage
              defaultMessage="It can happen if your credit card is expired or you don't have enough funds on your account."
              description="Subscription past due warning description."
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="If you believe this was a mistake, please contact support at team@tactiq.io."
              description="Subscription past due warning help text."
              id="M1ct+l"
            />
          </p>
        </div>
      }
    />
  );
};
