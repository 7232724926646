export type ExportTypes = 'Liquid';
// https://www.shopify.com/partners/shopify-cheat-sheet

// https://shopify.dev/docs/api/liquid/basics#operators
export enum ConditionOperator {
  Equal = '==',
  NotEqual = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  Contains = 'contains',
  StartsWith = 'starts with',
  OneOf = '| has_one_of:',
  NotOneOf = '| has_none_of: ',
}

// https://shopify.dev/docs/api/liquid/basics#types
export type ConditionType = 'string' | 'number' | 'date' | 'boolean' | 'array';

export type ConditionVariable = {
  label: string;
  value: string;
  type: ConditionType;
  defaultOperator?: ConditionOperator;
  options?: string[];
};

export type Condition = {
  variable?: ConditionVariable;
  operator?: ConditionOperator;
  value?: string | number | boolean | Date;
};

export type ConditionCollection = Map<string, Condition>;
