import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Page } from '../Common/Navigation';
import { trackWebPage } from '../../helpers/analytics';
import { SendInvitationComponent } from './SendInvitationComponent';

const InvitePageComponent: React.FC = () => {
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Invite');
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Get free meetings',
        description: 'Menu bar text.',
      })}
    >
      <SendInvitationComponent />
    </Page>
  );
};

export default InvitePageComponent;
