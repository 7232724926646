import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'lucide-react';
import AsyncButton from '../../components/buttons/AsyncButton';
import { trackWorkflowLinkCopied } from '../../helpers/analytics';
import { useHref } from 'react-router';
import { ButtonProps } from '../../components/buttons';
import { Tooltip } from '../../components/Tooltip';

type CopyWorkflowLinkButtonProps = {
  workflow: {
    id: string;
  };
  source: Parameters<typeof trackWorkflowLinkCopied>[0]['source'];
  className?: string;
  withMessage?: boolean;
  fullWidth?: boolean;
  variant?: ButtonProps['variant'];
  stopPropagation?: boolean;
};
const CopyWorkflowLinkButton: React.FC<CopyWorkflowLinkButtonProps> = ({
  workflow,
  source,
  className,
  withMessage,
  fullWidth,
  stopPropagation,
  variant,
}) => {
  const workflowPath = useHref(`/workflows/${workflow.id}`);
  const workflowLink = `${window.location.origin}/${workflowPath}`;
  const label = <FormattedMessage defaultMessage="Copy link" />;
  const button = (
    <AsyncButton
      fullWidth={fullWidth}
      className={className}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        navigator.clipboard.writeText(workflowLink);

        trackWorkflowLinkCopied({ source });
      }}
      startIcon={<Link size="1rem" />}
      variant={variant || 'neutral-secondary'}
    >
      {withMessage && label}
    </AsyncButton>
  );

  return !withMessage ? (
    <Tooltip title={label} placement="top" arrow>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

export default CopyWorkflowLinkButton;
