import React from 'react';
import { Button } from '../../components/buttons';
import { trackWebEvent } from '../../helpers/analytics';
import { TrackedFeature, TrackedMedium, getTrackingURL } from '@tactiq/model';
import { Alert } from '../../components/Alert';
import { FormattedMessage } from 'react-intl';

export const AlertExtensionIsNotInstalled: React.FC = () => {
  return (
    <Alert
      severity="warning"
      action={
        <Button
          color="warning"
          size="small"
          target="_blank"
          href={getTrackingURL(
            'https://tactiq.io/chrome',
            TrackedFeature.ExtensionNotInstalledAlert,
            TrackedMedium.Link
          )}
          onClick={() =>
            trackWebEvent('Clicked Button - Alert - Extension Is Not Installed')
          }
        >
          Install
        </Button>
      }
      description={
        <FormattedMessage defaultMessage="You need to install Tactiq extension" />
      }
    />
  );
};
