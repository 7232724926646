import React, { PropsWithChildren } from 'react';
import { cx } from '../../helpers/utils';

const BannerWrapperClasses =
  'mb-0 flex flex-col items-start justify-center gap-x-2 gap-y-2 border-b border-b-slate-200 bg-slate-50 px-6 py-4 md:mt-0 md:flex-row md:items-center';

/**
 *
 * @param {unknown} param0 params
 * @param {React.ReactNode} param0.actionButton actionButton
 * @returns {React.FC} Banner component
 */
export const Banner: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <div className={cx(BannerWrapperClasses, className)}>{children}</div>;
};
