import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import { createOauthRedirectUrl } from '../../../helpers/api';
import { Loading } from '../../Landing/Loading/LoadingComponent';
import { PlusIcon } from 'lucide-react';

const OAuthConnector: React.FC<{
  type: string;
  title: string;
  buttonLabel?: React.ReactNode;
  color?: 'primary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'soft' | 'neutral-secondary' | 'filled';
  icon?: React.ReactNode;
  newTab: boolean;
}> = ({
  type,
  title,
  buttonLabel,
  color,
  newTab,
  icon,
  variant = 'filled',
}) => {
  const [isRedirecting, setRedirecting] = useState(false);

  const createRedirectUrl = useCallback(async () => {
    setRedirecting(true);
    trackWebEvent(`Clicked Link - Connect To ${title}`);
    const { url } = await createOauthRedirectUrl(type);
    if (newTab) {
      window.open(url);
      setRedirecting(false);
    } else {
      window.location.assign(url);
    }
  }, [newTab, title, type]);

  if (isRedirecting) {
    return <Loading />;
  } else {
    return (
      <Button
        color={color}
        variant={variant}
        size="medium"
        startIcon={
          icon ??
          (buttonLabel ? undefined : <PlusIcon size={16} strokeWidth={2.5} />)
        }
        onClick={createRedirectUrl}
      >
        {buttonLabel ?? (
          <FormattedMessage
            defaultMessage="Connect with {title}"
            description="Integration. Connect button title."
            values={{ title }}
          />
        )}
      </Button>
    );
  }
};

/**
 * oAuth connection creator
 * @param {string} type type
 * @param {string} title title
 * @param {React.ReactNode} buttonLabel label
 * @param {'primary' | 'success' | 'error' | 'info' | 'warning'} color colour
 * @param {boolean} newTab Go to new tab
 * @param {'soft' | 'outlined' | 'filled'} variant variant
 * @param {React.ReactNode} icon Icon to display
 * @returns {React.FC} a component
 */
export const createOAuthConnector = (
  type: string,
  title: string,
  buttonLabel?: React.ReactNode,
  color?: 'primary' | 'success' | 'error' | 'info' | 'warning',
  newTab?: boolean,
  variant?: 'soft' | 'neutral-secondary' | 'filled',
  icon?: React.ReactNode
): React.FC => {
  const connector = () => (
    <OAuthConnector
      type={type}
      title={title}
      buttonLabel={buttonLabel}
      color={color}
      newTab={newTab || false}
      variant={variant}
      icon={icon}
    />
  );
  connector.displayName = `Connector-${type}`;

  return connector;
};
