import { LabelConfig } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, getColourByName } from '../../../components/Avatar';
import { Chip } from '../../../components/Chips';
import { archiveMeeting, removeMeetingLabel } from '../../../graphql/meetings';
import { DeleteMeetingDocument, UserSpace } from '../../../graphql/operations';
import {
  trackDeleteMeetingWithUploadProcessingError,
  trackMeetingLabelRemoved,
} from '../../../helpers/analytics';
import { isMeetingOwner } from '../../../helpers/meetings';
import { AnyMeeting } from '../../../models/meeting';
import {
  removeLabelFromMeeting,
  deleteMeeting as deleteMeetingAction,
} from '../../../redux/modules/global';
import {
  selectTeam,
  selectUid,
  selectUserName,
  selectUserSpaces,
} from '../../../redux/selectors';
import { useSearchQueryState } from '../../../services/Search';
import { Label } from '../../Common/Label';
import { MeetingActions } from '../card/MeetingActions';
import { UPLOAD_MAX_PROCESSING_TIME_MS } from '../card/MeetingCard';
import { Spinner } from '../../../components/Spinner';
import { Button } from '../../../components/buttons';
import { XOctagon } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { Checkbox } from '../../../components/Checkbox';
import { cx } from '../../../helpers/utils';
import { useUpdateMeetingSpaceStatus } from '../../../services/Space';

export const MeetingListItem: React.FC<{
  meeting: AnyMeeting;
  readOnly?: boolean;
  checkboxColumnVisible: boolean;
  actionsVisible: boolean;
  createdColumnMode?: 'time' | 'date';
  checked?: boolean;
  setChecked?: () => void;
  allowBulkActions?: boolean;
}> = ({
  meeting,
  readOnly,
  checkboxColumnVisible,
  actionsVisible,
  createdColumnMode = 'time',
  checked,
  setChecked,
  allowBulkActions = false,
}) => {
  const userId = useSelector(selectUid);
  const userName = useSelector(selectUserName);
  const userSpaces = useSelector(selectUserSpaces);
  const [, setFilter] = useSearchQueryState();
  const dispatch = useDispatch();
  const intl = useIntl();
  const team = useSelector(selectTeam);
  const { updateMeetingSpaceStatus } = useUpdateMeetingSpaceStatus();

  const [deleteMeeting, deleteMeetingMutation] = useMutation(
    DeleteMeetingDocument
  );

  const you = intl.formatMessage({
    defaultMessage: 'you',
  });

  const onRemoveLabel = async (label: LabelConfig) => {
    dispatch(
      removeLabelFromMeeting({ meetingId: meeting.id, labelId: label.id })
    );
    await removeMeetingLabel(meeting.id, label.id);
    trackMeetingLabelRemoved(team);
  };

  const onRemoveMeetingFromASpace = async (userSpace: UserSpace) => {
    await updateMeetingSpaceStatus(meeting.id, userSpace, false);
  };

  const defaultParticipants = meeting.participants ?? [];
  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;
  const isReadOnly = readOnly || isPreview || isArchived;
  const isOwner = isMeetingOwner(userId, meeting);

  const isUploadBeingProcessed = !!meeting.isUploading;
  const hadProcessingError =
    meeting.hadProcessingError ||
    (isUploadBeingProcessed &&
      meeting.modified < Date.now() - UPLOAD_MAX_PROCESSING_TIME_MS);
  const isActiveUpload =
    typeof meeting.uploadProgress !== 'undefined' &&
    meeting.uploadProgress >= 0 &&
    meeting.uploadProgress < 100;

  const otherParticipants =
    defaultParticipants.filter((p) => p && p.name !== userName) ?? [];

  otherParticipants.sort(
    (a, b) => b.analytics.textLength - a.analytics.textLength
  );
  const avatarParticipants =
    otherParticipants.length > 0
      ? otherParticipants[0]
      : defaultParticipants.length > 0
        ? defaultParticipants[0]
        : undefined;
  const avatarParticipantsSrc =
    avatarParticipants &&
    team?.members.find((m) => m.displayName === avatarParticipants.name)
      ?.photoURL;

  const spaces = (meeting.permissions?.allow.spaces
    ?.map((s) => userSpaces.find((us) => us.id === s))
    .filter(Boolean) || []) as UserSpace[];

  const onMeetingCardClick = useCallback(() => {
    if (hadProcessingError) {
      return null;
    }
    if (isActiveUpload || isUploadBeingProcessed || isActiveUpload) {
      return () =>
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'The meeting has not finished processing yet',
          }),
          { variant: 'WARNING' }
        );
    }
    return true;
  }, [intl, hadProcessingError, isActiveUpload, isUploadBeingProcessed]);

  const participants = [
    ...otherParticipants.map((p) => p.name),
    ...(defaultParticipants.length === otherParticipants.length ? [] : [you]), // could have been [`${userName} (${you})`] but it makes the list very repetitive with the brackets
  ];

  const activeDuration: number = Math.floor(
    (Number.parseInt(meeting.speechDuration.toString()) ||
      Number.parseInt(meeting.duration.toString())) / 60
  );

  const created = new Date(meeting.created);

  return (
    <a
      {...(!isActiveUpload && !isUploadBeingProcessed
        ? { href: `/#/transcripts/${meeting.id}` }
        : {
            onClick: onMeetingCardClick,
          })}
      onClick={onMeetingCardClick}
      aria-label="meeting-list-item"
      className={cx(
        'group flex items-center border-neutral-muted border-t py-2.5 pr-4 text-sm transition-all duration-200 hover:bg-neutral-xmuted/40',
        checked ? 'bg-neutral-xmuted/40' : 'bg-white'
      )}
    >
      {checkboxColumnVisible && (
        <div className="w-full min-w-8 max-w-8">
          {allowBulkActions ? (
            <div
              className={cx(
                'items-center justify-center',
                checked ? 'flex' : 'hidden group-hover:flex '
              )}
            >
              <Checkbox
                checked={checked ?? false}
                id={meeting.id}
                onChange={() => {
                  setChecked?.();
                }}
                hideLabel
                label={
                  <FormattedMessage
                    defaultMessage="Select {meetingTitle} for bulk actions"
                    values={{ meetingTitle: meeting.title }}
                  />
                }
              />
            </div>
          ) : null}
        </div>
      )}

      <div className="flex h-full w-16 min-w-16 max-w-16 flex-col flex-col-reverse items-start justify-between truncate pl-1">
        {createdColumnMode === 'time' && (
          <div aria-label="meeting-list-item-created">
            <div className="align-baseline font-medium text-neutral-subtle text-xs leading-5">
              {created.toLocaleTimeString(undefined, {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </div>
          </div>
        )}
        {!isActiveUpload && !isUploadBeingProcessed && (
          <div
            aria-label="meeting-list-item-duration"
            className="truncate text-center align-baseline font-semibold text-neutral-default text-sm"
          >
            {activeDuration}m
          </div>
        )}
      </div>

      <div
        aria-label="meeting-list-item-participant-avatars"
        className="mx-2 w-10 min-w-10 max-w-10 justify-center"
      >
        <Avatar
          src={avatarParticipantsSrc}
          name={avatarParticipants?.name ?? ''}
          twoLetters={true}
          color={getColourByName(avatarParticipants?.name ?? '')}
        />
      </div>

      <div className="flex min-w-0 flex-shrink flex-grow flex-col">
        {createdColumnMode === 'date' && (
          <h6
            aria-label="meeting-list-item-created"
            className="text-neutral-muted text-xs"
          >
            {created.toLocaleString(undefined, {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </h6>
        )}
        <h3
          aria-label="meeting-list-item-title"
          className="truncate font-semibold"
        >
          {meeting.title}
        </h3>
        <div
          aria-label="meeting-list-item-participants"
          className="max-w-full gap-2 truncate text-neutral-subtle text-sm"
        >
          {participants.join(', ')}
        </div>
      </div>

      {actionsVisible &&
      (meeting.labels?.length > 0 ||
        spaces.length > 0 ||
        isActiveUpload ||
        isUploadBeingProcessed ||
        hadProcessingError) ? (
        <div
          aria-label="meeting-list-item-actions"
          className="space-between ease hidden flex-shrink-[1.5] flex-grow gap-2 overflow-hidden transition-width md:flex [&:not(:empty)]:min-w-[150px] [&:not(:empty)]:hover:flex-shrink-[0.25]"
        >
          <div className="flex min-w-0 flex-grow" />
          {!isActiveUpload &&
            !isUploadBeingProcessed &&
            !hadProcessingError &&
            meeting.labels?.map((l) => (
              <div key={l.id} className="min-w-0">
                <Label
                  {...l}
                  onClick={() => setFilter({ labels: [l.name] })}
                  onDelete={
                    !l.isAuto && isOwner && !isReadOnly
                      ? () => onRemoveLabel(l)
                      : undefined
                  }
                  className="min-w-0 bg-opacity-100"
                />
              </div>
            ))}
          {spaces.map((space) => (
            <div key={space.id} className="min-w-0">
              <Chip
                onClick={() => setFilter({ spaces: [space.id] })}
                onDelete={() =>
                  isOwner && !isReadOnly && onRemoveMeetingFromASpace(space)
                }
                className="h-[100%] min-w-0 bg-opacity-100"
              >
                {space.icon} {space.name}
              </Chip>
            </div>
          ))}

          {isActiveUpload && (
            <Chip
              aria-label="meeting-list-item-active-upload"
              className="h-[100%] min-w-0 bg-opacity-100"
              color="slate"
            >
              <Spinner size="0.8rem" />
              <FormattedMessage defaultMessage="This meeting is being uploaded" />
            </Chip>
          )}

          {!isActiveUpload && isUploadBeingProcessed && !hadProcessingError && (
            <Chip
              aria-label="meeting-list-item-active-processed"
              className="h-[100%] min-w-0 bg-opacity-100"
              color="slate"
            >
              <Spinner size="0.8rem" />
              <FormattedMessage defaultMessage="This meeting is being processed" />
            </Chip>
          )}

          {!isActiveUpload && hadProcessingError && (
            <Chip
              aria-label="meeting-list-item-active-process-error"
              className="h-[100%] min-w-0 bg-opacity-100"
              color="red"
            >
              <FormattedMessage defaultMessage="There was an error processing this file" />
            </Chip>
          )}
        </div>
      ) : null}

      {actionsVisible &&
        !isActiveUpload &&
        !isUploadBeingProcessed &&
        !hadProcessingError && (
          <MeetingActions
            disabled={checked}
            meeting={meeting}
            fullSize={false}
            className="hidden items-center justify-end lg:flex"
          />
        )}

      {hadProcessingError && (
        <Button
          loading={deleteMeetingMutation.loading}
          onClick={async () => {
            trackDeleteMeetingWithUploadProcessingError();
            await archiveMeeting(meeting.id);
            await deleteMeeting({
              variables: {
                input: {
                  id: meeting.id,
                },
              },
            });
            dispatch(deleteMeetingAction(meeting.id));
          }}
          variant="filled"
          color="warning"
          size="tiny"
          endIcon={<XOctagon className="h-4 w-4 text-white" />}
          className="ml-2"
        >
          <FormattedMessage defaultMessage="Delete Upload" />
        </Button>
      )}
    </a>
  );
};
