import React from 'react';
import { Menu } from '../../components/Menu';

type AddNodeMenuItemProps = {
  label: string;
  icon?: JSX.Element;
  onSelect?: () => void;
};

const AddNodeMenuItem = ({ onSelect, label, icon }: AddNodeMenuItemProps) => {
  const handleOnSelected = () => {
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <Menu.Item
      icon={
        icon &&
        React.cloneElement(icon, { className: 'size-4 text-neutral-subtle' })
      }
      onClick={handleOnSelected}
    >
      {label}
    </Menu.Item>
  );
};

export default AddNodeMenuItem;
