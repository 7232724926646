import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useMeetingContext } from '../../../MeetingContext';
import { cx } from '../../../../../../helpers/utils';
import { Spinner } from '../../../../../../components/Spinner';
import { FormattedMessage } from 'react-intl';
import { AlertTriangle } from 'lucide-react';

const Backdrop: React.FC<
  React.PropsWithChildren<{ isOpen: boolean; onClick: () => void }>
> = ({ children, isOpen, onClick }) =>
  isOpen ? (
    <div
      onClick={onClick}
      className="fixed top-0 left-0 z-Backdrop flex h-svh w-svw items-center justify-center bg-slate-900/50"
    >
      {children}
    </div>
  ) : null;

/**
 * Tactiq Screenshot
 */
export const TactiqScreenshot: React.FC<{
  timestamp: number;
}> = ({ timestamp }) => {
  const [isOpen, setIsOpen] = useState(false);
  const showImage = useCallback(
    (event: React.MouseEvent) => {
      setIsOpen(true);
      event.stopPropagation();
      event.preventDefault();
    },
    [setIsOpen]
  );
  const hideImage = useCallback(() => setIsOpen(false), [setIsOpen]);

  const meetingId = useMeetingContext();
  const screenshotUrls = useSelector(
    (state: RootState) => state.global.screenshotUrls
  );

  const source = screenshotUrls?.[`${meetingId}/${timestamp.toString()}`];

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (source === undefined) {
    return <Spinner size="1.5rem" />;
  }

  if (source && isValidUrl(source)) {
    return (
      <>
        <Backdrop onClick={hideImage} isOpen={isOpen}>
          <img
            id={timestamp.toString()}
            className="max-w-80%"
            alt="Screenshot from meeting with backdrop"
            src={source}
          />
        </Backdrop>
        <img
          id={timestamp.toString()}
          alt="Screenshot from meeting"
          className={cx('max-h-[500px] max-w-80%')}
          src={source}
          onClick={showImage}
        />
      </>
    );
  }

  return (
    <div className="flex items-center gap-2 rounded-md border border-danger-default bg-danger-subtle px-3 py-2 text-danger-default">
      <AlertTriangle className="h-5 w-5 flex-shrink-0" />
      <span>
        <FormattedMessage defaultMessage="Error loading a screenshot" />
      </span>
    </div>
  );
};
