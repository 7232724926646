import { Code, ToggleLeft } from 'lucide-react';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { ConditionBuilder } from '../../components/ConditionBuilder';
import { LiquidTemplateInput } from '../../components/LiquidTemplateInput';
import { AutocompleteProps } from './nodes/useAutocomplete';
import { NodeType } from './nodes';
import { ConditionVariable } from '../../components/ConditionBuilder/types';
import { ingestString } from '../../components/ConditionBuilder/lib';
import { Tooltip } from '../../components/Tooltip';

export enum ConditionMode {
  SIMPLE = 'SIMPLE',
  ADVANCED = 'ADVANCED',
}

export default function ConditionInput(props: {
  ariaLabel?: string;
  autocomplete: AutocompleteProps;
  className?: string;
  disabled: boolean;
  nodeType: NodeType;
  onChange: (next: string) => void;
  placeholder?: string;
  value: string;
  workflowId: string;
  variables: ConditionVariable[];
  conditionMode?: ConditionMode;
  setConditionMode?: (next: ConditionMode) => void;
}): ReactElement {
  const {
    ariaLabel,
    autocomplete,
    className,
    disabled,
    nodeType,
    onChange,
    placeholder,
    value,
    workflowId,
    variables,
    conditionMode = ConditionMode.SIMPLE,
    setConditionMode,
  } = props;

  let isValid = true;
  if (conditionMode === ConditionMode.ADVANCED) {
    const details = ingestString(value, variables);

    isValid = [...details.values()].every((parsed) => {
      const isInVariables = variables.some(
        (v) => v.value === parsed.variable?.value
      );
      return isInVariables;
    });
  }

  return (
    <>
      <div className="nodrag mb-2 flex flex-row items-center justify-between font-medium text-sm">
        <FormattedMessage defaultMessage="Conditions" />
        {setConditionMode && (
          <Tooltip
            isDisabled={isValid}
            arrow
            title={
              <FormattedMessage defaultMessage="Condtion is too complex for simple mode" />
            }
          >
            <Button
              onClick={() =>
                setConditionMode(
                  conditionMode === ConditionMode.SIMPLE
                    ? ConditionMode.ADVANCED
                    : ConditionMode.SIMPLE
                )
              }
              className="self-end text-neutral-ghost"
              size="tiny"
              disabled={!isValid}
              textSize="xs"
              variant="text"
            >
              {conditionMode === ConditionMode.SIMPLE && (
                <>
                  <Code size={12} />
                  <FormattedMessage defaultMessage="Use advanced mode" />
                </>
              )}
              {conditionMode === ConditionMode.ADVANCED && (
                <>
                  <ToggleLeft size={12} />
                  <FormattedMessage defaultMessage="Use simple mode" />
                </>
              )}
            </Button>
          </Tooltip>
        )}
      </div>
      {conditionMode === ConditionMode.SIMPLE && (
        <ConditionBuilder
          variables={variables}
          value={value}
          disabled={disabled}
          onChange={onChange}
          format="Liquid"
        />
      )}
      {conditionMode === ConditionMode.ADVANCED && (
        <LiquidTemplateInput
          properties={autocomplete.properties}
          variables={autocomplete.variables}
          className={className}
          value={value}
          disabled={disabled}
          onChange={onChange}
          workflowId={workflowId}
          nodeType={nodeType}
          placeholder={placeholder}
          ariaLabel={ariaLabel}
        />
      )}
    </>
  );
}
