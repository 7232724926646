import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import {
  CreateTagDocument,
  DeleteTagDocument,
  Tag,
  UpdateUserSettingDocument,
  UserSettingsAutoHighlight,
} from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { selectUserTags } from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { EmojiField } from '../Common/icons/EmojiField';
import { omitDeep } from 'lodash-omitdeep';
import { Alert } from '../../components/Alert';
import { ArrowRight, PlusIcon, Trash, X } from 'lucide-react';
import { Chip } from '../../components/Chips';
import { Tooltip } from '../../components/Tooltip';
import { ColumnDefinition, Table } from '../../components/Table';
import { TextInput } from '../../components/TextInput/index';
import { ModalDialog } from '../../components/modals';

const DEFAULT_ICON = {
  unified: '2b50',
  emoji: '⭐',
  originalUnified: '2b50',
  names: ['white medium star', 'star'],
  activeSkinTone: 'neutral',
};

/**
 * Tags
 * @returns {React.FC} a component
 */
export const Tags: React.FC = () => {
  const tags = useSelector(selectUserTags);
  const autoHighlights = useSelector(
    (state: RootState) => state.user.settings?.autoHighlights
  );

  const [tagIcon, setTagIcon] = useState(DEFAULT_ICON);
  const [tagName, setTagName] = useState('');

  const intl = useIntl();

  const [createTag, createTagMutation] = useMutation(CreateTagDocument);

  const onAdd = useCallback(async () => {
    if (tags?.find((x) => x.icon === tagIcon.emoji)) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage:
            'You already have a tag with this icon, please choose another one',

          description: 'Duplicate tag setting message',
        }),
        { variant: 'WARNING' }
      );

      return;
    }

    if (tagName) {
      await createTag({
        variables: {
          input: { name: tagName, icon: tagIcon.emoji },
        },
      });

      setTagName('');
      setTagIcon(DEFAULT_ICON);
    }
  }, [tags, tagName, tagIcon.emoji, intl, createTag]);

  const columns: ColumnDefinition<Tag>[] = useMemo(() => {
    return [
      {
        accessorKey: 'icon',
        header: intl.formatMessage({
          defaultMessage: 'Icon',
        }),
        footer: () => (
          <div className="text-sm">
            <EmojiField
              onChange={(emoji) => {
                setTagIcon({
                  unified: emoji.unified,
                  emoji: emoji.emoji,
                  originalUnified: emoji.unifiedWithoutSkinTone,
                  names: emoji.names,
                  activeSkinTone: emoji.activeSkinTone,
                });
              }}
              value={tagIcon?.unified}
              defaultIcon={<>{tagIcon?.emoji}</>}
            />
          </div>
        ),
      },
      {
        id: 'arrow',
        cell: () => <ArrowRight size="1rem" />,
        footer: () => <ArrowRight size="1rem" />,
      },
      {
        accessorKey: 'name',
        header: intl.formatMessage({
          defaultMessage: 'Description',
        }),
        enableSorting: true,
        footer: () => (
          <TagInput
            onChange={setTagName}
            onAdd={onAdd}
            initialValue={tagName}
          />
        ),
      },
      {
        accessorKey: 'tags',
        header: intl.formatMessage({
          defaultMessage: 'Autohighlight keywords',
          id: 'Nt/Wm3',
        }),
        cell: ({ row }) => {
          const tag = row.original;
          return (
            <TagLine
              key={row.id}
              tag={tag}
              autoHighlights={autoHighlights ?? []}
            />
          );
        },
        footer: () => (
          <div className="text-right">
            <Button
              startIcon={<PlusIcon className="h-6 w-6" />}
              loading={createTagMutation.loading}
              onClick={onAdd}
              className="justify-self-end"
            >
              <FormattedMessage
                defaultMessage="Add"
                description="Button label."
              />
            </Button>
          </div>
        ),
      },
    ];
  }, [autoHighlights, tagIcon, onAdd, createTagMutation.loading, intl]);

  return (
    <div className="mt-4 flex flex-col gap-4">
      <Alert
        severity="info"
        description={
          <FormattedMessage
            defaultMessage="Add custom tags (and auto-highlight keywords) below to manually or automatically tag important quotes during transcription."
            id="83x/n2"
            description="Tags alert description."
          />
        }
      />

      <Table data={tags} columns={columns} />
    </div>
  );
};

function TagInput({
  initialValue,
  onChange,
  onAdd,
}: {
  initialValue: string;
  onChange: (next: string) => void;
  onAdd: () => void;
}) {
  const [tagName, setTagName] = useState(initialValue);
  return (
    <TextInput
      label={
        <FormattedMessage
          defaultMessage="Your tag"
          description="Tag settings page. Text field label"
        />
      }
      onBlur={() => onChange(tagName)}
      value={tagName}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onChange(tagName);
          setTagName('');
          return onAdd();
        }
      }}
      onChange={setTagName}
    />
  );
}

interface TagLineProps {
  tag: Tag;
  autoHighlights: UserSettingsAutoHighlight[];
}

const TagLine: React.FC<TagLineProps> = ({ tag, autoHighlights }) => {
  const thisHighlights = autoHighlights?.filter((a) => a.autoTag === tag.name);

  const intl = useIntl();

  const [isAdding, setAdding] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');

  const [confirmDeleteTagDialog, setConfirmDeleteTagDialog] = useState(false);
  const [
    confirmDeleteAutoHighlightDialog,
    setConfirmDeleteAutoHighlightDialog,
  ] = useState({ open: false, value: '' });

  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  const [deleteTag, deleteTagMutation] = useMutation(DeleteTagDocument);

  const tryToAdd = useCallback(async () => {
    const word = (newKeyword ?? '').trim();
    if (isAdding && word) {
      if (autoHighlights.find((a) => a.searchValue === word)) {
        trackWebEvent('Could not add duplicate autohighlight');
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'You cannot have the same keyword for multiple tags',
          }),
          { variant: 'WARNING' }
        );
      } else {
        trackWebEvent('Added autohighlight');
        await updateUserSetting({
          variables: {
            input: omitDeep(
              {
                autoHighlights: autoHighlights
                  .concat([{ searchValue: word, autoTag: tag.name }])
                  .sort((a, b) => a.searchValue.localeCompare(b.searchValue)),
              },
              '__typename'
            ),
          },
        });

        setAdding(false);
        setNewKeyword('');
      }
    }
  }, [newKeyword, isAdding, autoHighlights, intl, updateUserSetting, tag.name]);

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex flex-wrap gap-1">
        {thisHighlights.map((a) => (
          <Chip
            key={a.searchValue}
            onDelete={() =>
              setConfirmDeleteAutoHighlightDialog({
                open: true,
                value: a.searchValue,
              })
            }
          >
            {a.searchValue}
          </Chip>
        ))}
        {isAdding ? (
          <div className="flew-row flex items-center gap-1">
            <TextInput
              placeholder="Type your keyword"
              autoFocus
              onChange={(value) => {
                setNewKeyword(value);
              }}
              value={newKeyword}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  return tryToAdd();
                }
              }}
            />
            <div className="flex flex-row flex-nowrap">
              <Tooltip
                placement="top"
                arrow
                title={intl.formatMessage({
                  defaultMessage: 'Add keyword',
                })}
              >
                <Button
                  loading={updateUserSettingMutation.loading}
                  variant="icon"
                  size="tiny"
                  color="primary"
                  onClick={tryToAdd}
                >
                  <PlusIcon />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                arrow
                title={intl.formatMessage({
                  defaultMessage: 'Close',
                })}
              >
                <Button
                  variant="icon"
                  size="tiny"
                  onClick={() => {
                    setAdding(false);
                  }}
                >
                  <X />
                </Button>
              </Tooltip>
            </div>
          </div>
        ) : (
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              defaultMessage: 'Add keyword',
            })}
          >
            <Button
              onClick={() => {
                setAdding(true);
              }}
              variant="icon"
              color="success"
              size="tiny"
            >
              <PlusIcon />
            </Button>
          </Tooltip>
        )}
      </div>
      <Tooltip
        title={
          tag.isSystem ? (
            <FormattedMessage
              defaultMessage="This is a default tag. It cannot be removed"
              description="Tooltip for default tag"
              id="V/Ee4t"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Remove tag"
              description="Remove tag tooltip text"
            />
          )
        }
      >
        <Button
          variant="icon"
          disabled={
            tag.isSystem ||
            updateUserSettingMutation.loading ||
            deleteTagMutation.loading
          }
          onClick={() => setConfirmDeleteTagDialog(true)}
          size="large"
        >
          <Trash />
        </Button>
      </Tooltip>
      <ModalDialog
        title={intl.formatMessage({
          defaultMessage: 'Are you sure?',
          id: '+eqBXG',
          description: 'Dialog confirmation message.',
        })}
        text={
          <FormattedMessage defaultMessage="This will permanantly delete the tag" />
        }
        open={confirmDeleteTagDialog}
        onClose={() => setConfirmDeleteTagDialog(false)}
        actions={
          <div className="flex flex-row gap-2">
            <Button
              variant="neutral-secondary"
              onClick={() => setConfirmDeleteTagDialog(false)}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              loading={
                updateUserSettingMutation.loading || deleteTagMutation.loading
              }
              onClick={async () => {
                await updateUserSetting({
                  variables: {
                    input: omitDeep(
                      {
                        autoHighlights: autoHighlights.filter(
                          (y) => y.autoTag !== tag.name
                        ),
                      },
                      '__typename'
                    ),
                  },
                });
                await deleteTag({ variables: { input: tag.icon } });
              }}
            >
              <FormattedMessage
                defaultMessage="Delete"
                description="Delete tag button label."
              />
            </Button>
          </div>
        }
      />
      <ModalDialog
        title={intl.formatMessage({
          defaultMessage: 'Are you sure?',
          id: '+eqBXG',
          description: 'Dialog confirmation message.',
        })}
        text={
          <FormattedMessage defaultMessage="This will permanantly delete the autohighlight" />
        }
        open={confirmDeleteAutoHighlightDialog.open}
        onClose={() =>
          setConfirmDeleteAutoHighlightDialog({ open: false, value: '' })
        }
        actions={
          <div className="flex flex-row gap-2">
            <Button
              variant="neutral-secondary"
              onClick={() =>
                setConfirmDeleteAutoHighlightDialog({ open: false, value: '' })
              }
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              loading={
                updateUserSettingMutation.loading || deleteTagMutation.loading
              }
              onClick={async () => {
                trackWebEvent('Deleted autohighlight');
                await updateUserSetting({
                  variables: {
                    input: omitDeep(
                      {
                        autoHighlights: autoHighlights.filter(
                          (y) =>
                            y.searchValue !==
                            confirmDeleteAutoHighlightDialog.value
                        ),
                      },
                      '__typename'
                    ),
                  },
                });
                setConfirmDeleteAutoHighlightDialog({
                  open: false,
                  value: '',
                });
              }}
            >
              <FormattedMessage
                defaultMessage="Delete"
                description="Delete Auto highlight button label."
              />
            </Button>
          </div>
        }
      />
    </div>
  );
};
