import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { kBilling, kTeam } from '../../helpers/routes';
import { trackWebPage } from '../../helpers/analytics';
import { Page } from '../Common/Navigation';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { selectUserPlan } from '../../redux/selectors';

/**
 *
 * @returns {React.FC} component
 */
const PaymentSuccessComponent: React.FC = () => {
  useEffect(() => {
    trackWebPage('Payment Success Page');
  }, []);

  const userPlan = useSelector(selectUserPlan);
  const redirectTo =
    userPlan.paid?.__typename === 'TeamPaidPlan'
      ? `/#${kTeam}`
      : `/#${kBilling}`;

  return (
    <Page title="">
      <h1 className="font-bold text-2xl">
        <FormattedMessage
          defaultMessage="Success!"
          description="Payment success page title."
        />
      </h1>
      <div className="flex max-w-4xl flex-col lg:flex-row">
        <div className="flex flex-col gap-2">
          <p>
            <FormattedMessage
              defaultMessage="Your payment processed successfully."
              description="Payment success message."
            />
          </p>
          <p className="font-bold">
            <FormattedMessage
              defaultMessage="Congratulations!"
              description="Payment success message."
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="You have now joined the Tactiq {tier} community, it's great to have
                you on board!"
              values={{
                tier:
                  userPlan.paid?.__typename === 'TeamPaidPlan' ? 'Team' : 'Pro',
              }}
              description="Payment success message."
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="If you have any questions just hit us up at team@tactiq.io."
              description="Payment success page contact support text."
            />
          </p>
          <Button href={redirectTo}>
            <FormattedMessage
              defaultMessage="Continue"
              description="Link button title."
            />
          </Button>
        </div>
        <div className="p-4">
          <img
            className="mx-auto h-[268px] w-[268px] rounded-2xl"
            src="https://firebasestorage.googleapis.com/v0/b/tactiq-prod-au.appspot.com/o/icons%2FgiphyDog.gif?alt=media&token=8bf11d03-f8ef-4ed8-9230-f9b171a7dacb"
            alt=""
          />
        </div>
      </div>
    </Page>
  );
};

export default PaymentSuccessComponent;
