import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackWebEvent } from '../../helpers/analytics';
import { ChevronDown } from 'lucide-react';
import { Tooltip } from '../../components/Tooltip';
import { Button } from '../../components/buttons';
import { Spinner } from '../../components/Spinner';

export const OnboardingBlock: React.FC<{
  isLarge: boolean;
  icon: string;
  title: string;
  alerts: React.ReactNode[];
  button: React.ReactNode;
}> = ({ isLarge, icon, title, button, alerts }) => {
  const [isExpanded, setExpanded] = useState(isLarge);
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="w-full rounded-md border border-slate-200 px-3 py-4 md:w-[500px]">
      <div className="flex flex-row items-center gap-4">
        <img
          src={icon}
          alt={title}
          className="h-12 w-12 overflow-hidden rounded-xl"
        />
        <div className="flex-1 text-nowrap text-sm">{title}</div>
        {isExpanded ? (
          isLoading ? (
            <Spinner className="mr-3" />
          ) : (
            button
          )
        ) : (
          <Tooltip title={<FormattedMessage defaultMessage="See more" />}>
            <Button
              variant="icon"
              onClick={() => {
                setExpanded(true);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
                trackWebEvent('Expanded details on the setup page', {
                  app: title,
                });
              }}
            >
              <ChevronDown />
            </Button>
          </Tooltip>
        )}
      </div>
      {isExpanded ? (
        <div className="mt-2">
          {alerts.map((a, i) => (
            <React.Fragment key={i}>{a}</React.Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};
