import { Condition } from './Condition';
import { Confirmation } from './Confirmation';
import { CustomPrompt } from './CustomPrompt';
import { RunMeetingKit } from './RunMeetingKit';
import { SaveToSpace } from './SaveToSpace';
import { Share } from './Share';
import { StartNode } from './StartNode';
import { Template } from './Template';
import { SendData } from './SendData';
import { useIntl } from 'react-intl';
import { AddLabels } from './AddLabels';
import { SendEmailParticipants } from './SendEmailParticipants';
import { SendEmailTeam } from './SendEmailTeam';

export type EmailNodeData = {
  subjectTemplate: string;
  bodyTemplate: string;
  shareWithParticipants: boolean;
  recipients: { name: string; email: string }[];
};

export type AddNodeOptions = { addAsBranch?: boolean };
export type AddNodeFn = (
  type: NodeType,
  data?: Record<string, unknown> | null,
  options?: AddNodeOptions
) => void;

export const nodeTypes = {
  AddLabels,
  Condition,
  Confirmation,
  CustomPrompt,
  RunMeetingKit,
  SaveToSpace,
  Share,
  StartNode,
  SendData,
  Template,
  SendEmailParticipants,
  SendEmailTeam,
};

// The node types that can create outputs.
// Stored here so it stays in sync
export const outputNodeTypes = new Set([
  'Confirmation',
  'CustomPrompt',
  'RunMeetingKit',
  'Template',
]);

export type NodeType = keyof typeof nodeTypes;

export const useNodeTypeToName = (): Record<NodeType, string> => {
  const intl = useIntl();

  const Share = intl.formatMessage({
    defaultMessage: 'Share to an integration',
  });
  return {
    SaveToSpace: intl.formatMessage({
      defaultMessage: 'Save to a space',
    }),
    AddLabels: intl.formatMessage({
      defaultMessage: 'Add labels',
    }),
    RunMeetingKit: intl.formatMessage({
      defaultMessage: 'AI: Use a quick prompt',
    }),
    CustomPrompt: intl.formatMessage({
      defaultMessage: 'AI: Write your own prompt',
    }),
    Share,
    SendData: Share,
    Confirmation: intl.formatMessage({
      defaultMessage: 'Review before proceeding',
    }),
    Condition: intl.formatMessage({
      defaultMessage: 'Condition',
    }),
    StartNode: intl.formatMessage({
      defaultMessage: 'Start the workflow from a meeting',
    }),
    Template: intl.formatMessage({
      defaultMessage: 'Compose text from template',
    }),
    SendEmailTeam: intl.formatMessage({
      defaultMessage: 'Send email to team',
    }),
    SendEmailParticipants: intl.formatMessage({
      defaultMessage: 'Send email to participants',
    }),
  };
};
