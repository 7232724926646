import {
  ChevronDown,
  ChevronUp,
  Settings2,
  UserPlus,
  Users,
  Wallet2,
} from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { Team } from '../../../graphql/operations';
import { TierPricingDialogSource } from '../../../helpers/analytics';
import { kBilling, kSettingsTeam, kTeam } from '../../../helpers/routes';
import { cx } from '../../../helpers/utils';
import { selectUserTier } from '../../../redux/selectors';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { AddMembersModal } from '../../Teams/Team/AddMembers';
import { NavMenuItem } from './NavMenuItem';
import { Tooltip } from '../../../components/Tooltip';

const InviteTeammembersButtonClasses = 'group';

const InviteTeammembersButtonOpenClasses = 'flex justify-center items-center';

export const TeamMenu: React.FC<{
  team: Team;
  pageName: string;
  isMobileMenuVisible: boolean;
}> = ({ team, pageName, isMobileMenuVisible }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] =
    React.useState<boolean>(false);

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);

  return (
    <div
      className={cx(
        'flex-col gap-2 gap-y-2.5 rounded-card border border-neutral-subtle p-4 pt-3',
        isMobileMenuVisible ? 'flex' : 'hidden md:flex'
      )}
    >
      <div className="flex flex-col">
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant="text"
          fullWidth
          size="text"
        >
          <div className="group flex w-full flex-row justify-between">
            <p className="truncate font-semibold text-neutral-default text-sm">
              {team.displayName}
            </p>
            {isOpen ? (
              <ChevronUp
                strokeWidth={2}
                size={20}
                className="text-neutral-subtle"
                aria-hidden="true"
              />
            ) : (
              <ChevronDown
                strokeWidth={2}
                size={20}
                className="text-neutral-subtle"
                aria-hidden="true"
              />
            )}
          </div>
        </Button>
        <p className="text-neutral-subtle text-xs">
          {team.members.length === 1 ? (
            <FormattedMessage
              defaultMessage="{memberCount} member"
              values={{
                memberCount: team.members.length,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{memberCount} members"
              values={{
                memberCount: team.members.length,
              }}
            />
          )}
        </p>
      </div>
      {isOpen ? (
        <div>
          <NavMenuItem
            currentPage={pageName}
            href={kTeam}
            icon={Users}
            label="Users"
          />
          <NavMenuItem
            currentPage={pageName}
            href={kSettingsTeam}
            icon={Settings2}
            label="Team Settings"
          />
          <NavMenuItem
            currentPage={pageName}
            href={kBilling}
            icon={Wallet2}
            label="Billing"
          />
        </div>
      ) : null}

      <div
        className={
          isOpen
            ? InviteTeammembersButtonOpenClasses
            : InviteTeammembersButtonClasses
        }
      >
        <Tooltip
          arrow
          placement="right"
          title={<FormattedMessage defaultMessage="Invite teammates" />}
        >
          <Button
            variant="neutral-secondary"
            startIcon={<UserPlus className="h-4 w-4" />}
            size="small"
            fullWidth
            onClick={() => setIsAddMemberModalOpen(true)}
          >
            <p className="truncate">
              <FormattedMessage defaultMessage="Invite teammates" />
            </p>
          </Button>
        </Tooltip>
      </div>
      {isAddMemberModalOpen && (
        <AddMembersModal
          team={team}
          open={isAddMemberModalOpen}
          onClose={() => setIsAddMemberModalOpen(false)}
        />
      )}
      {showTierPricingDialog ? (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.TEAM_MENU}
          onClose={() => setShowTierPricingDialog(false)}
          teamSpecific
        />
      ) : null}
    </div>
  );
};
