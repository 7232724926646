import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { forceReload } from '../helpers/force-reload';
import { trackReloadForNewVersion } from '../helpers/analytics';

const NewBuildNotification: React.FC = function () {
  const currentVersion = process.env.BUILD_SHA || '';
  const userVersion =
    useSelector((state: RootState) => state.user.buildVersion) || '';

  const isVersionMismatched =
    currentVersion && userVersion && currentVersion !== userVersion;

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isVersionMismatched) {
      // Random delay to prevent all users from reloading at the same time
      // between 10 seconds and 5 minutes
      const randomDelay = 10000 + Math.random() * 290000;
      interval = setInterval(() => {
        forceReload('TWO_DAYS', () => {
          // biome-ignore lint: noConsole
          console.log(
            'Reloading the page because of a new version',
            currentVersion,
            userVersion
          );
          trackReloadForNewVersion(currentVersion, userVersion);
        });
      }, randomDelay);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isVersionMismatched, currentVersion, userVersion]);

  return null;
};

export default NewBuildNotification;
