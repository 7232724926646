import { enqueueSnackbar } from 'notistack';
import { IntlShape } from 'react-intl';

export function UserCannotChangeSettings(intl: IntlShape) {
  return enqueueSnackbar(
    intl.formatMessage({
      defaultMessage: 'Only team admins can change this setting',
    }),
    { variant: 'WARNING' }
  );
}
