import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch } from '../../components/buttons/Switch';
import { cx } from '../../helpers/utils';

/**
 * Billing cycle switch component
 * @param {unknown} param0 params
 * @param {boolean} param0.isAnnual is annual
 * @param {Function} param0.onChange on change
 * @returns {React.FC} Component
 */
export const BillingCycleSwitch: React.FC<{
  isAnnual: boolean;
  onChange: (isAnnual: boolean) => void;
}> = ({ isAnnual, onChange }) => {
  const onSwitchChange = useCallback(
    (event: any, checked: boolean) => {
      onChange(checked);
    },
    [onChange]
  );

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex flex-row items-center gap-2">
        <FormattedMessage
          defaultMessage="Billing cycle: "
          description="Upgrade plan component. Billing cycle switch label."
        />
        <p className={cx(isAnnual ? '' : 'underline')}>
          <FormattedMessage
            defaultMessage="Monthly"
            id="w+YMFS"
            description="Monthly plan title"
          />
        </p>
        <Switch isOn={isAnnual} onClick={onSwitchChange} />
        <p className={cx(isAnnual ? 'underline' : '')}>
          <FormattedMessage
            defaultMessage="Annual"
            description="Annual plan title"
          />
        </p>
      </div>
      <FormattedMessage
        defaultMessage="(save more on annual plan!)"
        description="Annual discount text"
      />
    </div>
  );
};
