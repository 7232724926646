import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { UpdateUserSettingDocument } from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { RootState } from '../../redux/store';
import { omitDeep } from 'lodash-omitdeep';
import { TextInput } from '../../components/TextInput';
import { ArrowRight, PlusIcon, Trash } from 'lucide-react';

const CellActionsClasses = 'flex flex-row justify-end';

/**
 * Auto Replace
 */
export const AutoReplace: React.FC = () => {
  const autoReplaces = useSelector(
    (state: RootState) => state.user.settings?.autoReplaces
  );

  const [searchValue, setSearchValue] = useState('');
  const [newValue, setNewValue] = useState('');

  const intl = useIntl();

  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  const onAdd = useCallback(async () => {
    if (searchValue) {
      if (autoReplaces?.find((x) => x.searchValue === searchValue)) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'You already have an auto replace, please choose another one',

            description: 'Duplicate replace setting message',
          }),
          { variant: 'WARNING' }
        );
      }

      trackWebEvent('Added autocorrect');
      await updateUserSetting({
        variables: {
          input: omitDeep(
            {
              autoReplaces: (autoReplaces ?? [])
                .concat([{ searchValue, newValue }])
                .sort((a, b) => a.searchValue.localeCompare(b.searchValue)),
            },
            '__typename'
          ),
        },
      });

      setSearchValue('');
      setNewValue('');
    }
  }, [searchValue, autoReplaces, updateUserSetting, newValue, intl]);

  return (
    <table className="text-sm">
      <thead className="text-left font-semibold">
        <th className="p-3" colSpan={2}>
          <FormattedMessage
            defaultMessage="Replace"
            description="Replace settings page. Table header"
          />
        </th>
        <th className="p-3" colSpan={2}>
          <FormattedMessage
            defaultMessage="With"
            description="Replace settings page. Table header"
          />
        </th>
      </thead>

      <tbody>
        {autoReplaces?.map((x, index) => (
          <tr key={index}>
            <TableCell>{x.searchValue}</TableCell>
            <TableCell>
              <ArrowRight />
            </TableCell>
            <TableCell>{x.newValue}</TableCell>
            <TableCell>
              <div className={CellActionsClasses}>
                <Button
                  variant="icon"
                  disabled={updateUserSettingMutation.loading}
                  onClick={async () => {
                    if (
                      confirm(
                        intl.formatMessage({
                          defaultMessage: 'Are you sure?',
                          id: '+eqBXG',
                          description: 'Dialog confirmation message.',
                        })
                      )
                    ) {
                      trackWebEvent('Deleted autocorrect');
                      await updateUserSetting({
                        variables: {
                          input: omitDeep(
                            {
                              autoReplaces: autoReplaces.filter(
                                (y) => y.searchValue !== x.searchValue
                              ),
                            },
                            '__typename'
                          ),
                        },
                      });
                    }
                  }}
                  size="large"
                >
                  <Trash />
                </Button>
              </div>
            </TableCell>
          </tr>
        ))}

        <tr>
          <TableCell>
            <TextInput
              value={searchValue}
              placeholder="tactic"
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') return onAdd();
                },
              }}
              onChange={setSearchValue}
            />
          </TableCell>

          <TableCell />

          <TableCell>
            <TextInput
              value={newValue}
              placeholder="Tactiq ❤️"
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') return onAdd();
                },
              }}
              onChange={setNewValue}
            />
          </TableCell>

          <TableCell>
            <div className={CellActionsClasses}>
              <Button
                color="primary"
                startIcon={<PlusIcon className="h-6 w-6" />}
                loading={updateUserSettingMutation.loading}
                onClick={onAdd}
              >
                <FormattedMessage
                  defaultMessage="Add"
                  description="Button label."
                />
              </Button>
            </div>
          </TableCell>
        </tr>
      </tbody>
    </table>
  );
};

function TableCell({ children }: { children?: ReactNode }) {
  return <td className="border-t p-3">{children}</td>;
}
