import { useMutation, useQuery } from '@apollo/client';
import { ClipboardCopy, Loader2 } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { ModalDialog } from '../../../components/modals';
import {
  GetTeamAuthConfigurationDocument,
  Team,
  TeamAuthConfiguration_ResetScimDocument,
} from '../../../graphql/operations';
import AsyncButton from '../../../components/buttons/AsyncButton';
import { enqueueSnackbar } from 'notistack';

export const SCIMConfigurationDialog: React.FC<{
  team: Team;
  onClose: () => void;
}> = ({ team, onClose }) => {
  const [baseUrl, setBaseUrl] = React.useState<string>('');
  const [token, setToken] = React.useState<string>('');

  const configQuery = useQuery(GetTeamAuthConfigurationDocument, {
    fetchPolicy: 'network-only',
    onCompleted(newData) {
      setBaseUrl(
        newData.teamAuthConfiguration.scim?.baseUrl ??
          configQuery.data?.teamAuthConfiguration.scim?.baseUrl ??
          ''
      );
      setToken(
        configQuery.data?.teamAuthConfiguration.scim?.token ??
          newData.teamAuthConfiguration.scim?.token ??
          ''
      );
    },
  });

  const [
    TeamAuthConfiguration_ResetSCIM,
    TeamAuthConfiguration_ResetSCIMMutation,
  ] = useMutation(TeamAuthConfiguration_ResetScimDocument);

  const isLoading =
    configQuery.loading || TeamAuthConfiguration_ResetSCIMMutation.loading;

  return (
    <ModalDialog
      open
      size="medium"
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      title={
        <div className="flex flex-row gap-4">
          <FormattedMessage defaultMessage="SCIM" />
          {isLoading ? <Loader2 className="animate-spin" /> : null}
        </div>
      }
      text={
        <div className="mt-4 flex flex-col gap-8">
          {baseUrl && token && (
            <div className="flex flex-col gap-4">
              {/* biome-ignore lint/a11y/useKeyWithClickEvents: */}
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  navigator.clipboard.writeText(baseUrl);
                  enqueueSnackbar('URL copied to clipboard', {
                    variant: 'SUCCESS',
                  });
                }}
              >
                <div className="flex-grow whitespace-nowrap">Base URL</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {baseUrl}
                </div>
                <ClipboardCopy className="ml-2 h-4 w-4 cursor-pointer" />
              </div>
              {/* biome-ignore lint/a11y/useKeyWithClickEvents: */}
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  navigator.clipboard.writeText(token);
                  enqueueSnackbar('Token copied to clipboard', {
                    variant: 'SUCCESS',
                  });
                }}
              >
                <div className="flex-grow whitespace-nowrap">Token</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {token}
                </div>
                <ClipboardCopy className="ml-2 h-4 w-4 cursor-pointer" />
              </div>
            </div>
          )}

          <div className="flex flex-row items-center gap-4">
            <AsyncButton
              disabled={isLoading}
              loading={TeamAuthConfiguration_ResetSCIMMutation.loading}
              onClick={async () => {
                const result = await TeamAuthConfiguration_ResetSCIM();

                if (result.data?.TeamAuthConfiguration_ResetSCIM) {
                  setBaseUrl(
                    result.data.TeamAuthConfiguration_ResetSCIM.scim?.baseUrl ??
                      ''
                  );
                  setToken(
                    result.data.TeamAuthConfiguration_ResetSCIM.scim?.token ??
                      ''
                  );
                }
              }}
              variant="neutral-primary"
            >
              <FormattedMessage defaultMessage="Reset SCIM Configuration" />
            </AsyncButton>
          </div>
        </div>
      }
      actions={
        <div className="flex flex-row items-center justify-end gap-2">
          <Button onClick={onClose} variant="neutral-secondary">
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </div>
      }
    />
  );
};
