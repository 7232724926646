import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { baseURL, isProduction } from '../../../helpers/firebase/config';
import icon from '../../../img/zoom.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import { PlusIcon } from 'lucide-react';

export const integration: IntegrationImplementation = {
  order: 3,
  hidden: true,
  id: 'zoomapp',
  category: IntegrationCategory.Meetings,
  title: 'Zoom App',
  icon,
  description: () => (
    <>
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Capture Zoom Web Client and Desktop transcripts."
          description="Zoom Desktop integration description"
        />
      </div>
    </>
  ),
  connector: () => {
    const clientId = isProduction()
      ? 'kj9O0L8Rq6EUi9Aub6V3Q'
      : 'J5812NwQMyGrJiQvQFAfQ';

    return (
      <Button
        startIcon={<PlusIcon className="h-6 w-6" />}
        href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${baseURL}/api/2/u/integrations/zoom-app/callback`}
      >
        Connect to Zoom App
      </Button>
    );
  },
  allowMultipleConnections: true,
};

export default integration;
