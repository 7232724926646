import React, { ReactElement, useState } from 'react';
import {
  useWorkflowList,
  useWorkflowRun,
  useWorkflowExecutionsMeeting,
  useWorkflowStopUsing,
} from '../../services/Workflow';
import { Menu } from '../../components/Menu';
import { Button } from '../../components/buttons';
import { FormattedMessage } from 'react-intl';
import {
  ChevronDown,
  CirclePlay,
  ChevronUp,
  X,
  SquareArrowOutUpRight,
  Pencil,
  GitCompare,
  Library,
} from 'lucide-react';
import { WorkflowsType } from '../../graphql/operations';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';
import {
  workflowItem,
  workflowList as workflowListRoute,
} from '../../helpers/routes';
import { BadgeNew } from '../Common/BadgeNew';
import {
  trackMyMeetingClickedWorkflowMenu,
  trackWorkflowLibraryClickedWorkflowMenu,
  trackWorkflowVideoClickedWorkflowMenu,
  trackWorkflowMenuOpened,
} from '../../helpers/analytics';
import { Alert } from '../../components/Alert';
import { Tooltip } from '../../components/Tooltip';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import { WorkflowTutorialVideoPlayer } from './VideoTutorialButtons';
import { WorkflowExecutionItemRow } from './WorkflowExecutionItemRow';
import { useFullMeeting } from '../Transcripts/common/meeting-hooks';
import { CanUseAIToolsResult, canUseAITools } from '../../helpers/meetings';

export default function WorkflowRunMenu(props: {
  meetingId: string;
}): ReactElement {
  const navigate = useNavigate();
  const { meetingId } = props;
  const [open, setOpen] = useState(false);
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [stopUsingId, setStopUsingId] = useState<string | undefined>();
  const usedWorkflows = useWorkflowList({
    input: { type: WorkflowsType.USED_ALL },
  });
  const workflowList = useWorkflowList({
    input: { type: WorkflowsType.MINE },
  });

  const executionData = useWorkflowExecutionsMeeting({ meetingId });
  const workflowRun = useWorkflowRun('meeting');
  const stopUsing = useWorkflowStopUsing();
  const loading = workflowRun.loading;

  const meeting = useFullMeeting();
  const aiAvailable = canUseAITools(meeting);

  const workflows = workflowList.data?.workflows ?? [];

  const used = (usedWorkflows.data?.workflows ?? []).filter((w) => w.isUsed);
  const usedIds = new Set(used.map((ii) => ii.id));

  const executions = executionData.data?.workflowExecutionsMeeting ?? [];

  const showEmptyState = workflows.length === 0 && used.length === 0;

  let workflowMenuContent = null;

  if (videoPlayerOpen) {
    return (
      <WorkflowTutorialVideoPlayer
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
      />
    );
  }

  if (!showEmptyState && aiAvailable === CanUseAIToolsResult.Yes) {
    workflowMenuContent = (
      <Menu width="100%">
        <Menu.Trigger>
          <Button
            variant="neutral-secondary"
            size="small"
            loading={loading}
            className="flex w-full max-w-full justify-between"
            onClick={() => trackWorkflowMenuOpened({ meetingId })}
          >
            <FormattedMessage defaultMessage="Run workflow" />
            <ChevronDown className="w-5 text-slate-500" />
          </Button>
        </Menu.Trigger>
        {used.length > 0 && (
          <>
            <Menu.Label>
              <FormattedMessage defaultMessage="Recently Used" />
            </Menu.Label>
            {used.slice(0, 10).map((w) => (
              <Menu.Item
                key={w.id}
                onClick={() =>
                  workflowRun.request({
                    input: { id: w.id, meetingId },
                  })
                }
              >
                <div className="group relative flex w-full items-center justify-between">
                  <span className="truncate">{w.name}</span>
                  <Tooltip
                    placement="top"
                    arrow
                    title={<FormattedMessage defaultMessage="Stop using" />}
                  >
                    <Button
                      disabled={stopUsing.loading}
                      loading={stopUsing.loading}
                      variant="naked"
                      size="icon"
                      className="absolute right-0 hidden group-hover:inline-flex"
                      onClick={(e) => {
                        e.stopPropagation();
                        setStopUsingId(w.id);
                      }}
                      startIcon={<X size="1rem" />}
                    />
                  </Tooltip>
                </div>
              </Menu.Item>
            ))}
            <Menu.Divider />
          </>
        )}
        <Menu.Label>
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Label>
        {workflows
          .filter((w) => !usedIds.has(w.id))
          .slice(0, 10)
          .map((w) => (
            <Menu.Item
              key={w.id}
              onClick={() => {
                workflowRun.request({ input: { id: w.id, meetingId } });
              }}
            >
              <div className="group relative flex w-full items-center justify-between">
                <span className="truncate">{w.name}</span>
                <Tooltip
                  placement="top"
                  arrow
                  title={<FormattedMessage defaultMessage="Edit workflow" />}
                >
                  <Button
                    variant="naked"
                    size="icon"
                    className="absolute right-0 hidden group-hover:inline-flex"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(workflowItem.replace(':id', w.id));
                    }}
                    startIcon={<Pencil size="1rem" />}
                  />
                </Tooltip>
              </div>
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item
          icon={<GitCompare className="size-4 stroke-slate-700" />}
          as="link"
          to={workflowListRoute}
          onClick={() => trackMyMeetingClickedWorkflowMenu({ meetingId })}
        >
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Item>
        <Menu.Item
          icon={<Library className="size-4 stroke-slate-700" />}
          onClick={() => {
            trackWorkflowLibraryClickedWorkflowMenu({ meetingId });
            setOpen(true);
          }}
        >
          <FormattedMessage defaultMessage="Workflow Library" />
        </Menu.Item>
        <Menu.Item
          icon={<CirclePlay className="size-4 stroke-slate-700" />}
          onClick={() => {
            trackWorkflowVideoClickedWorkflowMenu({ meetingId });
            setVideoPlayerOpen(true);
          }}
        >
          <FormattedMessage defaultMessage="Watch video" />
        </Menu.Item>
      </Menu>
    );
  }

  if (aiAvailable !== CanUseAIToolsResult.Yes) {
    switch (aiAvailable) {
      case CanUseAIToolsResult.TooShort:
        workflowMenuContent = (
          <Alert
            variant="light"
            severity="warning"
            description={
              <FormattedMessage defaultMessage="Workflows are available for meetings longer than 2 minutes." />
            }
          />
        );
        break;
      case CanUseAIToolsResult.IsPreview:
        workflowMenuContent = (
          <Alert
            variant="light"
            severity="warning"
            description={
              <FormattedMessage
                defaultMessage="You can't run workflow on a preview meeting."
                id=""
              />
            }
          />
        );
        break;
      case CanUseAIToolsResult.IsArchived:
        workflowMenuContent = (
          <Alert
            variant="light"
            severity="warning"
            description={
              <FormattedMessage
                defaultMessage="You can't run workflow on archived meeting."
                id=""
              />
            }
          />
        );
        break;
      default:
        workflowMenuContent = (
          <Alert
            variant="light"
            severity="warning"
            description={
              <FormattedMessage
                defaultMessage="You can't run workflow on this meeting."
                id=""
              />
            }
          />
        );
    }
  }

  return (
    <>
      <WorkflowDiscoveryModal
        meetingId={meetingId}
        open={open}
        setOpen={setOpen}
      />
      {stopUsingId && (
        <ConfirmActionDialog
          open
          title={<FormattedMessage defaultMessage="Stop using workflow" />}
          text={
            <FormattedMessage defaultMessage="Are you sure you want to stop using this workflow?" />
          }
          yes={<FormattedMessage defaultMessage="Yes" />}
          yesProps={{ loading: stopUsing.loading, color: 'error' }}
          onYes={async () => {
            await stopUsing.request({
              input: { id: stopUsingId },
            });
            setStopUsingId(undefined);
          }}
          onNo={() => setStopUsingId(undefined)}
        />
      )}
      <div className="rounded-2xl bg-gradient-to-b from-brand-400 to-pink-400 p-[3px]">
        <div className="flex h-full w-full flex-col gap-3 rounded-[13px] border border-slate-200/70 bg-white p-4 text-slate-600">
          <div className="flex items-center gap-2 font-medium text-slate-700 text-sm">
            <GitCompare className="size-7 rounded-lg bg-slate-100 p-1.5 text-slate-600" />

            {showEmptyState ? (
              <Button
                variant="naked"
                size="small"
                onClick={() => {
                  trackWorkflowLibraryClickedWorkflowMenu({ meetingId });
                  setOpen(true);
                }}
                endIcon={<SquareArrowOutUpRight className="w-5" />}
              >
                <FormattedMessage
                  defaultMessage="Discover workflows"
                  id="X/VvPv"
                />
              </Button>
            ) : (
              <>
                <FormattedMessage defaultMessage="Workflows" />
                <BadgeNew visible />
              </>
            )}
          </div>
          {workflowMenuContent}
          {executions.length > 0 && (
            <>
              <div className="flex items-center gap-1">
                <Button
                  variant="naked"
                  size="small"
                  onClick={() => setExpanded(!expanded)}
                  className="!text-slate-600"
                  endIcon={
                    expanded ? (
                      <ChevronUp size="1rem" className="" />
                    ) : (
                      <ChevronDown size="1rem" className="" />
                    )
                  }
                >
                  <FormattedMessage defaultMessage="Recent activity" />
                </Button>
              </div>
              {expanded && (
                <div className="flex flex-col gap-y-1">
                  {executions.map((ex) => {
                    return (
                      <WorkflowExecutionItemRow key={ex.id} execution={ex} />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
