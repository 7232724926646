import { Reply, Trash2 } from 'lucide-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../../../../components/buttons';
import {
  DeleteMeetingCommentDocument,
  MeetingComment,
} from '../../../../../../graphql/operations';
import { Dot } from '../../../../../Common/Dot';
import { Comment } from './editor/Comment';
import { useSelector } from 'react-redux';
import { selectUid } from '../../../../../../redux/selectors';
import { useFullMeeting } from '../../../../common/meeting-hooks';
import { useMutation } from '@apollo/client';
import { formatTimeAgo } from '../../../../../../helpers/i18n';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Avatar } from '../../../../../../components/Avatar';

export interface CommentBoxProps {
  comment: MeetingComment;
  onReply?: () => void;
}

export const CommentBox: React.FC<CommentBoxProps> = ({ comment, onReply }) => {
  const intl = useIntl();
  const displayName = comment.user?.displayName ?? 'Deleted user';
  const uid = useSelector(selectUid);
  const meeting = useFullMeeting();
  const canDelete = uid === comment.user?.id || meeting?.userId === uid;
  const [deleteComment, deleteCommentMutation] = useMutation(
    DeleteMeetingCommentDocument
  );

  return (
    <div className="flex flex-row space-x-2">
      <div>
        <Avatar
          src={comment.user?.photoURL}
          name={displayName}
          className="size-6"
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="mb-2 flex flex-col items-start space-x-2 space-y-2 sm:h-6 sm:flex-row sm:items-center sm:space-y-0">
          <div className="flex-1 font-semibold text-sm lg:flex-none">
            {displayName}
          </div>

          <Dot className="hidden lg:block" />

          <div className="hidden flex-1 font-normal text-xs lg:block">
            <FormattedMessage
              defaultMessage="Posted {date}"
              values={{
                date: formatTimeAgo(intl.locale, new Date(comment.createdAt)),
              }}
            />
          </div>

          {onReply && (
            <Tooltip
              className="hidden sm:block"
              title={<FormattedMessage defaultMessage="Reply" />}
            >
              <Button size="small" variant="naked" onClick={onReply}>
                <Reply className="h-4 w-4 text-slate-600" />
              </Button>
            </Tooltip>
          )}

          {canDelete && (
            <Tooltip
              className="hidden sm:block"
              title={<FormattedMessage defaultMessage="Delete" />}
            >
              <Button
                size="small"
                variant="naked"
                loading={deleteCommentMutation.loading}
                onClick={async () => {
                  await deleteComment({ variables: { id: comment.id } });
                }}
              >
                {!deleteCommentMutation.loading && (
                  <Trash2 className="h-4 w-4 text-red-400" />
                )}
              </Button>
            </Tooltip>
          )}
        </div>

        <div className="flex-1">
          <Comment mentions={[]} existingComment={comment} readonly={true} />
        </div>
        <div className="mt-4 flex items-center justify-end space-x-2 sm:hidden">
          {onReply && (
            <Tooltip title={<FormattedMessage defaultMessage="Reply" />}>
              <Button size="small" variant="naked" onClick={onReply}>
                <Reply className="h-4 w-4 text-slate-600" />
              </Button>
            </Tooltip>
          )}

          {canDelete && (
            <Tooltip title={<FormattedMessage defaultMessage="Delete" />}>
              <Button
                size="small"
                variant="naked"
                loading={deleteCommentMutation.loading}
                onClick={async () => {
                  await deleteComment({ variables: { id: comment.id } });
                }}
              >
                {!deleteCommentMutation.loading && (
                  <Trash2 className="h-4 w-4 text-red-400" />
                )}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
