import React from 'react';
import { Alert } from '../../components/Alert';
import { FormattedMessage } from 'react-intl';

export const AlertReadyToUseInThisBrowser: React.FC = () => {
  return (
    <Alert
      severity="success"
      description={
        <FormattedMessage defaultMessage="Ready to use in this browser" />
      }
    />
  );
};
