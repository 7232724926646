import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UserOnboarding } from '../../graphql/operations';
import {
  trackSetupHelperClosed,
  trackSetupHelperToTactiq,
  trackWebPage,
} from '../../helpers/analytics';
import { selectUserOnboarding } from '../../redux/selectors';
import { OnboardingMSTeams } from './OnboardingMSTeams';
import { OnboardingZoom } from './OnboardingZoom';
import { OnboardingGoogleMeetHero } from './OnboardingGoogleMeetHero';
import { Button } from '../../components/buttons';
import { X } from 'lucide-react';
import SignUpReason from '../Common/signup-reason-modal';
import { OnboardingGoogleMeet } from './OnboardingGoogleMeet';
import { OnboardingMSTeamsHero } from './OnboardingMSTeamsHero';

export type MainBlockTypes = 'msteams' | 'googlemeet';

export const SetupRenderComponent: React.FC<{ onboarding: UserOnboarding }> = ({
  onboarding,
}) => {
  const intl = useIntl();

  const isZoomSelected = onboarding.Zoom.isSelected;
  const isMSTeamsSelected = onboarding.Teams.isSelected;
  const isWebexSelected = onboarding.Webex.isSelected;
  const isGoogleMeetSelected =
    onboarding.GoogleMeet.isSelected ||
    Boolean(!isZoomSelected && !isMSTeamsSelected && !isWebexSelected);

  const nothingIsSelected =
    !isGoogleMeetSelected &&
    !isZoomSelected &&
    !isMSTeamsSelected &&
    !isWebexSelected;

  const onlyMSTeamsSelected =
    isMSTeamsSelected &&
    !isZoomSelected &&
    !isGoogleMeetSelected &&
    !isWebexSelected;

  const mainBlock: MainBlockTypes = onlyMSTeamsSelected
    ? 'msteams'
    : 'googlemeet';

  useEffect(() => {
    trackWebPage('Setup', {
      main_block: mainBlock,
    });
  }, [mainBlock]);

  return (
    <div className="mx-auto max-w-7xl p-8">
      <div className="fixed top-5 right-5">
        <Button
          href="/#/"
          variant="icon"
          onClick={() => {
            trackSetupHelperClosed(mainBlock);
          }}
        >
          <X className="h-6 w-6" />
        </Button>
      </div>

      <hgroup className="mb-8 flex flex-col text-center">
        <h1 className="font-bold text-4xl">
          {intl.formatMessage({
            defaultMessage: 'Great, you’re all set up!',
          })}
        </h1>
        <p className="mt-4 text-slate-500">
          {intl.formatMessage({
            defaultMessage: 'Time to transcribe your first meeting',
          })}
        </p>
      </hgroup>

      <div className="flex w-full flex-col items-center gap-8">
        {/* Hero needs to be able to swap between MS Teams and Google Meet */}
        {onlyMSTeamsSelected ? (
          <OnboardingMSTeamsHero mainBlock={mainBlock} />
        ) : (
          <OnboardingGoogleMeetHero mainBlock={mainBlock} />
        )}
        <OnboardingZoom
          isLarge={isZoomSelected || nothingIsSelected}
          mainBlock={mainBlock}
          onboarding={onboarding}
        />
        {/* OnboardingMSTeams could be OnboardingGoogleMeet  */}
        {onlyMSTeamsSelected ? (
          <OnboardingGoogleMeet
            isLarge={isGoogleMeetSelected || nothingIsSelected}
            mainBlock={mainBlock}
          />
        ) : (
          <OnboardingMSTeams
            isLarge={isMSTeamsSelected || nothingIsSelected}
            mainBlock={mainBlock}
          />
        )}

        <Button
          variant="soft"
          href="/#/"
          onClick={() => {
            trackSetupHelperToTactiq(mainBlock);
          }}
        >
          {intl.formatMessage({
            defaultMessage: "I'll try it later",
          })}
        </Button>
      </div>
      <SignUpReason />
    </div>
  );
};

/**
 * Setup component
 */
export const Setup: React.FC = () => {
  const onboarding = useSelector(selectUserOnboarding);

  return <SetupRenderComponent onboarding={onboarding} />;
};
