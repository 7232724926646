import { MeetingLinkOrigin, generateMeetingLink } from '@tactiq/model';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { HelpCircle, Link, Mail, UserCog2 } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { updateMeeting } from '../../../graphql/meetings';
import {
  MeetingReach,
  MeetingShare,
  MeetingShareSettings,
  TeamMember,
} from '../../../graphql/operations';
import {
  trackShareEmailLearnMoreClick,
  trackShareEmailOnShare,
  trackShareEmailShareWithParticipantsClick,
} from '../../../helpers/analytics';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import { baseURL } from '../../../helpers/firebase/config';
import { cx } from '../../../helpers/utils';
import icon from '../../../img/link.png';
import {
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import {
  getMeetingParticipantEmails,
  selectTeam,
  selectTeamEmails,
  selectUid,
  selectUserEmail,
} from '../../../redux/selectors';
import { Chip } from '../../../components/Chips';
import { Tooltip } from '../../../components/Tooltip';
import { Spinner } from '../../../components/Spinner';
import { RadioGroup, Radio } from '../../../components/Radio';
import { Avatar } from '../../../components/Avatar';
import { validate } from 'email-validator';
import { uniqBy } from 'lodash';
import { Alert } from '../../../components/Alert';
import { GenericMember, personEmail, personId, personName } from '.';
import { Autocomplete } from '../../../components/Autocomplete';

const CircleClasses = 'rounded-full p-2 flex';

const HelpLink = (props: { url: string; place: string }) => {
  const { url, place } = props;

  return (
    <Tooltip title="Learn more about sharing">
      <Button
        variant="icon"
        onClick={() => {
          trackShareEmailLearnMoreClick(place);
          window.open(url);
        }}
      >
        <HelpCircle />
      </Button>
    </Tooltip>
  );
};

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { meeting, connection, share } = props;

  const teamEmails = useSelector(selectTeamEmails);
  const userEmail = useSelector(selectUserEmail);
  const participantsEmails = getMeetingParticipantEmails(meeting, userEmail);

  const team = useSelector(selectTeam);
  const currentUserId = useSelector(selectUid);
  const excludedIds = new Set([currentUserId]);

  const [emails, setEmails] = useState<string[]>(uniq([...props.emails]));
  const [reach, setReach] = useState(
    props.emails.length
      ? MeetingReach.RESTRICTED
      : (meeting.permissions?.allow.reach ?? props.reach ?? MeetingReach.PUBLIC)
  );
  const [working, setWorking] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<
    Array<TeamMember | GenericMember>
  >([]);
  const intl = useIntl();

  const getSuggestedEmails = (includeTeam: boolean) => {
    return difference(
      uniq([...(includeTeam ? teamEmails : []), ...participantsEmails]),
      selectedMembers.map((member) => personEmail(member)),
      meeting.permissions?.allow.emails ?? []
    );
  };

  const suggestedEmails = getSuggestedEmails(false);
  const suggestedMembers: GenericMember[] = suggestedEmails.map(
    (suggestedEmail) => ({
      id: suggestedEmail,
      name: suggestedEmail,
    })
  );

  const onShare = useCallback(
    async (trigger: string, toSend?: string[], keepOpen?: boolean) => {
      if (working) return;

      try {
        setWorking(true);
        const selectedEmails = selectedMembers.map((member) =>
          personEmail(member)
        );

        const emailsToSend =
          toSend ??
          uniq([
            ...(meeting.permissions?.allow.emails ?? []),
            ...selectedEmails,
          ]);

        if (reach === MeetingReach.RESTRICTED && !toSend) {
          setEmails(emailsToSend);
        }

        trackShareEmailOnShare(reach, emailsToSend.length, trigger);

        await share(
          integration,
          connection,
          {
            reach,
            emails: emailsToSend,
            analyticsAttributes: {
              share_type: {
                'done-top': 'Email',
                'done-bottom': 'Link',
                removeaccess: 'RemoveAccess',
              }[trigger],
              recipients_email_count: emails.length,
              recipients_email_total: emailsToSend.length,
            },
          },
          keepOpen
        );
      } finally {
        setWorking(false);
      }
    },
    [
      working,
      meeting.permissions?.allow.emails,
      emails,
      selectedMembers,
      reach,
      share,
      connection,
    ]
  );

  // TODO later: show top suggested emails available for clicking a la autocomplete
  // console.log('AC', [currentEmail, ...getSuggestedEmails(true)]);

  return (
    <div className="flex flex-grow-1 flex-col gap-4">
      <div className="flex flex-row items-start gap-4">
        <div className={cx(CircleClasses, 'bg-accent-primary')}>
          <Mail className="size-5 text-white" />
        </div>
        <h3 className="flex-1 text-xl">
          <FormattedMessage defaultMessage="Share by email" />
        </h3>
        {integration.helpUrl ? (
          <div className="-mt-2 mr-6">
            <HelpLink place="top" url={integration.helpUrl} />
          </div>
        ) : null}
      </div>

      <div className="flex flex-col gap-2">
        {suggestedEmails.length ? (
          <div className="py-3">
            <Alert
              severity="info"
              action={
                <Button
                  loading={working}
                  onClick={() => {
                    setSelectedMembers(
                      uniqBy(
                        [...selectedMembers, ...suggestedMembers],
                        personEmail
                      )
                    );

                    trackShareEmailShareWithParticipantsClick(
                      suggestedMembers.length
                    );
                  }}
                >
                  <FormattedMessage defaultMessage="Add" />
                </Button>
              }
              description={
                <FormattedMessage
                  defaultMessage="Add meeting participants ({suggestedParticipants})"
                  values={{
                    suggestedParticipants: suggestedMembers
                      .map((member) => personEmail(member))
                      .join(', '),
                  }}
                />
              }
            />
          </div>
        ) : null}
        <div className="flex flex-row items-center gap-2">
          <div>
            <Autocomplete<TeamMember | GenericMember>
              value={selectedMembers}
              onChange={setSelectedMembers}
              full={true}
              freeSolo={true}
              validateFreeSolo={validate}
              multiple={true}
              id={(person) => personId(person)}
              name={(person) => personEmail(person)}
              optionsWidth="33rem"
              renderOption={(person) => (
                <div className="mt-1 flex items-center gap-1">
                  <Avatar
                    src={('photoURL' in person && person.photoURL) || ''}
                    className="flex-shrink-0"
                    name={personName(person)}
                  />
                  <span className="block truncate">
                    {personName(person)} ({personEmail(person)})
                  </span>
                </div>
              )}
              options={
                team?.members.filter(
                  (member) =>
                    member.uid &&
                    !excludedIds.has(member.uid) &&
                    !member.deactivated
                ) || []
              }
              placeholder={intl.formatMessage({
                defaultMessage: 'Type email or select team members',
              })}
            />
          </div>
          <div className="ml-auto flex items-end">
            <div className="ml-auto">
              <Button
                loading={working}
                onClick={() => {
                  if (!selectedMembers.length) return;
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  onShare('done-top');
                }}
              >
                <FormattedMessage defaultMessage="Share" />
              </Button>
            </div>
          </div>
        </div>

        {/* New share with participants button - only switch after capturing the analytics of the old way first */}
        {/* {suggestedEmails.length ? (
          <Button
            startIcon={<PlusIcon size="1rem" />}
            size="tiny"
            variant="neutral-tertiary"
            textSize="xs"
            loading={working}
            onClick={() => {
              setSelectedMembers(
                uniqBy([...selectedMembers, ...suggestedMembers], personEmail)
              );
              trackShareEmailShareWithParticipantsClick(
                suggestedMembers.length
              );
            }}
          >
            <FormattedMessage
              defaultMessage="Add meeting participants ({suggestedParticipants})"
              values={{
                suggestedParticipants: suggestedMembers.length,
              }}
            />
          </Button>
        ) : null} */}
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <div className={cx(CircleClasses, 'bg-accent-primary')}>
            <Link className="size-5 text-white" />
          </div>
          <h3 className="flex-1 text-xl">
            <FormattedMessage defaultMessage="Get link" />
          </h3>
          <div className="flex items-center">
            <div className="ml-auto">
              <Button
                onClick={() =>
                  onShare('done-bottom', meeting.permissions?.allow.emails)
                }
                loading={working}
              >
                <FormattedMessage defaultMessage="Save and Copy Link" />
              </Button>
            </div>
          </div>
        </div>

        <RadioGroup
          onChange={(value) => setReach(value as MeetingReach)}
          value={reach}
          className="mt-2 flex flex-col gap-2"
        >
          <Radio
            value={MeetingReach.RESTRICTED}
            label={<FormattedMessage defaultMessage="Restricted" />}
            description={
              <FormattedMessage defaultMessage="Only users with access can view this meeting using this link" />
            }
          />
          <Radio
            value={MeetingReach.PUBLIC}
            label={<FormattedMessage defaultMessage="Everyone with the link" />}
            description={
              <FormattedMessage defaultMessage="Everyone with a Tactiq account with this link can view this meeting" />
            }
          />
        </RadioGroup>
      </div>

      {(meeting.permissions?.allow.emails.length ?? 0) > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <div className={cx(CircleClasses, 'bg-accent-primary')}>
              <UserCog2 className="size-5 text-white" />
            </div>
            <h3 className="flex-1 text-xl">
              <FormattedMessage defaultMessage="Users with access" />
            </h3>
            {working && <Spinner />}
          </div>

          <div className="flex flex-wrap items-center gap-2">
            {reach === MeetingReach.PUBLIC ? (
              <Chip
                disabled={working}
                onDelete={() => setReach(MeetingReach.RESTRICTED)}
              >
                <FormattedMessage defaultMessage="Everyone with the link" />
              </Chip>
            ) : null}

            {meeting.permissions?.allow.emails.map((x) => (
              <Chip
                disabled={working}
                key={x}
                onDelete={() =>
                  onShare(
                    'removeaccess',
                    meeting.permissions?.allow.emails.filter((e) => e !== x),
                    true
                  )
                }
              >
                {x}
              </Chip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options, keepOpen) => {
  await updateMeeting({
    id: options.meetingId,
    rawTranscript: options.rawTranscript,
    permissions: {
      reach: options.reach ?? MeetingReach.RESTRICTED,
      emails: options.emails ?? [],
      settings: {
        isSharingDetails: options.isSharingDetails,
        isSharingHighlights: options.isSharingHighlights,
        isSharingNotes: options.isSharingNotes,
        isSharingTranscript: options.isSharingTranscript,
      },
    },
  });

  return {
    link: generateMeetingLink(
      baseURL,
      { meetingId: options.meetingId },
      MeetingLinkOrigin.SHARING_LINK
    ),
    keepOpen,
  };
};

const unshareImpl = async (
  meetingId: string,
  shareData?: MeetingShare
): Promise<void> => {
  const endpoint =
    shareData && 'key' in shareData.options
      ? `/a/meeting/${meetingId}/share/${shareData.options.key}`
      : `/a/meeting/${meetingId}/share`;
  await fetchApiV2(endpoint, {
    method: 'DELETE',
  });
};

export const integration: IntegrationImplementation = {
  order: 1,
  connector: () => <div></div>,
  id: 'link',
  title: 'Link',
  icon,
  shareDescription: () => <FormattedMessage defaultMessage="Shareable link" />,
  share: shareImpl,
  ShareDialog,
  getSharingDestinationTitle: (
    options: MeetingShareSettings & { emails?: string[]; reach?: MeetingReach }
  ) => {
    const whats: string[] = [];
    if (options.isSharingDetails) whats.push('meeting details');
    if (options.isSharingNotes) whats.push('notes');
    if (options.isSharingHighlights) whats.push('highlights');
    if (options.isSharingTranscript) whats.push('transcript');

    const what =
      options.isSharingDetails &&
      options.isSharingNotes &&
      options.isSharingHighlights &&
      options.isSharingTranscript
        ? 'entire meeting'
        : whats.join(', ');

    if (options.reach === MeetingReach.RESTRICTED) {
      return `Shared ${what} with ${(options.emails ?? []).join(', ')}`;
    } else {
      return `Shared ${what} with everyone with the link`;
    }
  },
  isConnected: () => true,
  unshare: unshareImpl,
  helpUrl:
    'https://help.tactiq.io/en/articles/9188113-how-to-share-a-transcript',
};

export default integration;
