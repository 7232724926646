import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { trackSetupHelperButton } from '../../helpers/analytics';
import iconGoogleMeet from '../../img/googlemeet.png';
import { RootState } from '../../redux/store';
import { AlertExtensionIsNotInstalled } from './AlertExtensionIsNotInstalled';
import { AlertReadyToUseInThisBrowser } from './AlertReadyToUseInThisBrowser';
import { OnboardingBlock } from './OnboardingBlock';
import { Alert } from '../../components/Alert';
import { MainBlockTypes } from '.';

export const OnboardingGoogleMeet: React.FC<{
  isLarge: boolean;
  mainBlock: MainBlockTypes;
}> = ({ isLarge, mainBlock }) => {
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const intl = useIntl();

  const alerts = [
    isExtensionUnavailable ? (
      <AlertExtensionIsNotInstalled key="a" />
    ) : (
      <AlertReadyToUseInThisBrowser key="b" />
    ),
  ];

  if (isExtensionUnavailable) {
    alerts.push(
      <Alert
        severity="info"
        description={
          <FormattedMessage defaultMessage="Desktop app needs browser extension, too" />
        }
      />
    );
  }

  return (
    <OnboardingBlock
      isLarge={isLarge}
      icon={iconGoogleMeet}
      title="Google Meet"
      alerts={alerts}
      button={
        <Button
          target="_blank"
          href="https://meet.google.com/new"
          onClick={() => {
            if (isExtensionUnavailable)
              enqueueSnackbar(
                intl.formatMessage({
                  defaultMessage: 'Please install Tactiq extension first',
                }),
                { variant: 'WARNING' }
              );
            trackSetupHelperButton('googlemeet', mainBlock);
          }}
        >
          Try in Google Meet
        </Button>
      }
    />
  );
};
