import { TranscriptNotes } from '@tactiq/model';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '../../../../components/TextInput';
import TranscriptSection, {
  TranscriptSectionProps,
} from '../TranscriptSection';

export interface TranscriptNotesComponentProps
  extends Omit<TranscriptSectionProps, 'header'> {
  notes?: TranscriptNotes;
  readOnly: boolean;
  onChange: (content: string) => void;
}

export const TranscriptNotesComponent: React.FC<
  TranscriptNotesComponentProps
> = ({ notes, onChange, readOnly, ...transcriptSectionProps }) => {
  const intl = useIntl();

  return (
    <TranscriptSection
      header={
        <FormattedMessage
          defaultMessage="Notes"
          description="Meeting view. Notes header."
        />
      }
      {...transcriptSectionProps}
    >
      <TextInput
        value={notes?.content}
        onBlur={onChange}
        disabled={readOnly}
        placeholder={
          readOnly
            ? intl.formatMessage({
                defaultMessage: 'Transcript does not have any notes',

                description: 'Placeholder for notes textarea field.',
              })
            : intl.formatMessage({
                defaultMessage: 'Add notes to your transcript',
                id: 'uQ5/8J',
                description: 'Placeholder for notes textarea field.',
              })
        }
        type="textarea"
        inputProps={{
          readOnly,
        }}
      />
    </TranscriptSection>
  );
};
