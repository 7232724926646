import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { trackZoomDesktopRequirementsHelpClicked } from '../../helpers/analytics';
import { Alert } from '../../components/Alert';

export const AlertZoomDesktopRequirementsInfo: React.FC = () => {
  return (
    <Alert
      severity="info"
      action={
        <Button
          href="https://help.tactiq.io/en/articles/9530151-how-to-use-tactiq-with-zoom"
          onClick={trackZoomDesktopRequirementsHelpClicked}
          color="info"
          target="_blank"
        >
          Help
        </Button>
      }
      description={
        <FormattedMessage defaultMessage="You must start cloud recording and turn on captions in the Zoom app to get the transcript" />
      }
    />
  );
};
