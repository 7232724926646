import { LabelConfig } from './label';

type FirebaseTimestamp = {
  toMillis: () => number;
};

export type WorkflowDocument = {
  id: string;
  name: string;
  definition: WorkflowDefinition;
  isArchived?: boolean;
  nodeCounter: number;
  createdBy: string;
  createdAt: FirebaseTimestamp;
  updatedAt?: FirebaseTimestamp;
  acl?: Record<
    string,
    {
      entity: 'USER' | 'TEAM' | 'ANYONE';
      canRead: boolean;
      canWrite: boolean;
    }
  >;
  usage?: {
    runs?: number;
    lastRun?: FirebaseTimestamp;
    duplications?: number;
    templates?: number;
  };
  tactiq?: boolean;
  publishedId?: string;
  publishedFrom?: string;
};

export type WorkflowNode = {
  id: string;
  type?: string;
  data: unknown;
  position: {
    x: number;
    y: number;
  };
  width?: number;
  height?: number;
};
export enum WorkflowTriggerType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}
export type ManualTrigger = { type: WorkflowTriggerType.MANUAL };
export type AutomaticTrigger = {
  type: WorkflowTriggerType.AUTOMATIC;
  conditions: string;
};

export type WorkflowExecution = {
  status: WorkflowStatus;
  startedAt?: number;
  completedAt?: number;
  output?: any;
  error?: {
    message: string;
  };
};

export type BaseNodeData = {
  displayName: string;
  execution?: WorkflowExecution;
  recentExecutionData?: WorkflowExecution & { id: string };
  isValid?: boolean;
};
export type StartNode = WorkflowNode & {
  data: {
    trigger: ManualTrigger | AutomaticTrigger;
  } & BaseNodeData;
};

export type RunMeetingKitNode = WorkflowNode & {
  data: {
    applyToMeeting: boolean;
    meetingKitItemId: string;
    meetingKitId: string;
    aiOutputLanguage?: string;
    promptType: 'system' | 'used' | 'explore';
  } & BaseNodeData;
};

export type CustomPromptNode = WorkflowNode & {
  data: {
    prompt: string;
    saveToMeeting: boolean;
    aiOutputLanguage: string;
    includeTranscriptContext: boolean;
  } & BaseNodeData;
};

export type TemplateNode = WorkflowNode & {
  data: {
    template: string;
    title: string;
    saveToMeeting: boolean;
  } & BaseNodeData;
};

export type SaveToSpaceNode = WorkflowNode & {
  data: {
    space: string;
  } & BaseNodeData;
};

export type AddLabelsNode = WorkflowNode & {
  data: {
    labels: LabelConfig[];
  } & BaseNodeData;
};

export type ConfirmationNode = WorkflowNode & {
  data: {
    type: string;
    prompt: string;
  } & BaseNodeData;
};

export type ConfirmationResult = {
  isYes: boolean;
  content: string;
};

export type ConditionNode = WorkflowNode & {
  data: {
    condition: string;
  } & BaseNodeData;
};

export type FieldDataValue = string | string[] | FieldDataObject;
export interface FieldDataObject {
  [key: string]: FieldDataValue;
}

export type SendDataNode = WorkflowNode & {
  data: {
    type: string;
    fieldData: FieldDataValue;
    parameterData: Record<string, string>;
    email?: {
      subject: string;
      message: string;
    };
  } & BaseNodeData;
};

export type ShareNode = WorkflowNode & {
  data: {
    type: 'slack' | 'email';
    bodyTemplate?: string;
    slack?: {
      id: string;
      name: string;
      scope: string;
      connectionKey: string;
    };
    email?: {
      subject: string;
    };
  } & BaseNodeData;
};

export interface EmailRecipient {
  name: string;
  email: string;
}

export type EmailNode = WorkflowNode & {
  data: {
    subjectTemplate: string;
    bodyTemplate: string;
    shareWithParticipants: boolean;
    recipients: EmailRecipient[];
  };
};

export type WorkflowEdge = {
  id: string;
  source: string;
  target: string;
  sourceHandle?: string;
};

export type WorkflowDefinition = {
  edges: WorkflowEdge[];
  nodes: WorkflowNode[];
};

export enum WorkflowStatus {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  COMPLETE = 'COMPLETE',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
}

export type WorkflowExecutionData = {
  id: string;
  workflowId: string;
  workflowName: string;
  meetingId: string;
  definition: WorkflowDefinition;
  status: WorkflowStatus;
  nodeData: Record<
    string,
    {
      id: string;
      status: WorkflowStatus;
      startedAt?: FirebaseTimestamp;
      completedAt?: FirebaseTimestamp;
      output?: any;
      error?: {
        message: string;
      };
    }
  >;
  uid: string;
  createdAt: FirebaseTimestamp;
  updatedAt?: FirebaseTimestamp;
  templateContext: WorkflowTemplateContext;
  executionCounter: number;
  triggerType?: WorkflowTriggerType;
};

/**
 * A slim version of users and meeting given to liquid templates
 * We wan't to add things sparingly, because once they are used in templates we
 * cant really remove them.
 */
export type WorkflowTemplateContext = {
  meeting: {
    title: string;
    transcript?: string;
    url: string;
    labels: string[];
    participantNames: string[];
    participantEmails: string[];
    participants: Array<{ name: string; email: string }>;
    date: string;
    dateFinished: string;
    notes: string;
  };
  user: {
    name: string;
    email: string;
  };
};
