import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EyeOff } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/buttons';
import { Alert } from '../../../components/Alert';
import { setFilteredParticipants } from '../../../redux/modules/global';

export const AlertAllParticipantsHidden: React.FC<{
  meetingId: string;
}> = ({ meetingId }) => {
  const dispatch = useDispatch();
  return (
    <Alert
      severity="info"
      action={
        <Button
          onClick={() =>
            dispatch(
              setFilteredParticipants({
                meetingId,
                participants: [],
              })
            )
          }
          color="info"
          target="_blank"
        >
          <EyeOff className="h-4 w-4" />
          <FormattedMessage defaultMessage="Reset" id="jm/spn" />
        </Button>
      }
      description={
        <FormattedMessage defaultMessage="All speakers are hidden." />
      }
    />
  );
};
