import React, { ReactElement, useMemo } from 'react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Target } from 'lucide-react';
import { Select } from '../../../components/Select';
import { useIntl, FormattedMessage } from 'react-intl';
import { useIsPreview } from './useIsPreview';
import { useReactFlow } from '@xyflow/react';
import featureFlagService from '../../../helpers/feature-flags';
import { WorkflowTriggerType } from '@tactiq/model';
import { minHeights, minWidths } from './minDimensions';
import ConditionInput from '../ConditionInput';
import { useWorkflowId } from '../WorkflowIdContext';
import { useAutocomplete } from './useAutocomplete';
import {
  ConditionOperator,
  ConditionVariable,
} from '../../../components/ConditionBuilder/types';

export function StartNode(
  props: WorkflowNodeProps<{
    trigger:
      | { type: WorkflowTriggerType.MANUAL }
      | { type: WorkflowTriggerType.AUTOMATIC; conditions: string };
  }>
): ReactElement {
  const reactFlow = useReactFlow();
  const disabled = useIsPreview() || Boolean(props.data.execution);
  const intl = useIntl();
  const { workflowId } = useWorkflowId();
  const autocomplete = useAutocomplete(props);

  const trigger = props.data.trigger ?? { type: WorkflowTriggerType.MANUAL };
  const isAutomatic = trigger.type === WorkflowTriggerType.AUTOMATIC;

  const variables: ConditionVariable[] = useMemo(
    () => [
      {
        value: 'meeting.title',
        type: 'string',
        label: intl.formatMessage({ defaultMessage: 'Meeting Title' }),
        defaultOperator: ConditionOperator.Contains,
      },
      {
        value: 'meeting.participantNames',
        type: 'array',
        label: intl.formatMessage({ defaultMessage: 'Participant Names' }),
        defaultOperator: ConditionOperator.OneOf,
      },
      {
        value: 'meeting.participantEmails',
        type: 'array',
        label: intl.formatMessage({ defaultMessage: 'Participant Emails' }),
        defaultOperator: ConditionOperator.OneOf,
      },
    ],
    [intl]
  );

  return (
    <SingleSourceNode
      icon={
        <Target className="size-8 rounded-lg border border-violet-400/15 bg-violet-50 p-1.5 text-violet-500" />
      }
      workflowNode={props}
      hideId
      disableRename={true}
      minHeight={minHeights.StartNode}
      minWidth={minWidths.StartNode}
      contentClassName={isAutomatic ? 'h-full' : ''}
    >
      {featureFlagService.isAutomaticWorkflowTriggersEnabled() && (
        <div className="nodrag flex h-full flex-col gap-6">
          <div className="flex w-full flex-col gap-y-1">
            <label className="block font-medium text-slate-500 text-sm leading-6">
              <FormattedMessage defaultMessage="Trigger type" />
            </label>
            <Select
              value={trigger.type}
              disabled={disabled}
              onChange={(type) =>
                reactFlow.updateNodeData(props.id, {
                  trigger:
                    type === WorkflowTriggerType.MANUAL
                      ? { type }
                      : {
                          type,
                          conditions: `{{meeting.title contains ""}}`,
                        },
                })
              }
              full
              options={[
                {
                  label: intl.formatMessage({
                    defaultMessage: 'Manual',
                  }),
                  value: WorkflowTriggerType.MANUAL,
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: 'Automatic',
                  }),
                  value: WorkflowTriggerType.AUTOMATIC,
                },
              ]}
            />
          </div>

          {trigger.type === WorkflowTriggerType.AUTOMATIC && (
            <div className="flex h-full flex-col">
              <ConditionInput
                variables={variables}
                className="min-h-16"
                ariaLabel={intl.formatMessage({ defaultMessage: 'Conditions' })}
                nodeType={props.type}
                autocomplete={autocomplete}
                workflowId={workflowId}
                value={isAutomatic ? trigger?.conditions : ''}
                onChange={(next: string) => {
                  reactFlow.updateNodeData(props.id, {
                    trigger: {
                      type: WorkflowTriggerType.AUTOMATIC,
                      conditions: next,
                    },
                  });
                }}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      )}
    </SingleSourceNode>
  );
}
