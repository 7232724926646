import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '../../../components/buttons';
import { Switch } from '../../../components/buttons/Switch';
import { Chip } from '../../../components/Chips';
import {
  TeamTier,
  UpdateTeamAuthMethodsInput,
  UpdateTeamSetting_AuthMethodsDocument,
  UpdateTeamSetting_ScimDocument,
} from '../../../graphql/operations';
import { trackWebEvent, trackWebPage } from '../../../helpers/analytics';
import featureFlags from '../../../helpers/feature-flags';
import { useQuery } from '../../../helpers/router';
import { gotTeam } from '../../../redux/modules/user';
import { selectTeam, selectUid } from '../../../redux/selectors';
import { SettingsRow } from '../../Common/SettingsRow';
import { OktaOIDCConfigurationDialog } from './OktaOIDCConfigurationDialog';
import { SAMLConfigurationDialog } from './SAMLConfigurationDialog';
import { UserCannotChangeSettings } from './UserCannotChangeSettings';
import { SCIMConfigurationDialog } from './SCIMConfigurationDialog';

export const TeamAuthenticationSettings: React.FC = () => {
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Team Settings');
  }, []);

  const isEnterpriseTeam = team?.tier === TeamTier.ENTERPRISE;

  const query = useQuery();
  const [showOktaConfigurationDialog, setOktaConfigurationDialog] = useState(
    query.get('configure') === 'oktaoidc'
  );
  const [showSAMLConfigurationDialog, setSAMLConfigurationDialog] = useState(
    query.get('configure') === 'saml'
  );
  const [showSCIMConfigurationDialog, setSCIMConfigurationDialog] = useState(
    query.get('configure') === 'scim'
  );
  const navigate = useNavigate();

  const [UpdateTeamSetting_AuthMethods, UpdateTeamSetting_AuthMethodsMutation] =
    useMutation(UpdateTeamSetting_AuthMethodsDocument);

  const [UpdateTeamSetting_SCIM, UpdateTeamSetting_SCIMMutation] = useMutation(
    UpdateTeamSetting_ScimDocument
  );

  const isTeamAdvancedAuthEnabled = featureFlags.isTeamAdvancedAuthEnabled();

  async function updateAuthMethod(update: Partial<UpdateTeamAuthMethodsInput>) {
    const input = {
      google: !!team?.settings.authMethods.google,
      emailLink: !!team?.settings.authMethods.emailLink,
      oktaOidc: !!team?.settings.authMethods.oktaOidc,
      saml: !!team?.settings.authMethods.saml,
      ...update,
    };

    if (!Object.values(input).find((v) => v)) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'At least one authentication method must be enabled',
        }),
        { variant: 'ERROR' }
      );
      return;
    }

    dispatch(
      gotTeam(
        (await UpdateTeamSetting_AuthMethods({ variables: { input } })).data
          ?.UpdateTeamSetting_AuthMethods
      )
    );
  }

  if (!isTeamAdvancedAuthEnabled) return null;

  return (
    <>
      <SettingsRow
        settingInfo={{
          title: intl.formatMessage({
            defaultMessage: 'Authentication',
          }),
          description: (
            <div className="flex flex-grow flex-col gap-4">
              <div className="">
                <FormattedMessage defaultMessage="Advanced authentication options (Okta OIDC, SAML, SCIM, etc.)" />
              </div>
              <div className="flex flex-row items-center gap-4">
                <div className="flex-grow">
                  <div className=" font-semibold">
                    <FormattedMessage defaultMessage="Sign in with Google" />
                  </div>
                  <div className="">
                    <FormattedMessage defaultMessage="This option is always available and does not require additional configuration" />
                  </div>
                </div>
                <Switch
                  disabled={
                    !isAdmin ||
                    !team?.settings.advancedAuthEnabled ||
                    UpdateTeamSetting_AuthMethodsMutation.loading
                  }
                  isOn={team?.settings.authMethods.google}
                  onClick={(e, checked) => {
                    updateAuthMethod({
                      google: checked,
                    });
                  }}
                />
              </div>

              <div className="flex flex-row items-center gap-4">
                <div className="flex-grow">
                  <div className=" font-semibold">
                    <FormattedMessage defaultMessage="E-Mail Magic Link" />
                  </div>
                  <div className="">
                    <FormattedMessage defaultMessage="This option is always available and does not require additional configuration" />
                  </div>
                </div>
                <Switch
                  disabled={
                    !isAdmin ||
                    !team?.settings.advancedAuthEnabled ||
                    UpdateTeamSetting_AuthMethodsMutation.loading
                  }
                  isOn={team?.settings.authMethods.emailLink}
                  onClick={(e, checked) => {
                    updateAuthMethod({
                      emailLink: checked,
                    });
                  }}
                />
              </div>

              <div className="flex flex-row items-center gap-4">
                <div className="flex-grow">
                  <div className=" flex flex-row gap-2 font-semibold">
                    <FormattedMessage defaultMessage="Okta OIDC" />
                    {!isEnterpriseTeam && (
                      <Chip type="upgrade">Enterprise</Chip>
                    )}
                    {isEnterpriseTeam &&
                      team?.settings.authMethods.oktaOidcConfigured && (
                        <Chip type="tag" color="green">
                          Available and configured
                        </Chip>
                      )}
                    {isEnterpriseTeam &&
                      !team?.settings.authMethods.oktaOidcConfigured && (
                        <Chip type="tag" color="yellow">
                          Not configured
                        </Chip>
                      )}
                  </div>
                  <div className="">
                    <FormattedMessage defaultMessage="This option requires additional configuration" />
                  </div>
                </div>
                <Button
                  onClick={() => {
                    if (!team) {
                      return;
                    }

                    trackWebEvent('Settings - Team - Configure Okta OIDC', {
                      isAdmin,
                      advancedAuthEnabled: team.settings.advancedAuthEnabled,
                    });

                    if (!isAdmin) {
                      UserCannotChangeSettings(intl);
                      return false;
                    }

                    if (
                      !team.settings.advancedAuthEnabled ||
                      !isEnterpriseTeam
                    ) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'To access advanced authentication options on Enterprise plans, please contact your customer success representative.',
                        }),
                        { variant: 'WARNING' }
                      );
                      return false;
                    }

                    setOktaConfigurationDialog(true);
                    return true;
                  }}
                  href="/settings/team?configure=oktaoidc"
                >
                  <FormattedMessage defaultMessage="Configure" />
                </Button>
                <Switch
                  disabled={
                    !isAdmin ||
                    !team?.settings.advancedAuthEnabled ||
                    !isEnterpriseTeam ||
                    UpdateTeamSetting_AuthMethodsMutation.loading
                  }
                  isOn={team?.settings.authMethods.oktaOidc}
                  onClick={(e, checked) => {
                    if (!team?.settings.authMethods.oktaOidcConfigured) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'To enable this option, you need to configure Okta OIDC first.',
                        }),
                        { variant: 'ERROR' }
                      );
                      return;
                    }

                    updateAuthMethod({ oktaOidc: checked });
                  }}
                />
              </div>

              <div className="flex flex-row items-center gap-4">
                <div className="flex-grow">
                  <div className=" flex flex-row gap-2 font-semibold">
                    <FormattedMessage defaultMessage="SAML" />
                    {!isEnterpriseTeam && (
                      <Chip type="upgrade">Enterprise</Chip>
                    )}
                    {isEnterpriseTeam &&
                      team?.settings.authMethods.samlConfigured && (
                        <Chip type="tag" color="green">
                          Available and configured
                        </Chip>
                      )}
                    {isEnterpriseTeam &&
                      !team?.settings.authMethods.samlConfigured && (
                        <Chip type="tag" color="yellow">
                          Not configured
                        </Chip>
                      )}
                  </div>
                  <div className="">
                    <FormattedMessage defaultMessage="This option requires additional configuration" />
                  </div>
                </div>

                <Button
                  onClick={() => {
                    if (!team) {
                      return;
                    }

                    trackWebEvent('Settings - Team - Configure SAML', {
                      isAdmin,
                      advancedAuthEnabled: team.settings.advancedAuthEnabled,
                    });

                    if (!isAdmin) {
                      UserCannotChangeSettings(intl);
                      return false;
                    }

                    if (
                      !team.settings.advancedAuthEnabled ||
                      !isEnterpriseTeam
                    ) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'To access advanced authentication options on Enterprise plans, please contact your customer success representative.',
                        }),
                        { variant: 'WARNING' }
                      );
                      return false;
                    }

                    setSAMLConfigurationDialog(true);
                    return true;
                  }}
                  href="/settings/team?configure=saml"
                >
                  <FormattedMessage defaultMessage="Configure" />
                </Button>
                <Switch
                  disabled={
                    !isAdmin ||
                    !team?.settings.advancedAuthEnabled ||
                    !isEnterpriseTeam ||
                    UpdateTeamSetting_AuthMethodsMutation.loading ||
                    UpdateTeamSetting_SCIMMutation.loading
                  }
                  isOn={team?.settings.authMethods.saml}
                  onClick={async (e, checked) => {
                    if (!team?.settings.authMethods.samlConfigured) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'To enable this option, you need to configure SAML first.',
                        }),
                        { variant: 'ERROR' }
                      );
                      return;
                    }

                    updateAuthMethod({ saml: checked });

                    if (!checked && team?.settings.scimEnabled) {
                      dispatch(
                        gotTeam(
                          (
                            await UpdateTeamSetting_SCIM({
                              variables: { enabled: checked },
                            })
                          ).data?.UpdateTeamSetting_SCIM
                        )
                      );
                    }
                  }}
                />
              </div>

              <div className="ml-4 flex flex-row items-center gap-4 md:ml-16">
                <div className="flex-grow">
                  <div className="flex flex-row gap-2 font-semibold">
                    <FormattedMessage defaultMessage="SCIM" />
                    {!team?.settings.authMethods.samlConfigured && (
                      <Chip type="tag" color="yellow">
                        SAML is not configured
                      </Chip>
                    )}
                  </div>
                  <div className="">
                    <FormattedMessage defaultMessage="This option requires SAML" />
                  </div>
                </div>
                <Button
                  onClick={() => {
                    if (!team) {
                      return;
                    }

                    trackWebEvent('Settings - Team - Configure SCIM', {
                      isAdmin,
                      advancedAuthEnabled: team.settings.advancedAuthEnabled,
                    });

                    if (!isAdmin) {
                      UserCannotChangeSettings(intl);
                      return false;
                    }

                    if (
                      !team.settings.advancedAuthEnabled ||
                      !isEnterpriseTeam
                    ) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'To access advanced authentication options on Enterprise plans, please contact your customer success representative.',
                        }),
                        { variant: 'WARNING' }
                      );
                      return false;
                    }

                    if (!team.settings.scimEnabled) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage: 'You need to enable SCIM first.',
                        }),
                        { variant: 'WARNING' }
                      );
                      return false;
                    }

                    setSCIMConfigurationDialog(true);
                  }}
                  href="/settings/team?configure=scim"
                >
                  <FormattedMessage defaultMessage="Configure" />
                </Button>
                <Switch
                  disabled={
                    !isAdmin ||
                    !team?.settings.advancedAuthEnabled ||
                    !isEnterpriseTeam ||
                    !team?.settings.authMethods.saml ||
                    UpdateTeamSetting_AuthMethodsMutation.loading ||
                    UpdateTeamSetting_SCIMMutation.loading
                  }
                  isOn={team?.settings.scimEnabled}
                  onClick={async (e, checked) => {
                    dispatch(
                      gotTeam(
                        (
                          await UpdateTeamSetting_SCIM({
                            variables: { enabled: checked },
                          })
                        ).data?.UpdateTeamSetting_SCIM
                      )
                    );
                  }}
                />
              </div>
            </div>
          ),
        }}
      />
      {showOktaConfigurationDialog && team?.settings.advancedAuthEnabled && (
        <OktaOIDCConfigurationDialog
          team={team}
          onClose={() => {
            setOktaConfigurationDialog(false);
            navigate('/settings/team');
          }}
        />
      )}
      {showSAMLConfigurationDialog && team?.settings.advancedAuthEnabled && (
        <SAMLConfigurationDialog
          team={team}
          onClose={() => {
            setSAMLConfigurationDialog(false);
            navigate('/settings/team');
          }}
        />
      )}
      {showSCIMConfigurationDialog &&
        team?.settings.advancedAuthEnabled &&
        team.settings.scimEnabled && (
          <SCIMConfigurationDialog
            team={team}
            onClose={() => {
              setSCIMConfigurationDialog(false);
              navigate('/settings/team');
            }}
          />
        )}
    </>
  );
};
