import { ChevronDown, ChevronUp, LucideIcon } from 'lucide-react';
import React from 'react';
import { cx } from '../../../helpers/utils';
import { BadgeNew } from '../BadgeNew';

type NavMenuSubItemProps = {
  href: string;
  label: string;
  subItemIsSelected?: boolean;
  isNew?: boolean;
};

export const NavMenuSubItem: React.FC<NavMenuSubItemProps> = ({
  href,
  label,
  subItemIsSelected,
  isNew = false,
}) => {
  return (
    <a
      href={`#${href}`}
      className={cx(
        'group relative flex h-9 items-center rounded-button px-2 py-2 text-sm ',
        subItemIsSelected
          ? '!hover:bg-none bg-neutral-muted '
          : 'text-neutral-default hover:bg-neutral-muted'
      )}
    >
      <div className="flew-row flex items-center gap-x-3">
        <p
          className={cx(
            'flex min-w-0 flex-col truncate pl-8 capitalize',
            subItemIsSelected ? '' : 'text-neutral-default'
          )}
        >
          {label}
        </p>
        <BadgeNew visible={isNew} />
      </div>
    </a>
  );
};

export const NavItemWithSubItems: React.FC<{
  label: string;
  currentPage: string;
  icon: LucideIcon;
  subItems: NavMenuSubItemProps[];
}> = ({ label, icon: Icon, currentPage, subItems }) => {
  const isSelected =
    label === currentPage || subItems.some((x) => x.label === currentPage);
  const [isOpen, setIsOpen] = React.useState<boolean>(isSelected);
  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          'group relative flex min-h-9 w-full flex-row items-center gap-x-2.5 rounded-button px-2.5 py-1.5 leading-6 hover:bg-neutral-muted md:bg-none',
          isSelected ? 'bg-white' : ' '
        )}
      >
        <div
          className={cx(
            'inline-flex',
            isSelected ? '' : 'text-neutral-default'
          )}
        >
          <Icon strokeWidth={1.75} size={18} />
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-x-2 truncate">
          <p
            className={cx(
              'truncate font-medium text-sm capitalize',
              isSelected ? '' : 'text-neutral-default'
            )}
          >
            {label}
          </p>
        </div>

        {isOpen ? (
          <ChevronUp
            strokeWidth={2}
            size={24}
            className="text-neutral-subtle"
            aria-hidden="true"
          />
        ) : (
          <ChevronDown
            strokeWidth={2}
            size={24}
            className="text-neutral-subtle"
            aria-hidden="true"
          />
        )}
      </button>

      {isOpen ? (
        <ul className="flex flex-col gap-y-0.5 pt-0.5">
          {subItems.map((subItem: NavMenuSubItemProps) => {
            return (
              <li key={subItem.label} className="relative">
                {subItems[subItems.length - 1] !== subItem ? <div /> : null}
                <NavMenuSubItem
                  key={subItem.label}
                  href={subItem.href}
                  label={subItem.label}
                  subItemIsSelected={subItem.label === currentPage}
                  isNew={subItem.isNew}
                />
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export const NavMenuItem: React.FC<{
  href: string;
  label: string;
  icon: LucideIcon;
  currentPage: string;
  isNew?: boolean;
  isSubItem?: boolean;
}> = ({
  href,
  label,
  currentPage,
  icon: Icon,
  isNew = false,
  isSubItem = false,
}) => {
  return (
    <a
      className={cx(
        'group relative flex min-h-9 w-full flex-row items-center gap-x-2.5 rounded-button px-2.5 py-1.5 leading-6 transition-colors duration-300 hover:bg-neutral-muted md:bg-none',
        label === currentPage ? 'bg-neutral-muted' : ''
      )}
      href={`#${href}`}
    >
      {/* icons */}
      <div
        className={cx(
          'inline-flex transition-colors duration-300',
          label === currentPage ? '' : 'text-neutral-default',
          isSubItem ? 'ml-11' : ''
        )}
      >
        <Icon strokeWidth={1.75} size={18} />
      </div>
      <div className={'flex-1 cursor-pointer overflow-hidden'}>
        <div className="flex flex-row items-center gap-x-3">
          <p
            className={cx(
              'truncate text-left font-medium text-sm capitalize',
              label === currentPage ? '' : 'text-neutral-default'
            )}
          >
            {label}
          </p>
          <div className="absolute right-2">
            <BadgeNew visible={isNew} />
          </div>
        </div>
      </div>
    </a>
  );
};
