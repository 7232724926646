import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'react-redux';
import { trackWebEvent } from '../../../../helpers/analytics';
import { saveMeetingTranscript } from '../../../../helpers/api';
import { isTactiqMeeting } from '../../../../helpers/meetings';
import { createSelectRawTranscriptSelector } from '../../../../redux/selectors';
import { TactiqStore } from '../../../../redux/store';
import { useMeeting } from '../../common/meeting-hooks';
import { createStopPropagationHandler } from '../../common/stopPropagation';
import { ShareCardWrapper } from './ShareCardWrapper';
import { AlertTriangle, Save } from 'lucide-react';
import { Button } from '../../../../components/buttons';

export const SaveToTactiqCard: React.FC = () => {
  const intl = useIntl();
  const meeting = useMeeting();

  const isTactiq = isTactiqMeeting(meeting);

  const store = useStore() as TactiqStore;
  const selectRawTranscript = createSelectRawTranscriptSelector();
  const rawTranscript = selectRawTranscript(
    store.getState(),
    meeting?.id ?? 'x'
  );

  if (!meeting) return null;
  if (isTactiq) return null;

  return (
    <ShareCardWrapper
      title={intl.formatMessage({
        defaultMessage: 'Not saved to Tactiq',
      })}
      imageComponent={
        <AlertTriangle strokeWidth={2} size={18} className="text-orange-600" />
      }
      actions={
        rawTranscript ? (
          <Button
            size="tiny"
            variant="icon"
            onClick={createStopPropagationHandler(async (): Promise<void> => {
              trackWebEvent('Clicked Link - Save Meeting to Tactiq', {
                place: `files-and-shares`,
              });

              const saveResponse = await saveMeetingTranscript({
                meetingId: meeting.id,
                rawTranscript,
              });

              if (saveResponse.mustRelogin) {
                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage:
                      'Cannot save the meeting. Please contact support.',
                  }),
                  { variant: 'WARNING' }
                );
              }
            })}
          >
            <Save strokeWidth={2} size={18} className="text-slate-600" />
          </Button>
        ) : null
      }
    />
  );
};
