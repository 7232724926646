import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../../components/buttons';
import { attachMeetingRecording } from '../../../../../graphql/meetings';
import { trackWebEvent } from '../../../../../helpers/analytics';
import { useMeeting } from '../../../common/meeting-hooks';
import { TextInput } from '../../../../../components/TextInput';
import { ModalDialog } from '../../../../../components/modals';

/**
 * Custom link dialog
 * @param {unknown}param0 parmas
 * @param {boolean} param0.open open
 * @param {() => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const CustomLinkDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const meeting = useMeeting();
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');
  const [isValid, setIsValid] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!meeting) return;
    if (isLoading || !isValid) return;

    trackWebEvent(
      'Meeting view - Recordings - Add custom link dialog - Submit button - clicked',
      { meetingId: meeting.id }
    );

    setIsLoading(true);

    await attachMeetingRecording(meeting.id, link.trim());

    setIsLoading(false);

    onClose();
  }, [meeting, isLoading, isValid, link, onClose]);

  const onCancel = () => {
    if (!meeting) return;
    if (isLoading) return;

    trackWebEvent(
      'Meeting view - Recordings - Add custom link dialog - Cancel button - clicked',
      {
        meetingId: meeting.id,
      }
    );

    onClose();
  };

  const onChangeLink = (newLink: string) => {
    newLink = newLink.trim();

    try {
      // eslint-disable-next-line no-new
      new URL(newLink);
      setIsValid(true);
    } catch {
      setIsValid(false);
    }

    setLink(newLink);
  };

  if (!meeting) {
    return null;
  }

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={<FormattedMessage defaultMessage="Add custom recording link" />}
      text={
        <div>
          <FormattedMessage defaultMessage="Add a custom link to a video recording of this meeting." />
          <TextInput
            autoFocus
            id="name"
            label="Link"
            placeholder="https://drive.google.com/file/d/abcdefghijklnopqrstuvwxyz/view?usp=sharing"
            type="url"
            value={link}
            disabled={isLoading}
            error={!isValid}
            helperText={
              !isValid && (
                <FormattedMessage
                  defaultMessage="Please enter a valid URL"
                  id="ajU+HT"
                />
              )
            }
            onChange={onChangeLink}
          />
        </div>
      }
      actions={
        <div className="flex gap-2">
          <Button onClick={onCancel} variant="neutral-secondary">
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button onClick={onSubmit} loading={isLoading}>
            <FormattedMessage defaultMessage="Add" id="2/2yg+" />
          </Button>
        </div>
      }
    />
  );
};
