import { useMutation, useQuery } from '@apollo/client';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { Chip } from '../../../components/Chips';
import { ModalDialog } from '../../../components/modals';
import { TextInput } from '../../../components/TextInput';
import {
  ApplyTeamAuthConfiguration_SamlDocument,
  GetTeamAuthConfigurationDocument,
  Team,
  TeamAuthConfiguration_LoadSamlMetadataDocument,
  UpdateTeamAuthConfiguration_SamlDocument,
} from '../../../graphql/operations';
import AsyncButton from '../../../components/buttons/AsyncButton';
import { gotTeam } from '../../../redux/modules/user';
import { useDispatch } from 'react-redux';

export const SAMLConfigurationDialog: React.FC<{
  team: Team;
  onClose: () => void;
}> = ({ team, onClose }) => {
  const dispatch = useDispatch();

  const [metadataUrl, setMetadataUrl] = React.useState<string>('');
  const [entityId, setEntityId] = React.useState<string>('');
  const [ssoUrl, setSsoUrl] = React.useState<string>('');
  const [sloUrl, setSloUrl] = React.useState<string>('');
  const [certificate, setCertificate] = React.useState<string>('');

  const configQuery = useQuery(GetTeamAuthConfigurationDocument, {
    fetchPolicy: 'network-only',
    onCompleted(newData) {
      setMetadataUrl(
        configQuery.data?.teamAuthConfiguration.saml?.incoming?.metadataUrl ??
          newData.teamAuthConfiguration.saml?.incoming?.metadataUrl ??
          ''
      );
      setEntityId(
        configQuery.data?.teamAuthConfiguration.saml?.incoming?.entityId ??
          newData.teamAuthConfiguration.saml?.incoming?.entityId ??
          ''
      );
      setSsoUrl(
        configQuery.data?.teamAuthConfiguration.saml?.incoming?.ssoUrl ??
          newData.teamAuthConfiguration.saml?.incoming?.ssoUrl ??
          ''
      );
      setSloUrl(
        configQuery.data?.teamAuthConfiguration.saml?.incoming?.sloUrl ??
          newData.teamAuthConfiguration.saml?.incoming?.sloUrl ??
          ''
      );
      setCertificate(
        configQuery.data?.teamAuthConfiguration.saml?.incoming?.certificate ??
          newData.teamAuthConfiguration.saml?.incoming?.certificate ??
          ''
      );
    },
  });

  const config = configQuery.data?.teamAuthConfiguration.saml;

  const [
    TeamAuthConfiguration_LoadSAMLMetadata,
    TeamAuthConfiguration_LoadSAMLMetadataMutation,
  ] = useMutation(TeamAuthConfiguration_LoadSamlMetadataDocument);

  const [
    UpdateTeamAuthConfiguration_SAML,
    UpdateTeamAuthConfiguration_SAMLMutation,
  ] = useMutation(UpdateTeamAuthConfiguration_SamlDocument);

  const [
    ApplyTeamAuthConfiguration_SAML,
    ApplyTeamAuthConfiguration_SAMLMutation,
  ] = useMutation(ApplyTeamAuthConfiguration_SamlDocument);

  const isLoading =
    configQuery.loading ||
    UpdateTeamAuthConfiguration_SAMLMutation.loading ||
    ApplyTeamAuthConfiguration_SAMLMutation.loading;

  const isIncomingChanged =
    config?.incoming?.entityId !== entityId ||
    config?.incoming?.ssoUrl !== ssoUrl ||
    (config?.incoming?.sloUrl ?? '') !== sloUrl ||
    config?.incoming?.certificate !== certificate;

  const canValidate = !!entityId && !!ssoUrl && !!certificate;

  const canApply = config?.incoming?.validated && !isIncomingChanged;

  return (
    <ModalDialog
      open
      size="medium"
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      title={
        <div className="flex flex-row gap-4">
          <FormattedMessage defaultMessage="SAML" />
          {isLoading ? <Loader2 className="animate-spin" /> : null}
        </div>
      }
      text={
        <div className="flex flex-col gap-4">
          <h2 className="font-semibold text-lg">Current configuration</h2>
          {!config?.current && (
            <div>
              <Chip color="yellow">Not configured</Chip>
            </div>
          )}
          {config?.current && (
            <>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">Entity ID</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.entityId}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">SSO Url</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.ssoUrl}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">SLO Url</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.sloUrl || 'Not set'}
                </div>
              </div>
              <div className="flex items-start gap-2">
                <div className="flex-grow whitespace-nowrap">Certificate</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-pre">
                  {config.current.certificate.split('\n')[0]}
                </div>
              </div>
            </>
          )}
          <h2 className="font-semibold text-lg">New configuration</h2>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Metadata URL</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput
                value={config?.incoming?.metadataUrl ?? ''}
                onChange={setMetadataUrl}
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <AsyncButton
              disabled={isLoading || !metadataUrl}
              loading={TeamAuthConfiguration_LoadSAMLMetadataMutation.loading}
              onClick={async () => {
                const result = await TeamAuthConfiguration_LoadSAMLMetadata({
                  variables: {
                    input: {
                      metadataUrl,
                    },
                  },
                });

                const values =
                  result.data?.TeamAuthConfiguration_LoadSAMLMetadata;

                if (values) {
                  setEntityId(values.entityId);
                  setSsoUrl(values.ssoUrl);
                  setSloUrl(values.sloUrl ?? '');
                  setCertificate(values.certificate);
                }
              }}
              variant={
                isLoading || !metadataUrl
                  ? 'neutral-tertiary'
                  : 'neutral-primary'
              }
            >
              <FormattedMessage defaultMessage="Load values from metadata" />
            </AsyncButton>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Entity ID</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput required value={entityId} onChange={setEntityId} />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">SSO Url</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput required value={ssoUrl} onChange={setSsoUrl} />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">SLO Url</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput value={sloUrl} onChange={setSloUrl} />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Certificate</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput
                value={certificate}
                onChange={setCertificate}
                type="textarea"
              />
            </div>
          </div>
          <div>
            {config?.incoming?.validated ? (
              <Chip color="green">Validated, can be applied</Chip>
            ) : (
              <Chip color="yellow">Not validated</Chip>
            )}
          </div>
          <div className="flex flex-row items-center gap-4">
            {canValidate && (
              <AsyncButton
                disabled={isLoading}
                loading={UpdateTeamAuthConfiguration_SAMLMutation.loading}
                onClick={async () => {
                  const result = await UpdateTeamAuthConfiguration_SAML({
                    variables: {
                      input: {
                        metadataUrl,
                        entityId,
                        ssoUrl,
                        sloUrl,
                        certificate,
                      },
                    },
                  });

                  const redirectUrl =
                    result.data?.UpdateTeamAuthConfiguration_SAML;
                  if (redirectUrl) {
                    window.location.href = redirectUrl;
                  }
                }}
                variant={canApply ? 'neutral-secondary' : 'neutral-primary'}
              >
                {canApply ? (
                  <FormattedMessage defaultMessage="Validate again" />
                ) : (
                  <FormattedMessage defaultMessage="Validate" />
                )}
              </AsyncButton>
            )}

            {config?.incoming?.validated && !isIncomingChanged ? (
              <AsyncButton
                disabled={isLoading}
                loading={ApplyTeamAuthConfiguration_SAMLMutation.loading}
                onClick={async () => {
                  dispatch(
                    gotTeam(
                      (await ApplyTeamAuthConfiguration_SAML()).data
                        ?.ApplyTeamAuthConfiguration_SAML
                    )
                  );
                  configQuery.refetch();
                }}
                variant="neutral-primary"
              >
                <FormattedMessage defaultMessage="Apply" />
              </AsyncButton>
            ) : null}
          </div>
        </div>
      }
      actions={
        <div className="flex flex-row items-center justify-end gap-2">
          <Button onClick={onClose} variant="neutral-secondary">
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </div>
      }
    />
  );
};
