import React, { useEffect, useState } from 'react';
import { ModalDialog, ConfirmActionDialog } from '../../../components/modals';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import {
  selectUid,
  selectUserSpaceNotifications,
} from '../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArchiveSpaceDocument,
  LeaveSpaceDocument,
  SpaceMember,
  SpacePermission,
  UpdateSpaceNotificationsDocument,
  UserSpaceNotificationsEmails,
} from '../../../graphql/operations';
import { useMutation } from '@apollo/client';
import { SpaceNameAndIcon } from '../SpaceNameAndIcon';
import { Avatar } from '../../../components/Avatar';
import { Menu } from '../../../components/Menu';
import {
  ArrowRightFromLineIcon,
  ChevronDown,
  Trash2Icon,
  X as RemoveIcon,
  UserPlus,
} from 'lucide-react';
import { Checkbox } from '../../../components/Checkbox';
import { spaceArchived } from '../../../redux/modules/global';
import { useNavigate } from 'react-router';
import { cx } from '../../../helpers/utils';
import {
  trackArchiveSpace,
  trackLeaveSpace,
  trackOpenSpaceSettings,
} from '../../../helpers/analytics';
import { AddMembersDialog } from '../AddMembersDialog';
import { Tooltip } from '../../../components/Tooltip';
import { RootState } from '../../../redux/store';
import { useSpace, useSpaceUpateMutation } from '../../../services/Space';
import { Chip } from '../../../components/Chips';

const isValidValueInRequiredField = (value: string | Array<unknown>) => {
  if (typeof value === 'string') {
    return value.trim() !== '';
  } else if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

/**
 * A modal dialog used to control the settings for a space.
 */
export const SpaceSettingsDialog: React.FC<{
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  spaceId: string;
  canCurrentUserManage: boolean;
}> = ({ isOpen, setIsOpen, spaceId, canCurrentUserManage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUserId = useSelector(selectUid);
  // email notifications are enabled by default, it needs to be explicitly disabled.
  const enableNotifications =
    useSelector((state: RootState) =>
      selectUserSpaceNotifications(state, spaceId)
    )?.emails !== 'NONE';
  const space = useSpace({ id: spaceId });
  const currentSpaceData = space.data?.space;
  // sort members by name, with current user always on top
  const members = [...(currentSpaceData?.members || [])].sort((a, b) => {
    if (a.uid === currentUserId) {
      return -1;
    }
    if (b.uid === currentUserId) {
      return 1;
    }
    return (a.metadata.name ?? '').localeCompare(b.metadata.name ?? '');
  });

  // A user can only leave the space if there is at least one other user with the manage permission
  const canLeaveSpace = members.some(
    (m) => m.permissions.MANAGE && m.uid !== currentUserId
  );

  const canSeeDangerZone = canCurrentUserManage || canLeaveSpace;

  const [page, setPage] = useState<
    'main' | 'members' | 'confirmArchive' | 'confirmLeave'
  >('main');

  const [spaceIcon, setSpaceIcon] = useState(currentSpaceData?.icon || '');
  const [spaceName, setSpaceName] = useState(currentSpaceData?.name || '');
  const [isSpaceNameValid, setIsSpaceNameValid] = useState(true);
  const { updateResult, onUpdate } = useSpaceUpateMutation();
  const [archiveSpace, archiveSpaceResult] = useMutation(ArchiveSpaceDocument);
  const [leaveSpace, leaveSpaceResult] = useMutation(LeaveSpaceDocument);
  const [updateSpaceNotifications, updateSpaceNotificationsResult] =
    useMutation(UpdateSpaceNotificationsDocument);

  useEffect(() => {
    if (isOpen) {
      trackOpenSpaceSettings();
    }
  }, [isOpen]);

  const handleSpaceNameAndIconUpdate = async () => {
    setIsSpaceNameValid(isValidValueInRequiredField(spaceName));
    if (isSpaceNameValid) {
      await onUpdate({
        id: spaceId,
        icon: spaceIcon,
        name: spaceName,
      });
    }
  };

  const handleArchiveSpace = () =>
    archiveSpace({
      variables: {
        input: {
          id: spaceId,
          isArchived: true,
        },
      },
      onCompleted: (data) => {
        if (data.archiveSpace.success) {
          setIsOpen(false);
          dispatch(spaceArchived({ spaceId }));
          trackArchiveSpace();
        }
      },
    });

  const handleLeaveSpace = async () => {
    if (!canLeaveSpace) {
      return;
    }
    await leaveSpace({
      variables: {
        id: spaceId,
      },
      onCompleted: (data) => {
        if (data.leaveSpace.success) {
          setIsOpen(false);
          // navigate the user back to the home page
          navigate('/');
          trackLeaveSpace();
        }
      },
    });
  };

  switch (page) {
    case 'members':
      return (
        <AddMembersDialog
          open={isOpen}
          onClose={() => setPage('main')}
          spaceId={spaceId}
          currentMembers={members}
        />
      );

    case 'confirmLeave':
      return (
        <ConfirmActionDialog
          open={isOpen}
          title={
            <FormattedMessage defaultMessage="Are you sure you want to leave this space?" />
          }
          text={
            <FormattedMessage defaultMessage="Leaving the space will not delete anything, but you won't be able to access any meetings that have been shared by others." />
          }
          yes={<FormattedMessage defaultMessage="Leave space" />}
          yesProps={{ loading: leaveSpaceResult.loading, color: 'error' }}
          onYes={handleLeaveSpace}
          onNo={() => setPage('main')}
        />
      );

    case 'confirmArchive':
      return (
        <ConfirmActionDialog
          open={isOpen}
          title={
            <FormattedMessage defaultMessage="Are you sure you want to archive this space?" />
          }
          text={
            <FormattedMessage defaultMessage="Archiving a space will not delete any meetings. All meetings will still be located in their respective users 'My Meetings' list." />
          }
          yes={<FormattedMessage defaultMessage="Archive space for everyone" />}
          yesProps={{ loading: archiveSpaceResult.loading, color: 'error' }}
          onYes={handleArchiveSpace}
          onNo={() => setPage('main')}
        />
      );

    case 'main':
    default:
      return (
        <ModalDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          title={
            <div className="flex items-center justify-between pr-8">
              <FormattedMessage defaultMessage="Space settings" />
              <Chip color="slate">
                {currentSpaceData?.teamId ? (
                  <FormattedMessage defaultMessage="Team space" />
                ) : (
                  <FormattedMessage defaultMessage="Personal space" />
                )}
              </Chip>
            </div>
          }
        >
          <div>
            <div className="mb-4">
              <SpaceNameAndIcon
                spaceIcon={spaceIcon}
                setSpaceIcon={setSpaceIcon}
                spaceName={spaceName}
                setSpaceName={setSpaceName}
                isSpaceNameValid={isSpaceNameValid}
                onUpdate={handleSpaceNameAndIconUpdate}
                isUpdateButtonEnabled={
                  spaceIcon !== currentSpaceData?.icon ||
                  spaceName !== currentSpaceData?.name
                }
                isUpdateButtonLoading={updateResult.loading}
                isReadOnly={!canCurrentUserManage}
              />
            </div>
            {/* Members */}
            <div className="mb-4">
              <h2 className="block pb-2 font-semibold text-slate-600 text-sm leading-6">
                <FormattedMessage defaultMessage="Members" id="+a+2ug" /> (
                {members.length})
              </h2>
              {/* Show the "add members" button only if the user can manage the space */}
              {canCurrentUserManage && (
                <div className="-ml-2 mb-3 flex">
                  <Button
                    startIcon={
                      <UserPlus className="mr-2 h-4 w-4 text-indigo-500" />
                    }
                    variant="naked"
                    color="primary"
                    onClick={() => setPage('members')}
                  >
                    <span className="text-indigo-500">
                      <FormattedMessage defaultMessage="Add members" />
                    </span>
                  </Button>
                </div>
              )}
              <div className="flex flex-col space-y-3">
                {members.map((member) => {
                  const {
                    uid,
                    metadata: { name, photoURL },
                  } = member;
                  const isCurrentUser = uid === currentUserId;
                  return (
                    <div key={uid} className="flex items-center gap-4">
                      <div className="flex flex-1 items-center space-x-2 overflow-hidden">
                        <Avatar src={photoURL!} name={name} />
                        <span className="truncate">
                          <span>{name}</span>
                          {isCurrentUser && (
                            <span className="ml-1">
                              <FormattedMessage defaultMessage="(you)" />
                            </span>
                          )}
                        </span>
                      </div>
                      <ManageMemberPermission
                        spaceId={spaceId}
                        member={member}
                        isReadOnly={!canCurrentUserManage || isCurrentUser}
                        className="-mr-3"
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Notifications */}
            <div className="mb-4 border-slate-300 border-t pt-4">
              <h2 className="block pb-2 font-semibold text-slate-600 text-sm leading-6">
                <FormattedMessage defaultMessage="Notifications" />
              </h2>

              <Checkbox
                id="enable-notifications"
                checked={enableNotifications}
                label={
                  <FormattedMessage defaultMessage="Enable email notifications" />
                }
                disabled={updateSpaceNotificationsResult.loading}
                onChange={async (checked) => {
                  await updateSpaceNotifications({
                    variables: {
                      input: {
                        spaceId,
                        notifications: {
                          emails: checked
                            ? UserSpaceNotificationsEmails.ALL
                            : UserSpaceNotificationsEmails.NONE,
                        },
                      },
                    },
                  });
                }}
              />
            </div>

            {/* Danger zone */}
            {canSeeDangerZone && (
              <div className="mb-4 border-slate-300 border-t pt-4">
                <h2 className="block pb-2 font-semibold text-slate-600 text-sm leading-6">
                  <FormattedMessage defaultMessage="Danger zone" />
                </h2>
                <div className="flex flex-col">
                  {/* Leave space button */}
                  <Tooltip
                    placement="bottom"
                    title={
                      !canLeaveSpace ? (
                        <FormattedMessage
                          defaultMessage="Leave space"
                          id="zXh7nb"
                        />
                      ) : (
                        ''
                      )
                    }
                  >
                    <Button
                      className={cx(
                        '-ml-2',
                        !canLeaveSpace ? 'cursor-not-allowed opacity-50' : ''
                      )}
                      variant="naked"
                      color="error"
                      startIcon={<ArrowRightFromLineIcon className="h-4 w-4" />}
                      onClick={() => setPage('confirmLeave')}
                      disabled={!canLeaveSpace}
                    >
                      <FormattedMessage defaultMessage="Leave space" />
                    </Button>
                  </Tooltip>

                  {/* Show the archive button only if the user can manage the space */}
                  {canCurrentUserManage && (
                    <Button
                      className="-ml-2"
                      variant="naked"
                      color="error"
                      startIcon={<Trash2Icon className="h-4 w-4" />}
                      loading={archiveSpaceResult.loading}
                      onClick={() => setPage('confirmArchive')}
                    >
                      <FormattedMessage defaultMessage="Archive space for everyone" />
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </ModalDialog>
      );
  }
};

const ManageMemberPermission: React.FC<{
  member: SpaceMember;
  spaceId: string;
  isReadOnly: boolean;
  className?: string;
}> = ({ member, spaceId, isReadOnly, className }) => {
  const canManageMeetings = member.permissions.MANAGE === true;
  const canAddMeetings = member.permissions.ADD === true;

  const space = useSpace({ id: spaceId });
  const currentSpaceData = space.data?.space;
  const members = currentSpaceData?.members || [];
  const { updateResult, onUpdate } = useSpaceUpateMutation();

  const handleMemberPermissionChange = async (
    uid: string,
    updatedPermissions: SpacePermission
  ) => {
    const updatedMembers = members.map((m) => ({
      uid: m.uid,
      permissions:
        m.uid === uid
          ? updatedPermissions
          : {
              ADD: m.permissions.ADD,
              MANAGE: m.permissions.MANAGE,
            },
    }));
    await onUpdate({
      id: spaceId,
      members: updatedMembers,
    });
  };

  const handleRemoveMember = async (uid: string) => {
    const updatedMembers = members
      .filter((m) => m.uid !== uid)
      .map((m) => ({
        uid: m.uid,
        permissions: {
          ADD: m.permissions.ADD,
          MANAGE: m.permissions.MANAGE,
        },
      }));
    await onUpdate({
      id: spaceId,
      members: updatedMembers,
    });
  };

  let spacePermissionLabel;
  if (canManageMeetings) {
    spacePermissionLabel = <FormattedMessage defaultMessage="Can manage" />;
  } else if (canAddMeetings) {
    spacePermissionLabel = (
      <FormattedMessage defaultMessage="Can add meetings" />
    );
  } else {
    spacePermissionLabel = <FormattedMessage defaultMessage="Can read" />;
  }

  if (isReadOnly) {
    return <div className="text-sm">{spacePermissionLabel}</div>;
  }

  return (
    <Menu className={className}>
      <Menu.Trigger>
        <Button
          variant="naked"
          loading={updateResult.loading}
          size="small"
          className="flex items-center space-x-2 text-slate-500 text-sm"
        >
          {spacePermissionLabel}
          <ChevronDown className="h-4 w-4" />
        </Button>
      </Menu.Trigger>
      <Menu.Item>
        <Checkbox
          id="can-add-meetings"
          checked={canManageMeetings ? true : canAddMeetings}
          label={<FormattedMessage defaultMessage="Can add meetings" />}
          disabled={canManageMeetings}
          onChange={(checked) =>
            handleMemberPermissionChange(member.uid, {
              ADD: checked,
              MANAGE: canManageMeetings,
            })
          }
        />
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          id="can-manage-meetings"
          checked={canManageMeetings}
          label={<FormattedMessage defaultMessage="Can manage" />}
          onChange={(checked) =>
            handleMemberPermissionChange(member.uid, {
              ADD: canAddMeetings,
              MANAGE: checked,
            })
          }
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        className="text-red-600"
        onClick={() => handleRemoveMember(member.uid)}
        icon={<RemoveIcon className="h-4 w-4" />}
      >
        <FormattedMessage defaultMessage="Remove from space" />
      </Menu.Item>
    </Menu>
  );
};
