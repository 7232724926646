import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TeamMember } from '../../graphql/operations';
import { selectTeam, selectUid } from '../../redux/selectors';
import { Avatar } from '../../components/Avatar';
import { Checkbox } from '../../components/Checkbox';
import { cx } from '../../helpers/utils';
import { Alert } from '../../components/Alert';
import { Users } from 'lucide-react';
import { Link } from '../../components/Link';
import { kTeam } from '../../helpers/routes';
import { FilterBox } from '../../components/FilterBox';

type AddMembersProps = {
  selectedMembers: TeamMember[];
  setSelectedMembers: (members: TeamMember[]) => void;
  canManageMeetings: boolean;
  canAddMeetings: boolean;
  setCanAddMeetings: (value: boolean) => void;
  setCanManageMeetings: (value: boolean) => void;
  excludedUsers: string[];
  label: string;
  isPersonalSpace: boolean;
  onClose: () => void;
};

export const AddMembers: React.FC<AddMembersProps> = ({
  selectedMembers,
  setSelectedMembers,
  canManageMeetings,
  canAddMeetings,
  setCanAddMeetings,
  setCanManageMeetings,
  excludedUsers,
  onClose,
  label,
  isPersonalSpace,
}) => {
  const intl = useIntl();
  const team = useSelector(selectTeam);
  const currentUserId = useSelector(selectUid);
  const teamHasOneMember = (team?.members?.length ?? 0) <= 1;
  const excludedIds = new Set([...excludedUsers, currentUserId]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="space-members"
          className={cx(
            'block font-semibold text-sm leading-6',
            'pb-2 text-slate-600'
          )}
        >
          {label}
        </label>
        <FilterBox<TeamMember>
          value={selectedMembers}
          onChange={setSelectedMembers}
          full={true}
          multiple={true}
          icon={<Users size="1rem" />}
          id={(person) => person.uid}
          name={(person) => person.displayName}
          disabled={teamHasOneMember}
          renderOption={(person) => (
            <div className="flex items-center gap-1">
              <Avatar
                src={person.photoURL!}
                className="flex-shrink-0"
                name={person.displayName}
              />
              <span className="block truncate">
                {person.displayName} ({person.email})
              </span>
            </div>
          )}
          options={
            team?.members.filter(
              (member) =>
                member.uid &&
                !excludedIds.has(member.uid) &&
                !member.deactivated
            ) || []
          }
          label={intl.formatMessage({
            defaultMessage: 'Team Members',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'e.g Sarah, mike@email.com',
          })}
        />
      </div>
      {teamHasOneMember ? (
        <Alert
          severity="info"
          alternateIcon={<Users />}
          compact={true}
          description={
            <FormattedMessage
              defaultMessage="You have no team members! To add users to this Space, you need to <a>Invite them to your team.</a>"
              id="FFBI/I"
              values={{
                a: (chunks) => (
                  <Link to={`${kTeam}/invite`} onClick={onClose}>
                    {chunks}
                  </Link>
                ),
              }}
            />
          }
        />
      ) : null}
      <div
        className={cx(
          'flex flex-col gap-2 text-sm',
          teamHasOneMember ? 'text-slate-400' : 'text-slate-500'
        )}
      >
        {isPersonalSpace && (
          <p>
            <FormattedMessage defaultMessage="Adding team members to a space will convert it from a personal to a team space, if you leave the team you will lose access to the space." />
          </p>
        )}
        <p>
          <FormattedMessage
            defaultMessage="All space members can <b>view & read</b> meetings. Set extra permissions below."
            values={{
              b: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </p>
      </div>
      <div className="flex flex-wrap gap-4">
        <Checkbox
          id="can-add-meetings"
          checked={canManageMeetings ? true : canAddMeetings}
          label={<FormattedMessage defaultMessage="Can add meetings" />}
          disabled={canManageMeetings || teamHasOneMember}
          onChange={setCanAddMeetings}
        />
        <Checkbox
          id="can-manage-meetings"
          checked={canManageMeetings}
          disabled={teamHasOneMember}
          label={<FormattedMessage defaultMessage="Can manage" />}
          onChange={setCanManageMeetings}
        />
      </div>
    </div>
  );
};
