import React, { useCallback, useId, useState } from 'react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAutocomplete } from './useAutocomplete';
import { useReactFlow } from '@xyflow/react';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';
import { useSelector } from 'react-redux';
import { selectNonPendingTeamMembers } from '../../../redux/selectors';
import { TeamMember } from '../../../graphql/operations';
import { minDimensions } from './minDimensions';
import {
  personEmail,
  personId,
  personName,
} from '../../Integration/integrations';
import { Avatar } from '../../../components/Avatar';
import {
  trackSendEmailToTeamBodyEdited,
  trackSendEmailToTeamSelectedMembersUpdated,
  trackSendEmailToTeamSubjectEdited,
} from '../../../helpers/analytics';
import { Autocomplete } from '../../../components/Autocomplete';
import { EmailNodeData } from '.';

export function SendEmailTeam(props: WorkflowNodeProps<EmailNodeData>) {
  const { id, data } = props;

  const intl = useIntl();
  const autocomplete = useAutocomplete(props);
  const { updateNodeData: update } = useReactFlow();
  const { workflowId } = useWorkflowId();
  const isExecution = Boolean(data.execution);
  const isPreview = useIsPreview();

  const teamMembers = useSelector(selectNonPendingTeamMembers);

  const [selectedMembers, setSelectedMembers] = useState<TeamMember[]>(
    teamMembers.filter((m) => {
      return data.recipients.some((r) => r.email === m.email);
    }) || []
  );

  const handleSubjectChange = useCallback(
    (subject: string) => {
      trackSendEmailToTeamSubjectEdited();
      update(id, { subjectTemplate: subject });
    },
    [id, update]
  );

  const handleBodyChange = useCallback(
    (body: string) => {
      trackSendEmailToTeamBodyEdited();
      update(id, { bodyTemplate: body });
    },
    [id, update]
  );

  const handleRecipientsChange = useCallback(
    (members: TeamMember[]) => {
      trackSendEmailToTeamSelectedMembersUpdated({ count: members.length });
      setSelectedMembers(members);
      update(id, {
        recipients: members.map((m) => ({
          name: m.displayName,
          email: m.email,
        })),
      });
    },
    [id, update]
  );

  const emailRecipients = useId();
  const emailSubjectId = useId();
  const emailBodyId = useId();

  return (
    <SingleSourceNode
      {...minDimensions('SendEmailTeam')}
      workflowNode={props}
      contentClassName="h-full"
    >
      <div className="flex grow flex-col gap-3">
        <div
          className="flex w-full flex-grow flex-col gap-1"
          id={emailRecipients}
        >
          <div className="flex font-medium text-sm">
            <label htmlFor={emailRecipients}>
              <FormattedMessage defaultMessage="Members" />
            </label>
          </div>
          <Autocomplete
            value={selectedMembers}
            onChange={handleRecipientsChange}
            full
            multiple
            id={(person) => personId(person)}
            name={(person) => personName(person)}
            optionsWidth="33rem"
            renderOption={(person) => (
              <div className="mt-1 flex items-center gap-1">
                <Avatar
                  src={('photoURL' in person && person.photoURL) || ''}
                  className="flex-shrink-0"
                  name={personName(person)}
                />
                <span className="block truncate">
                  {personName(person)} ({personEmail(person)})
                </span>
              </div>
            )}
            options={teamMembers}
            placeholder={intl.formatMessage({
              defaultMessage: 'Type email or select team members',
            })}
            disabled={isExecution}
          />
        </div>
        <div className="flex w-full flex-grow flex-col gap-1">
          <div className="flex font-medium text-sm">
            <label htmlFor={emailSubjectId}>
              <FormattedMessage defaultMessage="Subject" />
            </label>
          </div>
          <LiquidTemplateInput
            id={emailSubjectId}
            className="min-h-16 text-base"
            properties={autocomplete.properties}
            variables={autocomplete.variables}
            value={data.subjectTemplate}
            onChange={handleSubjectChange}
            workflowId={workflowId}
            nodeType={props.type}
            disabled={isExecution || isPreview}
            placeholder={intl.formatMessage({
              defaultMessage: 'Start typing your liquid template',
            })}
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Liquid template input',
            })}
          />
        </div>
        <div className="flex w-full flex-grow flex-col gap-1">
          <div className="flex font-medium text-sm">
            <label htmlFor={emailBodyId}>
              <FormattedMessage defaultMessage="Body" />
            </label>
          </div>
          <LiquidTemplateInput
            id={emailBodyId}
            className="min-h-48 text-base"
            properties={autocomplete.properties}
            variables={autocomplete.variables}
            value={data.bodyTemplate}
            onChange={handleBodyChange}
            workflowId={workflowId}
            nodeType={props.type}
            disabled={isExecution || isPreview}
            placeholder={intl.formatMessage({
              defaultMessage: 'Start typing your liquid template',
            })}
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Liquid template input',
            })}
          />
        </div>
      </div>
    </SingleSourceNode>
  );
}
