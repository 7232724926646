import React, { ReactElement } from 'react';
import { RadioGroup } from '@headlessui/react';
import { cx } from '../../helpers/utils';
import { Tooltip } from '../Tooltip';

function Radio({
  value,
  label,
  description,
  disabled = false,
  disabledReason,
}: {
  value: string;
  label: string | ReactElement;
  description?: string | ReactElement;
  disabled?: boolean;
  disabledReason?: string | ReactElement;
}): ReactElement {
  const RadioContents = (
    <RadioGroup.Option
      disabled={disabled}
      value={value}
      className={cx(
        'flex items-start gap-2 rounded-2xl border p-4 focus:outline-none ',
        disabled
          ? 'cursor-not-allowed border-neutral-200 bg-neutral-50 hover:border-neutral-200 focus:ring-0 focus:ring-neutral-200'
          : 'cursor-pointer border-neutral-300 hover:border-neutral-400 focus:border-transparent focus:ring-2 focus:ring-neutral-400',
        'aria-checked:border-neutral-950'
      )}
    >
      {({ checked }) => (
        <>
          <span
            className={cx(
              'mt-1 flex h-4 w-4 items-center justify-center rounded-full border-2 border-neutral-950',
              checked ? 'border-neutral-950' : 'border-slate-400',
              disabled ? 'border-neutral-200' : ''
            )}
          >
            <span
              className={cx(
                'inline-block h-2 w-2 rounded-full',
                checked ? 'bg-neutral-950' : 'bg-transparent',
                disabled ? 'opacity-50' : ''
              )}
            />
          </span>

          <div className={cx('flex flex-col items-baseline')}>
            <RadioGroup.Label
              className={cx(
                disabled
                  ? 'cursor-not-allowed text-neutral-400'
                  : 'cursor-pointer'
              )}
            >
              {label}
            </RadioGroup.Label>
            {description ? (
              <RadioGroup.Description
                className={cx(disabled ? 'text-neutral-400' : '', 'text-xs')}
              >
                {description}
              </RadioGroup.Description>
            ) : null}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
  return disabled && disabledReason ? (
    <Tooltip title={disabledReason} placement="right">
      {RadioContents}
    </Tooltip>
  ) : (
    RadioContents
  );
}

export { Radio, RadioGroup };
