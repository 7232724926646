import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../../../helpers/analytics';
import {
  selectIsUserPaidByTeam,
  selectTeam,
  selectUserPlan,
  selectUserTier,
  teamBillingPastDue,
  teamBillingRenewalDisabled,
  userBillingPastDue,
  userBillingRenewalDisabled,
} from '../../../../redux/selectors';
import { UpgradeOptionsButton } from '../../../Common/upgrade-dialog/UpgradeOptionsButton';
import { TierPricingDialog } from '../../../Credits/TierPricing/TierPricingDialog';
import Magic from '../../../Common/icons/Magic';
import { Zap } from 'lucide-react';

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.needsCredits If credits are needed
 * @returns {React.ReactNode} Credits component
 */
export const Credits: React.FC<{ needsCredits: boolean }> = ({
  needsCredits,
}) => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const userPlan = useSelector(selectUserPlan);
  const isTeamPaidPlan = useSelector(selectIsUserPaidByTeam);

  const tBillingPastDue = useSelector(teamBillingPastDue);
  const tBillingRenewDisabled = useSelector(teamBillingRenewalDisabled);
  const uBillingPastDue = useSelector(userBillingPastDue);
  const uBillingRenewDisabled = useSelector(userBillingRenewalDisabled);

  if (!needsCredits) {
    return (
      <div className="flex gap-2">
        <Magic />
        <div className="text-sm">
          <FormattedMessage defaultMessage="AI credit already used, no more needed" />
        </div>
      </div>
    );
  }

  const aiCreditsMessage = () => {
    if (isTeamPaidPlan) {
      return (
        <p className="text-sm">
          <FormattedMessage
            defaultMessage="You have {credits} AI credits."
            values={{
              credits: (
                <b>
                  <FormattedMessage defaultMessage="unlimited" />
                </b>
              ),
            }}
          />
        </p>
      );
    } else {
      return (
        <div className="wrap flex items-center gap-2">
          {tBillingPastDue ||
          tBillingRenewDisabled ||
          uBillingPastDue ||
          uBillingRenewDisabled ? (
            <p className="font-medium text-slate-500 text-sm">
              <FormattedMessage
                defaultMessage="You have {credits} AI credits left."
                values={{
                  credits: (
                    <b className="font-semibold">
                      {Math.max(
                        userPlan.free.aiCredits.allowance -
                          userPlan.free.aiCredits.used,
                        0
                      )}
                    </b>
                  ),
                }}
              />
            </p>
          ) : (
            <>
              <p className="font-medium text-slate-500 text-sm">
                <FormattedMessage
                  defaultMessage="You have {credits} AI credits left."
                  values={{
                    credits: (
                      <b className="font-bold text-slate-800">
                        {Math.max(
                          userPlan.free.aiCredits.allowance -
                            userPlan.free.aiCredits.used,
                          0
                        )}
                      </b>
                    ),
                  }}
                />
              </p>

              <UpgradeOptionsButton
                onUpgradeClick={() => {
                  trackWebEvent(
                    'Transcript page - Upgrade plan button clicked'
                  );
                  setShowTierPricingDialog(true);
                }}
              />
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row items-center gap-1">
      {isTeamPaidPlan ? (
        <Magic />
      ) : (
        <Zap className="size-4 fill-upgrade-default p-0.5 text-upgrade-default" />
      )}
      {aiCreditsMessage()}
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.SETTINGS}
          onClose={() => setShowTierPricingDialog(false)}
        />
      )}
    </div>
  );
};
