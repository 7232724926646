import { useIntl } from 'react-intl';
import { useEffect } from 'react';

type PageTitleProps = { title?: string };

const PageTitle = ({ title }: PageTitleProps) => {
  const intl = useIntl();

  useEffect(() => {
    const originalTitle = document.title;

    const defaultTitle = intl.formatMessage({
      defaultMessage: 'Meeting notes powered by transcription',
      description:
        'The default page title message, displayed as part of the page title.',
    });

    const newTitle = intl.formatMessage(
      {
        defaultMessage: 'Tactiq | {title}',
        description: 'The page title; displayed in the window or tab.',
      },
      {
        title: title ?? defaultTitle,
      }
    );

    document.title = newTitle;

    return () => {
      document.title = originalTitle;
    };
  }, [title, intl]);

  return null;
};

export default PageTitle;
