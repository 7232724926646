import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import React, { ReactElement } from 'react';
import { cx } from '../../helpers/utils';
import { ActionButton } from '../buttons';

type NotistackProps = Omit<
  CustomContentProps,
  | 'variant'
  | 'id'
  | 'style'
  | 'hideIconVariant'
  | 'anchorOrigin'
  | 'iconVariant'
>;

interface ToastNotificationProps extends NotistackProps {
  variant: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  title?: string;
  interaction?: {
    label: string;
    onClick?: () => void;
    href?: string;
    loading?: boolean;
  };
}

const variantClasses: Record<ToastNotificationProps['variant'], string> = {
  ERROR: 'bg-white border-red-500',
  INFO: 'bg-white border-blue-500',
  SUCCESS: 'bg-white border-green-500',
  WARNING: 'bg-white border-amber-500',
};

const iconVariantClasses: Record<ToastNotificationProps['variant'], string> = {
  ERROR: 'text-red-500',
  INFO: 'text-blue-500',
  SUCCESS: 'text-green-500',
  WARNING: 'text-amber-500',
};
const iconButtonClasses: Record<ToastNotificationProps['variant'], string> = {
  ERROR: ' focus:ring-red-300 ',
  INFO: ' focus:ring-blue-300 ',
  SUCCESS: ' focus:ring-green-300',
  WARNING: ' focus:ring-amber-300',
};

const errorIcon = (
  <div className="flex-shrink-0 justify-center" aria-hidden="true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  </div>
);

const warningIcon = (
  <div className="flex-shrink-0 justify-center" aria-hidden="true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
  </div>
);

const infoIcon = (
  <div className="flex-shrink-0 justify-center" aria-hidden="true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>
  </div>
);

const successIcon = (
  <div className="flex-shrink-0 justify-center" aria-hidden="true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </div>
);

const ToastNotification = React.forwardRef<
  HTMLDivElement,
  ToastNotificationProps
>(({ message, variant, title, interaction }, ref): ReactElement => {
  const { closeSnackbar } = useSnackbar();
  const { onClick, label } = interaction || {
    onClick: undefined,
    label: null,
  };

  const interactionButton = interaction ? (
    <ActionButton onClick={onClick}>
      <p>{label}</p>
    </ActionButton>
  ) : null;

  let icon = null;
  switch (variant) {
    case 'SUCCESS':
      icon = successIcon;
      break;
    case 'ERROR':
      icon = errorIcon;
      break;
    case 'INFO':
      icon = infoIcon;
      break;
    case 'WARNING':
      icon = warningIcon;
      break;
    default:
      icon = infoIcon;
      break;
  }
  return (
    <SnackbarContent ref={ref}>
      <div
        className={cx(
          'pointer-events-auto max-w-md overflow-hidden rounded-md border-y border-y-slate-200 border-r border-r-slate-200 border-l-4 shadow-lg shadow-slate-200',
          variantClasses[variant]
        )}
      >
        <div className="p-4">
          <div className="flex items-center">
            <div className={cx('flex-shrink-0', iconVariantClasses[variant])}>
              {icon}
            </div>
            <div className="ml-3 flex-1">
              {title && (
                <p className="mb-1 font-medium text-slate-800 text-sm">
                  {title}
                </p>
              )}
              <p className="text-slate-600 text-sm">{message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0 flex-col">
              <button
                type="button"
                className={cx(
                  'inline-flex rounded-md',
                  iconButtonClasses[variant]
                )}
                onClick={() => {
                  closeSnackbar();
                }}
              >
                <span className="sr-only">Close</span>
                <div
                  className={cx('h-5 w-5', iconVariantClasses[variant])}
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5 text-slate-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          {interactionButton && (
            <div className="flex flex-row justify-end">{interactionButton}</div>
          )}
        </div>
      </div>
    </SnackbarContent>
  );
});

ToastNotification.displayName = 'ToastNotification';

type ToastVariantProps = Pick<
  ToastNotificationProps,
  'message' | 'title' | 'interaction'
>;

export const SuccessToast = React.forwardRef<HTMLDivElement, ToastVariantProps>(
  (props, ref): ReactElement => {
    const { message, title, interaction } = props;
    return (
      <ToastNotification
        ref={ref}
        variant="SUCCESS"
        message={message}
        title={title}
        persist={false}
        interaction={interaction}
      />
    );
  }
);

SuccessToast.displayName = 'SuccessToast';

export const ErrorToast = React.forwardRef<HTMLDivElement, ToastVariantProps>(
  (props, ref): ReactElement => {
    const { message, title, interaction } = props;
    return (
      <ToastNotification
        ref={ref}
        variant="ERROR"
        message={message}
        title={title}
        persist={false}
        interaction={interaction}
      />
    );
  }
);

ErrorToast.displayName = 'ErrorToast';

export const InfoToast = React.forwardRef<HTMLDivElement, ToastVariantProps>(
  (props, ref): ReactElement => {
    const { message, title, interaction } = props;
    return (
      <ToastNotification
        ref={ref}
        variant="INFO"
        message={message}
        title={title}
        persist={false}
        interaction={interaction}
      />
    );
  }
);

InfoToast.displayName = 'InfoToast';

export const WarningToast = React.forwardRef<HTMLDivElement, ToastVariantProps>(
  (props, ref): ReactElement => {
    const { message, title, interaction } = props;
    return (
      <ToastNotification
        ref={ref}
        variant="WARNING"
        message={message}
        title={title}
        persist={false}
        interaction={interaction}
      />
    );
  }
);

WarningToast.displayName = 'WarningToast';

declare module 'notistack' {
  interface VariantOverrides {
    default: false;
    success: false;
    warning: false;
    info: false;
    error: false;
    SUCCESS: {
      title?: string;
      interaction?: {
        label: string;
        onClick?: () => void;
        loading?: boolean;
      };
    };
    ERROR: {
      title?: string;
      interaction?: {
        label: string;
        onClick?: () => void;
        loading?: boolean;
      };
    };
    INFO: {
      title?: string;
      interaction?: {
        label: string;
        onClick?: () => void;
        loading?: boolean;
      };
    };
    WARNING: {
      title?: string;
      interaction?: {
        label: string;
        onClick?: () => void;
        loading?: boolean;
      };
    };
  }
}
