import { AutoJoinDomain } from './settings';
import { StripePaidPlanData, TeamRole } from './user';

export interface TeamInvitationLink {
  id: string;
  usedTimes: number;
}

export interface TeamDataV1 {
  id: string;
  name?: string;
  plan?: StripePaidPlanData;
  stripe?: {
    stripeId: string;
  };
  link?: TeamInvitationLink;
  settings?: TeamSettingsData;
}

export interface TeamData {
  id: string;
  name: string;
  displayName?: string;
  plan?: StripePaidPlanData;
  stripeId?: string;
  link?: TeamInvitationLink;
  settings?: TeamSettingsData;
  members: Record<string, TeamMemberData>;
  domains?: string[];
  invitations?: string[];
  cancellationDiscountCouponUsed?: string;
  allowOverages?: boolean;
  // this field contains secrets, do not expose them to non-admin users!
  auth?: TeamAuthenticationData;
}

export interface OktaOidcConfiguration {
  // this is Okta domain for this customer
  domain: string;
  clientId: string;
  clientSecret: string;
}

export interface SamlConfiguration {
  // if the user supplied it, let's keep it, but it doesn't need to be shown in the UI
  metadataUrl?: string;
  // AKA issuer
  entityId: string;
  ssoUrl: string;
  // AKA sign out URL
  sloUrl?: string;
  certificate: string;
}

export interface Validatable {
  validated?: boolean;
}

// Here, current configuration is set only after testing was successful for the values in the incoming configuration.
// This is done to avoid breaking the current configuration if the new one is invalid (and locking out users from Tactiq as well).
export interface TeamAuthenticationData {
  oktaOidc?: {
    current?: OktaOidcConfiguration;
    incoming?: OktaOidcConfiguration & Validatable;
  };
  saml?: {
    current?: SamlConfiguration;
    incoming?: SamlConfiguration & Validatable;
  };
  scim?: {
    // this is unique for each customer
    token: string;
  };
}

export interface TeamMemberData {
  uid: string;
  email: string;
  displayName: string;
  photoURL?: string;
  roles: Record<TeamRole, boolean>;
  requestedUpgrade?: boolean;
  /**
   * equal to the value of `isOutOfCredits` of a `UserPlan` that would be constructed for the user from `UserData`
   *
   * This field is set when the user runs out of credits, their allowance changes, or their plan data changes
   */
  isOutOfMeetings?: boolean;
  /**
   * equal to the value of `isOutOfMeetings` of a `UserPlan` that would be constructed for the user from `UserData`
   *
   * This field is set when the user's plan data changes
   */
  hasPersonalActivePlan?: boolean;
  usingSeat?: boolean;
  settings?: TeamMemberSettingsData;
  deactivated?: boolean;
}

export interface TeamMemberSettingsData {
  meetingsRetentionPeriodDays?: number;
}

export interface TeamSettingsData {
  // @deprecated use `authMethods` instead
  enableEmailLinkSignIn?: boolean;
  enableLLM?: boolean;
  disableSelfJoinDomain?: boolean;
  autoJoinDomain: AutoJoinDomain;
  enableTeamInvite?: boolean;
  meetingsRetentionPeriodDays?: number;
  enableTranscriptionNotification?: boolean;
  // this can only be enabled by Tactiq at the moment, and should include setting team join mode to INVITATION_ONLY
  advancedAuthEnabled?: boolean;
  authMethods?: Partial<Record<TeamAuthenticationMethod, boolean>>;
  scimEnabled?: boolean;
}

export type TeamAuthenticationMethod =
  | 'google'
  | 'emailLink'
  | 'oktaOidc'
  | 'saml';

export type TeamAuthenticationSettings = {
  methods: TeamSettingsData['authMethods'];
  config?: TeamData['auth'];
};

export const MAX_DOMAIN_USERS = 200;
