import {
  AddLabelsNode,
  ConditionNode,
  ConfirmationNode,
  CustomPromptNode,
  EmailNode,
  RunMeetingKitNode,
  SaveToSpaceNode,
  SendDataNode,
  ShareNode,
  TemplateNode,
  WorkflowNode,
} from '../models/workflows';
import { Liquid } from 'liquidjs';
import { FieldDataObject } from '../models/workflows';
import get from 'lodash/get';

export class NodeValidator {
  static validate(node: WorkflowNode): WorkflowNode {
    switch (node.type) {
      case 'SaveToSpace':
        return NodeValidator.validateSaveToSpace(node);
      case 'AddLabels':
        return NodeValidator.validateAddLabels(node);
      case 'Share':
        return NodeValidator.validateShare(node);
      case 'SendData':
        return NodeValidator.validateSendData(node);
      case 'Condition':
        return NodeValidator.validateCondition(node);
      case 'Confirmation':
        return NodeValidator.validateConfirmation(node);
      case 'CustomPrompt':
        return NodeValidator.validateCustomPrompt(node);
      case 'RunMeetingKit':
        return NodeValidator.validateRunMeetingKit(node);
      case 'Template':
        return NodeValidator.validateTemplate(node);
      case 'SendEmailTeam':
        return NodeValidator.validateEmail(node);
      case 'SendEmailParticipants':
        return NodeValidator.validateEmail(node);

      default:
        return { ...node, data: { ...(node.data ?? {}), isValid: true } };
    }
  }

  private static validateSaveToSpace(node: WorkflowNode): WorkflowNode {
    const data = node.data as SaveToSpaceNode['data'];
    const isValid = Boolean(data.space);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateAddLabels(node: WorkflowNode): WorkflowNode {
    const data = node.data as AddLabelsNode['data'];
    const isValid = Boolean(data.labels);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateShare(node: WorkflowNode): WorkflowNode {
    const data = node.data as ShareNode['data'];
    let isValid = false;
    if (data.slack) {
      isValid =
        Boolean(data.slack.connectionKey) && isValidLiquid(data.bodyTemplate);
    } else if (data.email) {
      isValid =
        isValidLiquid(data.email.subject) && isValidLiquid(data.bodyTemplate);
    }
    return { ...node, data: { ...data, isValid } };
  }

  private static validateSendData(node: WorkflowNode): WorkflowNode {
    const data = node.data as SendDataNode['data'];
    let isValid = false;
    const fieldData = data.fieldData as FieldDataObject | undefined;
    if (typeof data.parameterData === 'undefined' && data.type !== 'email') {
      return { ...node, data: { ...data, isValid: true } };
    }

    switch (data.type) {
      case 'slack':
        isValid =
          Boolean(data.parameterData.conversationId) &&
          isValidLiquid(fieldData?.text as string);
        break;

      case 'email':
        isValid = Boolean(
          data.email &&
            isValidLiquid(data.email.subject) &&
            isValidLiquid(data.email.message)
        );
        break;

      case 'notion':
        isValid = Boolean(data.parameterData.database_id);
        break;

      case 'hubspot':
        isValid = Boolean(
          fieldData?.hs_meeting_body &&
            isValidLiquid(fieldData.hs_meeting_body as string)
        );
        break;

      case 'workable': {
        const comment = get(fieldData, 'comment.body');
        isValid = Boolean(comment && isValidLiquid(comment as string));
        break;
      }

      case 'googledrive': {
        isValid =
          Boolean(fieldData?.name && isValidLiquid(fieldData.name as string)) &&
          Boolean(
            fieldData?.content && isValidLiquid(fieldData.content as string)
          );
        break;
      }

      case 'linear':
        isValid =
          Boolean(data.parameterData.team_id) &&
          Boolean(
            fieldData &&
              isValidLiquid(fieldData.title as string) &&
              isValidLiquid(fieldData.description as string)
          );
        break;
    }

    return { ...node, data: { ...data, isValid } };
  }

  private static validateCondition(node: WorkflowNode): WorkflowNode {
    const data = node.data as ConditionNode['data'];
    return {
      ...node,
      data: { ...data, isValid: isValidLiquid(data.condition) },
    };
  }

  private static validateConfirmation(node: WorkflowNode): WorkflowNode {
    const data = node.data as ConfirmationNode['data'];
    return { ...node, data: { ...data, isValid: isValidLiquid(data.prompt) } };
  }

  private static validateCustomPrompt(node: WorkflowNode): WorkflowNode {
    const data = node.data as CustomPromptNode['data'];
    return { ...node, data: { ...data, isValid: isValidLiquid(data.prompt) } };
  }

  private static validateRunMeetingKit(node: WorkflowNode): WorkflowNode {
    const data = node.data as RunMeetingKitNode['data'];
    const isValid = Boolean(data.meetingKitId && data.meetingKitItemId);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateTemplate(node: WorkflowNode): WorkflowNode {
    const data = node.data as TemplateNode['data'];
    return {
      ...node,
      data: { ...data, isValid: isValidLiquid(data.template) },
    };
  }

  private static validateEmail(node: WorkflowNode): WorkflowNode {
    const data = node.data as EmailNode['data'];

    const isValid = !(
      !isValidLiquid(data.subjectTemplate) ||
      !isValidLiquid(data.bodyTemplate) ||
      !Array.isArray(data.recipients) ||
      (data.shareWithParticipants && data.recipients.length === 0) ||
      data.recipients.some((r) => !r.email || !r.name) ||
      data.recipients.some(
        (r) => !r.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
    );

    return {
      ...node,
      data: { ...data, isValid },
    };
  }
}

/**
 * Given a template, try to parse it and see if its good liquid syntax
 */
function isValidLiquid(template: string | undefined): boolean {
  try {
    new Liquid().parse(template ?? '');
    return Boolean(template);
  } catch (e) {
    return false;
  }
}
