import React, { useContext } from 'react';
import { Select } from '../Select';
import { ConditionContext } from './lib';
import { ChipInput } from '../ChipInput';
import { intl } from '../../stories/IntlWrapper';
import { TextInput } from '../TextInput';
import { DatePicker } from '../DatePicker';
import { format } from 'date-fns/format';

export const ConditionBuilderValue = () => {
  const { condition, key, onUpdate } = useContext(ConditionContext);
  const content = String(condition?.value || '');

  if (!condition?.variable || !key) {
    return <div />;
  }

  let elem: React.ReactElement;

  switch (condition?.variable?.type) {
    case 'string':
    case 'number':
      elem = (
        <TextInput
          autoFocus={true}
          variant="naked"
          onChange={(value) => onUpdate(key, { value })}
          onBlur={(value) => onUpdate(key, { value: value.trim() })}
          inputProps={{
            className: '!text-xs',
            'aria-label': 'condition-builder-value',
          }}
          value={content}
          type={condition.variable.type === 'number' ? 'number' : 'text'}
        />
      );
      break;
    case 'boolean':
      elem = (
        <Select
          full
          aria-label="condition-builder-value"
          variant="naked"
          size="small"
          onChange={(value) => onUpdate(key, { value })}
          options={['true', 'false'].map((value) => ({ value, label: value }))}
          value={content}
        />
      );
      break;
    case 'array':
      elem = (
        <ChipInput
          placeholder={intl.formatMessage({ defaultMessage: 'Add a new item' })}
          size="small"
          value={content.split(',')}
          onChange={(value) => onUpdate(key, { value: value.join(',') })}
        />
      );
      break;

    case 'date': {
      elem = (
        <DatePicker
          key={content}
          value={content ? new Date(content) : undefined}
          onChange={(next) =>
            onUpdate(key, { value: next ? format(next, 'yyyy-MM-dd') : '' })
          }
        />
      );
      break;
    }

    default:
      elem = <span />;
  }
  return (
    <fieldset aria-label="condition-value" className="flex-grow text-sm">
      {elem}
    </fieldset>
  );
};
