import React, { ReactElement, useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, BooleanNode } from './BaseNode';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { ThumbsUp as ConfirmationIcon } from 'lucide-react';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIntl } from 'react-intl';
import { useIsPreview } from './useIsPreview';
import { minHeights, minWidths } from './minDimensions';

export function Confirmation(
  props: WorkflowNodeProps<{
    prompt?: string;
  }>
): ReactElement {
  const { id } = props;
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const value = props.data.prompt ?? '';
  const { workflowId } = useWorkflowId();
  const intl = useIntl();
  const isExecution = Boolean(props.data.execution);
  const isPreview = useIsPreview();
  const handleChange = useCallback(
    (prompt: string) => {
      update(id, { prompt });
    },
    [id, update]
  );

  return (
    <BooleanNode
      icon={
        <ConfirmationIcon className="size-8 rounded-lg border border-green-500/20 bg-emerald-50 p-1.5 text-emerald-500" />
      }
      workflowNode={props}
      yes={intl.formatMessage({ defaultMessage: 'Accept' })}
      no={intl.formatMessage({ defaultMessage: 'Decline' })}
      minHeight={minHeights.Confirmation}
      minWidth={minWidths.Confirmation}
    >
      <LiquidTemplateInput
        className="min-h-40 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={value}
        onChange={handleChange}
        workflowId={workflowId}
        nodeType={props.type}
        disabled={isExecution || isPreview}
        placeholder={intl.formatMessage({
          defaultMessage: 'Write your confirmation prompt',
        })}
        ariaLabel={intl.formatMessage({
          defaultMessage: 'Confirmation prompt input',
        })}
      />
    </BooleanNode>
  );
}
