import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectUid, selectUserSettings } from '../redux/selectors';
import { setRequestedDeletion } from '../graphql/settings';
import { Button } from '../components/buttons';
import { Alert } from '../components/Alert';
import {
  trackAccountDeletionNotificationShown,
  trackCancelAccountDeletion,
} from '../helpers/analytics';

export const AccountDeletionNotification: React.FC = function () {
  const requestedDeletion = useSelector(selectUserSettings).requestedDeletion;
  const userId = useSelector(selectUid);
  if (!requestedDeletion) return null;

  if (requestedDeletion) {
    trackAccountDeletionNotificationShown(userId, requestedDeletion);
  }

  return (
    <div className="fixed right-0 bottom-0 left-0 m-2">
      <Alert
        severity="error"
        action={
          <div className="mr-16">
            <Button
              color="error"
              onClick={async () => {
                trackCancelAccountDeletion();
                await setRequestedDeletion({ requested: false });
              }}
            >
              Undo
            </Button>
          </div>
        }
        description={
          <FormattedMessage
            defaultMessage="Your account will be deleted soon. Account deletion requested on: {deletionDate}"
            values={{
              deletionDate: new Date(requestedDeletion * 1000).toString(),
            }}
          />
        }
      />
    </div>
  );
};
