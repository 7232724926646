import React from 'react';
import { AlertNeedAuthentication } from './AlertNeedAuthentication';
import { InstallExtensionBanner } from './install-extension-banner';
import { FinishOnboardingBanner } from './finish-onboarding-banner';
import { DataOrSubscriptionMoveBanner } from './DataOrSubscriptionMoveBanner';
import { LanguageSupportBanner } from './WeSupportYourLanguageBanner';
import { AccountDeletionNotification } from '../../AccountDeletionNotification';
import NewBuildNotification from '../../NewBuildNotification';
import { DownloadCompanionAppBanner } from './download-companion-app-banner';
import { UpgradeToPaidSeatBanner } from './UpgraidToPaidSeat';
import { useFeatureFlags } from '../../../helpers/feature-flags';

export const Banners: React.FC<{ title: string }> = ({ title }) => {
  const featureFlagService = useFeatureFlags();
  return (
    <div className="mt-[67px] md:mt-0">
      <AlertNeedAuthentication />
      <InstallExtensionBanner />
      <FinishOnboardingBanner />
      <DataOrSubscriptionMoveBanner location={title} />
      <LanguageSupportBanner />
      <AccountDeletionNotification />
      <NewBuildNotification />
      {featureFlagService.getDownloadCompanionAppBanner() ? (
        <DownloadCompanionAppBanner />
      ) : null}
      <UpgradeToPaidSeatBanner />
    </div>
  );
};
