import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import iconSalesforce from '../../../img/salesforce.png';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';

import {
  IntegrationCategory,
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import { NamedObject } from '@tactiq/model';
import { createOAuthConnector } from './connector';
import { trackWebEvent } from '../../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import {
  createSalesforceEvent,
  searchSalesforceContacts,
} from '../../../helpers/api/integrations/salesforce';
import { Search, User2 } from 'lucide-react';
import {
  ShareDialogFormClasses,
  ShareDialogListWrapperClasses,
  ShareDialogWrapperClasses,
} from './styles';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { connection, share } = props;
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState<NamedObject[]>([]);
  const intl = useIntl();

  useAsyncEffect(
    async () => {
      if (connection && isLoading) {
        const result = await searchSalesforceContacts({
          query,
          connectionKey: connection.connectionKey,
        });
        if (result) {
          setContacts(result.data);
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection, isLoading]
  );

  const listContent = contacts.length ? (
    contacts.map((x) => (
      <li key={x.id}>
        <Button
          variant="naked"
          className="w-full"
          onClick={() => {
            trackWebEvent('Selected a contacts in Salesforce to share into');
            return share(integration, connection, {
              user_id: connection.user_id,
              contactId: x.id,
              contactName: x.name,
            });
          }}
          startIcon={<User2 />}
        >
          {x.name}
        </Button>
      </li>
    ))
  ) : (
    <li>
      <FormattedMessage
        defaultMessage="No contacts was found. Try another search term."
        description="Saleforce integration. No contacts found message text."
      />
    </li>
  );

  return (
    <div className={ShareDialogWrapperClasses}>
      <div>
        <FormattedMessage
          defaultMessage="Please select contact. Your new meeting log will be created for it."
          description="Saleforce integration. Select contact message."
        />
      </div>
      <form
        className={ShareDialogFormClasses}
        onSubmit={(event) => {
          setIsLoading(true);

          event.preventDefault();
        }}
      >
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Search Saleforce contacts',

            description:
              'Saleforce integration. Search contacts input salesforce.',
          })}
          inputProps={{
            'aria-label': intl.formatMessage({
              defaultMessage: 'search Salesforce deals',

              description:
                'Salesforce integration. Search deals input aria-label.',
            }),
            name: 'query',
          }}
          disabled={isLoading}
          value={query}
          onChange={setQuery}
        />
        <Button
          type="submit"
          variant="icon"
          aria-label={intl.formatMessage({
            defaultMessage: 'Search',

            description:
              'Salesforce integration. Search button icon aria-label.',
          })}
        >
          <Search className="h-6 w-6" />
        </Button>
      </form>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <ul className={ShareDialogListWrapperClasses}>{listContent}</ul>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const result = await createSalesforceEvent({
    connectionKey: connection.connectionKey,
    meetingId: options.meetingId,
    title: options.title,
    rawTranscript: options.rawTranscript,
    contactId: options.contactId,
    shareSettings: {
      isSharingDetails: options.isSharingDetails,
      isSharingHighlights: options.isSharingHighlights,
      isSharingNotes: options.isSharingNotes,
      isSharingTranscript: options.isSharingTranscript,
    },
  });

  return { externalId: result.externalId, link: result.link };
};

export const integration: IntegrationImplementation = {
  id: 'salesforce',
  category: IntegrationCategory.CRM,
  title: 'Salesforce',
  icon: iconSalesforce,
  description: () => (
    <div className="text-slate-500">
      <FormattedMessage
        defaultMessage="Link your transcripts with contacts and opportunities in Salesforce, and create tasks based on your highlights and tags."
        description="Salesforce integration description"
        id="Ez+OyM"
      />
    </div>
  ),
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Link to records"
      id="rP2Lz/"
      description="Salesforce integration share description"
    />
  ),
  connector: createOAuthConnector('salesforce', 'Salesforce'),
  allowMultipleConnections: false,
  share: shareImpl,
  ShareDialog,
  waitlist: false,
  getSharingDestinationTitle: (options) => {
    return `${options.contactName}`;
  },
};

export default integration;
