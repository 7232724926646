import { useReactFlow, MarkerType, Node, Edge } from '@xyflow/react';
import { Button } from '../../components/buttons';
import { PlusIcon, ArrowDownToDot, Split } from 'lucide-react';
import { Menu } from '../../components/Menu';
import { nanoid } from 'nanoid';
import React from 'react';
import { useWorkflowId } from './WorkflowIdContext';
import { AddNodeFn, useNodeTypeToName } from './nodes';
import { trackAddWorkflowNode } from '../../helpers/analytics';

import { useIsPreview } from './nodes/useIsPreview';
import Dagre from 'dagre';
import { useIntl } from 'react-intl';
import AddNodeMenuList from './AddNodeMenuItems';

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: 'TB', ranksep: 100, nodesep: 30 });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    })
  );

  Dagre.layout(g);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = g.node(node.id);

    return {
      ...node,
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

function getPrefersReducedMotion() {
  const QUERY = '(prefers-reduced-motion: no-preference)';
  const mediaQueryList = window.matchMedia(QUERY);
  const prefersReducedMotion = !mediaQueryList.matches;
  return prefersReducedMotion;
}

type AddNodeButtonProps = {
  source: string;
  target?: string;
  label?: string;
  iconBefore?: boolean;
  sourceHandle?: string;
  position?: Node['position'];
  onMenuOpenChange?: (open: boolean) => void;
};

/**
 * A button that lets you add a node as the target of a node.
 * Adding nodes between nodes is not implemented yet
 */
const AddNodeButton = ({
  source,
  target,
  label,
  sourceHandle,
  position,
  iconBefore,
  onMenuOpenChange,
}: AddNodeButtonProps) => {
  const intl = useIntl();
  const reactFlow = useReactFlow();
  const { newId } = useWorkflowId();
  const nodeTypeToName = useNodeTypeToName();
  const readonly = useIsPreview();

  const sourceNode = reactFlow.getNode(source);
  if (!sourceNode) throw new Error('Node not found');

  if (readonly) return null;

  const onAddNode: AddNodeFn = (type, data, options) => {
    const id = newId();
    trackAddWorkflowNode({ workflowId: id, type });

    let newEdges: Edge[] = [...reactFlow.getEdges()];
    const newNodes = [
      {
        id,
        type,
        data: {
          displayName: nodeTypeToName[type] ?? type,
          ...(data ?? {}),
        },
        position: position ?? {
          x: sourceNode.position.x,
          y: sourceNode.position.y + (sourceNode.measured?.height ?? 0) + 100,
        },
      },
      ...reactFlow.getNodes(),
    ];

    // Add an edge from the source to the new node
    newEdges.push({
      id: nanoid(),
      sourceHandle,
      source,
      target: id,
      type: 'smoothstep',
      markerEnd: {
        type: target ? MarkerType.Arrow : MarkerType.ArrowClosed,
      },
    });

    if (target && !options?.addAsBranch) {
      // Add an edge from the new node to the target
      newEdges.push({
        id: nanoid(),
        sourceHandle:
          type === 'Condition' || type === 'Confirmation' ? 'true' : undefined,
        source: id,
        target,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.Arrow,
        },
      });

      // Remove the existing edge from the source to the target
      newEdges = newEdges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );
    }

    let newNodePosition: { x: number; y: number } | undefined;
    // Only auto layout when adding a node between two nodes
    if (target) {
      const layouted = getLayoutedElements(newNodes, newEdges);
      reactFlow.setNodes(layouted.nodes);
      reactFlow.setEdges(layouted.edges);
      newNodePosition = layouted.nodes.find((n) => n.id === id)?.position;
    } else {
      reactFlow.setNodes(newNodes);
      reactFlow.setEdges(newEdges);
      newNodePosition = newNodes.find((n) => n.id === id)?.position;
    }

    reactFlow
      .setCenter(newNodePosition?.x ?? 0, newNodePosition?.y ?? 0, {
        duration: getPrefersReducedMotion() ? 0 : 500,
      })
      .catch((error) => {
        // biome-ignore lint: noConsole
        console.error('Failed to center on new node', error);
      });
  };

  const iconProps = {
    [iconBefore ? 'startIcon' : 'endIcon']: (
      <PlusIcon className="size-4 text-white" />
    ),
  };

  return (
    <Menu onOpenChange={onMenuOpenChange}>
      <Menu.Trigger>
        <Button
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Add workflow node button',
          })}
          size="small"
          {...iconProps}
        >
          {label}
        </Button>
      </Menu.Trigger>
      {target && (
        <>
          <Menu
            label={intl.formatMessage({ defaultMessage: 'New step' })}
            icon={<ArrowDownToDot className="size-4 text-slate-500" />}
          >
            <AddNodeMenuList
              source={source}
              options={{ addAsBranch: false }}
              onAddNode={onAddNode}
            />
          </Menu>
          <Menu
            label={intl.formatMessage({ defaultMessage: 'New branch' })}
            icon={<Split className="size-4 text-slate-500" />}
          >
            <AddNodeMenuList
              source={source}
              options={{ addAsBranch: true }}
              onAddNode={onAddNode}
            />
          </Menu>
        </>
      )}
      {!target && (
        <AddNodeMenuList
          source={source}
          options={{ addAsBranch: false }}
          onAddNode={onAddNode}
        />
      )}
    </Menu>
  );
};

export default AddNodeButton;
