import { enqueueSnackbar } from 'notistack';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import iconMSTeams from '../../img/msteams.png';
import { RootState } from '../../redux/store';
import { AlertExtensionIsNotInstalled } from './AlertExtensionIsNotInstalled';
import { AlertNeedMorePermissions } from './AlertNeedMorePermissions';
import { AlertReadyToUseInThisBrowser } from './AlertReadyToUseInThisBrowser';
import { OnboardingBlock } from './OnboardingBlock';
import { PermissionsGrantedStatus } from '../../redux/modules/global';
import { trackSetupHelperButton } from '../../helpers/analytics';
import { MainBlockTypes } from '.';

export const OnboardingMSTeams: React.FC<{
  isLarge: boolean;
  mainBlock: MainBlockTypes;
}> = ({ isLarge, mainBlock }) => {
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations?.arePermissionsGranted
  );
  const intl = useIntl();

  let webEnabled: boolean;
  let alert1: ReactNode;

  if (isExtensionUnavailable) {
    alert1 = <AlertExtensionIsNotInstalled />;
    webEnabled = false;
  } else if (arePermissionsGranted === PermissionsGrantedStatus.MISSING) {
    alert1 = <AlertNeedMorePermissions />;
    webEnabled = false;
  } else {
    alert1 = <AlertReadyToUseInThisBrowser />;
    webEnabled = true;
  }

  return (
    <OnboardingBlock
      isLarge={isLarge}
      icon={iconMSTeams}
      title="Microsoft Teams"
      alerts={[alert1]}
      button={
        <Button
          target="_blank"
          href="https://teams.microsoft.com/v2/#/calendarv2"
          onClick={() => {
            if (!webEnabled)
              enqueueSnackbar(
                intl.formatMessage({
                  defaultMessage: 'Please configure first',
                }),
                { variant: 'WARNING' }
              );
            trackSetupHelperButton('msteams', mainBlock);
          }}
        >
          Try in Microsoft Teams
        </Button>
      }
    />
  );
};
