import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/buttons';
import { createCheckoutSession_Personal_SwitchToPayByCard } from '../../graphql/billing';
import { kTeam } from '../../helpers/routes';
import { selectUserPlan } from '../../redux/selectors';
import { useBuyMultipleSeatsButton } from './Team/BuyMultipleSeats';
import shareImage from './share.svg';
import { UserPlus2 } from 'lucide-react';
import { DeadEnd } from '../../components/DeadEnd';

/**
 * Empty team placeholder component.
 */
export const EmptyTeamPlaceholder: React.FC = () => {
  const { button: multipleSeatsButton, modal: multipleSeatsModal } =
    useBuyMultipleSeatsButton();
  const userPlan = useSelector(selectUserPlan);
  const [isProcessing, setProcessing] = React.useState(false);

  const title = (
    <FormattedMessage
      defaultMessage="You don't have any members on your team yet 😢"
      description="Team transcripts page. No team members message."
    />
  );
  return userPlan.paid?.__typename === 'PaypalPaidPlan' ? (
    <DeadEnd
      imgSrc={shareImage}
      imgAlt="Illustration of a team"
      title={title}
      action={
        <div className="mx-auto max-w-2xl text-left">
          <Alert
            severity="info"
            action={
              <Button
                aria-label="Accept and move to pay by card page."
                loading={isProcessing}
                onClick={async () => {
                  if (!isProcessing) {
                    setProcessing(true);
                    try {
                      window.location.href =
                        await createCheckoutSession_Personal_SwitchToPayByCard(
                          {}
                        );
                    } finally {
                      setProcessing(false);
                    }
                  }
                }}
              >
                <FormattedMessage defaultMessage="Start a team" />
              </Button>
            }
            title={
              <FormattedMessage
                defaultMessage="You're currently using PayPal"
                id="7+34wi"
              />
            }
            description={
              <FormattedMessage defaultMessage="Tactiq team requires a pay by card subscription. Before you can switch to the team plan, you will be taken to the pay by card screen and then we will cancel your PayPal subscription." />
            }
          />
        </div>
      }
    />
  ) : (
    <DeadEnd
      imgSrc={shareImage}
      imgAlt="Illustration of a team"
      title={title}
      description={
        <FormattedMessage
          defaultMessage="Invite your colleagues or friends to start sharing your meetings. It's free!"
          description="Team transcripts page. No team members message."
        />
      }
      action={
        <>
          <Button
            startIcon={<UserPlus2 />}
            size="small"
            href={`/#${kTeam}/invite`}
          >
            <FormattedMessage
              defaultMessage="Invite members - it's free"
              description="Team transcripts page. Invite members button title."
            />
          </Button>
          {multipleSeatsButton}
          {multipleSeatsModal}
        </>
      }
    />
  );
};
