import { useMutation } from '@apollo/client';
import { AutoJoinDomain } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/buttons';
import { Switch } from '../../../components/buttons/Switch';
import { Link } from '../../../components/Link';
import { Radio, RadioGroup } from '../../../components/Radio';
import { Select } from '../../../components/Select';
import { EditableText } from '../../../components/TextInput/EditableText';
import {
  TeamTier,
  UpdateDisplayNameDocument,
  UpdateTeamSetting_EnableEmailLinkSignInDocument,
  UpdateTeamSetting_EnableLlmDocument,
  UpdateTeamSetting_EnableTeamInviteDocument,
  UpdateTeamSetting_EnableTranscriptionNotificationDocument,
  UpdateTeamSetting_ResetMeetingsRetentionPeriodDocument,
  UpdateTeamSetting_SetMeetingsRetentionPeriodDocument,
} from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackWebEvent,
  trackWebPage,
} from '../../../helpers/analytics';
import featureFlags from '../../../helpers/feature-flags';
import { gotTeam } from '../../../redux/modules/user';
import {
  selectTeam,
  selectUid,
  selectUserTier,
  useTeamDomains,
} from '../../../redux/selectors';
import { useUpdateAutoJoinSettings } from '../../../services/Team';
import { SettingsRow } from '../../Common/SettingsRow';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { TeamAuthenticationSettings } from './TeamAuthenticationSettings';
import { UserCannotChangeSettings } from './UserCannotChangeSettings';

const InfoAlert: React.FC<{
  isTeam: boolean;
  isPaidTeam: boolean;
  isAdmin: boolean;
  upgradeAction: () => void;
}> = ({ isTeam, isPaidTeam, isAdmin, upgradeAction }) => {
  if (!isTeam) {
    // Not in team -> Settings disabled + encourage alert to create paid team
    return (
      <Alert
        variant="light"
        severity="upgrade"
        description={
          <FormattedMessage defaultMessage="These settings are only available for paid team plans. You will need to create a paid team to use this feature." />
        }
        upgradeAction={() => upgradeAction()}
      />
    );
  }
  if (isTeam && !isPaidTeam) {
    // Not in paid team -> Settings disabled + encourage alert to upgrade to paid team
    return (
      <Alert
        variant="light"
        severity="upgrade"
        description={
          <FormattedMessage defaultMessage="If you would like to activate this feature you will need to upgrade to a paid team." />
        }
        upgradeAction={() => upgradeAction()}
      />
    );
  }
  if (isPaidTeam && !isAdmin) {
    // Not an admin -> Settings disabled + encourage alert to ask admin to change settings
    return (
      <Alert
        variant="light"
        severity="locked"
        description={
          <FormattedMessage defaultMessage="The settings on this page are controlled by your team admin. If you would like a setting changed you will need to contact them." />
        }
      />
    );
  }
  // In paid team and admin -> Settings enabled
  if (isPaidTeam && isAdmin) {
    return (
      <Alert
        variant="light"
        severity="info"
        title="Ahoy captain!"
        description={
          <FormattedMessage defaultMessage="Changes you make on this page will apply to all members of your team." />
        }
      />
    );
  }
  return null;
};

const RetentionSettings: React.FC = () => {
  const intl = useIntl();
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;

  const dispatch = useDispatch();

  const [
    UpdateTeamSetting_SetMeetingsRetentionPeriod,
    UpdateTeamSetting_SetMeetingsRetentionPeriodMutation,
  ] = useMutation(UpdateTeamSetting_SetMeetingsRetentionPeriodDocument);

  const [
    UpdateTeamSetting_ResetMeetingsRetentionPeriod,
    UpdateTeamSetting_ResetMeetingsRetentionPeriodMutation,
  ] = useMutation(UpdateTeamSetting_ResetMeetingsRetentionPeriodDocument);

  if (!team?.plan?.isCustomContract) return null;

  const retentionDays = team?.settings.meetingsRetentionPeriodDays;
  const isRetentionEnabled = !!retentionDays;

  const isLoading =
    UpdateTeamSetting_SetMeetingsRetentionPeriodMutation.loading ||
    UpdateTeamSetting_ResetMeetingsRetentionPeriodMutation.loading;

  const DAYS_IN_A_MONTH = 30;
  const PossibleMonths = [...Array(55).keys()].map((i) => 6 + i); // 6 months to 5 years

  return (
    <>
      <SettingsRow
        settingInfo={{
          title: 'Use default data retention settings',
          description: (
            <FormattedMessage defaultMessage="By default, transcripts of all members of your team are kept forever." />
          ),
        }}
        settingAction={
          <Switch
            disabled={isLoading}
            isOn={!isRetentionEnabled}
            onClick={async () => {
              if (!isAdmin) {
                UserCannotChangeSettings(intl);
              } else {
                if (isRetentionEnabled) {
                  // disable retention
                  trackWebEvent(
                    'Settings - Team - Toggled Use default data retention settings'
                  );
                  dispatch(
                    gotTeam(
                      (
                        await UpdateTeamSetting_ResetMeetingsRetentionPeriod({
                          variables: {},
                        })
                      ).data?.UpdateTeamSetting_ResetMeetingsRetentionPeriod
                    )
                  );
                } else {
                  // enable retention, set to default 1 year
                  trackWebEvent(
                    'Settings - Team - Toggled Off use default data retention settings'
                  );
                  dispatch(
                    gotTeam(
                      (
                        await UpdateTeamSetting_SetMeetingsRetentionPeriod({
                          variables: {
                            input: {
                              value: 36 * DAYS_IN_A_MONTH,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_SetMeetingsRetentionPeriod
                    )
                  );
                }
              }
            }}
          />
        }
      />
      {isRetentionEnabled ? (
        <SettingsRow
          settingInfo={{
            title: 'Use custom retention period',
            description: (
              <>
                <FormattedMessage defaultMessage="Archive all transcripts of all members of your team after a set amount of time." />
                {retentionDays < DAYS_IN_A_MONTH * 12 ? (
                  <div className="mt-2">
                    <Alert
                      severity="warning"
                      description="You have a short retention period that may negatively impact your team members."
                    />
                  </div>
                ) : null}
              </>
            ),
          }}
          settingAction={
            <Select
              value={retentionDays}
              disabled={isLoading}
              onChange={async (value) => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                  return;
                }
                trackWebEvent('Settings - Custom retention value selected', {
                  value,
                });
                dispatch(
                  gotTeam(
                    (
                      await UpdateTeamSetting_SetMeetingsRetentionPeriod({
                        variables: {
                          input: {
                            value,
                          },
                        },
                      })
                    ).data?.UpdateTeamSetting_SetMeetingsRetentionPeriod
                  )
                );
              }}
              options={PossibleMonths.map((month) => ({
                value: month * DAYS_IN_A_MONTH,
                label: intl.formatMessage(
                  {
                    defaultMessage: '{month} months',
                  },
                  { month }
                ),
              }))}
            />
          }
        />
      ) : null}
    </>
  );
};

export const TeamSettings: React.FC = () => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const isPaidTeam = team?.isPaid;
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Team Settings');
  }, []);

  const [
    updateTeamSetting_EnableEmailLinkSignIn,
    updateTeamSetting_EnableEmailLinkSignInMutation,
  ] = useMutation(UpdateTeamSetting_EnableEmailLinkSignInDocument);

  const [updateTeamSetting_EnableLLM, updateTeamSetting_EnableLLMMutation] =
    useMutation(UpdateTeamSetting_EnableLlmDocument);

  const [
    updateTeamSetting_EnableTeamInvite,
    updateTeamSetting_EnableTeamInviteMutation,
  ] = useMutation(UpdateTeamSetting_EnableTeamInviteDocument);

  const [
    updateTeamSetting_EnableTranscriptionNotification,
    updateTeamSetting_EnableTranscriptionNotificationMutation,
  ] = useMutation(UpdateTeamSetting_EnableTranscriptionNotificationDocument);

  const [
    {
      loading: isAutoJoinSettingsLoading,
      error: autoJoinError,
      isInvitationOnly,
    },
    onUpdateAutoJoinSettings,
  ] = useUpdateAutoJoinSettings();

  const [updateDisplayName] = useMutation(UpdateDisplayNameDocument);
  const teamDomains = useSelector(useTeamDomains);
  const shouldShowListOfDomains =
    team?.settings.autoJoinDomain ===
      AutoJoinDomain.DISCOVERABLE_AUTOMATIC_WITH_DOMAIN ||
    team?.settings.autoJoinDomain ===
      AutoJoinDomain.DISCOVERABLE_OPEN_WITH_DOMAIN;
  const isEnterpriseTeam = team?.tier === TeamTier.ENTERPRISE;
  const isMoreThanOneDomainOwned = teamDomains.length > 1;

  const isTeamAdvancedAuthEnabled = featureFlags.isTeamAdvancedAuthEnabled();

  useEffect(() => {
    if (autoJoinError) {
      enqueueSnackbar(autoJoinError.message, { variant: 'ERROR' });
    }
  }, [autoJoinError]);

  return (
    <div className="flex flex-col">
      <div className="my-4">
        <InfoAlert
          isAdmin={isAdmin}
          isPaidTeam={isPaidTeam ?? false}
          isTeam={!!team}
          upgradeAction={() => setShowTierPricingDialog(true)}
        />
      </div>
      <ul className="flex flex-col divide-y divide-slate-100">
        {!isTeamAdvancedAuthEnabled && (
          <SettingsRow
            settingInfo={{
              title: intl.formatMessage({
                defaultMessage: 'Enable sign in with email magic link',
              }),
              description: (
                <FormattedMessage defaultMessage="This will allow your team members to sign in to Tactiq by getting an email with a one-time link." />
              ),
            }}
            requiresUpgrade={{
              condition: !isPaidTeam,
              reason: 'Needs team plan',
              action: () => setShowTierPricingDialog(true),
              chipMessage: 'Team',
            }}
            settingAction={
              <Switch
                disabled={
                  updateTeamSetting_EnableEmailLinkSignInMutation.loading
                }
                isOn={team?.settings.enableEmailLinkSignIn}
                onClick={async () => {
                  if (!isAdmin) {
                    UserCannotChangeSettings(intl);
                  } else {
                    trackWebEvent(
                      'Settings - Team - Toggled Enable Email Link Sign In',
                      {
                        enabled: !team?.settings.enableEmailLinkSignIn,
                      }
                    );
                    dispatch(
                      gotTeam(
                        (
                          await updateTeamSetting_EnableEmailLinkSignIn({
                            variables: {
                              input: {
                                value: !team?.settings.enableEmailLinkSignIn,
                              },
                            },
                          })
                        ).data?.UpdateTeamSetting_EnableEmailLinkSignIn
                      )
                    );
                  }
                }}
              />
            }
          />
        )}

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Enable AI features (Large Language Models)',
            }),
            description: (
              <FormattedMessage
                defaultMessage="If disabled, this overrides the settings of the individual team members. This gives you access to AI-generated summaries, Meeting Kits, and more. We use OpenAI GPT API for generation. They do not store your data and do not use it to improve their models. Read more at {link}"
                values={{
                  link: (
                    <Link blue target="_blank" to="https://tactiq.io/gpt">
                      https://tactiq.io/gpt
                    </Link>
                  ),
                }}
              />
            ),
          }}
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
            chipMessage: 'Team',
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_EnableLLMMutation.loading}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent('Settings - Team - Toggled Enable LLM', {
                    enabled: !team?.settings.enableLLM,
                  });
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableLLM({
                          variables: {
                            input: {
                              value: !team?.settings.enableLLM,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_EnableLLM
                    )
                  );
                }
              }}
              isOn={team?.settings.enableLLM}
            />
          }
        />
        <SettingsRow
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
            chipMessage: 'Team',
          }}
          settingInfo={{
            showLock: isPaidTeam && !isAdmin,
            title: intl.formatMessage({
              defaultMessage: 'Team sign-up mode',
            }),
            description: (
              <RadioGroup
                disabled={
                  (isEnterpriseTeam && team.settings.advancedAuthEnabled) ||
                  isMoreThanOneDomainOwned ||
                  isAutoJoinSettingsLoading ||
                  !team
                }
                name="team-sign-up-mode"
                value={team?.settings.autoJoinDomain}
                onChange={async (value) => {
                  if (!isAdmin) {
                    UserCannotChangeSettings(intl);
                  } else if (!isPaidTeam) {
                    setShowTierPricingDialog(true);
                  } else {
                    trackWebEvent('Settings - Team - Changed sign-up mode', {
                      value: value,
                    });
                    await onUpdateAutoJoinSettings(value);
                  }
                }}
                className="flex flex-col gap-2 text-black"
              >
                <Radio
                  key={1}
                  disabled={!team}
                  value={AutoJoinDomain.INVITATION_ONLY}
                  label={intl.formatMessage({
                    defaultMessage: 'Invitation only',
                  })}
                  description={intl.formatMessage({
                    defaultMessage: 'New members must be invited',
                  })}
                />
                {!isInvitationOnly && (
                  <Radio
                    key={2}
                    disabled={!team}
                    value={AutoJoinDomain.DISCOVERABLE_OPEN_WITH_DOMAIN}
                    label={intl.formatMessage({
                      defaultMessage:
                        'Discoverable and open to anyone with a certain domain',
                    })}
                    description={intl.formatMessage({
                      defaultMessage:
                        'New members from these domains are prompted to join',
                    })}
                  />
                )}
                {!isInvitationOnly && (
                  <Radio
                    key={3}
                    disabled={!team}
                    value={AutoJoinDomain.DISCOVERABLE_AUTOMATIC_WITH_DOMAIN}
                    label={intl.formatMessage({
                      defaultMessage:
                        'Discoverable and automatic for anyone with a certain domain',
                    })}
                    description={intl.formatMessage({
                      defaultMessage:
                        'New members from these domains will join the team automatically',
                    })}
                  />
                )}
              </RadioGroup>
            ),
          }}
          settingAction={
            shouldShowListOfDomains && (
              <div className="flex justify-end">
                <div className="mt-1 text-right text-slate-500 text-sm leading-5">
                  {teamDomains && teamDomains.length > 0 ? (
                    teamDomains.map((domain, index) => (
                      <div key={index} className="mb-2">
                        {domain}
                      </div>
                    ))
                  ) : (
                    <div className="mb-2">
                      <FormattedMessage defaultMessage="No domains yet configured." />
                    </div>
                  )}
                  <div className="mt-2">
                    <Button
                      upgrade={!isEnterpriseTeam}
                      variant="neutral-secondary"
                      onClick={() => {
                        if (isEnterpriseTeam) {
                          enqueueSnackbar(
                            intl.formatMessage({
                              defaultMessage:
                                'To configure your domains, please reach out to your customer success representative.',
                            }),
                            { variant: 'WARNING' }
                          );
                        } else {
                          enqueueSnackbar(
                            intl.formatMessage({
                              defaultMessage:
                                'Adding more domains is available on Enterprise plans only. Contact sales for more info.',
                            }),
                            { variant: 'WARNING' }
                          );
                        }
                      }}
                    >
                      <FormattedMessage defaultMessage="Add More Domains" />
                    </Button>
                  </div>
                </div>
              </div>
            )
          }
        />

        <SettingsRow
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
            chipMessage: 'Team',
          }}
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Restrict Non-Admin Invitations',
            }),
            description: (
              <FormattedMessage defaultMessage="Enable this setting to limit the ability to invite new members to the team exclusively to admin users." />
            ),
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_EnableTeamInviteMutation.loading}
              isOn={team?.settings.enableTeamInvite}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent(
                    'Settings - Team - Toggled Allow allow team invite',
                    {
                      enabled: team?.settings.enableTeamInvite,
                    }
                  );
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableTeamInvite({
                          variables: {
                            input: {
                              value: !team?.settings.enableTeamInvite,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_EnableTeamInvite
                    )
                  );
                }
              }}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'In-meeting transcription notification',
            }),
            description: (
              <FormattedMessage defaultMessage="Enforce chat notifications for all team members during meeting transcriptions. This team-wide setting overrides individual notification preferences." />
            ),
          }}
          settingAction={
            <Switch
              disabled={
                updateTeamSetting_EnableTranscriptionNotificationMutation.loading
              }
              isOn={team?.settings.enableTranscriptionNotification}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent('Settings - Team - Chat Notification', {
                    enabled: !team?.settings.enableTranscriptionNotification,
                  });
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableTranscriptionNotification(
                          {
                            variables: {
                              input: {
                                value:
                                  !team?.settings
                                    .enableTranscriptionNotification,
                              },
                            },
                          }
                        )
                      ).data?.UpdateTeamSetting_EnableTranscriptionNotification
                    )
                  );
                }
              }}
            />
          }
        />

        <RetentionSettings />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Change display name',
            }),
            description: intl.formatMessage({
              defaultMessage: 'Change your team name as it appears in Tactiq',
            }),
          }}
          settingAction={
            <EditableText
              value={team ? team.displayName : ''}
              onClick={() => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                  return false;
                }
                return true;
              }}
              onSave={async (newName: string) => {
                if (!team) {
                  return;
                }
                const oldName = team.displayName;
                dispatch(gotTeam({ ...team, displayName: newName }));
                try {
                  const response = await updateDisplayName({
                    variables: {
                      input: {
                        value: newName,
                      },
                    },
                  });
                  dispatch(gotTeam(response.data?.team_updateDisplayName));
                } catch (_) {
                  dispatch(gotTeam({ ...team, displayName: oldName }));
                }
              }}
              isValid={(s: string) => Boolean(s.trim())}
              errorLabel={intl.formatMessage({
                defaultMessage: 'Display name can not be empty',
              })}
            />
          }
        />

        <TeamAuthenticationSettings />
      </ul>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.TEAM_SETTINGS}
          onClose={() => setShowTierPricingDialog(false)}
          teamSpecific
        />
      )}
    </div>
  );
};
