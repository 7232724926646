import { useMutation, useQuery } from '@apollo/client';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { Chip } from '../../../components/Chips';
import { ModalDialog } from '../../../components/modals';
import { TextInput } from '../../../components/TextInput';
import {
  ApplyTeamAuthConfiguration_OktaOidcDocument,
  GetTeamAuthConfigurationDocument,
  Team,
  UpdateTeamAuthConfiguration_OktaOidcDocument,
} from '../../../graphql/operations';
import AsyncButton from '../../../components/buttons/AsyncButton';
import { useDispatch } from 'react-redux';
import { gotTeam } from '../../../redux/modules/user';

export const OktaOIDCConfigurationDialog: React.FC<{
  team: Team;
  onClose: () => void;
}> = ({ team, onClose }) => {
  const dispatch = useDispatch();

  const [oktaDomain, setOktaDomain] = React.useState<string>('');
  const [clientId, setClientId] = React.useState<string>('');
  const [clientSecret, setClientSecret] = React.useState<string>('');

  const configQuery = useQuery(GetTeamAuthConfigurationDocument, {
    fetchPolicy: 'network-only',
    onCompleted(newData) {
      setOktaDomain(
        configQuery.data?.teamAuthConfiguration.oktaOidc?.incoming?.domain ??
          newData.teamAuthConfiguration.oktaOidc?.incoming?.domain ??
          ''
      );
      setClientId(
        configQuery.data?.teamAuthConfiguration.oktaOidc?.incoming?.clientId ??
          newData.teamAuthConfiguration.oktaOidc?.incoming?.clientId ??
          ''
      );
      setClientSecret(
        configQuery.data?.teamAuthConfiguration.oktaOidc?.incoming
          ?.clientSecret ??
          newData.teamAuthConfiguration.oktaOidc?.incoming?.clientSecret ??
          ''
      );
    },
  });

  const config = configQuery.data?.teamAuthConfiguration.oktaOidc;

  const [
    UpdateTeamAuthConfiguration_OktaOIDC,
    UpdateTeamAuthConfiguration_OktaOIDCMutation,
  ] = useMutation(UpdateTeamAuthConfiguration_OktaOidcDocument);

  const [
    ApplyTeamAuthConfiguration_OktaOIDC,
    ApplyTeamAuthConfiguration_OktaOIDCMutation,
  ] = useMutation(ApplyTeamAuthConfiguration_OktaOidcDocument);

  const isLoading =
    configQuery.loading ||
    UpdateTeamAuthConfiguration_OktaOIDCMutation.loading ||
    ApplyTeamAuthConfiguration_OktaOIDCMutation.loading;

  const isIncomingChanged =
    config?.incoming?.domain !== oktaDomain ||
    config?.incoming?.clientId !== clientId ||
    config?.incoming?.clientSecret !== clientSecret;

  const canValidate = !!oktaDomain && !!clientId && !!clientSecret;
  const canApply = config?.incoming?.validated && !isIncomingChanged;

  return (
    <ModalDialog
      open
      size="medium"
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      title={
        <div className="flex flex-row gap-4">
          <FormattedMessage defaultMessage="Okta OIDC" />
          {isLoading ? <Loader2 className="animate-spin" /> : null}
        </div>
      }
      text={
        <div className="flex flex-col gap-4">
          <h2 className="font-semibold text-lg">Current configuration</h2>
          {!config?.current && (
            <div>
              <Chip color="yellow">Not configured</Chip>
            </div>
          )}
          {config?.current && (
            <>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">Okta Domain</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.domain}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">Client ID</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.clientId}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow whitespace-nowrap">Client Secret</div>
                <div className="max-w-[60%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">
                  {config.current.clientSecret.substring(0, 4)}
                  {config.current.clientSecret.substring(5).replace(/./g, '*')}
                </div>
              </div>
            </>
          )}
          <h2 className="font-semibold text-lg">New configuration</h2>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Okta Domain</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput
                required
                value={config?.incoming?.domain ?? ''}
                onChange={setOktaDomain}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Client ID</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput
                required
                value={config?.incoming?.clientId ?? ''}
                onChange={setClientId}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">Client Secret</div>
            <div className="max-w-[60%] flex-shrink flex-grow">
              <TextInput
                type="password"
                required
                value={config?.incoming?.clientSecret ?? ''}
                onChange={setClientSecret}
              />
            </div>
          </div>
          <div>
            {config?.incoming?.validated ? (
              <Chip color="green">Validated, can be applied</Chip>
            ) : (
              <Chip color="yellow">Not validated</Chip>
            )}
          </div>
          <div className="flex flex-row items-center gap-4">
            {(canValidate || canApply) && (
              <AsyncButton
                disabled={isLoading}
                loading={UpdateTeamAuthConfiguration_OktaOIDCMutation.loading}
                onClick={async () => {
                  const result = await UpdateTeamAuthConfiguration_OktaOIDC({
                    variables: {
                      input: {
                        domain: oktaDomain,
                        clientId,
                        clientSecret,
                      },
                    },
                  });

                  const redirectUrl =
                    result.data?.UpdateTeamAuthConfiguration_OktaOIDC;
                  if (redirectUrl) {
                    window.location.href = redirectUrl;
                  }
                }}
                variant={canApply ? 'neutral-secondary' : 'neutral-primary'}
              >
                {canApply ? (
                  <FormattedMessage defaultMessage="Validate again" />
                ) : (
                  <FormattedMessage defaultMessage="Validate" />
                )}
              </AsyncButton>
            )}

            {canApply ? (
              <AsyncButton
                disabled={isLoading}
                loading={ApplyTeamAuthConfiguration_OktaOIDCMutation.loading}
                onClick={async () => {
                  dispatch(
                    gotTeam(
                      (await ApplyTeamAuthConfiguration_OktaOIDC()).data
                        ?.ApplyTeamAuthConfiguration_OktaOIDC
                    )
                  );
                  configQuery.refetch();
                }}
                variant="neutral-primary"
              >
                <FormattedMessage defaultMessage="Apply" />
              </AsyncButton>
            ) : null}
          </div>
        </div>
      }
      actions={
        <div className="flex flex-row items-center justify-end gap-2">
          <Button onClick={onClose} variant="neutral-secondary">
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </div>
      }
    />
  );
};
