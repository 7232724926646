import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import { Combobox as Combo } from '@headlessui/react';
import { Check, ChevronDown } from 'lucide-react';
import { cx, usePortalRoot } from '../../helpers/utils';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  FloatingPortal,
} from '@floating-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';
import { enqueueSnackbar } from 'notistack';
import { Chip } from '../Chips';

type Props<T> = {
  label?: React.ReactNode;
  options: T[];
  /** How to uniquely identify each option */
  id: (item: T) => string;
  /** A nice string representation of each option */
  name: (item: T) => string;
  icon?: ReactNode;
  renderOption?: (item: T) => ReactNode;
  renderValue?: (item: T) => ReactNode;
  /** If the onRemove callback is provided, when the Combo has a value it will display a clear button instead of the downward chevron. */
  onRemove?: () => void;
  multiple?: boolean;
  loading?: boolean;
  /** Keeps the selected items in the list and shows a "✔" next to them, defaults to `false` */
  keepSelected?: boolean;
  /** Highlights the combobox when it has something selected, defaults to `true` */
  highlightWhenSelected?: boolean;
  placeholder?: string;
  /** No limit on width */
  full?: boolean;
  disabled?: boolean;
  variant?: 'default' | 'naked';
  size?: 'xs' | 'sm' | 'md';
  buttonClasses?: string;
  /** Width of the options dropdown */
  optionsWidth?: string;
  /** Use freeSolo - meaning that you can add the data regardless if it's within the options list */
  freeSolo?: boolean;
  /** Validate freeSolo input */
  validateFreeSolo?: (query: string) => boolean;
};

type SingleProps<T> = Props<T> & {
  multiple: false;
  onChange: (next: T | null) => void;
  value: T | undefined;
};

type MultiProps<T> = Props<T> & {
  multiple: true;
  value: T[];
  onChange: (next: T[]) => void;
};

/**
 * A searchable multi select
 */
export const Autocomplete = <T = { id: string; name: string }>(
  props: SingleProps<T> | MultiProps<T>
): ReactElement => {
  const {
    options,
    name,
    id,
    keepSelected = false,
    placeholder,
    disabled,
    freeSolo,
    validateFreeSolo,
  } = props;
  const root = usePortalRoot();

  const intl = useIntl();
  // Coerce single value to an array for simpler handling
  const value = ([] as T[]).concat(props.value ?? []);
  const hasItems = value.length > 0;
  const ids = new Set(value.map((ii) => id(ii)));
  const [query, setQuery] = useState('');
  const filteredOptions = options.filter((item) =>
    keepSelected
      ? item
      : !ids.has(id(item)) &&
        name(item).toLowerCase().includes(query.toLowerCase())
  );
  const renderOption = props.renderOption ?? ((x: T) => name(x));

  // Actions
  const removeItem = (next: string) => {
    if (props.multiple) {
      props.onChange(value.filter((_) => id(_) !== next));
    } else {
      props.onChange(null);
    }
  };

  // Floating bits
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const popItem = () => {
    if (props.multiple) {
      props.onChange(value.slice(0, -1));
    }
  };

  const addFreeSoloItem = () => {
    if (validateFreeSolo && !validateFreeSolo(query)) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Please enter a valid input',
        }),
        {
          variant: 'WARNING',
          autoHideDuration: 5000,
        }
      );
      return;
    }
    const newItem = { id: `new-${Date.now()}`, name: query } as T;
    if (props.multiple) {
      props.onChange([...value, newItem]);
    } else {
      props.onChange(newItem);
    }
    setQuery('');
  };

  const optionsContent = (): ReactNode => {
    if (filteredOptions.length === 0 && freeSolo && query) {
      return (
        <button
          type="button"
          className="cursor-pointer px-1 py-1 text-neutral-tertiary text-sm hover:bg-neutral-primary"
          onClick={() => {
            addFreeSoloItem();
          }}
        >
          Add "{query}"
        </button>
      );
    }

    if (filteredOptions.length === 0) {
      return (
        <span className="px-1 text-slate-500 text-sm">
          <FormattedMessage defaultMessage="No available options" />
        </span>
      );
    }

    return filteredOptions.map((item) => (
      <Combo.Option key={id(item)} value={item} as={Fragment}>
        {({ active }) => (
          <li
            className={cx(
              'flex cursor-pointer appearance-none items-center gap-2 overflow-hidden truncate rounded px-2 py-1 text-left text-sm',
              active ? 'bg-slate-100' : '',
              'disabled:cursor-default'
            )}
          >
            <span className="flex-1 truncate">{renderOption(item)}</span>
            {keepSelected && ids.has(id(item)) && <Check className="h-4 w-4" />}
          </li>
        )}
      </Combo.Option>
    ));
  };

  return (
    <Combo
      value={props.value}
      onChange={props.onChange}
      disabled={disabled}
      immediate
      // @ts-expect-error the headlessui types are too correct to be practical
      multiple={props.multiple}
    >
      <div className="flex flex-col">
        <div
          className={cx(
            'flex flex-row items-center rounded-lg border border-slate-200 bg-white',
            props.full ? 'max-w-full' : 'max-w-96'
          )}
          ref={(ref) => {
            refs.setReference(ref);
          }}
          style={props.optionsWidth ? { width: props.optionsWidth } : {}}
        >
          {/* Wrapper for chips and input */}
          <div className="flex w-full flex-wrap items-center gap-2 p-2 text-xs">
            {props.multiple && props.value?.length
              ? props.value.map((x) => (
                  <Chip key={name(x)} onDelete={() => removeItem(id(x))}>
                    {name(x)}
                  </Chip>
                ))
              : null}

            <Combo.Input
              className="min-w-[10rem] flex-grow border-none px-2 py-1 text-sm focus:outline-none"
              value={query}
              onFocus={(e) => {
                // This is a hack to make the dropdown open when the input is focused
                e.target.dispatchEvent(
                  new KeyboardEvent('keydown', {
                    key: 'ArrowDown',
                    code: 'ArrowDown',
                    bubbles: true,
                  })
                );
              }}
              placeholder={!hasItems ? placeholder : ''}
              onChange={(event) => setQuery(event.target.value)}
              onKeyDown={(event) => {
                if (event.code === 'Backspace' && query === '') popItem();
                if (event.code === 'Enter' && query) {
                  event.preventDefault();
                  addFreeSoloItem();
                }
              }}
            />
          </div>

          <Combo.Button
            className={cx(
              'ml-auto h-8 cursor-pointer items-center gap-2 rounded-button px-2 py-0',
              props.buttonClasses
            )}
          >
            <ChevronDown size="1rem" />
          </Combo.Button>
        </div>
        <FloatingPortal root={root}>
          <Combo.Options
            className={cx(
              'z-Combobox max-h-[30vh] overflow-auto rounded-lg border bg-white p-2 shadow-slate-600/20 shadow-xl',
              props.full ? 'max-w-full' : 'max-w-96'
            )}
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              width: props.optionsWidth || '16rem',
            }}
          >
            {optionsContent()}
          </Combo.Options>
        </FloatingPortal>
      </div>
    </Combo>
  );
};
