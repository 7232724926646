import React, { ReactElement, ReactNode, useState } from 'react';
import { ConfirmActionDialog } from '../../../components/modals';
import { FormattedMessage, useIntl } from 'react-intl';
import { Team, TeamMemberStatus } from '../../../graphql/operations';
import { useSpaceList, useSpaceUpdateOwner } from '../../../services/Space';
import { Avatar } from '../../../components/Avatar';
import { labelBaseClasses } from '../../../components/TextInput';
import { Select } from '../../../components/Select';
import {
  trackAdminRemovedAndAssigned,
  trackAdminRemovedThemself,
  trackAdminRemoveThemselfCancelledInvitations,
  trackTeamMemberRemoved,
  trackUpdateSpaceOwner,
} from '../../../helpers/analytics';
import {
  useAssignNewAdmin,
  useRemoveInvitation,
  useRemoveTeamMember,
} from '../../../services/Team';
import { Alert } from '../../../components/Alert';
import { Spinner } from '../../../components/Spinner';
import { useCurrentUserId } from '../../../services/User';

/**
 * Before a member can leave a team we need to check if they have any spaces
 * that need new owners. And if they are the only teamAdmin. These things
 * need to be handed off to someone else. This is a quick get out, if they need
 * to do a more complicated hadn over, they can cancel out and do the steps one at a time.
 */
export function RemoveMemberDialog(props: {
  /** This is a subset of member, because sometimes we need to pass in user data */
  member: { uid: string; displayName: string; email: string };
  team: Team;
  onClose: () => void;
  onSuccess?: () => void;
}): ReactElement {
  const intl = useIntl();
  const { onClose, onSuccess, team, member } = props;
  const currentUserId = useCurrentUserId();
  const isCurrentUser = member.uid === currentUserId;
  const [loading, setLoading] = useState(false);
  // The person the spaces/teamAdmin role are being handed over to
  const [newUser, setNewUser] = useState<string>();
  const teamSpaces = useSpaceList({
    teamId: isCurrentUser ? undefined : team.id,
    refetch: true,
  });
  const updateSpaceOwner = useSpaceUpdateOwner();

  // badSpaces are those with teamIds and
  // no other managers except the user who is getting removed
  const badSpaces = (teamSpaces.data?.spaces ?? []).filter(
    (space) =>
      space.teamId &&
      (space.members ?? []).filter((m) => m.permissions.MANAGE).length &&
      (space.members ?? [])
        .filter((m) => m.permissions.MANAGE)
        .every((m) => m.uid === member.uid)
  );

  const copy = useCopy({
    isCurrentUser,
    teamName: team.displayName ?? '',
    memberName: member.displayName,
    badSpaces: badSpaces.map((s) => s.name).join(', '),
  });
  const removeInvitation = useRemoveInvitation();
  const assignNewAdmin = useAssignNewAdmin();
  const removeMember = useRemoveTeamMember({
    userId: currentUserId,
    teamId: team.id,
  });

  const hasNoOtherRealMembers = !team.members.filter(
    (m) => m.status !== TeamMemberStatus.PENDING && m.uid !== member.uid
  ).length;

  const teamHasOtherAdmins =
    team?.members.some(
      (m) => m.uid !== member.uid && m.roles.ADMIN && !m.deactivated
    ) ?? false;

  const needNewTeamAdmin = !teamHasOtherAdmins && !hasNoOtherRealMembers;
  const hasBadSpaces = badSpaces.length > 0;
  const needsNewOwner = hasBadSpaces && needNewTeamAdmin;

  // The main logic for when a the final button is clicked
  async function onRemoveMember() {
    if (!newUser && needsNewOwner) return;
    const newSpaceOwners = newUser
      ? badSpaces.map((space) => ({
          spaceId: space.id,
          createdBy: newUser,
        }))
      : [];

    if (needNewTeamAdmin && newUser) {
      trackAdminRemovedAndAssigned();
      await assignNewAdmin(newUser);
    }

    if (newSpaceOwners.length) {
      trackUpdateSpaceOwner(newSpaceOwners.map((i) => i.spaceId));
      await updateSpaceOwner.request({ input: newSpaceOwners });
    }

    if (isCurrentUser) {
      if (teamHasOtherAdmins || (!team.isPaid && team.members.length === 1)) {
        trackAdminRemovedThemself();
      } else if (!teamHasOtherAdmins && hasNoOtherRealMembers) {
        trackAdminRemoveThemselfCancelledInvitations();
        await Promise.all(
          team.members
            .filter((m) => m.status === TeamMemberStatus.PENDING)
            .map(removeInvitation)
        );
      }
    } else {
      trackTeamMemberRemoved();
    }

    return removeMember(member);
  }

  const yesDisabled = teamSpaces.loading || (badSpaces.length > 0 && !newUser);

  const labelCopy =
    hasBadSpaces && needNewTeamAdmin ? (
      <FormattedMessage defaultMessage="New admin & space owner" />
    ) : needNewTeamAdmin ? (
      <FormattedMessage defaultMessage="New admin" />
    ) : (
      <FormattedMessage defaultMessage="New owner" />
    );

  function dialogBody() {
    if (teamSpaces.loading) return <Spinner className="mx-auto my-3" />;
    if (!hasBadSpaces && !needNewTeamAdmin) return copy.simpleBody;

    return (
      <div className="flex flex-col gap-3">
        {needNewTeamAdmin && (
          <Alert
            variant="light"
            severity="warning"
            description={copy.teamAdminWarning}
          />
        )}
        {hasBadSpaces && (
          <Alert
            variant="light"
            severity="warning"
            description={copy.spacesWarning}
          />
        )}
        <div>
          <label className={labelBaseClasses}>{labelCopy}</label>
          <Select
            value={newUser}
            onChange={setNewUser}
            full={true}
            options={(
              team?.members.filter((m) => m.uid !== member.uid) || []
            ).map((m) => ({
              value: m.uid,
              label: `${m.displayName} (${m.email})`,
              icon: (
                <Avatar
                  src={m.photoURL ?? ''}
                  size="sm"
                  className="size-3 flex-shrink-0"
                  name={m.displayName}
                />
              ),
            }))}
            placeholder={intl.formatMessage({
              defaultMessage: 'e.g Sarah, mike@email.com',
            })}
          />
        </div>
      </div>
    );
  }

  return (
    <ConfirmActionDialog
      open={true}
      title={copy.title}
      text={dialogBody()}
      yes={copy.action}
      yesProps={{
        loading,
        disabled: yesDisabled,
        className: yesDisabled ? 'opacity-80' : '',
        color: 'error',
      }}
      onYes={async () => {
        setLoading(true);
        await onRemoveMember();
        setLoading(false);
        onSuccess?.();
        onClose();
      }}
      onNo={onClose}
    />
  );
}

function useCopy(input: {
  isCurrentUser: boolean;
  teamName: string;
  memberName: string;
  badSpaces: string;
}): {
  title: ReactNode;
  simpleBody: ReactNode;
  teamAdminWarning: ReactNode;
  spacesWarning: ReactNode;
  action: ReactNode;
} {
  const { isCurrentUser, teamName, memberName, badSpaces } = input;

  if (isCurrentUser) {
    return {
      title: (
        <FormattedMessage
          defaultMessage="Leave {teamName}"
          values={{ teamName }}
        />
      ),
      simpleBody: (
        <FormattedMessage
          defaultMessage="Are you sure you want to leave {teamName}?"
          values={{ teamName }}
        />
      ),
      teamAdminWarning: (
        <FormattedMessage
          defaultMessage="You are the last admin, you need to select who will replace you before you can leave the team"
          id="Ed/IDY"
        />
      ),
      spacesWarning: (
        <FormattedMessage
          defaultMessage="You are the owner of some spaces that need to be re-assigned before you leave the team: {badSpaces}"
          values={{ badSpaces }}
        />
      ),
      action: <FormattedMessage defaultMessage="Leave" />,
    };
  }

  return {
    title: (
      <FormattedMessage
        defaultMessage="Remove {memberName}"
        values={{ memberName }}
      />
    ),
    simpleBody: (
      <FormattedMessage
        defaultMessage="Are you sure you want to remove {memberName} from your team?"
        values={{ memberName }}
      />
    ),
    teamAdminWarning: null,
    spacesWarning: (
      <FormattedMessage
        defaultMessage="{memberName} is the owner of some spaces that need to be re-assigned before removing them from the team: {badSpaces}"
        values={{ memberName, badSpaces }}
      />
    ),
    action: <FormattedMessage defaultMessage="Remove" id="G/yZLu" />,
  };
}
