import React, { ReactElement, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { useSelector } from 'react-redux';
import { selectLabels } from '../../../redux/selectors';
import { FormattedMessage } from 'react-intl';
import { ListTodo, Tag } from 'lucide-react';
import { FilterBox } from '../../../components/FilterBox';
import { Label } from '../../Common/Label';
import { LabelConfig } from '@tactiq/model';
import { minHeights, minWidths } from './minDimensions';

export function AddLabels(
  props: WorkflowNodeProps<{ labels: LabelConfig[] }>
): ReactElement {
  // Ensure `labels` from redux is an array, defaulting to an empty array if undefined
  const labels = useSelector(selectLabels) || [];
  const reactFlow = useReactFlow();
  const [selectedLabels, setSelectedLabels] = useState(props.data.labels);
  const options = labels.filter((label) => label && label.id && label.name); // Filter out any invalid labels
  const isExecution = Boolean(props.data.execution);

  return (
    <SingleSourceNode
      icon={
        <ListTodo className="size-8 rounded-lg border border-pink-500/10 bg-pink-50 p-1.5 text-pink-600" />
      }
      workflowNode={props}
      noResize
      minHeight={minHeights.AddLabels}
      minWidth={minWidths.AddLabels}
    >
      <div className="nodrag">
        <FilterBox
          buttonClasses="w-full !h-9 rounded-input hover:border-slate-400/50"
          label={<FormattedMessage defaultMessage="Labels" />}
          disabled={isExecution}
          icon={<Tag size=".85rem" />}
          options={options}
          full
          id={(x) => x.id}
          name={(x) => x.name}
          multiple
          value={selectedLabels}
          onChange={(selectedLabelsFromSelector) => {
            setSelectedLabels(selectedLabelsFromSelector);
            reactFlow.updateNodeData(props.id, {
              labels: selectedLabelsFromSelector,
            });
          }}
          renderOption={(item) => (
            <div className="flex min-w-0 items-center gap-2">
              <Label {...item} />
            </div>
          )}
        />
      </div>
    </SingleSourceNode>
  );
}
