import React from 'react';
import { ShareCardWrapper } from './ShareCardWrapper';
import { ExternalLink, HelpCircle, Maximize } from 'lucide-react';
import { Popover } from '../../../../components/Popover';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  trackScreenshotsInfoClose,
  trackScreenshotsInfoOpen,
} from '../../../../helpers/analytics';
import { Button } from '../../../../components/buttons';
import { useMeeting } from '../../common/meeting-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { createSelectTranscriptBlocksSelector } from '../../../../redux/selectors';
import { Tooltip } from '../../../../components/Tooltip';
import { Ref as TranscriptRef } from '../transcript/Transcript';

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const ScreenshotsCard: React.FC<{
  transcriptViewer: React.MutableRefObject<TranscriptRef | undefined>;
}> = ({ transcriptViewer }) => {
  const intl = useIntl();
  const meeting = useMeeting();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const selectTranscriptBlocks = React.useMemo(
    createSelectTranscriptBlocksSelector,
    []
  );
  const meetingScreenshots = useSelector((state: RootState) =>
    selectTranscriptBlocks(state, meeting?.id ?? '')
  ).filter(
    (block) => block.type === 'screenshot' && isValidUrl(block.transcript)
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popperId = anchorEl ? 'screenshotInfo' : undefined;

  if (!meeting) return null;

  return (
    <ShareCardWrapper
      title={intl.formatMessage({
        defaultMessage: 'Screenshots',
        id: '4w6TQ/',
      })}
      imageComponent={
        <Maximize strokeWidth={2} size={16} className="text-slate-600" />
      }
      belowContent={
        !meetingScreenshots.length ? null : (
          <div className="grid grid-cols-4 items-center gap-1 lg:grid-cols-3 ">
            {meetingScreenshots.map((screenshot, index) => {
              // @note screenshot.index is unreliable here, as it changes even if the screenshots haven't
              return (
                <Tooltip title={screenshot.fromStart} key={index} arrow>
                  <img
                    onClick={() => {
                      transcriptViewer?.current?.scrollTo(screenshot.messageId);
                    }}
                    className="max-h-20 hover:cursor-pointer "
                    src={screenshot.transcript}
                    alt={`Screenshot ${index + 1} from ${screenshot.fromStart}`}
                  />
                </Tooltip>
              );
            })}
          </div>
        )
      }
      actions={
        <Popover
          onOpenChange={(open) => {
            if (!open) {
              trackScreenshotsInfoClose();
            }
          }}
          variant="default"
          content={
            <div className="flex max-w-md flex-col gap-3 border-1 p-2">
              <p className="text-xs">
                <FormattedMessage defaultMessage="Any screenshots you have made during your meeting will be appear here. Screenshots can be captured in Google Meet and Zoom." />
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                className="flex flex-row justify-end gap-1 underline"
                href="https://help.tactiq.io/en/articles/5858522-how-to-take-a-screenshot-in-google-meet-and-zoom"
                onClick={() => {
                  trackScreenshotsInfoOpen();
                }}
              >
                <p className="text-xs">
                  <FormattedMessage defaultMessage="Learn how to take screenshots" />
                </p>
                <ExternalLink
                  strokeWidth={2}
                  size={18}
                  className="inline-block text-slate-600"
                />
              </a>
            </div>
          }
        >
          <Button
            aria-descrubedby={popperId}
            variant="icon"
            size="tiny"
            onClick={handleClick}
          >
            <HelpCircle strokeWidth={2} size={18} className="text-slate-600" />
          </Button>
        </Popover>
      }
    />
  );
};
