import React, { useState } from 'react';
import { ConditionBuilderOperator } from './ConditionBuilderOperator';
import { ConditionBuilderValue } from './ConditionBuilderValue';
import { Button } from '../buttons';
import { ConditionContext, exportConditions, id, ingestString } from './lib';

import { PlusIcon, X } from 'lucide-react';
import {
  Condition,
  ExportTypes,
  ConditionCollection,
  ConditionVariable,
} from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu } from '../Menu';

type ConditionBuilderProps = {
  disabled?: boolean;
  value: string;
  format?: ExportTypes;
  onChange: (value: string) => void;
  variables: ConditionVariable[];
};

export const ConditionBuilder = ({
  disabled = false,
  value,
  format = 'Liquid',
  onChange,
  variables,
}: ConditionBuilderProps) => {
  const intl = useIntl();

  const [conditionCollection, setConditionCollection] =
    useState<ConditionCollection>(new Map(ingestString(value, variables)));

  const onUpdate = (key: string, updatedValue: Partial<Condition>) => {
    setConditionCollection((prev) => {
      const newCollection: ConditionCollection = new Map(prev);
      const condition = { ...newCollection.get(key), ...updatedValue };
      newCollection.set(key, condition);
      onChange?.(exportConditions(newCollection, format));
      return newCollection;
    });
  };

  const onDelete = (key: string) => {
    setConditionCollection((prev) => {
      const newCollection: ConditionCollection = new Map(prev);
      newCollection.delete(key);
      onChange?.(exportConditions(newCollection, format));
      return newCollection;
    });
  };

  return (
    <section
      aria-label="condition-builder"
      className="nodrag flex flex-col gap-2"
    >
      {Array.from(conditionCollection).map(([key, condition], index) => (
        <div className="flex items-center gap-2" key={key}>
          <ConditionContext.Provider
            value={{ variables, key, condition, onUpdate }}
          >
            {index > 0 && (
              <span className="px-1 text-neutral-subtle text-xs">
                <FormattedMessage defaultMessage="and" />
              </span>
            )}
            <fieldset
              aria-label="condition"
              className="group flex flex-grow flex-row items-center rounded-input border border-slate-200 shadow-sm"
            >
              <div
                className="whitespace-nowrap pr-1 pl-2 font-medium text-xs"
                aria-label="condition-variable"
              >
                {condition.variable?.label}
              </div>
              <ConditionBuilderOperator />
              <ConditionBuilderValue />
              <Button
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Remove Condition',
                })}
                className="mr-1 ml-auto"
                onClick={() => onDelete(key)}
                size="tiny"
                variant="neutral-quaternary"
              >
                <X size={12} />
              </Button>
            </fieldset>
          </ConditionContext.Provider>
        </div>
      ))}
      {!disabled && (
        <Menu placement="bottom-end">
          <Menu.Trigger>
            <Button
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Add Condition',
              })}
              size="tiny"
              variant="neutral-tertiary"
              className="ml-auto"
              startIcon={<PlusIcon size="1rem" />}
            >
              <FormattedMessage defaultMessage="Add condition" />
            </Button>
          </Menu.Trigger>
          {variables.map(({ label, value, defaultOperator }) => (
            <Menu.Item
              key={value}
              onClick={() => {
                onUpdate(id(), {
                  variable: variables.find((v) => v.value === value),
                  operator: defaultOperator,
                });
              }}
            >
              {label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </section>
  );
};
