import React from 'react';
import {
  UserSettingsDataMoveStatus,
  UserSettingsSubscriptionMoveStatus,
} from '@tactiq/model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation } from '@apollo/client';
import {
  AcceptDataMoveDocument,
  AcceptSubscriptionMoveDocument,
} from '../../../graphql/operations';
import { Banner } from '../../../components/Banner';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import {
  trackDataMove,
  trackSubscriptionMove,
} from '../../../helpers/analytics';

enum UserMoveType {
  DATA = 'data',
  SUBSCRIPTION = 'subscription',
}

/**
 * DataOrSubscriptionMoveBanner
 * @param {string} location
 * @returns {React.ReactNode} Component
 */
export const DataOrSubscriptionMoveBanner: React.FC<{ location: string }> = ({
  location,
}) => {
  const userId = useSelector((state: RootState) => state.user.id);
  const name = useSelector((state: RootState) => state.user.displayName);

  const isAwaitingDataMoveApproval = useSelector(
    (state: RootState) =>
      state.user.settings.requestedDataMove?.status ===
      UserSettingsDataMoveStatus.PENDING
  );
  const isAwaitingSubscriptionMoveApproval = useSelector(
    (state: RootState) =>
      state.user.settings.requestedSubscriptionMove?.status ===
      UserSettingsSubscriptionMoveStatus.PENDING
  );

  const [acceptDataMove, acceptDataMoveMutation] = useMutation(
    AcceptDataMoveDocument
  );
  const [acceptSubscriptionMove, acceptSubscriptionMoveMutation] = useMutation(
    AcceptSubscriptionMoveDocument
  );

  let pendingMoveType = null;

  if (isAwaitingDataMoveApproval) {
    pendingMoveType = UserMoveType.DATA;
  } else if (isAwaitingSubscriptionMoveApproval) {
    pendingMoveType = UserMoveType.SUBSCRIPTION;
  }

  if (pendingMoveType === null) return null;

  return (
    <Banner>
      <p>
        <FormattedMessage
          defaultMessage="Hey {name}, your {moveType} move needs to be approved"
          id="VSdS+/"
          values={{
            name,
            moveType:
              pendingMoveType === UserMoveType.SUBSCRIPTION ? (
                <FormattedMessage defaultMessage="subscription" />
              ) : (
                <FormattedMessage defaultMessage="data" />
              ),
          }}
        />
      </p>
      <Button
        size="small"
        loading={
          pendingMoveType === UserMoveType.SUBSCRIPTION
            ? acceptSubscriptionMoveMutation.loading
            : acceptDataMoveMutation.loading
        }
        onClick={async () => {
          pendingMoveType === UserMoveType.SUBSCRIPTION
            ? await acceptSubscriptionMove()
            : await acceptDataMove();
          pendingMoveType === UserMoveType.SUBSCRIPTION
            ? trackSubscriptionMove(userId, location)
            : trackDataMove(userId, location);
        }}
      >
        <FormattedMessage defaultMessage="Approve" />
      </Button>
    </Banner>
  );
};
