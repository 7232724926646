import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/buttons';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../helpers/analytics';
import { upgradeToAnnualPlan } from '../../helpers/api/billing';
import { updateBillingProcessingStatus } from '../../redux/modules/global';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserPricing,
  selectUserTier,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { PreviewInvoice } from './PreviewInvoice';
import { TierPricingDialog } from './TierPricing/TierPricingDialog';

export const UpgradePlanAlert: React.FC = () => {
  const userId = useSelector(selectUid);
  const plan = useSelector(selectUserPlan);
  const userPricing = useSelector(selectUserPricing);
  const team = useSelector(selectTeam);

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);

  const isProcessingBilling = useSelector(
    (state: RootState) => state.global.operations.isProcessingBilling
  );
  const paidPlan = plan.paid;
  const { individual: individualPricing, team: teamPricing } = userPricing;
  const teamPlan = team?.plan;

  const dispatch = useDispatch();
  const intl = useIntl();
  const [successVisible, setSuccessVisible] = useState(false);
  const [previewInvoiceVisible, setPreviewInvoiceVisible] = useState(false);

  const pricing = useMemo(() => {
    if (
      individualPricing.currencies &&
      paidPlan?.__typename === 'StripePaidPlan'
    ) {
      const currency = individualPricing.currencies.find(
        (c) => c.currency === paidPlan.currency
      );

      if (currency) {
        return currency;
      }
    } else if (
      teamPricing.currencies &&
      paidPlan?.__typename === 'TeamPaidPlan' &&
      teamPlan
    ) {
      const currency = teamPricing.currencies.find(
        (c) => c.currency === teamPlan?.currency
      );

      if (currency) {
        return currency;
      }
    }

    return paidPlan?.__typename === 'TeamPaidPlan'
      ? teamPricing
      : individualPricing;
  }, [individualPricing, teamPricing, paidPlan, teamPlan]);

  if (successVisible) {
    return (
      <Alert
        severity="success"
        action={
          <Button
            onClick={() => {
              setSuccessVisible(false);
            }}
          >
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
        description={
          <FormattedMessage defaultMessage="You have been upgraded to the annual plan! Congratulations!" />
        }
      />
    );
  }

  let canUpgradeToAnnualPlan = false;

  switch (plan.paid?.__typename) {
    case 'StripePaidPlan':
      // supported
      canUpgradeToAnnualPlan = plan.paid.recurrenceInterval === 'month';
      break;
    case 'PaypalPaidPlan':
      // not supported right now
      return null;
    case 'TeamPaidPlan':
      if (teamPlan?.isCustomContract) {
        return null;
      } else if (team?.members.find((m) => m.uid === userId)?.roles?.ADMIN) {
        canUpgradeToAnnualPlan = teamPlan?.recurrenceInterval === 'month';
      } else {
        return null;
      }
      break;
    default:
      return null;
  }

  const discount = (
    100 *
    (1 - pricing.annualAmount / 12 / pricing.monthlyAmount)
  ).toFixed(0);

  return (
    <>
      {canUpgradeToAnnualPlan ? (
        <>
          <Alert
            severity="success"
            title={
              <FormattedMessage
                defaultMessage="Save {discount}% with an annual subscription!"
                values={{
                  discount,
                }}
              />
            }
            description={
              <p>
                <FormattedMessage
                  defaultMessage="Get Tactiq with <b>{discount}% discount</b>. {pricing}{teamSeat} on the annual plan."
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                    discount,
                    pricing: (
                      <strong>
                        {'currencySymbol' in pricing
                          ? pricing.currencySymbol
                          : pricing.currency}
                        {pricing.annualAmount}
                      </strong>
                    ),
                    teamSeat:
                      plan.paid?.__typename === 'TeamPaidPlan'
                        ? ' per seat'
                        : '',
                  }}
                />
              </p>
            }
            action={
              <Button
                color="primary"
                loading={isProcessingBilling}
                onClick={async () => {
                  if (!isProcessingBilling) {
                    trackWebEvent('Upgrade to Annual');
                    dispatch(updateBillingProcessingStatus(true));
                    try {
                      setPreviewInvoiceVisible(true);
                    } catch (e) {
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage:
                            'Payment failed. Please update your payment method using Manage Billing button below or reach out to support.',
                        }),
                        {
                          variant: 'ERROR',
                        }
                      );
                    } finally {
                      dispatch(updateBillingProcessingStatus(false));
                    }
                  }
                }}
              >
                <FormattedMessage
                  defaultMessage="Switch to annual billing now!"
                  id="KmdX/s"
                  description="Upgrade to annual billing button."
                />
              </Button>
            }
          />

          <PreviewInvoice
            open={previewInvoiceVisible}
            onClose={() => setPreviewInvoiceVisible(false)}
            invoiceProducer={(approved: boolean) =>
              upgradeToAnnualPlan(approved, {
                userId,
                plan: team?.isPaid ? team.id : userId,
              })
            }
            tag="upgrade-to-annual"
            description={
              <FormattedMessage defaultMessage="Upgrade to annual billing." />
            }
          />
        </>
      ) : null}

      {plan.paid?.__typename !== 'TeamPaidPlan' ? (
        <Alert
          severity="info"
          title={
            <FormattedMessage defaultMessage="🦄 Need more AI credits? ✨" />
          }
          description={
            <FormattedMessage
              defaultMessage="Upgrade to Tactiq Team and get {credits} AI credits
            every month."
              id="IY+t87"
              values={{
                credits: (
                  <b>
                    <FormattedMessage defaultMessage="unlimited" />
                  </b>
                ),
              }}
            />
          }
          action={
            <Button
              color="primary"
              onClick={() => setShowTierPricingDialog(true)}
            >
              <FormattedMessage
                defaultMessage="Upgrade to Team Plan"
                description="Upgrade to annual billing button."
              />
            </Button>
          }
        />
      ) : null}

      {showTierPricingDialog ? (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.BILLING}
          onClose={() => setShowTierPricingDialog(false)}
        />
      ) : null}
    </>
  );
};
